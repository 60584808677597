import React from 'react';
import { DropdownForm, NumericField } from '../../../../components';
import { useFieldProps } from '../../../../hooks';
import { quotaData } from '../../../../mocks';
import { roundNumber } from '../../../../utils';

export const Quota: React.FC<{ name: string }> = ({ name }) => {
  const {
    field: {
      value: { quantity },
    },
    setFieldValue,
  } = useFieldProps({ name: 'installments' });
  const {
    field: { value: budgetTotalAmountValue },
  } = useFieldProps({ name: 'budget_total_amount' });
  const {
    field: { value: initPayment },
  } = useFieldProps({ name });

  React.useMemo(() => {
    const result = quotaData.find((quota) => quota.id === quantity);
    const value = result?.value ?? 0;
    const calculatedValue =
      (budgetTotalAmountValue - initPayment.amount) / value;

    setFieldValue({
      quantity,
      value: roundNumber(calculatedValue),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, budgetTotalAmountValue, initPayment.amount]);

  return (
    <>
      <DropdownForm
        className="col-span-3"
        data={quotaData}
        label="Cuotas"
        name="installments.quantity"
        keyId="id"
        keyData="text"
      />
      <NumericField
        className="col-span-3"
        label="Valor de cada cuota"
        name="installments.value"
        disabled
      />
    </>
  );
};
