import React from 'react';
import {
  Subtitle,
  TextFieldForm,
  CheckboxForm,
  DatePicker,
  NumericField,
} from '../../../../../../components';
import { useFieldProps } from '../../../../../../hooks';

export const Billing: React.FC<{ useUsd: boolean }> = ({ useUsd }) => {
  const checkboxFieldName = 'bill_enabled';
  const billUserFieldName = 'bill_user';
  const billPassFieldName = 'bill_pass';
  const {
    field: { value: enabled },
  } = useFieldProps({ name: checkboxFieldName });
  const { setFieldValue: setBillUserValue } = useFieldProps({
    name: billUserFieldName,
  });
  const { setFieldValue: setBillPassValue } = useFieldProps({
    name: billPassFieldName,
  });

  React.useEffect(() => {
    if (!enabled) {
      setBillUserValue('');
      setBillPassValue('');
    }
  }, [enabled, setBillPassValue, setBillUserValue]);

  return (
    <>
      <Subtitle>Datos facturación</Subtitle>
      <div className="grid grid-cols-3 gap-10 mb-5">
        <CheckboxForm
          name={checkboxFieldName}
          label="Habilitar facturación"
          description="Define a esta sucursal como punto de venta."
          showError={false}
        />
        <TextFieldForm name="bill_user" label="Usuario *" disabled={!enabled} />
        <TextFieldForm
          name="bill_pass"
          label="Contraseña *"
          disabled={!enabled}
        />
      </div>
      <div className="grid grid-cols-3 gap-10">
        <TextFieldForm
          name="bill_point"
          label="Punto de venta"
          disabled={!enabled}
        />
        <NumericField
          name="bill_balance"
          label="Saldo de caja"
          disabled={!enabled}
        />
        {useUsd && (
          <NumericField
            name="cash_balance_usd"
            label="Saldo de caja U$D"
            disabled={!enabled}
          />
        )}
        <DatePicker
          name="cash_closure"
          label="Último cierre de caja"
          disabled={!enabled}
        />
      </div>
    </>
  );
};
