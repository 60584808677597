import React from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { daily, weekly, reduced } from './Columns';
import { Daily, Weekly } from './Data';
import {
  tableScheduleMapper,
  getWeeklySchedules,
  getProfessionalsSchedules,
} from '../../../utils';
import { useFieldProps } from '../../../hooks';
import { AppointmentT, TranslationsT } from '#interfaces/general-values';
import { CountriesT } from '#interfaces/components/commons';

export const Table: React.FC<{
  data: AppointmentT[];
  actions: any;
  translations: TranslationsT;
  permissions: any;
  layout: string;
  setLayout: React.Dispatch<React.SetStateAction<string>>;
  config: {
    region: string;
    countries: CountriesT[];
  };
}> = ({
  data,
  actions,
  translations,
  permissions,
  layout,
  setLayout,
  config,
}) => {
  const dispatch = useDispatch();
  const [columnVisibility, setColumnVisibility] = React.useState({});
  const {
    field: { value },
  } = useFieldProps({ name: 'layout' });

  const makeTableData = (type: string) => {
    const res: { [key: string]: any } = {
      buscarpordiatodosprof: getProfessionalsSchedules(data),
      buscarsemana: getWeeklySchedules(data),
      buscar: tableScheduleMapper(data),
    };

    return res[type];
  };

  const weeklyCss = classNames(
    makeTableData(value).length === 1 && 'flex w-full',
    makeTableData(value).length === 2 && `grid grid-cols-2 gap-x-4`,
    makeTableData(value).length === 3 && `grid grid-cols-3 gap-x-4`,
    makeTableData(value).length >= 4 &&
      'flex flex-nowrap w-full overflow-hidden overflow-x-scroll gap-x-4',
  );
  const weeklyDivCss = classNames(
    makeTableData(value).length === 1 && 'w-full',
  );

  const Table = () => {
    const Comp: { [key: string]: any } = {
      buscarpordiatodosprof: (
        <div className={weeklyCss}>
          {makeTableData(value).map((prof: any, i: number) => (
            <div className={weeklyDivCss} key={i}>
              <p className="text-center pb-2 text-base">{prof.name}</p>
              <Weekly
                key={i}
                columns={weekly({
                  actions,
                  dispatch,
                  translations,
                  permissions,
                  config,
                })}
                data={prof.schedule}
              />
            </div>
          ))}
        </div>
      ),
      buscarsemana: (
        <div className={weeklyCss}>
          {makeTableData(value).map((day: any, i: number) => (
            <div className={weeklyDivCss} key={i}>
              <p className="text-center pb-2 text-base">
                {day.day} <span className="font-bold">{day.date}</span>
              </p>
              <Weekly
                key={i}
                columns={weekly({
                  actions,
                  dispatch,
                  translations,
                  permissions,
                  config,
                })}
                data={day.schedule}
              />
            </div>
          ))}
        </div>
      ),
      buscar: (
        <Tabs.Root
          defaultValue={layout}
          onValueChange={(layout) => setLayout(layout)}
        >
          <Tabs.Content value="expanded">
            <Daily
              columns={daily({
                actions,
                dispatch,
                translations,
                permissions,
                config,
              })}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              data={makeTableData(value)}
            />
          </Tabs.Content>
          <Tabs.Content value="compact">
            <Daily
              columns={reduced({
                actions,
                dispatch,
                translations,
                permissions,
                config,
              })}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
              data={makeTableData(value)}
            />
          </Tabs.Content>
        </Tabs.Root>
      ),
    };

    return Comp[value];
  };

  return (
    <div className="mx-auto w-full py-10">
      <Table />
    </div>
  );
};
