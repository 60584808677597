import React from 'react';
import { CheckboxForm, DropdownForm } from '../../../../../../components';
import { useFieldProps } from '../../../../../../hooks';
import { BenefitCheckBoxT } from '#interfaces/components';

export const BenefitCheckbox: React.FC<BenefitCheckBoxT> = ({
  checkboxName,
  label,
  dropdownName,
  dropdownData,
  dropdownKeyId,
  dropdownKeyData,
  placeholder,
}) => {
  const {
    field: { value },
  } = useFieldProps({ name: checkboxName });
  const { setFieldValue } = useFieldProps({
    name: dropdownName,
  });

  React.useMemo(() => {
    return value && setFieldValue(null);
  }, [value]);

  return (
    <div className="grid grid-cols-3 gap-4 items-end mb-4">
      <div className="col-span-2">
        <DropdownForm
          name={dropdownName}
          label={placeholder}
          disabled={value}
          data={dropdownData}
          keyId={dropdownKeyId}
          keyData={dropdownKeyData}
        />
      </div>
      <CheckboxForm name={checkboxName} small label={label} showError={false} />
    </div>
  );
};
