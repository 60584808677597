import React, { useMemo } from 'react';
import { Icons, TextField } from '../../commons';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '#components/shadcn';
import { FilterT, VisibilityT } from '#interfaces/tables/benefit';

export function Filter<TData>({ table, name }: FilterT<TData>) {
  const column = useMemo(() => table.getColumn(name), [table, name]);
  return (
    <TextField
      className="w-54 h-10"
      placeholder="Buscar historia clínica..."
      value={column?.getFilterValue() ?? ''}
      onChange={(value) => column?.setFilterValue(value)}
    />
  );
}

export function Visibility<TData>({ table }: VisibilityT<TData>) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <p
          className="h-10 inline-flex items-center justify-between gap-x-1.5 rounded-md bg-white px-4 py-2 text-sm font-semibold text-slate-600 shadow-sm border hover:bg-gray-50 hover:cursor-pointer"
          id="schedule-view-visibility"
        >
          <Icons type="settings" className="w-5 h-5" />
          Columnas
        </p>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {table
          .getAllColumns()
          .filter((column) => column.getCanHide())
          .map((column: any) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value: any) =>
                  column.toggleVisibility(!!value)
                }
              >
                {column.columnDef.header}
              </DropdownMenuCheckboxItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
