import { createSlice } from '@reduxjs/toolkit';

const initialSteps: { id: string; name: string; status: string }[] = [
  {
    id: '01',
    name: 'Datos del pedido',
    status: 'current',
  },
  {
    id: '02',
    name: 'Lista de trabajos',
    status: 'upcoming',
  },
  {
    id: '03',
    name: 'Resumen del pedido a laboratorio',
    status: 'upcoming',
  },
];

const initialState = {
  currentPosition: 0,
  lastPosition: 0,
  stepsBar: initialSteps,
  concepts: [],
  selectedOrder: null,
};

export const orderLaboratorySlice = createSlice({
  name: 'orderLaboratory',
  initialState,
  reducers: {
    setConceptsLabs(state, action) {
      state.concepts = action.payload;
    },
    setTabIndexLab(state, action) {
      state.currentPosition = action.payload;
    },
    setStepsBarLab(state, action) {
      state.stepsBar = action.payload;
    },
    setSelectedOrder(state, action) {
      state.selectedOrder = action.payload;
    },
    goToNextStepLab(state) {
      state.lastPosition = state.currentPosition;
      state.currentPosition = state.currentPosition + 1;
      state.stepsBar[state.lastPosition].status = 'complete';
      state.stepsBar[state.currentPosition].status = 'current';
    },
    updateProgressBarLab(state, action) {
      const position = action.payload;

      if (state.currentPosition === position) return;

      state.lastPosition = state.currentPosition;
      state.currentPosition = position;
      state.stepsBar[position].status = 'current';
      state.stepsBar[state.lastPosition].status = 'complete';
    },
    completeProgressBarLab(state) {
      state.lastPosition = state.currentPosition;
      state.currentPosition = 2;
      state.stepsBar.forEach((step) => {
        step.status = 'complete';
      });
      state.stepsBar[state.stepsBar.length - 1].status = 'current';
    },
    resetProgressBarLab(state) {
      state.currentPosition = 0;
      state.stepsBar = initialSteps;
    },
  },
});

export const {
  setTabIndexLab,
  setStepsBarLab,
  goToNextStepLab,
  updateProgressBarLab,
  resetProgressBarLab,
  completeProgressBarLab,
  setConceptsLabs,
  setSelectedOrder,
} = orderLaboratorySlice.actions;

export default orderLaboratorySlice.reducer;
