import React from 'react';
import { Dropdown } from '../../../../components';

export const OdontogramDropdown: React.FC<{
  onChange: (value: any) => void;
  currentLayout: any;
}> = ({ onChange, currentLayout }) => {
  const odontogramaType = [
    {
      id: 'pri',
      text: 'Primario',
    },
    {
      id: 'per',
      text: 'Permanente',
    },
  ];

  const onChangeHandler = (value: any) => {
    onChange(value);
  };

  return (
    <Dropdown
      name="odontogram_type"
      data={odontogramaType}
      label="Tipo de odontograma"
      keyId="id"
      keyData="text"
      currentValue={currentLayout}
      onChange={onChangeHandler}
    />
  );
};
