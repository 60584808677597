import * as React from 'react';
import { ColumnsT } from '#interfaces/tables/benefit';
import classNames from 'classnames';
import { Icons, Badge } from '../../commons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../shadcn';

export const Columns = ({ translations, action, config }: ColumnsT) => {
  const {
    permissions: { permissions, isSupervisor },
    useUsd,
  } = config;

  const rows = () => {
    const rowsArray: any = [
      {
        id: 'actions',
        enableHiding: false,
        cell: ({ row }: { row: any }) => {
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className="relative p-2 hover:cursor-pointer flex-none">
                  <Icons
                    type="context"
                    className="text-gray-500 hover:text-gray-900 h-4 w-4"
                  />
                  <span className="sr-only">Abrir menu</span>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56" align="start">
                <DropdownMenuGroup>
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    onSelect={() => action(row.original.id, 'edit')}
                  >
                    <Icons type="edit" className="mr-2 h-4 w-4" />
                    <span>Modificar prestación</span>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="hover:cursor-pointer text-red-500"
                    onSelect={() => action(row.original.id, 'delete')}
                  >
                    <Icons type="delete" className="mr-2 h-4 w-4" />
                    <span>Eliminar prestación</span>
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
      {
        accessorKey: 'date',
        header: 'Fecha',
        cell: ({ row }: { row: any }) => {
          const { date } = row.original;

          return (
            <p className="font-bold text-sm leading-6 text-gray-500 my-1">
              {date}
            </p>
          );
        },
      },
      {
        accessorKey: 'professional',
        header: 'Profesional',
        cell: ({ row }: { row: any }) => {
          const { professional } = row.original;

          return (
            <p className="text-base font-semibold leading-6 text-slate-800 my-1">
              {professional}
            </p>
          );
        },
      },
      {
        accessorKey: 'benefit_code',
        header: 'Código prestación',
        cell: ({ row }: { row: any }) => {
          const { benefit_code, medical_insurance_code } = row.original;

          return (
            <div>
              <p className="font-bold text-sm leading-6 text-gray-500">
                Cod. Interno:{' '}
                <span className="font-bold text-sm text-slate-800">
                  {benefit_code}
                </span>
              </p>
              <p className="font-bold text-sm leading-6 text-gray-500">
                {translations?.cod_mi + ' '}
                <span className="font-bold text-sm text-slate-800">
                  {medical_insurance_code}
                </span>
              </p>
            </div>
          );
        },
      },
      {
        accessorKey: 'description',
        header: 'Descripción',
        cell: ({ row }: { row: any }) => {
          const { description, state } = row.original;

          return (
            <>
              {state.status && (
                <Badge
                  className={classNames('w-auto mt-2 mb-1', state.style)}
                  text={state.status}
                />
              )}
              <p className="font-bold text-sm leading-6 text-gray-500 my-1 hover:cursor-pointer">
                {description}
              </p>
            </>
          );
        },
      },
      {
        accessorKey: 'medical_insurance',
        header: translations?.medical_insurances,
        cell: ({ row }: { row: any }) => {
          const { medical_insurance, medical_insurance_plan } = row.original;

          return (
            <div>
              <p className="font-bold text-base leading-6 text-slate-800 my-1">
                {medical_insurance}
              </p>
              <p className="font-bold text-sm leading-6 text-gray-500">
                Plan: {medical_insurance_plan}
              </p>
            </div>
          );
        },
      },
      {
        accessorKey: 'thoot',
        header: 'Diente',
        cell: ({ row }: { row: any }) => {
          const { thoot, thoot_face } = row.original;

          return (
            <div>
              {thoot && (
                <p className="font-bold text-sm leading-6 text-gray-500">
                  Pieza:{' '}
                  <span className="font-bold text-sm text-slate-800">
                    {thoot}
                  </span>
                </p>
              )}
              {thoot_face && (
                <p className="font-bold text-sm leading-6 text-gray-500">
                  Cara:{' '}
                  <span className="font-bold text-sm text-slate-800">
                    {thoot_face}
                  </span>
                </p>
              )}
            </div>
          );
        },
      },
    ];
    if (permissions.hasOwnProperty('Ver Importes Prest.') || isSupervisor) {
      rowsArray.splice(8, 0, {
        accessorKey: 'amouht_medical_insurance',
        header: `Importe ${translations?.medical_insurance_abbreviation}`,
        cell: ({ row }: { row: any }) => {
          const { amouht_medical_insurance } = row.original;

          return (
            <p className="text-base font-semibold leading-6 text-slate-800 my-1 hover:cursor-pointer">
              {amouht_medical_insurance}
            </p>
          );
        },
      });
    }
    if (permissions.hasOwnProperty('Ver Importes Prest.') || isSupervisor) {
      rowsArray.splice(9, 0, {
        accessorKey: 'amount_patient',
        header: 'Importe paciente',
        cell: ({ row }: { row: any }) => {
          const { amount_patient } = row.original;

          return (
            <p className="text-base font-semibold leading-6 text-slate-800 my-1 hover:cursor-pointer">
              {amount_patient}
            </p>
          );
        },
      });
    }
    if (useUsd.params) {
      rowsArray.splice(7, 0, {
        accessorKey: 'is_usd',
        header: 'En USD',
        cell: ({ row }: { row: any }) => {
          const { is_usd } = row.original;

          return (
            <Icons
              type={is_usd ? 'check_clean' : 'cancel'}
              className={classNames(
                'w-5 h-5 mx-auto',
                is_usd ? 'text-green-500' : 'text-slate-500',
              )}
            />
          );
        },
        filterFn: (row: any, id: string, value: any) =>
          value.includes(row.getValue(id)),
      });
    }
    return rowsArray;
  };
  return rows();
};
