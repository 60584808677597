import React from 'react';
import classNames from 'classnames';
import { StatsT } from '#interfaces/components';

export const Stats: React.FC<StatsT> = ({ data }) => {
  const containerCss = classNames(
    'mt-5 grid divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:divide-y-0 md:divide-x',
    `grid-cols-${data.length}`,
  );

  return (
    <div>
      <dl className={containerCss}>
        {data.map((item: any) => {
          return (
            <div key={item.name} className="px-4 py-5 sm:p-6">
              <dt className="text-base font-normal text-gray-900">
                {item.name}
              </dt>
              <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-blue-600">
                  {item.stat}
                </div>
              </dd>
            </div>
          );
        })}
      </dl>
    </div>
  );
};
