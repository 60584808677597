import * as React from 'react';
import classNames from 'classnames';
import { ColumnsT } from '#interfaces/tables/benefit';
import { Icons, Badge } from '#components/commons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '#components/shadcn';

export const Columns = ({ action, config }: ColumnsT) => {
  const { permissions, isSupervisor, translations } = config;
  const columns = [
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        const { id_budget } = row.original;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="relative p-2 hover:cursor-pointer flex-none">
                <Icons
                  type="context"
                  className="text-gray-500 hover:text-gray-900 h-4 w-4"
                />
                <span className="sr-only">Abrir menu</span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start">
              <DropdownMenuGroup>
                <DropdownMenuItem
                  className="hover:cursor-pointer"
                  onSelect={() => action(id_budget, 'edit')}
                >
                  <Icons type="edit" className="mr-2 h-4 w-4" />
                  <span>Ver detalles</span>
                </DropdownMenuItem>
                {(isSupervisor ||
                  permissions.hasOwnProperty('Ver Pagos Ppto.')) && (
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    onSelect={() => action(id_budget, 'payments')}
                  >
                    <Icons type="eye" className="mr-2 h-4 w-4" />
                    <span>Ver pagos</span>
                  </DropdownMenuItem>
                )}
                {(isSupervisor ||
                  permissions.hasOwnProperty('Eliminar Presupuesto')) && (
                  <DropdownMenuItem
                    className="hover:cursor-pointer text-red-500"
                    onSelect={() => action(id_budget, 'delete')}
                  >
                    <Icons type="delete" className="mr-2 h-4 w-4" />
                    <span>Eliminar presupuesto</span>
                  </DropdownMenuItem>
                )}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: 'id_budget',
      header: 'N° y estado',
      cell: ({ row }: { row: any }) => {
        const { id_budget, status } = row.original;

        return (
          <div className="flex gap-x-2">
            <Badge
              className="w-auto bg-blue-50 text-blue-600 border-blue-600/10"
              text={`N°: ${id_budget}`}
            />
            <Badge
              className={classNames('w-auto', status.style)}
              text={status.text}
            />
          </div>
        );
      },
    },
    {
      accessorKey: 'date',
      header: 'Fecha',
      cell: ({ row }: { row: any }) => {
        const { date } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {date}
          </p>
        );
      },
    },
    {
      accessorKey: 'professional',
      header: 'Profesional',
      cell: ({ row }: { row: any }) => {
        const { professional } = row.original;

        return (
          <p className="text-base font-semibold leading-6 text-slate-800 my-1">
            {professional}
          </p>
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Descripción',
      cell: ({ row }: { row: any }) => {
        const { description } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {description}
          </p>
        );
      },
    },
    {
      accessorKey: 'medical_insurance',
      header: translations.medical_insurance,
      cell: ({ row }: { row: any }) => {
        const { medical_insurance } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {medical_insurance}
          </p>
        );
      },
    },
    {
      accessorKey: 'is_usd',
      header: 'En USD',
      cell: ({ row }: { row: any }) => {
        const { is_usd } = row.original;

        return (
          <Icons
            type={is_usd ? 'check_clean' : 'cancel'}
            className={classNames(
              'w-5 h-5 mx-auto',
              is_usd ? 'text-green-500' : 'text-slate-500',
            )}
          />
        );
      },
      filterFn: (row: any, id: string, value: any) =>
        value.includes(row.getValue(id)),
    },
    {
      accessorKey: 'total',
      header: 'Importe total',
      cell: ({ row }: { row: any }) => {
        const { total } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{total}</p>
        );
      },
    },
    {
      accessorKey: 'balance',
      header: 'Saldo',
      cell: ({ row }: { row: any }) => {
        const { balance } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{balance}</p>
        );
      },
    },
  ];
  !config.useUsd.params && columns.splice(6, 1);

  return columns;
};
