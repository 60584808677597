import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { CheckboxForm, TextFieldForm, Form } from '#components/index';
import { PlanFormT } from '#interfaces/components';

export const PlanForm: React.FC<PlanFormT> = ({
  plan,
  selectedMed,
  onChange,
  plans,
}) => {
  const { initialValues, validate } = useForm(plan, selectedMed);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={useSubmit({ plan, selectedMed, onChange, plans })}
      enableReinitialize
    >
      <Form ignoreDirty>
        <div className="flex gap-x-5 items-end mt-2 mb-10">
          <TextFieldForm name="code" label="Código" />
          <TextFieldForm name="name" label="Nombre" />
          <CheckboxForm
            name="enabled"
            small
            label="Plan habilitado"
            description="Habilita o deshabilita el plan."
            showError={false}
          />
        </div>
      </Form>
    </Formik>
  );
};
