import React from 'react';
import { getDefaultPeriod } from '../../../../../utils';
import { BenefitT, OfficesT, ProfessionalT } from '#interfaces/general-values';

export function useForm(
  benefitTypes: BenefitT[],
  offices: OfficesT[],
  professionals: ProfessionalT[],
  tooth: any,
) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues(offices, professionals, tooth),
  );

  React.useEffect(() => {
    setInitialValues(getInitialValues(offices, professionals, tooth));
  }, [offices, professionals, tooth]);

  const validate = React.useCallback(
    (values: any) => {
      const errors: { [key: string]: string } = {};
      const requiredMsg = 'Campo requerido';
      const requiredFields = ['benefit_type', 'professional', 'date'];
      requiredFields.forEach((requiredField) => {
        if (!values[requiredField]) {
          errors[requiredField] = requiredMsg;
        }
      });

      if (offices?.length) {
        requiredFields.push('office');
      }

      if (values.benefit_type) {
        const benefitType = benefitTypes.find(
          (el) => el.id_prestacion === values.benefit_type,
        );

        if (['DIE', 'ALG'].includes(benefitType?.diente || '')) {
          requiredFields.push('tooth_section');
          if (!values.tooth_section) {
            errors.tooth_section = 'Debes especificar pieza o sector';
          }
        }

        if (benefitType?.pide_cara) {
          requiredFields.push('tooth_faces');
          const checkedToothFacesAmount =
            values.tooth_faces &&
            Object.values(values.tooth_faces).filter(Boolean).length;
          if (checkedToothFacesAmount < 1) {
            errors.tooth_faces =
              'Debes seleccionar entre un mínimo de una y un máximo de cinco caras';
          }
          if (checkedToothFacesAmount > 5) {
            errors.tooth_faces =
              'La prestación seleccionada no puede tener más de 5 caras';
          }
        }
      }

      return errors;
    },
    [benefitTypes, offices?.length],
  );

  return { initialValues, validate };
}

function getInitialValues(
  offices: OfficesT[],
  professionals: ProfessionalT[],
  tooth: any,
) {
  const office = offices?.length > 0 ? offices[0] : null;
  const getProfessional = () => {
    if (offices?.length) {
      return professionals.find(
        (prof) =>
          prof.prof_horarios.filter((ph: any) => {
            return ph.id_sucursal === office?.id_sucursal;
          }).length > 0,
      );
    }

    return professionals.filter((p) => p.habilitado === true)[0];
  };

  const professional = getProfessional();

  return {
    date: new Date(),
    office: office?.id_sucursal || null,
    professional: professional?.id_prof,
    period: getDefaultPeriod(),
    tooth_faces: {},
    tooth_section: tooth,
  };
}
