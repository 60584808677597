import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { CheckboxForm, Form } from '#components/index';
import { ProfessionalDropdown } from '#components/forms/Benefits/BenefitsForm/professional-dropdown';
import { DaysOfWeek } from './days-of-week';
import { FindNextAvailableAppointment } from './FindNextAvailableAppointment';
import { GoToToday } from './go-to-day';
import { TurnReminder } from './turn-reminder';
import { OfficesDropdown } from './ofiices-dropdown';
import { SpecialtyDropdown } from './specialty-dropdown';
import { LayoutDropdown } from './layout-dropdown';
import {
  FormDatePicker as DatePicker,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '#components/shadcn';
import { SearchFormT } from '#interfaces/components';

export const SearchForm: React.FC<SearchFormT> = ({
  children,
  offices,
  professionals,
  specialties,
  schedule,
  setAppointment,
  openFirstTimeAppointmentForm,
  openCreateAppointmentForm,
  onChangeRoute,
  permissions: { weeklyView, validationPlan },
  forceRefresh,
  findNextAvailableAppointment,
  current,
}) => {
  const { initialValues } = useForm({
    offices,
    professionals,
    current,
  });
  const dateFieldName = 'date';
  const officeFieldName = 'office';
  const professionalFieldName = 'professional';
  const filterProfessionalByDayFieldName = 'filter_professional_by_day';
  const dayOfWeekFieldName = 'day_of_week';
  const specialtyFieldName = 'specialty';
  const layoutFieldName = 'layout';

  const layouts = [
    { id: 'buscar', description: 'Diaria' },
    !validationPlan && {
      id: 'buscarpordiatodosprof',
      description: 'Por profesionales',
    },
    weeklyView && { id: 'buscarsemana', description: 'Semanal' },
  ].filter(Boolean);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={useSubmit({
        forceRefresh,
        findNextAvailableAppointment,
        current,
      })}
    >
      <Form
        className="mx-auto w-full px-10 pt-10"
        showActions={false}
        autoSubmit
      >
        <div className="grid grid-cols-12 gap-5">
          <Card className="col-span-6" id="schedule-view-days">
            <CardHeader className="flex !flex-row justify-between items-center">
              <div className="space-y-1">
                <CardTitle className="text-base">Selección de fechas</CardTitle>
                <CardDescription>
                  Seleccioná el día que quieres ver y el tipo de agenda.
                </CardDescription>
              </div>
              <TurnReminder onClick={() => onChangeRoute('/reminders')} />
            </CardHeader>
            <CardContent className="grid gap-6">
              <div className="flex items-end justify-evenly space-x-4">
                <DatePicker name={dateFieldName} className="h-[38px] text-sm" />
                <LayoutDropdown name={layoutFieldName} layouts={layouts} />
                <GoToToday
                  name={dateFieldName}
                  layoutFieldName={layoutFieldName}
                />
              </div>
              <DaysOfWeek
                name={dayOfWeekFieldName}
                dateFieldName={dateFieldName}
                officeFieldName={officeFieldName}
                professionalFieldName={professionalFieldName}
                specialtyFieldName={specialtyFieldName}
                professionals={professionals}
              />
            </CardContent>
          </Card>
          <Card className="col-span-6" id="schedule-view-professionals">
            <CardHeader className="flex !flex-row justify-between items-center">
              <div className="space-y-1">
                <CardTitle className="text-base">
                  Selección de profesional
                </CardTitle>
                <CardDescription>
                  Seleccioná el profesional y la especialidad que quieres ver.
                </CardDescription>
              </div>
              <FindNextAvailableAppointment
                dateFieldName={dateFieldName}
                professionalFieldName={professionalFieldName}
                schedule={schedule}
                setAppointment={setAppointment}
                openFirstTimeAppointmentForm={openFirstTimeAppointmentForm}
                openCreateAppointmentForm={openCreateAppointmentForm}
                specialtyFieldName={specialtyFieldName}
                filterProfessionalByDayFieldName={
                  filterProfessionalByDayFieldName
                }
                officeFieldName={officeFieldName}
                offices={offices}
              />
            </CardHeader>
            <CardContent className="grid grid-cols-12 gap-4">
              <div className="col-span-6">
                <ProfessionalDropdown
                  name={professionalFieldName}
                  professionals={professionals}
                  officeFieldName={officeFieldName}
                  dayOfWeekFieldName={dayOfWeekFieldName}
                  specialtyFieldName={specialtyFieldName}
                  dateFieldName={dateFieldName}
                  ignoreDateRange
                  filterProfessionalByDayFieldName={
                    filterProfessionalByDayFieldName
                  }
                />
              </div>
              <div className="col-span-6">
                {specialties && (
                  <SpecialtyDropdown
                    name={specialtyFieldName}
                    specialties={specialties}
                  />
                )}
              </div>
              <div className="col-span-6">
                <CheckboxForm
                  label="Filtrar profesional por día"
                  description="Muestra los habilitados el día elegido."
                  name={filterProfessionalByDayFieldName}
                  showError={false}
                />
              </div>
              {offices && offices.length > 0 && (
                <div className="col-span-6">
                  <OfficesDropdown name={officeFieldName} offices={offices} />
                </div>
              )}
            </CardContent>
          </Card>
        </div>
        {children}
      </Form>
    </Formik>
  );
};
