import { BenefitT, BudgetT, PatientT } from '#interfaces/general-values';
import { axiosInstance, getStatisticsPeriod, platform } from '../utils';
import { PaymentsT } from '#interfaces/components/forms/payments';

export function getNewPatient(from?: Date, to?: Date) {
  const [defaultFrom, defaultTo] = getStatisticsPeriod();
  const body = {
    estadisticas: {
      opcionEstadis: 'PacNuevos',
      agrupado_por: 'dia',
      fecha_desde: from ?? defaultFrom,
      fecha_hasta: to ?? defaultTo,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/auestadisticas', body);
}

export function getAttendans(from?: Date, to?: Date) {
  const [defaultFrom, defaultTo] = getStatisticsPeriod();
  const body = {
    estadisticas: {
      opcionEstadis: 'PacAtendidos',
      agrupado_por: 'dia',
      fecha_desde: from ?? defaultFrom,
      fecha_hasta: to ?? defaultTo,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/auestadisticas', body);
}

export function getScheduled(from?: Date, to?: Date) {
  const [defaultFrom, defaultTo] = getStatisticsPeriod();
  const body = {
    estadisticas: {
      opcionEstadis: 'PacAgendados',
      agrupado_por: 'dia',
      fecha_desde: from ?? defaultFrom,
      fecha_hasta: to ?? defaultTo,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/auestadisticas', body);
}

export function getDaily() {
  const [t] = new Date().toISOString().split('T');
  const body = {
    estadisticas: {
      opcionEstadis: 'Diarias',
      agrupado_por: 'dia',
      fecha_desde: t,
      fecha_hasta: t,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/auestadisticas', body);
}

export const getNewPatients = (data: PatientT) => {
  const body = {
    Statistics: {
      ...data,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/statistics/patients/new', body);
};

export const getPatientAttended = (data: PatientT) => {
  const body = {
    Statistics: {
      ...data,
    },
    plataforma: platform,
  };

  return axiosInstance.post('statistics/patients/attended', body);
};

export const getPatientScheduled = (data: PatientT) => {
  const body = {
    Statistics: {
      ...data,
    },
    plataforma: platform,
  };

  return axiosInstance.post('statistics/patients/scheduled', body);
};

export const getPaymentsEntered = (data: PaymentsT) => {
  const body = {
    Statistics: {
      ...data,
    },
    plataforma: platform,
  };

  return axiosInstance.post('statistics/payments/entered', body);
};

export const getBenefitAmounts = (data: BenefitT) => {
  const body = {
    Statistics: {
      ...data,
    },
    plataforma: platform,
  };

  return axiosInstance.post('statistics/benefits/amount', body);
};
export const getBudgets = (data: BudgetT) => {
  const body = {
    Statistics: {
      ...data,
    },
    plataforma: platform,
  };
  return axiosInstance.post('/statistics/budgets', body);
};

export const getBenefits = (data: BenefitT) => {
  const body = {
    Statistics: {
      ...data,
    },
    plataforma: platform,
  };
  return axiosInstance.post('/statistics/benefits', body);
};

export const getFinances = (data: {
  DateFrom: Date | string;
  DateTo: Date | string;
  ComparationDateFrom: Date | string;
  ComparationDateTo: Date | string;
  ProfessionalId: number;
  MedicalInsuranceId: number;
  OfficeId: number;
  CalculateInUSD: boolean;
}) => {
  const body = {
    Statistics: {
      ...data,
    },
    plataforma: platform,
  };
  return axiosInstance.post('/statistics/finance', body);
};
