import { useState, useEffect, useCallback } from 'react';

export function useForm(office: { [key: string]: any }) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues(office),
  );

  useEffect(() => {
    setInitialValues(getInitialValues(office));
  }, [office]);

  const validate = useCallback((values: { [key: string]: any }) => {
    const errors: { [key: string]: any } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = [];

    if (values.enabled) {
      requiredFields.push('office', 'description');
    }

    if (values.bill_enabled) {
      requiredFields.push('bill_user', 'bill_pass');
    }

    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues(office: { [key: string]: any }) {
  const { rowversion } = office;

  return {
    id: office?.id || 0,
    id_new_office: 0,
    enabled: office?.id ? office.enabled : true,
    office: office?.office || '',
    description: office?.description || '',
    cash_balance: office?.cash_balance || null,
    cash_balance_usd: office?.cash_balance_usd || null,
    cash_closure: office?.cash_closure || new Date(),
    bill_enabled: office?.bill_user ? true : false,
    bill_company: office?.bill_company || null,
    bill_user: office?.bill_user || null,
    bill_pass: office?.bill_pass || null,
    bill_point: office?.bill_point || '',
    option: office?.option || null,
    users_all: true,
    users_one: false,
    rowversion,
  };
}
