import React from 'react';
import { Formik } from 'formik';
import classNames from 'classnames';
import {
  CheckboxForm,
  DropdownForm,
  TextFieldForm,
  Form,
  SpecialtyDropdown,
  Modal,
  NumericField,
  Tooltip,
  Icons,
} from '#components/index';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
  CardFooter,
  Button,
} from '#components/shadcn';
import { DataFormT } from '#interfaces/components';
import { ProfessionalT } from '#interfaces/general-values';
import { Signature } from './signature';
import { DeleteProfForm } from './DeleteProf';
import { SpeciatlyCheckbox } from './specialty-checkbox';
import { useForm } from './use-form';
import { useSubmit } from './use-submit';
import { useForm as useFormHook, useSetHeight } from '../../../../../hooks';
import { TaxCategory, Prefix, typesRegistrations } from '../../../../../mocks';

const DataForm: React.FC<DataFormT> = ({
  professionals,
  selectedProf,
  specialties,
  refTarget,
  newProfHandler,
  focusElement,
  onChange,
  modifying,
  modifyingHandler,
  translations,
  newProf,
  planVersion,
  openModalSuscribe,
  provinces,
  region,
}) => {
  const current: ProfessionalT | undefined = React.useMemo(() => {
    if (selectedProf && !newProf) {
      const foundProf = professionals?.find(
        (p: { [key: string]: any }) => p.id_prof === selectedProf,
      );
      return foundProf || professionals?.[0];
    }
  }, [professionals, selectedProf, newProf]);
  const { initialValues, validate } = useForm(current, provinces);
  const { isOpenForm, closeForm, openForm } = useFormHook();
  const height = useSetHeight(290);

  async function handleOnSubmit(submitForm: () => void) {
    if (modifying) {
      await submitForm();
      return modifyingHandler(false);
    }
    modifyingHandler(true);
    focusElement();
  }
  const specialty1FieldName = 'specialty_1';
  const specialty2FieldName = 'specialty_2';

  const formOpen = ({
    modifying,
    resetForm,
  }: {
    modifying: boolean | undefined;
    resetForm: any;
  }) => {
    if (!modifying) {
      planVersion === 'premium' ? openForm() : openModalSuscribe();
    }
    resetForm();
    newProfHandler(false);
    modifyingHandler(false);
  };
  const professionalTaxCategory = TaxCategory.filter(
    (tax: any) => tax.id !== 'CF',
  );
  const showInputsFilter = ['arg'].includes(region);

  return (
    <div
      className="overflow-hidden overflow-y-scroll"
      style={{ height: `${height}px` }}
    >
      <Formik
        initialValues={initialValues}
        validateOnMount
        validateOnBlur
        validate={validate}
        onSubmit={useSubmit(current, onChange)}
        enableReinitialize
      >
        {({ submitForm, isSubmitting, isValid, values, resetForm }) => (
          <>
            <Form showActions={false} className="relative mt-8">
              <Card className="mt-5">
                <CardHeader>
                  {showInputsFilter && (
                    <div className="flex justify-end">
                      <Tooltip
                        type="dark"
                        align="left"
                        className="hover:cursor-pointer"
                        text="* Campos obligatorios para guardar un profesional. ** Campos necesarios para el uso de recetas."
                        offset={{ left: 0 }}
                        delay={300}
                      >
                        <Icons
                          className="text-slate-500 hover:text-blue-500"
                          width={21}
                          height={21}
                          type="alert"
                        />
                      </Tooltip>
                    </div>
                  )}
                  <CardTitle>Datos del profesional</CardTitle>
                  <CardDescription>
                    Aquí puedes añadir, editar o eliminar la información de los
                    profesionales en tu cuenta.
                  </CardDescription>
                </CardHeader>
                <CardContent
                  className={classNames(
                    'grid grid-cols-12 gap-5',
                    !modifying &&
                      'pointer-events-none cursor-not-allowed filter grayscale-100 opacity-60',
                  )}
                >
                  <TextFieldForm
                    multiline
                    className="col-span-2"
                    label="Código *"
                    name="code"
                    refTarget={refTarget}
                  />
                  <DropdownForm
                    className="col-span-2"
                    name="prefix"
                    data={Prefix}
                    label="Prefijo **"
                    keyId="id"
                    keyData="text"
                  />
                  <TextFieldForm
                    className="col-span-4"
                    label="Nombre y Apellido *"
                    name="name"
                  />
                  <TextFieldForm
                    className="col-span-4"
                    label="Domicilio"
                    name="address"
                  />
                  <TextFieldForm
                    className="col-span-4"
                    label="Localidad"
                    name="location"
                  />
                  <TextFieldForm
                    className="col-span-2"
                    label="Provincia"
                    name="state"
                  />
                  <TextFieldForm
                    className="col-span-2"
                    label="Teléfono"
                    name="phone"
                  />
                  <TextFieldForm
                    className="col-span-4"
                    label="Email"
                    name="email"
                  />
                  <TextFieldForm
                    className="col-span-3"
                    label={`${translations.taxes} **`}
                    name="cuit"
                  />
                  <DropdownForm
                    className="col-span-3"
                    name="tax_category"
                    data={professionalTaxCategory}
                    label="Condición Fiscal *"
                    keyId="id"
                    keyData="text"
                  />
                  {showInputsFilter && (
                    <>
                      <TextFieldForm
                        className="col-span-3"
                        label="Profesión **"
                        name="profession"
                      />
                      <DropdownForm
                        name="type_registration"
                        data={typesRegistrations}
                        clearable
                        label="Tipo de matrícula **"
                        keyId="value"
                        keyData="type"
                        className="col-span-3"
                      />
                      <DropdownForm
                        data={provinces}
                        className="col-span-3"
                        label="Jurisdicción **"
                        name="jurisdiction"
                        keyId="name"
                        keyData="name"
                        disabled={
                          !values.type_registration ||
                          values.type_registration === 'MN'
                        }
                      />
                    </>
                  )}
                  <NumericField
                    className="col-span-3"
                    label="Matrícula **"
                    name="plate"
                  />
                  <SpecialtyDropdown
                    className="col-span-3"
                    label="Especialidad 1"
                    specialties={specialties}
                    name={specialty1FieldName}
                  />
                  <SpecialtyDropdown
                    className="col-span-3"
                    label="Especialidad 2"
                    specialties={specialties}
                    name={specialty2FieldName}
                  />
                  <TextFieldForm
                    className="col-span-3"
                    label="Cod. de Liq. Para OSDE"
                    name="osde_id"
                  />
                  <SpeciatlyCheckbox
                    className="col-span-3"
                    specialty1FieldName={specialty1FieldName}
                    specialty2FieldName={specialty2FieldName}
                  />
                  <CheckboxForm
                    classNameContainer="col-span-3"
                    label="Solo un supervisor puede ver la liquidación de este profesional."
                    small
                    name="supervisor_view"
                    showError={false}
                  />
                  <CheckboxForm
                    classNameContainer="col-span-3"
                    label="Es profesional de guardia"
                    small
                    name="is_guard"
                    showError={false}
                  />
                  <CheckboxForm
                    classNameContainer="col-span-3"
                    label="Habilitado"
                    name="enabled"
                    small
                    showError={false}
                  />
                  <TextFieldForm
                    className="col-span-9"
                    label="Observaciones"
                    name="observations"
                  />
                </CardContent>
                <Signature name="signature" modifying={modifying} />
                <CardFooter className="gap-5">
                  <Button
                    type="button"
                    onClick={() => {
                      formOpen({ modifying, resetForm });
                    }}
                    className="flex justify-center w-max px-10 h-10 bg-red-500 hover:bg-red-600 text-white"
                  >
                    {modifying ? 'Cancelar' : 'Eliminar'}
                  </Button>
                  <Button
                    onClick={() => handleOnSubmit(submitForm)}
                    disabled={isSubmitting || !isValid}
                    className="flex justify-center w-max px-10 h-10 bg-blue-500 hover:bg-blue-600 text-white"
                  >
                    {modifying ? 'Guardar' : 'Editar'}
                  </Button>
                </CardFooter>
              </Card>
            </Form>
            <Modal
              title="Eliminar Profesional"
              isOpenModal={isOpenForm}
              closeModal={closeForm}
              openModal={openForm}
              width="90%"
            >
              <DeleteProfForm profesional={current} onChange={onChange} />
            </Modal>
          </>
        )}
      </Formik>
    </div>
  );
};

export default DataForm;
