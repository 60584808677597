import React from 'react';
import { columns } from './columns';
import { Data } from './data';
import { attendanceGridMapper } from '../../../../utils';

export const Table: React.FC<any> = ({ data, onSubmit, closeModal }) => {
  return (
    <div className="mx-auto w-full">
      <Data
        columns={columns}
        data={attendanceGridMapper(data)}
        onSubmit={onSubmit}
        closeModal={closeModal}
      />
    </div>
  );
};
