import React from 'react';
import { TableToolbarT } from '#interfaces/tables/benefit';
import { FacetedFilter, Visibility } from './filters';

export function TableToolbar<TData>({
  table,
  options,
  config,
}: TableToolbarT<TData> & { config: any }) {
  return (
    <div className="flex items-center justify-between mb-4">
      {config.params && (
        <FacetedFilter
          title="Filtrar por moneda"
          name="is_usd"
          table={table}
          options={options}
        />
      )}

      <Visibility table={table} />
    </div>
  );
}
