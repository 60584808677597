import React from 'react';
import { Formik } from 'formik';
import {
  DatePicker,
  DropdownForm,
  Textarea,
  Form,
} from '../../../../components';
import { ProfessionalDropdown } from '../../Benefits/BenefitsForm/professional-dropdown';
import { useForm } from './use-form';
import { useSubmit } from './use-submit';
import { NewHistoryClinicFormT } from '#interfaces/components';

export const NewClinicHistoryForm: React.FC<NewHistoryClinicFormT> = ({
  professionals,
  offices,
  clinicHistory,
  onChange,
  patientId,
  userId,
}) => {
  const { initialValues, validate } = useForm({
    clinicHistory,
    offices,
    professionals,
  });
  const officeFieldName = 'office';

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={useSubmit({ clinicHistory, patientId, userId, onChange })}
    >
      <Form ignoreDirty>
        <div className="my-4 grid grid-cols-3 gap-5">
          <DatePicker fullWidth label="Fecha" name="date" />
          {offices?.length > 1 && (
            <DropdownForm
              name={officeFieldName}
              data={offices}
              label="Sucursal"
              keyId="id_sucursal"
              keyData="sucursal"
            />
          )}
          <ProfessionalDropdown
            officeFieldName={officeFieldName}
            professionals={professionals}
            specialtyFieldName="specialty"
            name="professional"
            label="Professional"
            ignoreDateRange
          />
        </div>
        <Textarea
          name="hist_cli"
          className="pb-4"
          label="Historia clínica"
          rows={5}
        />
      </Form>
    </Formik>
  );
};
