export const TaxCategory: { [key: string]: string }[] = [
  { id: 'CF', text: 'Consumidor Final' },
  { id: 'RI', text: 'Responsable Inscripto' },
  { id: 'MO', text: 'Monotributista' },
  { id: 'EX', text: 'IVA Exento' },
];

export const Condition: { [key: string]: string }[] = [
  { id: 'NG', text: 'No gravado' },
  { id: 'GR', text: 'Gravado' },
  { id: 'PR', text: 'Privado' },
];

export const Gender: { [key: string]: string }[] = [
  { id: 'F', text: 'Femenino' },
  { id: 'M', text: 'Masculino' },
];

export const Prefix: { [key: string]: string }[] = [
  { id: 'Dra.', text: 'Dra.' },
  { id: 'Dr.', text: 'Dr.' },
];
export const benefitFormFields = {
  ageField: [
    { id: 'none', text: 'Todas' },
    { id: 'major', text: 'Mayor a' },
    { id: 'minor', text: 'Menor a' },
  ],
  unitField: [
    { id: 'none', text: 'Ninguno' },
    { id: 'year', text: 'Año' },
    { id: 'month', text: 'Mes' },
    { id: 'day', text: 'Día' },
  ],
  toothField: [
    { id: 'NIN', text: 'No pide' },
    { id: 'DIE', text: 'Pide uno' },
    { id: 'ALG', text: 'Pide algunos' },
    { id: 'SUP', text: 'Completa superior' },
    { id: 'INF', text: 'Completa inferior' },
  ],
};
export const days = [
  { id: 1, text: 'Lunes' },
  { id: 2, text: 'Martes' },
  { id: 3, text: 'Miércoles' },
  { id: 4, text: 'Jueves' },
  { id: 5, text: 'Viernes' },
  { id: 6, text: 'Sábado' },
  { id: 7, text: 'Domingo' },
];

export const attendanceOptions = [
  {
    text: 'Presente',
    id: 'PRE',
    styles: 'bg-lime-200 text-lime-800 border-lime-800',
  },
  {
    text: 'Atendido',
    id: 'ATE',
    styles: 'bg-blue-200 text-blue-800 border-blue-800',
  },
  {
    text: 'Ausente con aviso',
    id: 'ACA',
    styles: 'bg-amber-200 text-amber-800 border-amber-800',
  },
  {
    text: 'Ausente sin aviso',
    id: 'ASA',
    styles: 'bg-red-200 text-red-800 border-red-800',
  },
  {
    text: 'Confirmado',
    id: 'CON',
    styles: 'bg-emerald-200 text-emerald-800 border-emerald-800',
  },
  {
    text: 'Cancelado',
    id: 'CAN',
    styles: 'bg-slate-200 text-slate-800 border-slate-800',
  },
  {
    text: 'Sin confirmar',
    id: null,
    styles: 'bg-neutral-100 text-neutral-500 border-neutral-500',
  },
  {
    text: 'Limpiar asistencia',
    id: 'DEL',
    styles: '',
  },
];

export const menuIcons: { [key: string]: { [key: string]: string } } = {
  'Agenda Turnos': {
    name: 'Agenda Turnos',
    icon: 'schedule',
  },
  Pacientes: {
    name: 'Pacientes',
    icon: 'user',
  },
  Tablas: {
    name: 'Configuración',
    icon: 'config',
  },
};

export const gridContextMenu = [
  {
    text: 'Modificar',
    target: '.e-row',
    iconCss: 'e-icons e-edit',
    id: 'edit',
  },
  {
    text: 'Eliminar',
    target: '.e-row',
    iconCss: 'e-icons e-delete',
    id: 'delete',
  },
];

export const supMenu = ['Agenda Turnos', 'Pacientes', 'Tablas'];

export const version = '4.4.0';

export const categories = [
  {
    id: 'images',
    subtitle: 'Imágenes',
  },
  {
    id: 'videos',
    subtitle: 'Videos',
  },
  {
    id: 'texts',
    subtitle: 'Textos',
  },
  {
    id: 'pdfs',
    subtitle: 'PDFs',
  },
  {
    id: 'others',
    subtitle: 'Others',
  },
];

export const defaultApi = 'https://api.bilog.com.ar';

export const PHONE_CONFIG: {
  [key: string]: { prefix: string; adjustments?: any; regex: RegExp };
} = {
  arg: {
    prefix: '549',
    adjustments: (number: string) => {
      if (number.length === 8) {
        return `11${number}`;
      } else if (number.startsWith('15')) {
        return `11${number.slice(2)}`;
      }
      return number;
    },
    regex: /^(549)(?:11|[2368]\d)\d{8}$/,
  },
  pry: {
    prefix: '595',
    regex: /^(5959)([6-9][1-6])\d{6,7}$/,
  },
  ury: {
    prefix: '598',
    regex: /^(598)(9)([1-9])\d{6}$/,
  },
  col: {
    prefix: '57',
    regex: /^(57)(3[0-9][0-9])\d{7}$/,
  },
  per: {
    prefix: '51',
    regex: /^(51)(9)\d{8}$/,
  },
  chl: {
    prefix: '56',
    regex: /^(56)(9)\d{8}$/,
  },
  ven: {
    prefix: '58',
    regex: /^(58)(?:(41[245678])|(42[46]))\d{7}$/,
  },
  ecu: {
    prefix: '593',
    regex: /^(593)(9)\d{8}$/,
  },
  bol: {
    prefix: '591',
    regex: /^(591)([6-7])\d{7}$/,
  },
  esp: {
    prefix: '34',
    regex: /^(34)(?:(6)|(7))\d{8}$/,
  },
  default: {
    prefix: '549',
    regex: /^(549)(?:11|[2368]\d)\d{8}$/,
  },
};
