import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Form, Copy } from '#components/index';
import { CancelPaymentFormT } from '#interfaces/components';

export const CancelPaymentForm: React.FC<CancelPaymentFormT> = ({
  payment,
  isSupervisor,
  onChange,
}) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={useSubmit(payment, onChange)}
      enableReinitialize
    >
      <Form
        submitTitle={!payment.anulado ? 'Anular pago' : 'Habilitar pago'}
        ignoreDirty
      >
        <Copy>
          {isSupervisor
            ? `Esta intentando ${
                !payment.anulado ? 'anular' : 'habilitar'
              } un pago del sistema, pero este se encuentra en un periodo de administración ya cerrado.

            Debido a que Ud. es Supervisor, el sistema puede autorizarlo.`
            : !payment.anulado
            ? 'Esta acción anulará el pago seleccionado.'
            : 'Esta acción habilitará el pago seleccionado.'}
        </Copy>
      </Form>
    </Formik>
  );
};
