import React from 'react';
import { Columns } from './columns';
import { Data } from './data';
import { tablePaymentsMapper } from '../../../utils';

export const Table: React.FC<any> = ({ data, action, config }) => {
  return (
    <div className="mx-auto w-full">
      <Data
        config={config}
        columns={Columns({ action, config })}
        data={tablePaymentsMapper(data).reverse()}
      />
    </div>
  );
};
