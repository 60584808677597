import React from 'react';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { NormalSwitchT } from '#interfaces/components';
import './index.scss';

export const Switch: React.FC<NormalSwitchT> = ({
  className,
  disabled,
  onChange,
  checked,
}) => {
  return (
    <SwitchComponent
      checked={checked}
      disabled={disabled}
      cssClass={`switch ${className}`}
      change={onChange}
    />
  );
};
