import * as React from 'react';
import { Icons } from '../commons';
import { DayPicker } from 'react-day-picker';
import classnames from 'classnames';
import es from 'date-fns/locale/es';
import { CalendarSCNT } from '#interfaces/components';

export const Calendar: React.FC<CalendarSCNT> = ({
  type = 'principal',
  month,
  className,
  classNames,
  showOutsideDays = true,
  min,
  ...props
}) => {
  const stylesDaysCalendar: { [key: string]: { [key: string]: string } } = {
    principal: {
      day: 'hover:bg-blue-600',
      day_selected: 'bg-blue-500 hover:bg-blue-600',
      day_outside: 'hover:bg-blue-600',
      day_range_middle: 'aria-selected:bg-blue-100',
    },
    compare: {
      day: 'hover:bg-green-600',
      day_selected: 'bg-green-500 hover:bg-green-600',
      day_outside: 'hover:bg-green-600',
      day_range_middle: 'aria-selected:bg-green-100',
    },
  };
  const [currentMonth, setCurrentMonth] = React.useState(month);

  const handleMonthChange = (direction: string) => {
    const directionMonth = new Date(currentMonth);
    directionMonth.setMonth(
      currentMonth.getMonth() + (direction === 'next' ? 1 : -1),
    );
    setCurrentMonth(directionMonth);
  };
  React.useEffect(() => {
    setCurrentMonth(month);
  }, [month]);

  return (
    <DayPicker
      key={month.toISOString()}
      month={currentMonth}
      disabled={(date) => date < min || date < new Date('1900-01-01')}
      locale={es}
      showOutsideDays={showOutsideDays}
      className={className}
      classNames={{
        months:
          'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 text-sm',
        month: 'capitalize space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: classnames(
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex space-x-3 pl-2',
        head_cell: 'text-slate-500 rounded-md',
        row: 'flex w-full',
        cell: 'text-center text-sm',
        day: classnames(
          'h-7 w-7 text-xs aria-selected:opacity-100 rounded hover:text-white',
          stylesDaysCalendar[type].day,
        ),
        day_selected: classnames(
          'rounded text-white  text-white',
          stylesDaysCalendar[type].day_selected,
        ),

        day_outside: classnames(
          'text-slate-500 opacity-50',
          stylesDaysCalendar[type].day_outside,
        ),
        day_disabled: 'text-slate-500 opacity-50',
        day_range_middle: classnames(
          'aria-selected:text-slate-900',
          stylesDaysCalendar[type].day_range_middle,
        ),
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: () => (
          <Icons
            type="arrow_back"
            className="h-6 p-1 w-6 border border-slate-400 rounded-md"
            onClick={() => handleMonthChange('prev')}
          />
        ),
        IconRight: () => (
          <Icons
            type="arrow_right"
            className="h-6 p-1 w-6 border border-slate-400 rounded-md"
            onClick={() => handleMonthChange('next')}
          />
        ),
      }}
      {...props}
    />
  );
};
Calendar.displayName = 'Calendar';
