import React from 'react';
import { AccountBanner } from './card';
import { Title } from '../../components';
import { useSetHeight } from '../../hooks';
import { CardBannerT } from '#interfaces/components';
export const CardBanner: React.FC<CardBannerT> = ({
  data,
  plan,
  ManagerCode,
  webUser,
}) => {
  const height = useSetHeight(180);
  const isSuscribed = (cardPlan: string) => plan === cardPlan;

  return (
    <div
      className="p-10 overflow-hidden overflow-y-scroll"
      style={{ height: `${height}px` }}
    >
      <Title>Mi suscripción</Title>
      <div className="grid grid-cols-2 gap-10 pt-4">
        <AccountBanner
          plan="lender"
          data={data}
          suscribed={isSuscribed('lender')}
          managerCode={ManagerCode}
          webUser={webUser}
        />
        <AccountBanner
          plan="freemium"
          data={data}
          suscribed={isSuscribed('freemium')}
          webUser={webUser}
          managerCode={ManagerCode}
        />
        <AccountBanner
          plan="premium"
          data={data}
          suscribed={isSuscribed('premium')}
          showAction
          webUser={webUser}
          managerCode={ManagerCode}
        />
      </div>
    </div>
  );
};
