import React from 'react';
import { format } from 'date-fns';
import { useFieldProps } from '../../../../hooks';
import { Badge, Icons, Tooltip } from '../../../../components';

export const DatesComponent: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  const {
    field: {
      value: { date_from, date_to, comparation_date_from, comparation_date_to },
    },
  } = useFieldProps({ name: 'date_comparison' });
  const {
    field: { value: badgeValue },
  } = useFieldProps({ name: 'badges' });

  return (
    <div className="col-span-5 h-auto w-auto  border-l pl-6 border-l-slate-300">
      <div className="flex flex-row justify-between w-96">
        <Badge
          text={badgeValue.principal}
          className="h-4 text-slate-500 inline-block font-normal bg-slate-100 !ring-0 !ring-inset !ring-inset-transparent"
        />
        <Tooltip
          align="bottom"
          className="hover:cursor-pointer"
          text="Modificar parámetros"
          offset={{ left: 0 }}
          delay={300}
        >
          <Icons
            onClick={onClick}
            type="knob_horizontal"
            width={24}
            className="text-white bg-blue-500 hover:scale-110 rounded-full w-8 h-8 p-1 cursor-pointer"
          />
        </Tooltip>
      </div>
      <div className="text-slate-500 flex items-center font-normal">
        Desde
        <span className="font-bold text-slate-500 text-base mx-2">
          {format(new Date(date_from), 'dd/MM/yyyy')}
        </span>
        <>
          hasta
          <span className="font-bold text-slate-500 text-base mx-2">
            {format(new Date(date_to), 'dd/MM/yyyy')}
          </span>
        </>
      </div>
      {comparation_date_from && (
        <div className="opacity-80 text-slate-500 text-xs">
          Comparación:
          <span className="mx-2">
            {format(new Date(comparation_date_from), 'dd/MM/yyyy')}
          </span>
          <>
            al
            <span className="ml-2">
              {format(new Date(comparation_date_to), 'dd/MM/yyyy')}
            </span>
          </>
        </div>
      )}
    </div>
  );
};
