import React from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Resize,
  ContextMenu,
} from '@syncfusion/ej2-react-grids';
import { DeleteSpecialtyForm } from './DeleteSpecialty';
import { SpecialtyForm } from './SpecialtyForm';
import { EmptyState, Button, Modal } from '../../../../../components';
import { useForm } from '../../../../../hooks';
import { gridContextMenu } from '../../../../../mocks';
import { SpecialtiesFormT } from '#interfaces/components';

export const SpecialtiesForm: React.FC<SpecialtiesFormT> = ({
  specialties,
  onChange,
  onClose,
}) => {
  const defaultSpecialty = {
    id: null,
    code: '',
    description: '',
    rowversion: undefined,
  };
  const grid = React.useRef<any>(null);
  const [specialtyRow, setSpecialtyRow] = React.useState(defaultSpecialty);
  const {
    isOpenForm: isOpenDelete,
    closeForm: closeDelete,
    openForm: openDelete,
  } = useForm();
  const {
    isOpenForm: isOpenEdit,
    closeForm: closeEdit,
    openForm: openEdit,
  } = useForm();

  React.useEffect(() => {
    onChange('specialties');
  }, [onChange]);

  const onChangeHandler = () => {
    onChange('specialties');
    onClose();
  };

  const onCancelHandler = () => {
    setSpecialtyRow(defaultSpecialty);
    closeEdit();
    closeDelete();
  };

  const contextMenuClickHandler = (e: { [key: string]: any }) => {
    const [selectedRow] = grid.current.getSelectedRecords();
    setSpecialtyRow(selectedRow);
    const events: { [key: string]: any } = {
      delete: openDelete,
      edit: openEdit,
    };
    if (!!events[e.item.id]) {
      return events[e.item.id](e);
    }
  };

  return (
    <section className="specialties-form">
      {specialties.length ? (
        <GridComponent
          ref={grid}
          dataSource={specialties}
          pageSettings={{ pageSize: 15 }}
          allowResizing={true}
          allowTextWrap={true}
          height="100%"
          contextMenuItems={gridContextMenu}
          textWrapSettings={{ wrapMode: 'Content' }}
          contextMenuClick={(e) => contextMenuClickHandler(e)}
        >
          <Inject services={[Resize, ContextMenu]} />
          <ColumnsDirective>
            <ColumnDirective field="id_especialidad" visible={false} />
            <ColumnDirective
              field="cod_especialidad"
              width="30"
              headerText="Código"
            />
            <ColumnDirective field="descripcion" headerText="Descripción" />
            <ColumnDirective field="rowversion" visible={false} />
          </ColumnsDirective>
        </GridComponent>
      ) : (
        <EmptyState
          title="Ups!"
          copy="El profesional no tiene especialidades."
        />
      )}
      <Button className="mt-10" onClick={openEdit}>
        Nueva especialidad
      </Button>
      <Modal
        title="Eliminar especialidad"
        isOpenModal={isOpenDelete}
        closeModal={onCancelHandler}
        openModal={openDelete}
      >
        <DeleteSpecialtyForm
          specialty={specialtyRow}
          onChange={onChangeHandler}
        />
      </Modal>
      <Modal
        title={specialtyRow ? 'Editar especialidad' : 'Nueva especialidad'}
        isOpenModal={isOpenEdit}
        closeModal={onCancelHandler}
        openModal={openEdit}
      >
        <SpecialtyForm
          specialty={specialtyRow}
          specialties={specialties}
          onChange={onChangeHandler}
        />
      </Modal>
    </section>
  );
};
