import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { importMedins } from '../../services';

const initialState = {
  listMedIns: [],
  dataBaseName: '',
};

export const getAvailableMedins = createAsyncThunk(
  'account/getAvailableMedins',
  async (data: any) => {
    try {
      const {
        data: { listaObraSociales, databaseName },
      } = await importMedins(data);

      return { listaObraSociales, databaseName };
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const lenderFlowSlice = createSlice({
  name: 'lenders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAvailableMedins.fulfilled, (state, action) => {
      const { listaObraSociales, databaseName } = action.payload;
      state.listMedIns = listaObraSociales;
      state.dataBaseName = databaseName;
    });
  },
});

export default lenderFlowSlice.reducer;
