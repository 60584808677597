import React from 'react';
import { DropdownForm } from '../../../components';
import { OfficesT } from '#interfaces/general-values';

export const OfficesDropdown: React.FC<{ offices: OfficesT[]; name: string }> =
  ({ offices = [], name }) => {
    const data = offices?.filter((office) => office.habilitada);

    return (
      <DropdownForm
        name={name}
        data={data}
        placeholder="Selecciona una sucursal"
        keyId="id_sucursal"
        keyData="sucursal"
      />
    );
  };
