import { axiosInstance, platform } from '../utils';

export function sendWhatsapp(
  date: string | Date,
  messages: any,
  option: string,
) {
  const body = {
    whatsapp: {
      fecha: date,
      opcion: option,
      listawhatsapps: messages,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/auwhatsapp', body);
}

export function getWhatsapp(params: { fecha: string | Date; opcion: string }) {
  const body = {
    whatsapp: params,
    plataforma: platform,
  };

  return axiosInstance.post('/auwhatsapp', body);
}

export function processWhatsapp(
  data: any,
  option: string,
  date: Date | string,
) {
  const { idagendaprocesar, listawhatsapps } = data;
  const body = {
    whatsapp: {
      opcion: 'procesarwapps',
      fecha: date,
      opcionagenda: option,
      idagendaprocesar,
      listawhatsapps,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/auwhatsapp', body);
}
