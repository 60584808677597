import { useCallback, useEffect, useState } from 'react';
import { dateToISO } from '../../../../utils';
import { PaymentsT } from '#interfaces/components/forms';
import {
  BudgetT,
  OfficesT,
  PatientT,
  ProfessionalT,
  TaxesT,
} from '#interfaces/general-values';

export function useForm({
  payment,
  patient,
  offices,
  professionals,
  invoices,
  invoiceTypes,
  methods,
  budgets,
  banks,
  cards,
  taxes,
}: {
  payment: PaymentsT;
  patient: PatientT;
  offices: OfficesT[];
  professionals: ProfessionalT[];
  invoices: any;
  invoiceTypes: any;
  methods: any;
  budgets: BudgetT[];
  banks: any;
  cards: any;
  taxes: TaxesT[];
}) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues({
      payment,
      patient,
      offices,
      professionals,
      invoices,
      invoiceTypes,
      methods,
      budgets,
      banks,
      cards,
      taxes,
    }),
  );

  useEffect(() => {
    setInitialValues(
      getInitialValues({
        payment,
        patient,
        offices,
        professionals,
        invoices,
        invoiceTypes,
        methods,
        budgets,
        banks,
        cards,
        taxes,
      }),
    );
  }, [
    payment,
    patient,
    offices,
    professionals,
    invoices,
    invoiceTypes,
    methods,
    budgets,
    banks,
    cards,
    taxes,
  ]);

  const validate = useCallback(
    (values: any) => {
      const errors: { [key: string]: string } = {};
      const requiredMsg = 'Campo requerido';
      const requiredFields = [
        'professional',
        'date',
        'invoice',
        'invoice_type',
        'payment_method',
      ];
      requiredFields.forEach((requiredField) => {
        if (!values[requiredField]) {
          errors[requiredField] = requiredMsg;
        }
      });
      if (!values.paid && !values.to_pay) {
        errors.to_pay = requiredMsg;
        errors.paid = requiredMsg;
      }
      if (offices?.length) {
        requiredFields.push('office');
      }

      return errors;
    },
    [offices?.length],
  );

  return { initialValues, validate };
}

function getInitialValues({
  payment,
  patient,
  offices,
  professionals,
  invoices,
  invoiceTypes,
  methods,
  budgets,
  banks,
  cards,
  taxes,
}: {
  payment: PaymentsT;
  patient: PatientT;
  offices: OfficesT[];
  professionals: ProfessionalT[];
  invoices: any;
  invoiceTypes: any;
  methods: any;
  budgets: BudgetT[];
  banks: any;
  cards: any;
  taxes: TaxesT[];
}) {
  const withBudget = payment?.id_presup ? true : false;
  const withInstallments = payment?.cant_cuotas > 1;
  const office = offices && offices[0];

  const getProfessional = () => {
    if (offices?.length) {
      return professionals.find(
        (prof) =>
          prof.prof_horarios.filter((ph: { [key: string]: any }) => {
            return ph.id_sucursal === office.id_sucursal;
          }).length > 0,
      );
    }

    return professionals?.filter((p) => p.habilitado)[0];
  };

  const professional = getProfessional();

  const getTaxes = () => {
    if (payment?.iva) {
      const paymentTax = taxes.find((t) => t.tax === payment?.iva);
      return paymentTax?.id;
    }
    return parseInt(taxes[0].id);
  };

  const getBudget = () => {
    if (payment?.id_presup) {
      const paymentBudget = budgets?.find(
        (b) => b.id_presup === payment?.id_presup,
      );
      return paymentBudget?.id_presup;
    } else if (budgets.length) {
      return budgets[0].id_presup;
    }
    return null;
  };

  const getBank = () => {
    if (payment?.id_banco) {
      const paymentBank = banks.find(
        (b: { [key: string]: string }) => b?.id_banco === payment?.id_banco,
      );
      return paymentBank?.id_banco;
    }
    return null;
  };

  const getCard = () => {
    if (payment?.tarjetas_mov) {
      const paymentCard = cards.find(
        (c: { [key: string]: string }) =>
          c.id_tarjeta === payment?.tarjetas_mov[0]?.id_tarjeta,
      );
      return paymentCard?.id_tarjeta;
    }
  };

  const getCardDetails = () => {
    if (payment?.tarjetas_mov) {
      return {
        card_number: payment?.tarjetas_mov[0]?.nro_tarjeta,
        card_number_auth: payment?.tarjetas_mov[0]?.nro_aut,
      };
    }
    return null;
  };

  const getPaycheck = () => {
    if (payment?.cheques.length) {
      return {
        paycheck_date: payment?.cheques[0].fecha_dife,
        bank_emitter: payment?.cheques[0].banco,
        paycheck_number: payment?.cheques[0].nro_cheque,
      };
    }
    return null;
  };

  return {
    id_payment: payment?.id_pago || 0,
    id_patient: payment?.id_paciente || patient.id_paciente,
    professional: payment?.id_prof || professional?.id_prof,
    date: payment ? dateToISO(payment.fecha) : dateToISO(new Date()),
    concept: payment?.concepto || '',
    invoice: payment?.comprobante || invoices[0].id,
    invoice_type: payment?.tipo || invoiceTypes[0].id,
    invoice_number: payment?.numero || '',
    payment_method: payment?.forma || methods[0].id,
    to_pay: payment?.debe || 0,
    paid: payment?.haber || 0,
    is_usd: payment?.es_usd ?? false,
    values_to_pay_prof: {
      professional_percentage: payment?.comi_pago || null,
      professional_amount: payment?.comi_pago_imp || null,
      can_edit: false,
    },
    tax: getTaxes(),
    with_budget: withBudget,
    id_budget: getBudget(),
    canceled: payment?.anulado || false,
    id_user: payment?.id_usuario || null,
    office: payment?.id_sucursal || office?.id_sucursal || null,
    id_insurance: payment?.id_os || patient.id_planNavigation.id_os,
    notes: payment?.obs || '',
    interest: (payment?.debe * payment?.interes_porc) / 100 || 0,
    interest_perc: payment?.interes_porc || 0,
    with_installments: withInstallments,
    installments: payment?.cant_cuotas || 0,
    id_bank: getBank(),
    id_prof_liq: payment?.id_liq_prof || null,
    amount_prof_liq: payment?.importe_liq_prof || null,
    id_electronic_invoice: payment?.id_fact_electronica || null,
    url_pdf: payment?.urlpdf || '',
    invoice_state: payment?.estado_comprob || '',
    msg_AFIP: payment?.mensaje_AFIP || '',
    save_register: false,
    register: payment?.caja || null,
    id_card: getCard(),
    mov_cards: getCardDetails(),
    paychecks: getPaycheck(),
  };
}
