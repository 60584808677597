import * as React from 'react';
import classNames from 'classnames';
import { Badge } from '#components/commons';

export const Columns = () => {
  return [
    {
      accessorKey: 'date',
      header: 'Fecha',
      cell: ({ row }: { row: any }) => {
        const { date } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {date}
          </p>
        );
      },
    },
    {
      accessorKey: 'time',
      header: 'Hora',
      cell: ({ row }: { row: any }) => {
        const { time } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1 hover:cursor-pointer">
            {time}
          </p>
        );
      },
    },
    {
      accessorKey: 'professional',
      header: 'Turno dado por',
      cell: ({ row }: { row: any }) => {
        const { id_professional, professional } = row.original;

        return (
          <p
            data-id={id_professional}
            className="text-base font-semibold leading-6 text-slate-800 my-1"
          >
            {professional}
          </p>
        );
      },
    },
    {
      accessorKey: 'attendance',
      header: 'Asistencia',
      cell: ({ row }: { row: any }) => {
        const { attendance } = row.original;

        return (
          <Badge
            className={classNames('w-auto mt-2 mb-1', attendance?.styles)}
            text={attendance?.text}
          />
        );
      },
    },
    {
      accessorKey: 'observations',
      header: 'Observaciones',
      cell: ({ row }: { row: any }) => {
        const { observations } = row.original;

        return (
          <p className="max-w-[500px] font-bold text-sm leading-6 text-gray-500 my-1 hover:cursor-pointer">
            {observations}
          </p>
        );
      },
    },
    {
      accessorKey: 'given_date',
      header: 'Turno dado el',
      cell: ({ row }: { row: any }) => {
        const { given_date } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1 hover:cursor-pointer">
            {given_date}
          </p>
        );
      },
    },
  ];
};
