import React from 'react';
import { Formik } from 'formik';
import { useForm } from './use-form';
import { Controller } from './controller';

export const LaboratoriesForm: React.FC<any> = ({
  laboratories,
  selectedLab,
  children,
}) => {
  const { initialValues } = useForm(laboratories, selectedLab);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={() => {}}
      >
        <div className="px-10 pt-5 items-end relative grid grid-cols-4 gap-x-10">
          <Controller
            name="laboratory"
            showenabledFieldName="show_enabled"
            laboratories={laboratories}
          />
          {children}
        </div>
      </Formik>
    </div>
  );
};
