import React from 'react';
import { CheckboxForm, NumericField } from '../../../../../../components';
import { useFieldProps } from '../../../../../../hooks';

export const PeriodFormat: React.FC = () => {
  const {
    field: { value },
  } = useFieldProps({ name: 'period_format' });

  return (
    <>
      <div>
        <CheckboxForm
          label="Cargar por período correspondiente, utilizando el formato AAAAMM"
          small
          name="period_format"
          showError={false}
        />
      </div>
      <div>
        <NumericField
          placeholder="Cantidad"
          name="benefits_amount"
          hint="Cantidad máxima de prestaciones por pacientes por período (0 = Sin límites)"
          className="small"
          disabled={!value}
        />
      </div>
    </>
  );
};
