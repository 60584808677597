import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Copy, Form } from '../../../../../../components';
import { DeleteTimeFormT } from '#interfaces/components';

export const DeleteTimeForm: React.FC<DeleteTimeFormT> = ({
  time,
  selectedProf,
  onChange,
}) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={useSubmit({ time, selectedProf, onChange })}
    >
      <Form submitTitle="Eliminar" ignoreDirty>
        <Copy>
          Estás por eliminar este horario y esta acción es irreversible, el
          horario se eliminará definitivamente.
        </Copy>
      </Form>
    </Formik>
  );
};
