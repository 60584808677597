import React from 'react';
import { GridT } from '#interfaces/components';
export { GridWrapper } from './grid-wraper';
export { GridRow } from './grid-row';

export const Grid: React.FC<GridT> = ({ height = 'auto', children }) => {
  return (
    <div
      className="mt-8 flex flex-col overflow-y-auto overflow-x-auto ring-1 md:rounded-lg ring-black ring-opacity-5 shadow"
      style={{ height }}
    >
      <div className="my-2 mx-4 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full align-middle md:pl-6 lg:pl-8">
          <div className="overflow-hidden">{children}</div>
        </div>
      </div>
    </div>
  );
};
