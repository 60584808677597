import React from 'react';
import { Formik } from 'formik';
import { Copy, Form } from '../../../../../../components';
import { useSubmit } from './use-submit';
import { AbsenceFormT } from '#interfaces/components';

export const DeleteAbsenceForm: React.FC<AbsenceFormT> = ({
  absence,
  selectedProf,
  onChange,
}) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={useSubmit(absence, selectedProf, onChange)}
    >
      <Form submitTitle="Eliminar" ignoreDirty>
        <Copy>
          Estás por eliminar esta ausencia y esta acción es irreversible, la
          ausencia se eliminará definitivamente.
        </Copy>
      </Form>
    </Formik>
  );
};
