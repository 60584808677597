export const dataPreset: { id: string; label: string }[] = [
  { id: 'last7', label: 'Últimos 7 días' },
  { id: 'last15', label: 'Últimos 15 días' },
  { id: 'last30', label: 'Últimos 30 días' },
  { id: 'last90', label: 'Últimos 90 días' },
  { id: 'personalized', label: 'Personalizado' },
];
export const dataPresetCompare: { id: string; label: string }[] = [
  { id: 'lastperiod', label: 'Periodo anterior' },
  { id: 'sameperiodlastyear', label: 'Mismo periodo del año anterior' },
  { id: 'personalized', label: 'Personalizado' },
];

export const colors: { original: string; comparison: string }[] = [
  { original: '#3B82F6', comparison: '#F59E0B' },
  { original: '#3B82F6', comparison: '#93C5FD' },
  { original: '#8B5CF6', comparison: '#C084FC' },
  { original: '#4299E1', comparison: '#93C5FD' },
  { original: '#8B5CF6', comparison: '#C084FC' },
  { original: '#F59E0B', comparison: '#FBBF24' },
  { original: '#F59E0B', comparison: '#FBBF24' },
  { original: '#F59E0B', comparison: '#FBBF24' },
  { original: '#F59E0B', comparison: '#FBBF24' },
  { original: '#F59E0B', comparison: '#FBBF24' },
];
export const namesGraphics: {
  [key: string]: {
    title: string;
    subtitle: string;
    translations: boolean;
  };
} = {
  totalBilled: {
    title: 'Facturación total',
    subtitle: 'Sumatoria pagos por paciente, más importes a cargo de cada ',
    translations: true,
  },
  paymentMethodBilling: {
    title: 'Facturación por forma de pago',
    subtitle: 'Basado en las formas de pago en caja.',
    translations: false,
  },
  medicalInsuranceBilling: {
    title: 'Facturación por ',
    subtitle: 'Incluye los importes de facturación de cada ',
    translations: true,
  },
  mostProfitablePatient: {
    title: 'Rentabilidad de paciente por pago en caja',
    subtitle: 'Incluye pacientes más rentables.',
    translations: false,
  },
  mostPerformedPractice: {
    title: 'Práctica más realizada',
    subtitle: 'Incluye las prácticas más realizadas.',
    translations: false,
  },
  mostProfitablePractice: {
    title: 'Práctica más rentable',
    subtitle: 'Incluye las prácticas con mayor rentabilidad.',
    translations: false,
  },
  cashInDrawer: {
    title: 'Dinero en caja',
    subtitle: 'Incluye los pagos realizados por pacientes.',
    translations: false,
  },
  billingToMedicalInsurance: {
    title: 'Facturación por ',
    subtitle: 'Importe de las prestaciones a cargo de cada',
    translations: true,
  },
  averagePatientTicket: {
    title: 'Ticket promedio por paciente',
    subtitle: 'Ingreso promedio por paciente.',
    translations: false,
  },
  averagePracticeValue: {
    title: 'Valor de práctica promedio',
    subtitle: 'Importe promedio por práctica.',
    translations: false,
  },
};
export const variationIcons: {
  [key: string]: { [key: string]: string };
} = {
  increase: {
    sumOrRest: '+',
    arrow: '↑',
    className: 'text-green-500',
  },
  decrease: {
    sumOrRest: '-',
    arrow: '↓',
    className: 'text-red-500',
  },
};
