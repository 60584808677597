import React from 'react';
import { AppointmentT, OfficesT } from '#interfaces/general-values';

type DataFormT = {
  appointment: AppointmentT;
  userId: number;
  date: Date | string;
  offices: OfficesT[];
};

export function useForm({ appointment, userId, date, offices }: DataFormT) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues({ appointment, userId, date, offices }),
  );

  React.useEffect(() => {
    setInitialValues(getInitialValues({ appointment, userId, date, offices }));
  }, [appointment, userId, date, offices]);

  const validate = React.useCallback((values: any) => {
    const errors: { [key: string]: string } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['overturn'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues({ appointment, userId, date, offices }: DataFormT) {
  const office = offices && offices.length > 1 ? offices[0].id_sucursal : null;
  const [hour, minutes] = appointment && appointment.time.split(':');

  return {
    id_professional: appointment.id_professional,
    date: appointment?.date ?? new Date(date).toISOString(),
    overturn: {
      hour,
      minutes: parseInt(minutes) + 1,
    },
    notes: '',
    id_user: userId,
    office: appointment.id_office || office,
  };
}
