import React from 'react';
import { DropdownForm } from '../../../commons';
import { OfficesT, ProfessionalT } from '#interfaces/general-values';
import { PaymentsT } from '#interfaces/components/forms';

export const PaymentDropdown: React.FC<{
  officeFieldName: string;
  professionalFieldName: string;
  offices: OfficesT[];
  professionals: ProfessionalT[];
  payment: PaymentsT;
  isElectronicInvoice: boolean;
}> = ({
  officeFieldName,
  professionalFieldName,
  offices,
  professionals,
  payment,
  isElectronicInvoice,
}) => {
  const disableOffice = () => {
    if (payment) {
      const [office] = Array.isArray(offices)
        ? offices?.filter(
            (office) => office.id_sucursal === payment.id_sucursal,
          )
        : [];
      return !office?.habilitada || false;
    }
    return false;
  };

  const fiteredOffices = disableOffice()
    ? offices
    : offices?.filter((o) => o.habilitada);
  const fiteredProfessionals = payment
    ? professionals
    : professionals.filter((p) => p.habilitado);
  const disabled = isElectronicInvoice || disableOffice();

  return (
    <>
      {offices?.length > 1 && (
        <DropdownForm
          className="col-span-4"
          name={officeFieldName}
          data={fiteredOffices}
          label="Sucursal"
          keyId="id_sucursal"
          keyData="sucursal"
          disabled={disabled}
        />
      )}
      <DropdownForm
        className="col-span-4"
        name={professionalFieldName}
        data={fiteredProfessionals}
        label="Profesional"
        keyId="id_prof"
        keyData="nombre"
      />
    </>
  );
};
