import React from 'react';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Resize,
  Reorder,
  Sort,
  VirtualScroll,
  ContextMenu,
  Edit,
  SortSettingsModel,
  SortDirection,
} from '@syncfusion/ej2-react-grids';
import { UserForm } from './UserForm';
import { DeleteUserForm } from './DeleteUserForm';
import { Enabled, Dentist, Name, Supervisor } from './templates';
import {
  EmptyState,
  Button,
  Modal,
  TabSection,
} from '../../../../../components';
import { useForm } from '../../../../../hooks';
import { usersGridMapper } from '../../../../../utils';
import { UsersFormT } from '#interfaces/components';
import { UserT } from '#interfaces/general-values';

export const UsersForm: React.FC<UsersFormT> = ({
  userName,
  userId,
  users,
  permissions,
  useUsd,
  mappedPermissions,
  professionals,
  offices,
  planVersion,
  onChange,
}) => {
  const grid = React.useRef<any>(null);
  const [userRow, setUserRow] = React.useState<any>(null);
  const {
    isOpenForm: isOpenDelete,
    closeForm: closeDelete,
    openForm: openDelete,
  } = useForm();
  const {
    isOpenForm: isOpenModal,
    closeForm: closeModal,
    openForm: openModal,
  } = useForm();
  const sortSettings: SortSettingsModel = {
    columns: [
      {
        field: 'code_medical_insurance',
        direction: 'Ascending' as SortDirection,
      },
    ],
  };

  const gridContextMenu = [
    {
      text: 'Ver detalle',
      target: '.e-row',
      iconCss: 'e-icons e-add',
      id: 'edit',
    },
    {
      text: 'Eliminar',
      target: '.e-row',
      iconCss: 'e-icons e-delete',
      id: 'delete',
    },
  ];
  const usersWithPermissions: UserT[] = usersGridMapper(users).filter(
    (u: { [key: string]: any }) =>
      u.permissions.length > 0 &&
      !u.supervisor &&
      (!userRow || u.id !== userRow.id),
  );

  const contextMenuClickHandler = (e: { [key: string]: any }) => {
    const [selectedRow] = grid.current.getSelectedRecords();
    setUserRow(selectedRow);
    const events: { [key: string]: any } = {
      delete: openDelete,
      edit: openModal,
    };
    if (!!events[e.item.id]) {
      return events[e.item.id](e);
    }

    return setUserRow(null);
  };

  const onCancelHandler = () => {
    setUserRow(null);
    closeModal();
    closeDelete();
  };

  React.useEffect(() => {
    onChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRow]);

  React.useEffect(() => {
    if (grid.current && users) {
      grid.current.dataSource = usersGridMapper(users);
    }
  }, [users]);

  const renderGrid = () => (
    <>
      {users && users.length ? (
        <GridComponent
          ref={grid}
          dataSource={usersGridMapper(users)}
          allowReordering={true}
          allowResizing={true}
          allowSorting={true}
          height={window.innerHeight - 400}
          sortSettings={sortSettings}
          contextMenuItems={gridContextMenu}
          contextMenuClick={(e) => contextMenuClickHandler(e)}
        >
          <Inject
            services={[Resize, Reorder, Sort, VirtualScroll, ContextMenu, Edit]}
          />
          <ColumnsDirective>
            <ColumnDirective field="id" visible={false} />
            <ColumnDirective field="id_prof" visible={false} />
            <ColumnDirective field="id_office" visible={false} />
            <ColumnDirective field="password" visible={false} />
            <ColumnDirective field="permissions" visible={false} />
            <ColumnDirective field="option" visible={false} />
            <ColumnDirective field="dentist_schedule" visible={false} />
            <ColumnDirective field="dentist_guard" visible={false} />
            <ColumnDirective field="dentist_data" visible={false} />
            <ColumnDirective field="use_usd" visible={false} />
            <ColumnDirective field="rowversion" visible={false} />
            <ColumnDirective field="name" headerText="Nombre" template={Name} />
            <ColumnDirective field="user" headerText="Usuario" />
            <ColumnDirective
              field="supervisor"
              headerText="Es supervisor"
              template={Supervisor}
            />
            <ColumnDirective
              field="dentist"
              headerText="Es odontologo"
              template={Dentist}
            />
            <ColumnDirective
              field="enabled"
              headerText="Habilitado"
              template={Enabled}
            />
          </ColumnsDirective>
        </GridComponent>
      ) : (
        <EmptyState title="Ups!" copy="No hay usuarios definidos." />
      )}
      <Button className="mt-4" onClick={openModal}>
        Nuevo usuario
      </Button>
    </>
  );

  return (
    <>
      {!isOpenModal && renderGrid()}
      <TabSection
        title={userRow ? 'Modificar usuario' : 'Nuevo usuario'}
        isOpen={isOpenModal}
        close={onCancelHandler}
        open={openModal}
        hiddeBack
      >
        <UserForm
          userId={userId}
          userName={userName}
          user={userRow}
          useUsd={useUsd}
          usersToCopy={usersWithPermissions}
          mappedPermissions={mappedPermissions}
          permissions={permissions}
          professionals={professionals}
          offices={offices}
          planVersion={planVersion}
          onChange={onChange}
        />
      </TabSection>
      <Modal
        title="Eliminar usuario"
        isOpenModal={isOpenDelete}
        closeModal={onCancelHandler}
        openModal={openDelete}
      >
        <DeleteUserForm
          user={userRow}
          userName={userName}
          onChange={onChange}
        />
      </Modal>
    </>
  );
};
