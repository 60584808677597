import { useEffect, useState } from 'react';
import { getWeek } from '../../../../utils';

export function useForm(current: any) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues(current),
  );
  useEffect(() => {
    setInitialValues(getInitialValues(current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { initialValues };
}

function getInitialValues(current: any) {
  return {
    id_prof: current?.id_prof || null,
    id_med_ins: current?.id_med_ins || null,
    id_subsidiary: current?.id_subsidiary || null,
    calculate_usd: current?.calculate_usd || false,
    show_disabled_med_ins: current?.show_disabled_med_ins || false,
    show_disabled_prof: current?.show_disabled_prof || false,
    check_all_prof: current?.check_all_prof || true,
    check_all_med_ins: current?.check_all_med_ins || true,
    check_all_offices: current?.check_all_offices || true,
    date_comparison: current?.date_comparison || {
      date_from: new Date(getWeek().sevenDaysAgo),
      date_to: new Date(),
      comparation_date_from: null,
      comparation_date_to: null,
    },
    badges: {
      principal: current?.badges.principal || 'Personalizado',
      compare: current?.badges.compare || 'Mismo periodo del año anterior',
    },
  };
}
