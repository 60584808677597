import React, { useEffect, useMemo } from 'react';
import { DropdownForm } from '../../../..//commons';
import { useFieldProps } from '../../../../../hooks';

export const GroupsDropdown: React.FC<{
  medIns: { [index: string]: any };
  medInsFieldName: string;
  planFieldName: string;
  onChange: (value: number) => void;
}> = ({ medIns, medInsFieldName, planFieldName, onChange }) => {
  const {
    field: { value: medInsValue },
  } = useFieldProps({ name: medInsFieldName });

  const {
    field: { value: planValue },
    setFieldValue: planSetFieldValue,
  } = useFieldProps({ name: planFieldName });

  const currentMedIns = useMemo(
    () => medIns.find((m: { [index: string]: any }) => m.id_os === medInsValue),
    [medIns, medInsValue],
  );

  useEffect(() => {
    onChange(planValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planValue]);

  useEffect(() => {
    planSetFieldValue(currentMedIns?.planes[0]?.id_plan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medInsValue]);

  const plans = medIns.filter(
    (m: { [index: string]: any }) => m.id_os === medInsValue,
  )?.[0]?.planes;

  return (
    <div className="col-span-3">
      <DropdownForm
        name={planFieldName}
        data={plans}
        label="Planes"
        keyId="id_plan"
        keyData="nombre"
      />
    </div>
  );
};
