import React from 'react';
import { useFieldProps } from '../../../../hooks';
import { Badge, Title } from '../../../../components';
import {
  MedicalInsurancesT,
  OfficesT,
  ProfessionalT,
  TranslationsT,
} from '#interfaces/general-values';

export const Parameters: React.FC<{
  medicalInsurances: MedicalInsurancesT[];
  professionals: ProfessionalT[];
  offices: OfficesT[];
  translations: TranslationsT;
  sentencesAndArticles: {
    articles: {
      [key: string]: string;
    };
    sentenceStarter: {
      [key: string]: string;
    };
  };
}> = ({
  medicalInsurances,
  professionals,
  offices,
  translations,
  sentencesAndArticles,
}) => {
  const {
    field: { value: profValue },
  } = useFieldProps({ name: 'id_prof' });
  const {
    field: { value: medInsValue },
  } = useFieldProps({ name: 'id_med_ins' });
  const {
    field: { value: officeValue },
  } = useFieldProps({ name: 'id_office' });
  const medInsName =
    medicalInsurances?.find((m) => m.id_os === medInsValue)?.obra_social ||
    `${sentencesAndArticles.sentenceStarter[translations.medical_insurances]} ${
      sentencesAndArticles.articles[translations.medical_insurances]
    } ${translations.medical_insurances}`;
  const profName =
    professionals?.find((p) => p.id_prof === profValue)?.nombre ||
    'Todos los profesionales';
  const officeName =
    offices?.find((o) => o.id_sucursal === officeValue)?.sucursal ||
    'Todas las sucursales';

  return (
    <div className="col-span-7 flex flex-col items-start space-y-4">
      <Title className="text-base">Parámetros</Title>
      <div className="flex space-x-4">
        <Badge
          text={medInsName}
          className="text-violet-500 bg-violet-50 h-8 !rounded-3xl !text-sm truncate"
        />
        <Badge
          text={profName}
          className="text-yellow-500 bg-yellow-50 h-8 px-2 !rounded-3xl !text-sm truncate"
        />
        {offices?.length > 0 && (
          <Badge
            text={officeName}
            className="text-pink-500 bg-pink-50 h-8 ml-10 !rounded-3xl !text-sm truncate"
          />
        )}
      </div>
    </div>
  );
};
