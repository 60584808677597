import React from 'react';
import { Icons } from '../../../../../commons';

export const Enabled: React.FC<{ [key: string]: any }> = (props) => {
  const { enabled } = props;
  const text = enabled ? (
    <Icons
      type="check_clean"
      className="h-6 w-6 text-sm text-green-500 left-0 top-0 check-1 transition"
    />
  ) : (
    <Icons
      type="close"
      className="h-6 w-6 text-sm text-red-500 left-0 top-0 check-1 transition"
    />
  );
  return <span className="enabled">{text}</span>;
};
