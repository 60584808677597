export const sentencesAndArticles: {
  articles: {
    [key: string]: string;
  };
  sentenceStarter: {
    [key: string]: string;
  };
} = {
  articles: {
    'Obra social': 'la',
    'Obras sociales': 'las',
    'Seguros médicos': 'los',
    'Seguro médico': 'el',
  },
  sentenceStarter: {
    'Obras sociales': 'Todas',
    'Seguros médicos': 'Todos',
  },
};
