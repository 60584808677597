import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { DatePicker, DropdownForm, Form } from '../../../../../../components';
import { days } from '../../../../../../mocks';
import { AbsenceFormT } from '#interfaces/components';

export const AbsenceForm: React.FC<AbsenceFormT> = ({
  absence,
  selectedProf,
  onChange,
  absences,
}) => {
  const { initialValues, validate } = useForm(absence);
  const DAYS = [{ id: null, text: 'Todos' }, ...days];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={useSubmit(selectedProf, onChange, absences)}
      enableReinitialize
      validate={validate}
    >
      {({ values }) => (
        <Form ignoreDirty>
          <div className="selected-professional_data">
            <label>Profesional seleccionado</label>
            <p className="name">{selectedProf.nombre}</p>
          </div>
          <div className="grid grid-cols-3 gap-10 pb-5">
            <DropdownForm
              name="day_num"
              data={DAYS}
              label="Día"
              keyId="id"
              keyData="text"
            />
            <DatePicker label="Fecha inicial" name="from" fullWidth />
            <DatePicker
              label="Fecha final"
              name="to"
              fullWidth
              minDate={values.from}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
