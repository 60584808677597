import React from 'react';
import classNames from 'classnames';
import { LogoT } from '#interfaces/components';

export const Logo: React.FC<LogoT> = ({ className }) => {
  const classes = classNames('block relative right-1', className);
  const logo = 'https://www.bilog.com.ar/static/logo-bilog.svg';

  return <img className={classes} src={logo} alt="Logo Bilog" />;
};
