import React from 'react';
import { ParamsT } from '#interfaces/general-values';

export function useForm(params: ParamsT) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues(params),
  );

  React.useEffect(() => {
    setInitialValues(getInitialValues(params));
  }, [params]);

  return { initialValues };
}

function getInitialValues(params: ParamsT) {
  return {
    what_template_turns: params?.what_template_turno || '',
    what_template_birthday: params?.what_template_cumple || '',
  };
}
