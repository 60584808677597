import React from 'react';
import { FormikHelpers } from 'formik';
import { SnackbarContext, FormContext } from '../../../../../../context';
import { newArticle, updateArticle } from '../../../../../../services';

export const useSubmit = (onChange: () => void, article: any) => {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(spec, article);
      await onChange();
      showSuccessSnackbar(
        `${article ? 'Cambios' : 'Trabajo'} guardado correctamente`,
      );
      closeForm();
      formikActions.resetForm();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} ${err.status}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
};

const axiosPromise = (spec: { [key: string]: any }, article: any) => {
  const apiSpec = transformSpec(spec);

  return article ? updateArticle(apiSpec) : newArticle(apiSpec);
};

const transformSpec = (spec: { [key: string]: any }) => {
  const apiSpec = {
    id_concepto: spec.id_concept,
    concepto: spec.concept,
    id_labo: spec.id_labo,
    codigo: spec.code,
    importe: spec.amount,
    habilitado: spec.enabled,
    es_usd: spec.is_usd,
    rowversion: spec.rowversion,
  };

  return apiSpec;
};
