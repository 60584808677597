import React from 'react';
import { ProductsBanner } from './banner';
import { Title } from '../../components';
import { useSetHeight } from '../../hooks';
import { ProductT } from '#interfaces/components';

export const Products: React.FC<ProductT> = ({ data, products, webUser }) => {
  const height = useSetHeight(180);
  const isSuscribedWapp = () => (data ? data.wapp_pack > 0 : false);
  const isSuscribedCloud = () => (data ? data.pack_storage !== null : false);

  return (
    <div
      className="p-10 overflow-hidden overflow-y-scroll"
      style={{ height: `${height}px` }}
    >
      <Title className="text-lg">Productos</Title>
      <div className="grid grid-cols-2 gap-10">
        <ProductsBanner
          product="Whatsapp"
          suscribed={isSuscribedWapp()}
          data={[data, products]}
          webUser={webUser}
        />
        <ProductsBanner
          product="Cloud"
          suscribed={isSuscribedCloud()}
          data={[data, products]}
          webUser={webUser}
        />
      </div>
    </div>
  );
};
