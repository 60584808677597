import React from 'react';
import { useDispatch } from 'react-redux';
import { CheckboxForm, DropdownForm } from '#components/index';
import { LaboratoryT } from '#interfaces/components/forms';
import { setSelectedLab } from '../../../../../redux/slices';
import { useFieldProps } from '../../../../../hooks';

export const Controller: React.FC<{
  name: string;
  showenabledFieldName: string;
  laboratories: LaboratoryT[];
}> = ({ name, showenabledFieldName, laboratories }) => {
  const {
    field: { value: enabled },
  } = useFieldProps({ name: showenabledFieldName });
  const {
    field: { value: laboratory },
  } = useFieldProps({ name });
  const dispatch = useDispatch();
  const labsToShow = React.useMemo(
    () =>
      enabled
        ? laboratories?.filter((el: { [key: string]: any }) => el.habilitado)
        : laboratories || [],
    [enabled, laboratories],
  );

  React.useEffect(() => {
    dispatch(setSelectedLab(laboratory));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laboratory, laboratories]);

  return (
    <>
      <DropdownForm
        name={name}
        data={labsToShow}
        label="Laboratorios"
        keyId="id_labo"
        keyData="nombre"
      />
      <div className="col-span-2">
        {' '}
        <CheckboxForm
          label="Mostrar laboratorios habilitados"
          small
          description="Se mostraran solo los laboratorios habilitados."
          name={showenabledFieldName}
          showError={false}
        />
      </div>
    </>
  );
};
