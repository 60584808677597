import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BudgetFormT } from '#interfaces/components/budgets.ts';
import { AppDispatch, RootState } from '../../../redux';
import { DeleteBudgetForm } from './DeleteBudget';
import { PatientBudgets } from '#components/Tables';
import { SuccesFull as Empty, Modal } from '#components/index';
import { Button } from '#components/shadcn';
import { tableBudgetsMapper } from '../../../utils';
import { emptyStateBudget, emptyStateNotPatient } from '../../../mocks';
import { useForm, useSetHeight } from '../../../hooks';
import {
  setBudgetSelected,
  resetBudgetSelected,
  completeProgressBar,
  resetProgressBar,
  openSectionInBudget,
  getBudgetPayments,
} from '../../../redux/slices';

export const BudgetsForm: React.FC<BudgetFormT> = ({
  patient,
  userId,
  handlerFocus,
  permissions,
  refresh,
  isSupervisor,
  useUsd,
  translations,
}) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch<AppDispatch>();
  const {
    budgets: { selected },
  } = useSelector((state: RootState) => state);
  const budgets = tableBudgetsMapper(patient.presup).reverse() || [];
  const {
    isOpenForm: isOpenModalDeletePresup,
    closeForm: closeModalDeletePresup,
    openForm: openModalDeletePresup,
  } = useForm();

  const actions = (id: number, action: string) => {
    const selected = patient.presup?.find(
      (budget: { [key: string]: any }) => budget.id_presup === id && budget,
    );

    dispatch(setBudgetSelected(selected));
    if (action === 'edit') {
      dispatch(openSectionInBudget('patient-budgets'));
      dispatch(completeProgressBar());
      navigate('/budgets');
    } else if (action === 'delete') {
      openModalDeletePresup();
    } else if (action === 'payments') {
      dispatch(getBudgetPayments(id));
      dispatch(openSectionInBudget('budgets-payments'));

      navigate('/budgets');
    }
  };

  const handleNewBudgetOnClick = () => {
    dispatch(openSectionInBudget('patient-budgets'));
    dispatch(resetBudgetSelected(null));
    dispatch(resetProgressBar());
    navigate('/budgets');
  };
  const handlerEmpty = {
    dataMocks: !patient.id_paciente ? emptyStateNotPatient : emptyStateBudget,
    onClick: !patient.id_paciente ? handlerFocus : handleNewBudgetOnClick,
  };
  const height = useSetHeight(patient?.id_paciente ? 420 : 220);

  return (
    <section className="mt-5" style={{ height: `${height}px` }}>
      {budgets && budgets.length ? (
        <>
          <PatientBudgets
            data={budgets}
            config={{ useUsd, permissions, isSupervisor, translations }}
            action={actions}
          />
          {(isSupervisor ||
            permissions.hasOwnProperty('Nuevo Presupuesto')) && (
            <div className="flex mt-5 space-x-5 justify-end">
              <Button
                onClick={handleNewBudgetOnClick}
                className="px-10 h-10 hover:bg-blue-600"
              >
                Nuevo presupuesto
              </Button>
            </div>
          )}
        </>
      ) : (
        <Empty
          typeSucces="emptyState"
          dataMocks={handlerEmpty.dataMocks}
          onClick={handlerEmpty.onClick}
        />
      )}
      <Modal
        title="Eliminar Presupuesto"
        isOpenModal={isOpenModalDeletePresup}
        closeModal={closeModalDeletePresup}
        openModal={openModalDeletePresup}
      >
        <DeleteBudgetForm
          selectedBudget={selected}
          userId={userId}
          refresh={refresh}
        />
      </Modal>
    </section>
  );
};
