import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Icons } from '../commons';
import { ActionCard } from './actions';
import { Subscription } from './price-detail';
import { plans } from '../../mocks';
import { AccountBannerT } from '#interfaces/components';

export const AccountBanner: React.FC<AccountBannerT> = ({
  plan,
  data,
  suscribed,
  showAction = false,
  managerCode,
  webUser,
}) => {
  const cardCss = classNames(
    'rounded-t h-24  flex justify-between items-center px-8',
    plan === 'lender' && 'text-black bg-slate-50 font-extrabold ',
    plan === 'freemium' && 'text-white bg-blue-500 mb-2',
    plan === 'premium' && 'text-white bg-amber-500 mb-2',
  );
  const descriptionCss = classNames(
    'text-slate-500 flex justify-between px-8 pt-6',
  );
  const price = data.pack_usuarios * (plans[plan]?.price?.money || 0);

  return (
    <div className="shadow rounded-md mt-2">
      <div className={cardCss}>
        <h1 className="text-2xl">{plans[plan].title}</h1>
        {suscribed && (
          <h4 className="bg-blue-200 w-24 h-6 text-center text-blue-700 font-extrabold">
            Plan Actual
          </h4>
        )}
      </div>
      <div className={descriptionCss}>
        <p>{plans[plan].description}</p>
      </div>
      {plans[plan].price ? (
        <div className="flex justify-content px-4">
          <h2 className="font-black text-2xl p-4 mt-4">{`${plans[plan]?.price?.currency} ${price}`}</h2>
          <Subscription suscribed={suscribed} plan={plan} data={data} />
        </div>
      ) : (
        !managerCode && (
          <Link
            className="mt-8 text-lg flex text-blue-500 py-4 px-8 justify-start hover:text-blue-400 cursor-pointer"
            to="/lenders"
          >
            <p className="mr-3">{plans[plan].btnText}</p>
            <Icons className="h-6" type="arrow_right_icon" height={'6px'} />
          </Link>
        )
      )}
      {showAction && (
        <ActionCard suscribed={suscribed} data={data} webUser={webUser} />
      )}
    </div>
  );
};
