import React from 'react';
import { NormalCheckBoxT } from '#interfaces/components';

export const Normal: React.FC<NormalCheckBoxT> = ({
  className,
  checked,
  text,
  onChange,
}) => {
  const onChangeHandler = (e: any) => onChange(e);

  return (
    <div className="flex h-12 items-center">
      <input
        type="checkbox"
        value={text}
        checked={checked}
        name={text}
        onChange={onChangeHandler}
        className={className}
      />
      <p className="mr-2 text-slate-900 relative text-base">{text}</p>
    </div>
  );
};
