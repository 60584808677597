import React from 'react';
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import { NumericField } from '../../../../commons';
import { useFieldProps } from '../../../../../hooks';
import { zeroToNull } from '../../../../../utils';
import { ValueToPayT } from '#interfaces/components';
import './index.scss';

export const ValueToPay: React.FC<ValueToPayT> = ({
  name,
  entity,
  label,
  paymentOption,
  defaultValue,
  maxLength,
}) => {
  const isMounted = React.useRef(false);
  const fieldName = `${name}.${entity}_${paymentOption}`;
  const oppositeFieldName = `${name}.${entity}_${
    paymentOption === 'amount' ? 'percentage' : 'amount'
  }`;
  const canEditFieldName = `${name}.can_edit`;
  const { field, setFieldValue } = useFieldProps({ name: fieldName });
  const { setFieldValue: setOppositeFieldValue } = useFieldProps({
    name: oppositeFieldName,
  });
  const { field: canEditField } = useFieldProps({ name: canEditFieldName });
  const checked =
    canEditField.value && field.value !== false && field.value >= 0;

  React.useEffect(() => {
    async function updateValues() {
      if (isMounted.current && !canEditField.value) {
        if (Number.isInteger(defaultValue)) {
          setFieldValue(zeroToNull(defaultValue));
        } else if (Number.isInteger(field.value)) {
          setFieldValue(zeroToNull(field.value));
        } else {
          setFieldValue(null);
        }
      }
    }

    updateValues();
    isMounted.current = true;
  }, [canEditField.value, defaultValue, field.value, setFieldValue]);
  const handleRadioButton = () => {
    setFieldValue(0);
    setOppositeFieldValue(null);
  };

  return (
    <div className="col-span-6">
      <RadioButtonComponent
        label={label}
        checked={checked}
        disabled={!canEditField.value}
        change={handleRadioButton}
      />
      <NumericField
        placeholder="Monto"
        name={fieldName}
        disabled={!checked}
        min={0}
        max={maxLength}
      />
    </div>
  );
};
