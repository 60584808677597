import { useEffect, useState } from 'react';

const demos = ['demo', 'demo1'];

const checkUserName = (name: string) => {
  return !!demos.includes(name);
};

export function useDemoName(userName: string) {
  const [isDemo, setIsDemo] = useState(() => checkUserName(userName));

  useEffect(() => {
    setIsDemo(checkUserName(userName));
  }, [userName]);

  return { isDemo, checkUserName };
}
