import {
  configureStore,
  combineReducers,
  Middleware,
  Dispatch,
  AnyAction,
} from '@reduxjs/toolkit';
import { persistReducer, PersistConfig } from 'redux-persist';
import thunk, { ThunkDispatch } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import {
  loginReducer,
  commonsReducer,
  onboardingsReducer,
  patientsReducer,
  accountReducer,
  configReducer,
  budgetReducer,
  scheduleReducer,
  homeReducer,
  statisticsReducer,
  lendersReducer,
  reminderReducer,
  orderLaboratoryReducer,
  recipesReducer,
} from './slices';

const persistConfig: PersistConfig<any, any, any, any> = {
  key: 'root',
  storage,
  whitelist: ['login', 'onboardings'],
};

const rootReducer = combineReducers({
  commons: commonsReducer,
  login: loginReducer,
  home: homeReducer,
  onboardings: onboardingsReducer,
  patients: patientsReducer,
  account: accountReducer,
  config: configReducer,
  reminder: reminderReducer,
  statistics: statisticsReducer,
  lenders: lendersReducer,
  budgets: budgetReducer,
  schedule: scheduleReducer,
  laboratories: orderLaboratoryReducer,
  recipes: recipesReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware: Middleware[] = [thunk];

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction> & Dispatch;
