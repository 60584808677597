import React from 'react';
import { BenefitRow } from './benefit-row';
import { BenefitT } from '#interfaces/general-values';

export const CardDetailTooth: React.FC<{
  selectedQuantity: number;
  benefits: BenefitT[];
}> = ({ selectedQuantity, benefits }) => {
  const benefitRows: JSX.Element[] = [];
  for (let i = 0; i < selectedQuantity; i++) {
    benefitRows.push(
      <BenefitRow name="todos" position={i} benefits={benefits} />,
    );
  }

  return <div className="grid grid-cols-2 gap-10 py-4">{benefitRows}</div>;
};
