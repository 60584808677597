import React from 'react';
import { Button } from '../components';
import errorImage from '../static/error_network.svg';

export const NotFound: React.FC = () => {
  return (
    <div className="flex items-center mt-40 flex-col">
      <img
        className="w-44"
        src={errorImage}
        alt="Crying thoot for error page"
      />
      <h2 className="text-xl font-bold mt-4 mb-2">
        Ups! Parece que la página no éxiste
      </h2>
      <p className="text-sm mb-8 font-mono bg-orange-100 py-1 px-2 rounded text-orange-900">
        ERROR: 404 Not Found
      </p>
      <Button className="!w-auto" href="/">
        Ir al inicio
      </Button>
    </div>
  );
};
