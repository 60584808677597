import React, { Dispatch, SetStateAction } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getFacetedUniqueValues,
  getFacetedRowModel,
  VisibilityState,
  ColumnFiltersState,
} from '@tanstack/react-table';
import { TableToolbar } from '../toolbar';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../shadcn';
import { useSetHeight } from '../../../../hooks';
import { AppointmentT } from '#interfaces/general-values';

export function Daily({
  columns,
  data,
  setColumnVisibility,
  columnVisibility,
}: {
  columns: any;
  data: AppointmentT[];
  setColumnVisibility: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
  columnVisibility: VisibilityState | undefined;
}) {
  const height = useSetHeight(465);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const table = useReactTable({
    data,
    columns,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
    state: {
      columnFilters,
      columnVisibility,
    },
  });

  const options = [
    {
      value: 'ATE',
      label: 'Atendido',
    },
    {
      value: 'ASA',
      label: 'Ausente sin aviso',
    },
    {
      value: 'PRE',
      label: 'Presente',
    },
    {
      value: 'ACA',
      label: 'Ausente con aviso',
    },
    {
      value: 'CAN',
      label: 'Cancelado',
    },
    {
      value: 'CON',
      label: 'Confirmado',
    },
    {
      value: null,
      label: 'Sin confirmar',
    },
  ];

  return (
    <div>
      <TableToolbar table={table} options={options} />
      <div className="rounded-md bg-white border shadow-md" id="schedule-table">
        <Table className="rounded-md" height={`${height}px`}>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                key={headerGroup.id}
                className="sticky top-0 bg-white z-10"
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  Sin resultados.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
