import React from 'react';
import { Columns } from './columns';
import { Data } from './data';
import { tablePatientChMapper } from '../../../utils';

export const Table: React.FC<any> = ({ data, action }) => {
  return (
    <div className="mx-auto w-full">
      <Data
        columns={Columns({ action })}
        data={tablePatientChMapper(data).reverse()}
      />
    </div>
  );
};
