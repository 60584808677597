import { useEffect, useState } from 'react';
import { MedicalInsurancesT } from '#interfaces/general-values';

export function useForm(
  medicalInsurances: MedicalInsurancesT[],
  selectedMed: MedicalInsurancesT | undefined,
  check: boolean | undefined,
) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues(medicalInsurances, selectedMed, check),
  );

  useEffect(() => {
    setInitialValues(getInitialValues(medicalInsurances, selectedMed, check));
  }, [medicalInsurances, selectedMed, check]);

  return { initialValues };
}

function getInitialValues(
  medicalInsurances: MedicalInsurancesT[],
  selectedMed: MedicalInsurancesT | undefined,
  check: boolean | undefined,
) {
  const medicalInsurance =
    selectedMed?.id_os ||
    medicalInsurances.filter((el) => !el.inhabilitada)[0]?.id_os ||
    null;

  const plan =
    selectedMed?.planes[0]?.id_plan ||
    medicalInsurances.filter((el) => !el.inhabilitada)[0]?.planes[0]?.id_plan;

  return {
    medical_insurance: medicalInsurance,
    plan,
    show_enabled: check || false,
  };
}
