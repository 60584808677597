import React from 'react';
import Apple from './apple-icon';
import Android from './android-icon';
import Facebook from './facebook-icon';
import Instagram from './instagram-icon';
import WhatsApp from './whatsapp-icon';
import {
  MoreVertical,
  PlaneLanding,
  CalendarClock,
  CircleSlash,
  Contact,
  Search,
  Settings2,
  CheckCircleIcon,
  Trash2,
  EyeIcon,
  ImageIcon,
  File,
  FileQuestion,
  Clapperboard,
  AlarmClock,
  AlertCircle,
} from 'lucide-react';
import {
  AdjustmentsVerticalIcon,
  AdjustmentsHorizontalIcon,
  ArrowRightOnRectangleIcon,
  ArrowPathIcon,
  ArrowRightIcon,
  BuildingOfficeIcon,
  CalendarIcon,
  CalendarDaysIcon,
  CogIcon,
  ChatBubbleLeftEllipsisIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  CheckIcon,
  ClipboardDocumentListIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentCheckIcon,
  DocumentTextIcon,
  CloudArrowUpIcon,
  ClockIcon,
  EnvelopeOpenIcon,
  EyeSlashIcon,
  EllipsisVerticalIcon,
  HomeIcon,
  InformationCircleIcon,
  PaperAirplaneIcon,
  PencilSquareIcon,
  SparklesIcon,
  UserGroupIcon,
  UsersIcon,
  XMarkIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  IdentificationIcon,
  GlobeAltIcon,
  QueueListIcon,
  ChartBarIcon,
  ArrowTrendingUpIcon,
  UserPlusIcon,
  QuestionMarkCircleIcon,
  PlusIcon,
  PencilIcon,
  MegaphoneIcon,
  BeakerIcon,
  NoSymbolIcon,
  DocumentArrowDownIcon,
} from '@heroicons/react/24/outline';
import { IconsT } from '#interfaces/components/index';

const icons: { [key: string]: React.ComponentType<any> } = {
  apple: Apple,
  android: Android,
  alert: ExclamationCircleIcon,
  arrow_back: ChevronLeftIcon,
  arrow_down: ChevronDownIcon,
  arrival: PlaneLanding,
  arrow_right: ChevronRightIcon,
  arrow_top: ChevronUpIcon,
  arrow_right_icon: ArrowRightIcon,
  building: BuildingOfficeIcon,
  close: XMarkIcon,
  check_clean: CheckCircleIcon,
  check: CheckIcon,
  context: MoreVertical,
  copy_manual: PencilSquareIcon,
  copy_automatic: ClipboardDocumentCheckIcon,
  config: CogIcon,
  cancel: CircleSlash,
  clock: AlarmClock,
  dentist: UserGroupIcon,
  document: DocumentTextIcon,
  delete: Trash2,
  eye: EyeIcon,
  email_list: EnvelopeOpenIcon,
  ellipsis_vertical: EllipsisVerticalIcon,
  edit: PencilIcon,
  facebook: Facebook,
  file_broken: EyeSlashIcon,
  file: File,
  file_unknown: FileQuestion,
  globe: GlobeAltIcon,
  help: InformationCircleIcon,
  home: HomeIcon,
  holidays: SparklesIcon,
  instagram: Instagram,
  image: ImageIcon,
  identification: IdentificationIcon,
  knob: AdjustmentsVerticalIcon,
  knob_horizontal: AdjustmentsHorizontalIcon,
  logout: ArrowRightOnRectangleIcon,
  list: ClipboardDocumentListIcon,
  success: MegaphoneIcon,
  overturn: CalendarClock,
  plus: PlusIcon,
  patient_data: Contact,
  queue: QueueListIcon,
  question: QuestionMarkCircleIcon,
  refresh: ArrowPathIcon,
  schedule: CalendarIcon,
  send: PaperAirplaneIcon,
  search: Search,
  settings: Settings2,
  sms: ChatBubbleLeftEllipsisIcon,
  specialty: ClipboardDocumentIcon,
  today: ClockIcon,
  user: UsersIcon,
  upload: CloudArrowUpIcon,
  video: Clapperboard,
  weekly: CalendarDaysIcon,
  whatsapp: WhatsApp,
  warning: ExclamationTriangleIcon,
  statistics: ChartBarIcon,
  trending_up: ArrowTrendingUpIcon,
  user_plus: UserPlusIcon,
  beaker: BeakerIcon,
  no_simbol: NoSymbolIcon,
  download: DocumentArrowDownIcon,
  error: AlertCircle,
};

export const Icons: React.FC<IconsT> = ({
  type,
  width,
  height,
  className,
  onClick,
  fill = 'transparent',
}) => {
  const Icon = icons[type];

  return (
    <Icon
      width={width}
      height={height}
      className={className}
      fill={fill}
      onClick={onClick}
    />
  );
};
