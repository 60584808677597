import React from 'react';
import { Icons, TextField } from '#components/commons';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '#components/shadcn';

export const Filter: React.FC<{ table: any; name: string }> = ({
  table,
  name,
}) => {
  const column = React.useMemo(() => table.getColumn(name), [table, name]);

  return (
    <TextField
      className="w-54 h-10"
      placeholder="Filtrar por paciente..."
      value={column?.getFilterValue() ?? ''}
      onChange={(value) => column?.setFilterValue(value)}
    />
  );
};

export const Visibility: React.FC<{ table: any }> = ({ table }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <p
          className="h-10 inline-flex items-center justify-between gap-x-1.5 rounded-md bg-white px-4 py-2 text-sm font-semibold text-slate-600 shadow-sm border hover:bg-gray-50 hover:cursor-pointer"
          id="schedule-view-visibility"
        >
          <Icons type="settings" className="w-5 h-5" />
          Columnas
        </p>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {table
          .getAllColumns()
          .filter((column: any) => column.getCanHide())
          .map((column: any) => {
            const extractHeaderValue = (header: any) => {
              if (typeof header === 'function') {
                const headerElement = header({ column: {} });
                return headerElement.props.children[0];
              }
              return header;
            };

            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
              >
                {extractHeaderValue(column.columnDef.header)}
              </DropdownMenuCheckboxItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
