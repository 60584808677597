import * as React from 'react';
import { Icons } from '#components/commons';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuGroup,
} from '#components/shadcn';

export const Columns = (action: any) => {
  const columns = [
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="relative p-2 hover:cursor-pointer flex-none">
                <Icons
                  type="context"
                  className="text-gray-500 hover:text-gray-900 h-4 w-4"
                />
                <span className="sr-only">Abrir menu</span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start">
              <DropdownMenuGroup>
                <DropdownMenuItem
                  className="hover:cursor-pointer"
                  onSelect={() => action(row.original, 'edit')}
                >
                  <Icons type="edit" className="mr-2 h-4 w-4" />
                  <span>Editar</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="hover:cursor-pointer text-red-500"
                  onSelect={() => action(row.original, 'delete')}
                >
                  <Icons type="delete" className="mr-2 h-4 w-4" />
                  <span>Eliminar</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: 'code',
      header: 'Código',
      cell: ({ row }: { row: any }) => {
        const { code } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{code}</p>
        );
      },
    },
    {
      accessorKey: 'concept',
      header: 'Concepto',
      cell: ({ row }: { row: any }) => {
        const { concept } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{concept}</p>
        );
      },
    },
    {
      accessorKey: 'quantity',
      header: 'Cantidad',
      cell: ({ row }: { row: any }) => {
        const { quantity } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {quantity}
          </p>
        );
      },
    },
    {
      accessorKey: 'tooth_section',
      header: 'Pieza/s',
      cell: ({ row }: { row: any }) => {
        const { tooth_section } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {tooth_section}
          </p>
        );
      },
    },
    {
      accessorKey: 'color',
      header: 'Color',
      cell: ({ row }: { row: any }) => {
        const { color } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{color}</p>
        );
      },
    },
    {
      accessorKey: 'individual_amount',
      header: 'Imp. indiv.',
      cell: ({ row }: { row: any }) => {
        const { individual_amount } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {individual_amount}
          </p>
        );
      },
    },
    {
      accessorKey: 'total_amount',
      header: 'Imp. total',
      cell: ({ row }: { row: any }) => {
        const { total_amount } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-green-500">
            {total_amount}
          </p>
        );
      },
    },
  ];
  !action && columns.splice(0, 1);

  return columns;
};
