import React from 'react';
import { Button } from '../shadcn/button';
import { PrivacyT } from '#interfaces/components';

export const Privacy: React.FC<PrivacyT> = ({
  text,
  linkText,
  href,
  textButton,
  onSubmit,
}) => {
  return (
    <div className="flex items-center">
      <p className="text-white mr-2">{text}</p>
      <a className="text-white mr-10 hover:underline" href={href}>
        {linkText}
      </a>
      <Button className="py-2 px-4" onClick={onSubmit}>
        {textButton}
      </Button>
    </div>
  );
};
