import React from 'react';
import { Formik } from 'formik';
import classNames from 'classnames';
import { useForm } from './use-form';
import { Controller } from './controller';
import { ScheduleCheckbox } from './schedule-checkbox';
import { Card, CardContent } from '#components/shadcn';
import { ProfessionalsFormT } from '#interfaces/components';

const ProfessionalsForm: React.FC<ProfessionalsFormT> = ({
  professionals,
  selectedProf,
  hasSchedule,
  children,
  onChange,
  newProf,
}) => {
  const { initialValues } = useForm(professionals, selectedProf);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={() => {}}
    >
      <Card
        className={classNames('mt-5', {
          'pointer-events-none opacity-60': newProf,
        })}
      >
        <CardContent className="!p-6 items-end relative z-40 grid grid-cols-4 gap-x-10">
          <Controller
            name="professional"
            showenabledFieldName="show_enabled"
            professionals={professionals}
          />
          {hasSchedule ? <ScheduleCheckbox onChange={onChange} /> : <div />}
          {children}
        </CardContent>
      </Card>
    </Formik>
  );
};

export default ProfessionalsForm;
