import { useEffect, useState } from 'react';

export function useForm(
  professionals: { [key: string]: any }[],
  selectedProf: { [key: string]: any },
) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues(professionals, selectedProf),
  );
  useEffect(() => {
    setInitialValues(getInitialValues(professionals, selectedProf));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professionals, selectedProf]);

  return { initialValues };
}

function getInitialValues(
  professionals: { [key: string]: any }[],
  selectedProf: { [key: string]: any },
) {
  return {
    professional: selectedProf || professionals[0]?.id_prof,
    professionalSchedule:
      professionals.find((el) => el.id_prof === selectedProf) ||
      professionals.filter((el) => el.habilitado)?.[0],
    no_schedule:
      professionals.find((el) => el.id_prof === selectedProf)
        ?.no_tiene_agenda || false,
    show_enabled: false,
  };
}
