import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useJwt } from 'react-jwt';
import { Button } from '../../components';

export const FreemiumForm: React.FC<{ onCancel: () => void }> = ({
  onCancel,
}) => {
  const {
    login: { token },
  } = useSelector((state: any) => state);
  const [userData, setUserData] = useState<any>({ user: '', webUser: '' });
  const { decodedToken }: { decodedToken: any } = useJwt(token);

  useEffect(() => {
    if (decodedToken)
      setUserData({ user: decodedToken.User, webUser: decodedToken.WebUser });
  }, [decodedToken]);

  return (
    <>
      <p className="my-6 text-base font-regular text-neutral-500">
        Esta función esta disponible solamente en la versión de Bilog Premium.
        ¿Quieres suscribirte a Bilog Premium?
      </p>
      <div className="flex items-center gap-x-4">
        <Button
          size="large"
          href={`https://signup.bilog.com.ar/checkout?email=${userData.user}.com&webuser=${userData.webUser}`}
        >
          Contratar ahora
        </Button>
        <Button onClick={onCancel} modifier="warning" size="large">
          Cancelar
        </Button>
      </div>
    </>
  );
};
