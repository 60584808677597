import React from 'react';
import { Formik } from 'formik';
import { useForm } from './use-form';
import { useSubmit } from './use-submit';
import { GraphicContainer } from './graphic-container';
import { DatesComponent } from './dates';
import { Parameters } from './parameters-badges';
import {
  ControllerMedIns,
  ControllerOffices,
  ControllerProf,
} from '../Controllers';
import { sentencesAndArticles } from '../../../../mocks';
import { useSetHeight } from '../../../../hooks';
import {
  Button,
  DatePickerWithRange,
  Form,
  Title,
  Sidebar,
} from '../../../../components';
import { FinancesFormT } from '#interfaces/components';

export const FinancesForm: React.FC<FinancesFormT> = ({
  medicalInsurances,
  translations,
  professionals,
  offices,
  finances,
  current,
  handleOnExit,
  onboarding,
}) => {
  const height = useSetHeight(180);
  const [showSidebar, setShowSidebar] = React.useState(false);
  const { initialValues } = useForm(current);

  const onSubmit = (submitForm: () => void) => {
    submitForm();
    setShowSidebar(!showSidebar);
  };

  return (
    <div
      className="p-10 overflow-hidden overflow-y-scroll"
      style={{ height: `${height}px` }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={useSubmit()}
        enableReinitialize
      >
        {({ submitForm }) => (
          <Form showActions={false}>
            <Title>Finanzas</Title>
            <div className="mt-6">
              <div className="grid grid-cols-12 p-5 border border-slate-200 bg-slate-50 rounded-md gap-5 parameters">
                <Parameters
                  medicalInsurances={medicalInsurances}
                  professionals={professionals}
                  offices={offices}
                  translations={translations}
                  sentencesAndArticles={sentencesAndArticles}
                />
                <DatesComponent onClick={() => setShowSidebar(!showSidebar)} />
              </div>
              <Sidebar
                setShowSidebar={setShowSidebar}
                showSidebar={showSidebar}
                title="Parámetros"
                handleOnExit={handleOnExit}
                onboarding={onboarding}
              >
                <div className="border border-slate-200 bg-white rounded-md p-6 mx-4 date-range-picker">
                  <Title className="mb-2 text-xl">Selección de fecha</Title>
                  <DatePickerWithRange
                    align="end"
                    showCompare
                    name="date_comparison"
                  />
                </div>
                <div className="border border-slate-200 bg-white rounded-md p-4 m-4">
                  <Title className="pl-2 mb-2.5 text-xl">
                    {translations.medical_insurance}
                  </Title>
                  <ControllerMedIns
                    translations={translations}
                    medicalInsurances={medicalInsurances}
                    fieldNameAllMedIns="check_all_med_ins"
                    variant="flex"
                  />
                </div>
                {offices?.length > 0 && (
                  <div className="border border-slate-200 bg-white rounded-md p-4 m-4">
                    <Title className="pl-2 mb-2.5 text-xl">Sucursal</Title>
                    <ControllerOffices
                      offices={offices}
                      variant="flex"
                      financesStatitics
                    />
                  </div>
                )}
                <div className="border border-slate-200 bg-white rounded-md p-4 m-4">
                  <Title className="pl-2 mb-2.5 text-xl">Profesional</Title>
                  <ControllerProf
                    professionals={professionals}
                    fieldNameCheckAllProf="check_all_prof"
                    variant="flex"
                  />
                </div>
                <div className="flex flex-row justify-between m-4 gap-5">
                  <Button
                    modifier="transparent"
                    className="w-full"
                    onClick={() => setShowSidebar(!showSidebar)}
                  >
                    Cancelar
                  </Button>
                  <Button
                    className="w-full"
                    onClick={() => onSubmit(submitForm)}
                  >
                    Aplicar
                  </Button>
                </div>
              </Sidebar>
            </div>
          </Form>
        )}
      </Formik>
      <div className="grid grid-cols-12 gap-10 mt-14">
        <GraphicContainer data={finances} translations={translations} />
      </div>
    </div>
  );
};
