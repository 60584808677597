import React from 'react';
import { FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { updateDataAccount } from '../../../services';
import { SnackbarContext, FormContext } from '../../../context';
import { startLoading, stopLoading } from '../../../redux/slices';
import { RegionsT } from '#interfaces/general-values';

export function useSubmit({
  onChange,
  regions,
}: {
  onChange: () => void;
  regions: RegionsT[];
}) {
  const dispatch = useDispatch();
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      dispatch(startLoading());
      await axiosPromise({ spec, regions });
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Datos actualizados correctamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(err.message);
    } finally {
      formikActions.setSubmitting(false);
      dispatch(stopLoading());
    }
  };
}

function axiosPromise({ spec, regions }: { spec: {}; regions: RegionsT[] }) {
  const apiSpec = transformSpec({ spec, regions });

  return updateDataAccount(apiSpec);
}

function transformSpec({ spec, regions }: { spec: any; regions: RegionsT[] }) {
  const country = regions.find(
    (e: { [key: string]: any }) => e.iso3 === spec.country_iso3,
  );
  let apiSpec: { [key: string]: any } = {
    first_name: spec.first_name,
    last_name: spec.last_name,
    country: country?.name,
    country_iso3: country?.iso3,
    birthday: spec.birthday,
    phone: spec.phone,
    is_whatsapp: spec.is_whatsapp,
  };
  return apiSpec;
}
