import React from 'react';
import { DropdownForm, NumericField } from '../../../../../../components';
import { useFieldProps } from '../../../../../../hooks';
import { BenefitDropdownT } from '#interfaces/components';

export const BenefitDropdown: React.FC<BenefitDropdownT> = ({
  numericName,
  numericPlaceholder,
  dropdownName,
  dropdownData,
  dropdownKeyId,
  dropdownKeyData,
  dropdownPlaceholder,
}) => {
  const {
    field: { value },
  } = useFieldProps({ name: dropdownName });
  const { setFieldValue: setFieldNumericValue } = useFieldProps({
    name: numericName,
  });
  const disabled = value === 'none';
  React.useMemo(() => value !== 'none' ? setFieldNumericValue(1) : setFieldNumericValue(0), [value]);

  return (
    <>
      <div className="col-span-3">
        <DropdownForm
          name={dropdownName}
          data={dropdownData}
          label={dropdownPlaceholder}
          keyId={dropdownKeyId}
          keyData={dropdownKeyData}
        />
      </div>
      <div className="col-span-3">
        <NumericField
          disabled={disabled}
          name={numericName}
          label={numericPlaceholder}
          min={value ? 1 : 0}
        />
      </div>
    </>
  );
};
