import React from 'react';
import classNames from 'classnames';
import { Icons, Tooltip, Badge, Select as BadgeSelect } from '../../../commons';
import { attendanceOptions } from '../../../../mocks';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
  HoverCard,
  HoverCardTrigger,
  HoverCardContent,
} from '../../../shadcn';
import { setAppointment } from '../../../../redux/slices';
import { AppointmentT, TranslationsT } from '#interfaces/general-values';
import { CountriesT } from '#interfaces/components/commons';
import { AppDispatch } from '../../../../redux';
import { formatPhoneNumberWhatsapp } from '../../../../utils';

const DropdownActions: React.FC<{
  type: string;
  appointment: AppointmentT;
  actions: any;
  dispatch: AppDispatch;
  permissions: any;
}> = ({ type, appointment, actions, dispatch, permissions }) => {
  const { viewPatientData, giveOverturn, disableAppointment, editObservation } =
    permissions;
  const onSelectHandler = (type: string) => {
    actions({
      appointment,
      type,
    });
    dispatch(setAppointment(appointment));
  };
  const renderActions: { [key: string]: any } = {
    free: () => (
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuLabel>Turnos</DropdownMenuLabel>
        <DropdownMenuGroup>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <Icons type="today" className="mr-2 h-4 w-4" />
              <span>Nuevo turno</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem
                  className="hover:cursor-pointer"
                  onSelect={() =>
                    onSelectHandler('new_appointment_patient_exist')
                  }
                >
                  <Icons type="user" className="mr-2 h-4 w-4" />
                  <span>Paciente existente</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="hover:cursor-pointer"
                  onSelect={() =>
                    onSelectHandler('new_appointment_new_patient')
                  }
                >
                  <Icons type="user_plus" className="mr-2 h-4 w-4" />
                  <span>Paciente nuevo</span>
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          {giveOverturn && (
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onSelect={() => onSelectHandler('new_over_appointment')}
            >
              <Icons type="overturn" className="mr-2 h-4 w-4" />
              <span>Nuevo sobreturno</span>
            </DropdownMenuItem>
          )}
          {disableAppointment && (
            <DropdownMenuItem
              className="hover:cursor-pointer text-red-500"
              onSelect={() => onSelectHandler('disable_appointment')}
            >
              <Icons type="cancel" className="mr-2 h-4 w-4" />
              <span>Anular horario</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    ),
    patient_exist: () => (
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuLabel>Turnos</DropdownMenuLabel>
        <DropdownMenuGroup>
          {giveOverturn && (
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onSelect={() => onSelectHandler('new_over_appointment')}
            >
              <Icons type="overturn" className="mr-2 h-4 w-4" />
              <span>Nuevo sobreturno</span>
            </DropdownMenuItem>
          )}
          {editObservation && (
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onSelect={() => onSelectHandler('edit_observation')}
            >
              <Icons type="edit" className="mr-2 h-4 w-4" />
              <span>Editar observación</span>
            </DropdownMenuItem>
          )}
          {disableAppointment && (
            <DropdownMenuItem
              className="hover:cursor-pointer text-red-500"
              onSelect={() => onSelectHandler('delete_appointment')}
            >
              <Icons type="delete" className="mr-2 h-4 w-4" />
              <span>Eliminar turno</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
        {viewPatientData && (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuLabel>Paciente</DropdownMenuLabel>
            <DropdownMenuGroup>
              <DropdownMenuItem
                className="hover:cursor-pointer"
                onSelect={() => onSelectHandler('preview_patient')}
              >
                <Icons type="patient_data" className="mr-2 h-4 w-4" />
                <span>Ir a datos del paciente</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </>
        )}
      </DropdownMenuContent>
    ),
    patient_first_time: () => (
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuLabel>Turnos</DropdownMenuLabel>
        <DropdownMenuGroup>
          {giveOverturn && (
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onSelect={() => onSelectHandler('new_over_appointment')}
            >
              <Icons type="overturn" className="mr-2 h-4 w-4" />
              <span>Nuevo sobreturno</span>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            className="hover:cursor-pointer"
            onSelect={() => onSelectHandler('new_appointment_new_patient')}
          >
            <Icons type="edit" className="mr-2 h-4 w-4" />
            <span>Modificar turno</span>
          </DropdownMenuItem>
          {disableAppointment && (
            <DropdownMenuItem
              className="hover:cursor-pointer text-red-500"
              onSelect={() => onSelectHandler('delete_appointment')}
            >
              <Icons type="delete" className="mr-2 h-4 w-4" />
              <span>Eliminar turno</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    ),
    canceled: () => (
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuLabel>Turnos</DropdownMenuLabel>
        <DropdownMenuGroup>
          {giveOverturn && (
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onSelect={() => onSelectHandler('new_over_appointment')}
            >
              <Icons type="overturn" className="mr-2 h-4 w-4" />
              <span>Nuevo sobreturno</span>
            </DropdownMenuItem>
          )}
          {disableAppointment && (
            <DropdownMenuItem
              className="hover:cursor-pointer text-green-500"
              onSelect={() => onSelectHandler('delete_appointment')}
            >
              <Icons type="check_clean" className="mr-2 h-4 w-4" />
              <span>Habilitar horario</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    ),
  };

  return renderActions[type]();
};
type RowDataT = {
  name: string;
  id_patient: number;
  hc_number: string;
  medical_insurance_name: string;
  id_medical_insurance: number;
  medical_insurance_plan: string;
  nro_afil: string;
  document: string;
  address: string;
  birth_date: string;
  getValue: (value: number | string) => any;
  original: any;
};

export const weekly = ({
  actions,
  dispatch,
  translations,
  permissions,
  config,
}: {
  actions: any;
  dispatch: AppDispatch;
  translations: TranslationsT;
  permissions: any;
  config: {
    region: string;
    countries: CountriesT[];
  };
}) => {
  const { viewContactData } = permissions;
  const { region, countries } = config;

  return [
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { appointment_type } = row.original;
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="relative py-2 hover:cursor-pointer flex-none">
                <span className="sr-only">Abrir menu</span>
                <Icons
                  type="context"
                  className="text-gray-500 hover:text-gray-900 h-4 w-4"
                />
              </div>
            </DropdownMenuTrigger>
            <DropdownActions
              type={appointment_type.status}
              appointment={row.original}
              actions={actions}
              dispatch={dispatch}
              permissions={permissions}
            />
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: 'time',
      header: 'Hora',
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { appointment_type, time, eta } = row.original;
        const showStatus =
          appointment_type.type === 'overturn' ||
          appointment_type.status === 'canceled';
        const statusCss = classNames(
          'rounded-full p-1 h-1.5 w-1.5 rounded-full',
          appointment_type.type === 'overturn' && 'bg-purple-400',
          appointment_type.status === 'canceled' && 'bg-red-500',
        );
        const appointmentCss = classNames(
          'font-regular text-base text-slate-800',
          showStatus && 'mr-2',
          eta && 'underline hover:cursor-pointer',
        );

        return eta ? (
          <Tooltip
            align="bottom"
            id={`${time}-tooltip`}
            text={`Hora de llegada: ${eta}`}
            offset={{ left: 15 }}
          >
            <div className="flex items-center">
              <p className={appointmentCss}>{time}</p>
              {showStatus && <div className={statusCss} />}
            </div>
          </Tooltip>
        ) : (
          <div className="flex items-center">
            <p className={appointmentCss}>{time}</p>
            {showStatus && <div className={statusCss} />}
          </div>
        );
      },
    },
    {
      accessorKey: 'attendance',
      header: 'Presentismo',
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const attendance = attendanceOptions.find(
          (att) => att.id === row.getValue('attendance'),
        );

        if (
          (!row.original.id_patient && !row.original.patient_name) ||
          row.original.dont_give
        ) {
          return null;
        }

        const badgeCss = classNames(
          attendance?.styles,
          'mlg:w-full md:justify-between',
        );

        return (
          <BadgeSelect
            text={attendance?.id || '-'}
            className={badgeCss}
            options={attendanceOptions}
            onSelect={(e) =>
              actions({
                appointment: row.original,
                type: 'change_attendance',
                attendanceId: e.target.id,
              })
            }
          />
        );
      },
    },
    {
      accessorKey: 'patient_name',
      header: 'Paciente',
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const {
          patient_name,
          date,
          professional_name,
          time,
          patient_phone,
          clinic_history_number,
          medical_insurance: { name, plan, affiliate_number },
        } = row.original;

        const messageDate = new Date(date).toLocaleDateString('es', {
          day: 'numeric',
          year: 'numeric',
          month: 'short',
        });
        const message = `Hola, ${patient_name}! Te recordamos tu turno con *${professional_name}* el dia *${messageDate}* a las *${time}hs*. _Para cancelar o modificar el turno no dudes en contactarnos._ Te esperamos!`;
        const areaNumber =
          countries?.find(
            (country: any) => country.iso3 === region?.toUpperCase(),
          )?.phoneCode || '54';

        const onClickHandler = () => {
          const baseUrl = 'https://wa.me/';
          const VALIDATE_NUMBER = formatPhoneNumberWhatsapp(
            patient_phone,
            areaNumber,
          );
          const completeUrl = `${baseUrl}${VALIDATE_NUMBER}?text=${message}`;
          window.open(completeUrl, '_blank');
        };

        return (
          <div className="min-w-[200px]">
            <HoverCard>
              <HoverCardTrigger>
                <p className="font-bold text-sm text-slate-800 my-1 hover:underline hover:cursor-pointer">
                  {patient_name}
                </p>
              </HoverCardTrigger>
              {clinic_history_number || patient_phone || name ? (
                <HoverCardContent>
                  <h4 className="text-sm font-bold">Datos del paciente</h4>
                  <div className="grid grid-cols-2 gap-5 mt-2">
                    {clinic_history_number && (
                      <div className="flex items-end">
                        <p className="text-sm text-slate-800 mr-2">
                          Historia clínica:
                        </p>
                        <Badge
                          className="w-auto !inline"
                          text={`${clinic_history_number}`}
                        />
                      </div>
                    )}
                    {patient_phone && viewContactData && (
                      <div className="flex items-end">
                        <p className="text-sm text-slate-800 mr-2">
                          Tel:{' '}
                          <span className="text-slate-500">
                            {patient_phone}
                          </span>
                        </p>
                        <span onClick={onClickHandler}>
                          <Icons
                            type="whatsapp"
                            className="ml-2 w-4 h-4 hover:cursor-pointer"
                            fill="#35c13e"
                          />
                        </span>
                      </div>
                    )}
                  </div>
                  {name && (
                    <>
                      <h4 className="text-sm font-bold mt-4">
                        {translations.medical_insurance_data}
                      </h4>
                      <div className="grid grid-cols-2 gap-5 mt-2">
                        {name && (
                          <p className="text-sm text-slate-800">
                            {`${translations.medical_insurance_abbreviation}: `}
                            <span className="text-slate-500">
                              {name} - {plan}
                            </span>
                          </p>
                        )}
                        {affiliate_number && (
                          <p className="text-sm text-slate-800">
                            Nro. Afiliado:{' '}
                            <span className="text-slate-500">
                              {affiliate_number}
                            </span>
                          </p>
                        )}
                      </div>
                    </>
                  )}
                </HoverCardContent>
              ) : (
                <HoverCardContent>
                  <h4 className="text-sm font-bold">
                    No hay datos para mostrar
                  </h4>
                </HoverCardContent>
              )}
            </HoverCard>
          </div>
        );
      },
    },
  ];
};
