import React from 'react';
import { Modal, TabSection } from '#components/index';
import { Button } from '#components/shadcn/button';
import { SuccesFull as Empty } from '#components/commons';
import { BenefitsForm } from './BenefitsForm/forms-benefits';
import { DeleteBenefitForm } from './DeleteBenefitForm';
import { SnackbarContext } from '../../../context';
import { useForm, useSetHeight } from '../../../hooks';
import { emptyStateBenefits, emptyStateNotPatient } from '../../../mocks';
import { Benefits as BenefitTable } from '#components/Tables';
import { BenefitsT } from '#interfaces/components';

export const Benefits: React.FC<BenefitsT> = ({
  userId,
  patient,
  translations,
  benefits,
  offices,
  professionals,
  benefitTypes,
  useUsd,
  onChange,
  handlerFocus,
  permissions,
}) => {
  const { showErrorSnackbar } = React.useContext(SnackbarContext);
  const [selectedBenefit, setSelectedBenefit] = React.useState<any>(null);
  const selectedPatientId = patient.id_paciente;
  const medicalInsurance = patient?.id_planNavigation?.id_osNavigation;
  const { isOpenForm, closeForm, openForm } = useForm();
  const {
    isOpenForm: isOpenModalDelete,
    closeForm: closeModalDelete,
    openForm: openModalDelete,
  } = useForm();
  const height = useSetHeight(patient?.id_paciente ? 478 : 318);

  React.useEffect(() => {
    if (!isOpenForm) {
      setSelectedBenefit(null);
    }
  }, [isOpenForm, setSelectedBenefit]);

  React.useEffect(() => {
    if (!isOpenModalDelete) {
      setSelectedBenefit(null);
    }
  }, [isOpenModalDelete, setSelectedBenefit]);

  const selectBenefit = (id: number, action: string) => {
    const currentBenefit = benefits.find((el: any) => el.id_todos === id);
    setSelectedBenefit(currentBenefit);
    if (action === 'delete') {
      openModalDelete();
    } else if (
      currentBenefit &&
      currentBenefit.id_prestacionNavigation?.id_plan !== patient.id_plan
    ) {
      showErrorSnackbar('No se puede editar la prestación (plan distinto)');
    } else if (useUsd.params && !useUsd.user && currentBenefit?.es_usd) {
      showErrorSnackbar(
        'La prestación seleccionada se encuentra en USD y el usuario no puede operar doble moneda.',
      );
    } else {
      openForm();
    }
  };

  const handlerEmpty = {
    dataMocks: !patient.id_paciente ? emptyStateNotPatient : emptyStateBenefits,
    onClick: !patient.id_paciente ? handlerFocus : openForm,
  };

  return (
    <section className="mt-5" style={{ height: `${height}px` }}>
      {!isOpenForm && (
        <>
          {benefits && benefits.length ? (
            <>
              <BenefitTable
                data={benefits}
                translations={translations}
                action={selectBenefit}
                permissions={permissions}
                config={useUsd}
              />
              {selectedPatientId && (
                <div className="flex mt-5 space-x-5 justify-end">
                  <Button
                    onClick={openForm}
                    className="px-10 h-10 hover:bg-blue-600"
                  >
                    Nueva prestación
                  </Button>
                </div>
              )}
            </>
          ) : (
            <Empty
              typeSucces="emptyState"
              dataMocks={handlerEmpty.dataMocks}
              onClick={handlerEmpty.onClick}
            />
          )}
        </>
      )}
      <TabSection isOpen={isOpenForm} close={closeForm} open={openForm}>
        <BenefitsForm
          height={height}
          benefit={selectedBenefit}
          userId={userId}
          offices={offices}
          medicalInsurance={medicalInsurance}
          patientId={selectedPatientId}
          professionals={professionals}
          benefitTypes={benefitTypes}
          useUsd={useUsd}
          onChange={onChange}
          onCancel={closeForm}
          permissions={permissions}
        />
      </TabSection>
      {selectedBenefit && (
        <Modal
          title="Estás por eliminar una prestación"
          isOpenModal={isOpenModalDelete}
          closeModal={closeModalDelete}
          openModal={openModalDelete}
        >
          <DeleteBenefitForm
            benefit={selectedBenefit}
            onChange={onChange}
            onCancel={closeModalDelete}
          />
        </Modal>
      )}
    </section>
  );
};
