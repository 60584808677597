import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import {
  Copy,
  CheckboxForm,
  TextFieldForm,
  NumericField,
  Form,
  Button,
} from '#components/index';
import { ArticleT } from '#interfaces/tables/article';
import { useSetHeight } from '../../../../../../hooks';

export const ArticleForm: React.FC<ArticleT> = ({
  article,
  selectedLab,
  onChange,
  closeOrBackArticleForm,
  is_usd,
}) => {
  const height = useSetHeight(400);
  const { initialValues, validate } = useForm(article, selectedLab);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={useSubmit(onChange, article)}
      enableReinitialize
    >
      {({ submitForm, isValid }) => (
        <Form
          showActions={false}
          className="relative z-10 flex flex-col justify-between"
          height={height}
        >
          <div>
            <Copy>Carga todos los datos referidos al trabajo.</Copy>
            <div className="grid grid-cols-12 gap-10 mb-4">
              <TextFieldForm
                name="code"
                label="Código"
                className="col-span-3"
              />
              <TextFieldForm
                name="concept"
                label="Concepto"
                className="col-span-3"
              />
              <NumericField
                name="amount"
                label="Importe"
                className="col-span-3"
              />
              <div className="col-span-3 mt-6">
                <CheckboxForm
                  name="enabled"
                  small
                  label="Trabajo habilitado"
                  showError={false}
                />
              </div>
              {is_usd && (
                <div className="col-span-3 mt-6">
                  <CheckboxForm
                    name="is_usd"
                    small
                    label="Importe en USD"
                    showError={false}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-5 relative">
            <Button
              onClick={closeOrBackArticleForm}
              modifier="warning"
              size="large"
            >
              Cancelar
            </Button>
            <Button onClick={submitForm} size="large" disabled={!isValid}>
              Guardar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
