import React from 'react';
import { Columns } from './columns';
import { Data } from './data';

export const Table: React.FC<any> = ({ data, action, config }) => {
  return (
    <div className="mx-auto w-full">
      <Data
        columns={Columns({ action, config })}
        data={data}
        config={config.useUsd}
      />
    </div>
  );
};
