import React from 'react';
import { saveTurn } from '../../../../services';
import {
  SnackbarContext,
  FormContext,
  ModalContext,
} from '../../../../context';
import { FreemiumForm } from '../../../../components';
import { FormikHelpers } from 'formik';

export function useSubmit({
  onChange,
  alert,
}: {
  onChange: (value: any) => void;
  alert: string;
}) {
  const { closeForm } = React.useContext(FormContext);
  const { showSuccessSnackbar, showErrorSnackbar } =
    React.useContext(SnackbarContext);
  const { showModal, hideModal } = React.useContext(ModalContext);

  return async (spec: any, formikActions: FormikHelpers<any>) => {
    try {
      const {
        data: { listaAgenda },
      } = await axiosPromise(spec);

      const newAppointmentData = {
        ...spec,
        alert,
        rowversion: listaAgenda[0].rowversion,
        id_appointment: listaAgenda[0].id_agenda,
      };

      showSuccessSnackbar('Turno guardado con éxito');
      onChange(newAppointmentData);
    } catch (err: any) {
      err.message.includes('40000')
        ? showModal(
            'Suscribirse a Premium',
            <FreemiumForm onCancel={hideModal} />,
          )
        : showErrorSnackbar(
            `${err.message} Error status code: ${err.statusCode}`,
          );
    } finally {
      closeForm();
      formikActions.resetForm();
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(spec: any) {
  const apiSpec = transformSpec(spec);

  return saveTurn(apiSpec);
}

function transformSpec(spec: any) {
  return {
    id_prof: spec.id_professional,
    id_usuario: spec.id_user,
    id_paciente: spec.id_patient,
    id_sucursal: spec.id_office,
    id_agenda: spec.id_appointment,
    opcion: 'agregarturnopacexist',
    hora: spec.time,
    fecha_dado: spec.given_date,
    fecha: spec.date,
    obs: spec.observation,
    es_sobreturno: false,
    no_dar: false,
    asistencia: null,
    paciente: null,
    telefono: null,
    obra_social: spec.medical_insurance,
    plan_nombre: spec.medical_insurance_plan,
    nro_afil: null,
    hora_llegada: null,
    rowversion: spec.rowversion,
  };
}
