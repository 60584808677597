import React from 'react';
import { Icons } from '../../../../../index';

export const Enabled: React.FC<{ [key: string]: any }> = (props) => {
  const { enabled } = props;
  const text = enabled ? (
    <Icons type="check_clean" className="w-6 h-6 text-green-600" />
  ) : (
    <Icons type="close" className="w-6 h-6 text-red-600" />
  );
  return <span className="enabled">{text}</span>;
};
