import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { DataTableProps } from '#interfaces/tables/benefit';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Button,
} from '#components/shadcn';
import { Icons } from '#components/index';
import { useSetHeight } from '../../../../hooks';

export function Data<TData, TValue>({
  columns,
  data,
  onSubmit,
  closeModal,
}: DataTableProps<TData, TValue> & {
  onSubmit: (messages: any[]) => void;
  closeModal: () => void;
}) {
  const height = useSetHeight(300);
  const [rowSelection, setRowSelection] = React.useState({});
  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  });

  const selected =
    table
      .getFilteredSelectedRowModel()
      .rows.map((row: any) => {
        return {
          paciente: row.original.paciente,
          nroTel: row.original.telefono,
        };
      })
      .filter((s) => s.nroTel) || [];

  return (
    <div id="patient-result-table">
      <Table className="rounded-md md border bg-white" height={`${height}px`}>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    className="sticky top-0 bg-white z-10"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row: any) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                className="hover:cursor-pointer"
              >
                {row.getVisibleCells().map((cell: any) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                Sin resultados.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <div className="mt-8 flex flex-row gap-x-4">
        <Button
          className="bg-green-500 hover:bg-green-600 flex px-5 py-3 gap-x-1"
          onClick={() => onSubmit(selected)}
        >
          <Icons fill="#fff" type="whatsapp" />
          <p className="ml-2 inline relative top-[1px]">Enviar mensajes</p>
        </Button>
        <Button
          className="bg-red-500 hover:bg-red-600 flex px-5 py-3 gap-x-2"
          onClick={closeModal}
        >
          Cancelar
        </Button>
      </div>
    </div>
  );
}
