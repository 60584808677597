import React from 'react';
import { Modal } from '../components';
import { useModal } from '../hooks';

export const ModalContext = React.createContext<any>({
  showModal: null,
});
export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isOpenModal, modalTitle, showModal, hideModal, modalContent } =
    useModal();

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      <Modal
        title={modalTitle}
        isOpenModal={isOpenModal}
        closeModal={hideModal}
        openModal={showModal}
      >
        {modalContent}
      </Modal>
    </ModalContext.Provider>
  );
};
