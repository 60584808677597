import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Copy, Form } from '../../../../../../components';
import { DeleteSpecialtyFormT } from '#interfaces/components';

export const DeleteSpecialtyForm: React.FC<DeleteSpecialtyFormT> = ({
  specialty,
  onChange,
}) => {
  return (
    <Formik initialValues={{}} onSubmit={useSubmit(specialty, onChange)}>
      <Form submitTitle="Eliminar" ignoreDirty>
        <Copy>
          Estás por eliminar esta especialidad y esta acción es irreversible, la
          especialidad se eliminará definitivamente.
        </Copy>
      </Form>
    </Formik>
  );
};
