import React from 'react';
import { CheckboxForm, Error } from '../../../commons';
import { useFieldProps, useBenefitType } from '../../../../hooks';
import classNames from 'classnames';
import { ToothFacesT } from '#interfaces/components';

export const ToothFaces: React.FC<ToothFacesT> = ({
  name,
  benefitTypeFieldName,
  benefitTypes,
  className,
  checksCss,
}) => {
  const [disabled, setDisabled] = React.useState(true);
  const { benefitType } = useBenefitType({
    benefitTypeFieldName,
    benefitTypes,
  });
  const { setFieldValue } = useFieldProps({ name });

  React.useEffect(() => {
    if (!benefitType || !benefitType.pide_cara) {
      setDisabled(true);
      setFieldValue(null);
    } else {
      setDisabled(!benefitType.pide_cara);
    }
  }, [benefitType, setFieldValue]);

  const toothFacesOptions = ['v', 'p', 'o', 'd', 'm', 'l', 'i', 'g'];
  const checksStyles = classNames('mt-1.5 grid grid-cols-4', checksCss);

  return (
    <div className={className}>
      <label htmlFor="input" className="mb-2 block">
        Cara/s
      </label>
      <div className={checksStyles}>
        {toothFacesOptions.map((option, i) => (
          <CheckboxForm
            key={i}
            label={option.toUpperCase()}
            name={`${name}.${option}`}
            disabled={disabled}
            showError={false}
          />
        ))}
      </div>
      <Error name={name} />
    </div>
  );
};
