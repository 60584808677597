import React from 'react';
import { TextComponent } from './text-component';
import emptyState from '../../../../static/imagesGraphics/empty_state.png';
import { Copy, NewGraphic, Title } from '../../../../components';
import {
  colors,
  namesGraphics,
  variationIcons,
} from '../../../../mocks/statistics';
import { TranslationsT } from '#interfaces/general-values';
import { numberToCurrency } from '../../../../utils';

export const GraphicContainer: React.FC<{
  data: any;
  translations: TranslationsT;
}> = ({ data, translations }) => {
  const itemsGraphics = Object.keys(data)
    .filter(
      (i) => data[i] !== null && data[i] !== 0 && data[i].chartData !== null,
    )
    .map((key, index) => ({
      ...data[key],
      total: numberToCurrency(data[key]['total']),
      color: colors[index],
      title: namesGraphics[key].title,
      subtitle: namesGraphics[key].subtitle,
      translations: namesGraphics[key].translations,
    }));

  const itemsComponents = Object.keys(data)
    .filter((i) => data[i] !== null && data[i] !== 0)
    .map((key, index) => ({
      ...data[key],
      color: colors[index],
      title: namesGraphics[key].title,
      subtitle: namesGraphics[key].subtitle,
      translations: namesGraphics[key].translations,
    }));
  const nullItems = itemsComponents.filter((item) => item.chartData === null);
  const groupedNullItems = [];
  for (let i = 0; i < nullItems.length; i += 2) {
    groupedNullItems.push({
      first: {
        ...nullItems[i],
      },
      second: {
        ...nullItems[i + 1],
      },
    });
  }

  for (let i = 0; i < groupedNullItems.length; i++) {
    const nullIndex = itemsComponents.indexOf(nullItems[i * 2]);
    itemsGraphics.splice(nullIndex, 0, groupedNullItems[i]);
  }

  return (
    <>
      {itemsGraphics.map((key) => {
        const compare = key.chartData?.length < 0 || key.performance;
        const title =
          key.translations && key.title !== 'Facturación total'
            ? `${key.title} ${translations.medical_insurance}`
            : key.title;
        const subTitle = key.translations
          ? `${key.subtitle} ${translations.medical_insurance}.`
          : key.subtitle;
        if (key && key.chartData) {
          return (
            <div className="col-span-6 border border-slate-300 rounded-md p-8 px-6 graphics">
              <div>
                <Title className="text-xl text-slate-900">{title}</Title>
                <Copy className="text-slate-400 pb-6">{subTitle}</Copy>
                {key.chartType === 'line' && (
                  <Title className="text-3xl">{key?.total} Totales</Title>
                )}
                {key.chartType === 'line' && key.performance && (
                  <span
                    className={variationIcons[key.performance.type]?.className}
                  >
                    {`${
                      variationIcons[key.performance.type]?.sumOrRest || ''
                    } ${key.performance.variationValue} (${
                      variationIcons[key.performance.type]?.arrow || ''
                    }%${key.performance.variationPercentage})`}
                  </span>
                )}
                {key.chartData.length > 0 ? (
                  <NewGraphic data={key} color={key.color} compare={compare} />
                ) : (
                  <img src={emptyState} alt="emptyState" />
                )}
              </div>
            </div>
          );
        }
        return (
          <TextComponent
            data={key}
            variationIcons={variationIcons}
            translations={translations}
          />
        );
      })}
    </>
  );
};
