import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux';
import { format } from 'date-fns';
import {
  startLoading,
  stopLoading,
  listFinances,
  setCurrentStatistics,
} from '../../../../redux/slices';
import { SnackbarContext, FormContext } from '../../../../context';
import { FormikHelpers } from 'formik';

export function useSubmit() {
  const dispatch: AppDispatch = useDispatch();
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async (spec: any, formikActions: FormikHelpers<any>) => {
    try {
      dispatch(startLoading());
      await axiosPromise(spec, dispatch);
      dispatch(setCurrentStatistics({ financesCurrent: spec }));
      showSuccessSnackbar('Estadísticas generadas correctamente');
      closeForm();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
      dispatch(stopLoading());
    }
  };
}

async function axiosPromise(spec: any, dispatch: AppDispatch) {
  const apiSpec = transformSpec(spec);

  return await dispatch(listFinances(apiSpec));
}

function transformSpec(spec: any) {
  const { comparation_date_from, comparation_date_to, date_from, date_to } =
    spec.date_comparison;
  const from = format(date_from, 'yyyy-MM-dd');
  const to = format(date_to, 'yyyy-MM-dd');
  const fromComparation =
    (comparation_date_from && format(comparation_date_from, 'yyyy-MM-dd')) ||
    null;
  const toComparation =
    (comparation_date_to && format(comparation_date_to, 'yyyy-MM-dd')) || null;

  return {
    DateFrom: from,
    DateTo: to,
    ComparationDateFrom: fromComparation,
    ComparationDateTo: toComparation,
    ProfessionalId: spec.id_prof,
    MedicalInsuranceId: spec.id_med_ins,
    OfficeId: spec.id_subsidiary,
    CalculateInUSD: spec.calculate_usd,
  };
}
