import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dropdown } from '../../../../../components';
import { insertBenefit } from '../../../../../redux/slices';
import { useFieldProps } from '../../../../../hooks';

export const ServicePerformed = ({
  hiddenView,
  benefit,
}: {
  hiddenView: () => void;
  benefit: { [key: string]: any };
}) => {
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const {
    field: { value: discount },
  } = useFieldProps({ name: 'discount' });
  const {
    field: { value: iva },
  } = useFieldProps({ name: 'iva' });
  const {
    field: { value: interestRate },
  } = useFieldProps({ name: 'interest_rate' });
  const dispatch = useDispatch();
  const handlerClick = () => {
    hiddenView();
    return dispatch(
      insertBenefit({
        ...benefit,
        discount,
        iva,
        interest_rate: interestRate,
        selected_quantity: selectedQuantity,
        performance_indicator: true,
      }),
    );
  };
  const dropDownDataGenerate = (cant: number) => {
    return Array.from({ length: cant }, (_, index) => ({ id: index + 1 }));
  };

  return (
    <div className="grid grid-cols-12 grid-rows-2 pt-3 w-96">
      <Dropdown
        className="col-span-12 row-span-1"
        data={dropDownDataGenerate(benefit?.quantity)}
        keyId="id"
        keyData="id"
        onChange={setSelectedQuantity}
        currentValue={selectedQuantity}
      />
      <Button className="mt-6 col-span-12 row-span-1" onClick={handlerClick}>
        siguiente
      </Button>
    </div>
  );
};
