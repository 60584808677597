import React from 'react';
import classNames from 'classnames';
import { Icons, TextField, Badge } from '#components/commons';
import {
  Command,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandSeparator,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '#components/shadcn';

export const Filter: React.FC<{ table: any; name: string }> = ({
  table,
  name,
}) => {
  const column = React.useMemo(() => table.getColumn(name), [table, name]);

  return (
    <TextField
      className="w-54 h-10"
      placeholder="Filtrar por paciente..."
      value={column?.getFilterValue() ?? ''}
      onChange={(value) => column?.setFilterValue(value)}
    />
  );
};

export const Visibility: React.FC<{ table: any }> = ({ table }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <p
          className="h-10 inline-flex items-center justify-between gap-x-1.5 rounded-md bg-white px-4 py-2 text-sm font-semibold text-slate-600 shadow-sm border hover:bg-gray-50 hover:cursor-pointer"
          id="schedule-view-visibility"
        >
          <Icons type="settings" className="w-5 h-5" />
          Columnas
        </p>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {table
          .getAllColumns()
          .filter((column: any) => column.getCanHide())
          .map((column: any) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
              >
                {column.columnDef.header}
              </DropdownMenuCheckboxItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const FacetedFilter: React.FC<{
  title: string;
  table: any;
  name: string;
  options: any;
}> = ({ title, table, name, options }) => {
  const column = table.getColumn(name);
  const facets = column?.getFacetedUniqueValues();
  const selectedValues = new Set(column?.getFilterValue());

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div
          className="h-10 inline-flex items-center justify-between gap-x-1.5 rounded-md bg-white px-4 py-2 text-sm font-semibold text-slate-600 shadow-sm border border-dashed hover:bg-gray-50 hover:cursor-pointer"
          id="schedule-view-filter-pres"
        >
          <Icons type="plus" className="w-5 h-5" />
          {title}
          {selectedValues?.size > 0 && (
            <>
              <span className="w-px h-full bg-gray-200/80" />
              {selectedValues.size > 2 ? (
                <Badge
                  text={`${selectedValues.size} seleccionados`}
                  className="bg-slate-100 text-slate-700"
                />
              ) : (
                options
                  .filter((option: any) => selectedValues.has(option.value))
                  .map((option: any) => (
                    <Badge
                      key={option.value}
                      text={option.label}
                      className="bg-slate-100 text-slate-700"
                    />
                  ))
              )}
            </>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0" align="start">
        <Command>
          <CommandInput placeholder={title} />
          <CommandList>
            <CommandEmpty>No se encontraron resultados.</CommandEmpty>
            <CommandGroup>
              {options.map((option: any) => {
                const isSelected = selectedValues.has(option.value);
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      if (isSelected) {
                        selectedValues.delete(option.value);
                      } else {
                        selectedValues.add(option.value);
                      }
                      const filterValues = Array.from(selectedValues);
                      column?.setFilterValue(
                        filterValues.length ? filterValues : undefined,
                      );
                    }}
                  >
                    <div
                      className={classNames(
                        'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-px',
                        isSelected
                          ? 'bg-blue-500 border-blue-500 text-white'
                          : 'opacity-50 [&_svg]:invisible border-slate-700',
                      )}
                    >
                      <Icons type="check" className="h-4 w-4" />
                    </div>
                    {option.icon && option.icon}
                    <span>{option.label}</span>
                    {facets?.get(option.value) && (
                      <span className="ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs">
                        {facets.get(option.value)}
                      </span>
                    )}
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => column?.setFilterValue(undefined)}
                    className="justify-center text-center"
                  >
                    Limpiar filtros
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
