import React from 'react';
import { useNavigate } from 'react-router-dom';
import Autoplay from 'embla-carousel-autoplay';
import { ItemMaterialT, MaterialT } from '#interfaces/components';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '#components/commons/carousel';

const Item: React.FC<ItemMaterialT> = ({ src, alt, url, isSupervisor }) => {
  const navigate = useNavigate();
  const isExternalLink = url.includes('http') || url.includes('https');

  return (
    <div
      onClick={() =>
        isExternalLink
          ? window.open(url, '_blank')
          : isSupervisor && navigate(url)
      }
    >
      <img
        src={src}
        alt={alt}
        className="h-full w-full object-cover hover:cursor-pointer"
      />
    </div>
  );
};

export const MaterialCarousel: React.FC<MaterialT> = ({
  items,
  version,
  isSupervisor,
}) => {
  return (
    <Carousel
      opts={{ loop: true }}
      className="w-full rounded-md overflow-x-clip mb-10"
      plugins={[Autoplay()]}
    >
      <CarouselContent>
        {items.map(
          (item: any, index: number) =>
            (item.plans.includes(version) || item.plans.includes('any')) && (
              <CarouselItem key={index}>
                <Item
                  url={item.url}
                  key={item.id}
                  src={`data:image/jpg;base64, ${item.image_data}`}
                  alt={item.alt}
                  isSupervisor={isSupervisor}
                />
              </CarouselItem>
            ),
        )}
      </CarouselContent>
    </Carousel>
  );
};
