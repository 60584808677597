import React from 'react';
import classNames from 'classnames';
import { NormalTextFieldT } from '#interfaces/components';

export const TextField: React.FC<NormalTextFieldT> = ({
  onChange,
  placeholder,
  disabled,
  multiline,
  width,
  value,
  refTarget,
  autocomplete,
  className = 'w-full mt-1.5',
}) => {
  const [focused, setFocused] = React.useState(false);
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value);
  const isFocused = () => setFocused(!focused);
  const inputCss = classNames(
    'relative focus:ring-2 focus:ring-blue-500 shadow-sm block px-4 py-2 text-sm border border-slate-200 rounded-md',
    className,
  );

  return (
    <label onFocus={isFocused} onBlur={isFocused} ref={refTarget}>
      <input
        className={inputCss}
        width={width}
        disabled={disabled}
        onChange={onChangeHandler}
        placeholder={placeholder && placeholder}
        value={value}
        autoComplete={autocomplete}
      />
    </label>
  );
};
