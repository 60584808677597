import React from 'react';
import { Formik } from 'formik';
import { useForm } from './use-form';
import { useSubmit } from './use-submit';
import { NumericField, Form, Copy } from '#components/index';
import './index.scss';
import { OverTurnFormT } from '#interfaces/components';

const OverturnForm: React.FC<OverTurnFormT> = ({
  appointment,
  date,
  offices,
  userId,
  onChange,
  schedule,
}) => {
  const focused = React.useRef<any>(null);
  const { initialValues, validate } = useForm({
    appointment,
    userId,
    date,
    offices,
  });

  React.useEffect(() => {
    focused.current.focus();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={useSubmit(onChange, schedule)}
      enableReinitialize
    >
      <Form ignoreDirty>
        <Copy>Por favor, elegí la hora del sobreturno.</Copy>
        <div className="grid grid-cols-12 gap-x-5 mb-5">
          <NumericField
            refTarget={focused}
            className="col-span-6"
            label="Hora"
            name="overturn.hour"
            min={0}
            max={23}
          />
          <NumericField
            className="col-span-6"
            label="Minutos"
            name="overturn.minutes"
            min={0}
            max={59}
          />
        </div>
      </Form>
    </Formik>
  );
};

export default OverturnForm;
