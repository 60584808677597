export const initialWeeklyStats: {
  name: string;
  color: string;
  data: any[];
}[] = [
  {
    name: 'Pacientes nuevos',
    color: '#007aff',
    data: [],
  },
  {
    name: 'Pacientes atendidos',
    color: '#ffb600',
    data: [],
  },
  {
    name: 'Turnos agendados',
    color: '#09d242',
    data: [],
  },
];

export const initialDailyStats: {
  name: string;
  description: string;
  data: any[];
}[] = [
  {
    name: 'Turnos con pacientes ya atendidos',
    description: 'Mira la lísta de pacientes que ya has antendido previamente',
    data: [],
  },
  {
    name: 'Turnos con pacientes nuevos',
    description: 'Mira la lísta de pacientes que se atenderán por primera vez',
    data: [],
  },
  {
    name: 'Cumpleaños de pacientes que ya conoces',
    description: 'Envíales un saludo',
    data: [],
  },
  {
    name: 'Pedidos de laboratorios deberían ingresar hoy',
    description: '',
    data: [],
  },
];
