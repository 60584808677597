import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Copy, Form } from '../../../../../../components';
import { DeleteHolidayFormT } from '#interfaces/components';

export const DeleteHolidayForm: React.FC<DeleteHolidayFormT> = ({
  holiday,
  onChange,
}) => {
  return (
    <Formik initialValues={{}} onSubmit={useSubmit(holiday, onChange)}>
      <Form submitTitle="Eliminar" ignoreDirty>
        <Copy>
          Estás por eliminar esta fecha y esta acción es irreversible, el
          feriado se eliminará definitivamente.
        </Copy>
      </Form>
    </Formik>
  );
};
