import React from 'react';
import { Columns } from './columns';
import { Data } from './data';
import { abrencesGridMapper } from '../../../../utils';

export const Table: React.FC<any> = ({ data, action }) => {
  return (
    <div className="mt-5 mx-auto w-full">
      <Data columns={Columns({ action })} data={abrencesGridMapper(data)} />
    </div>
  );
};
