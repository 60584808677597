import React from 'react';
import { DropdownForm, TextFieldForm } from '../../../commons';
import { useFieldProps } from '../../../../hooks';

export const ControllerMedIns: React.FC<{
  medicalInsurances: { [key: string]: any };
}> = ({ medicalInsurances }) => {
  const showFieldNameAfiliate = 'affiliate_number';
  const showFieldNameListBenefits = 'benefits_list';
  const showFieldNameMedIns = 'id_medical_insurance';
  const showFieldNamePlan = 'id_plan';
  const {
    field: { value: idMedIns },
  } = useFieldProps({ name: showFieldNameMedIns });
  const { setFieldValue: setFieldIdPlanValue } = useFieldProps({
    name: showFieldNamePlan,
  });
  const {
    field: { value: listBenefitValue },
  } = useFieldProps({ name: showFieldNameListBenefits });
  const medInsEnabled = medicalInsurances.filter(
    (el: { [key: string]: any }) => !el.inhabilitada,
  );
  const disabledDropdown = listBenefitValue.length > 0;

  const plans = React.useMemo(() => {
    if (!idMedIns) {
      return [];
    }
    const medicalInsurance = medInsEnabled.find(
      (m: { [key: string]: any }) => m.id_os === idMedIns,
    );
    !listBenefitValue.length &&
      medicalInsurance?.planes?.[0] &&
      setFieldIdPlanValue(medicalInsurance?.planes?.[0]?.id_plan);
    return medicalInsurance?.planes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMedIns]);

  return (
    <>
      <div className="col-span-4 pb-5">
        <DropdownForm
          name={showFieldNameMedIns}
          data={medInsEnabled}
          label="Obra social"
          keyId="id_os"
          disabled={disabledDropdown}
          keyData="obra_social"
        />
      </div>
      <div className="col-span-4">
        <DropdownForm
          name={showFieldNamePlan}
          data={plans}
          disabled={disabledDropdown}
          label="Plan"
          keyId="id_plan"
          keyData="nombre"
        />
      </div>
      <TextFieldForm
        className="col-span-4"
        name={showFieldNameAfiliate}
        label="Nro de afiliado"
        multiline
      />
    </>
  );
};
