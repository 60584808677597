import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import { Input, Button } from '#components/shadcn';
import { Medicines } from '#components/Tables';
import { AppDispatch } from '../../../../redux';
import {
  startLoading,
  stopLoading,
  getListMedications,
} from '../../../../redux/slices';
import {
  useFieldProps,
  useOnClickOutside,
  useDebounce,
} from '../../../../hooks';

export const SearchMedicine: React.FC<{
  searchResults: {
    medications: {
      hasCoverage: boolean;
      requiresApproval: boolean;
      discount: number;
      isPsychopharmaceutical: boolean;
      isNarcotic: boolean;
      isControlledSale: boolean;
      isForHIV: boolean;
      requiresDuplicate: boolean;
      productName: string;
      drugName: string;
      presentation: string;
      registerNumber: string;
    }[];
    medicationsInfo: {
      currentPage: number;
      totalPages: number;
      hasMore: boolean;
    };
  };
  placeholder: string;
  label: string;
  name: string;
}> = ({ searchResults, placeholder, label, name }) => {
  const dispatch: AppDispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [sorting, setSorting] = useState([]);
  const pageNumber = useRef<number>(1);
  const refWrapper = useRef<any>(null);
  const refList = useRef<any>(null);
  const { medications, medicationsInfo } = searchResults;
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name });
  const {
    field: { value: fromListValue },
    setFieldValue: setFromListValue,
  } = useFieldProps({ name: 'medicine_from_list' });

  useOnClickOutside({
    ref: refWrapper,
    handler: () => {
      setIsOpen(false);
      setSearchInput('');
    },
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsOpen(false);
    setFieldValue(e.target.value);
    setSearchInput(e.target.value);
    setFromListValue({ ...fromListValue, productName: '', presentation: '' });
    setHasSearched(false);
  };

  const onSubmitHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (searchInput.trim() !== '' && !hasSearched) {
      dispatch(startLoading());
      pageNumber.current = 1;
      await dispatch(
        getListMedications({
          input: searchInput,
          pageNumber: 1,
        }),
      );

      dispatch(stopLoading());

      setIsOpen(true);
      setHasSearched(true);
    }
  };

  const onClickHandler = async (selectedMedicine: any) => {
    dispatch(startLoading());
    setFromListValue({ ...fromListValue, ...selectedMedicine });
    dispatch(stopLoading());
    setIsOpen(false);
    setSearchInput('');
  };

  const handleScroll = useDebounce(
    React.useCallback(
      async (currentRef?: any) => {
        if (medicationsInfo.hasMore) {
          if (currentRef) {
            const { scrollTop, scrollHeight, clientHeight } = currentRef;

            if (
              scrollHeight - scrollTop - clientHeight < 500 &&
              pageNumber.current < 13
            ) {
              pageNumber.current = pageNumber.current + 1;

              await dispatch(
                getListMedications({
                  input: searchInput,
                  pageNumber: pageNumber.current,
                  scrolling: true,
                }) as any,
              );
            }
          }
        }
      },
      [medicationsInfo, searchInput, pageNumber, dispatch],
    ),
    300,
  );

  React.useEffect(() => {
    handleScroll(refList.current);
  }, [handleScroll]);

  const showResults = isOpen && medications && medications.length >= 1;

  const searchBarContainerCss = classNames(
    'rounded-md will-change-[left] transition-all duration-100 ease-out w-full group col-span-6',
    isOpen && 'searchbar-container--is_open relative z-10',
  );
  const height =
    searchResults && searchResults.medications?.length < 9
      ? `${searchResults.medications?.length * 65 + 80}px`
      : '520px';

  return (
    <form ref={refWrapper} className={searchBarContainerCss}>
      <div className="grid grid-cols-12 gap-x-4 rounded-md">
        <label htmlFor="input" className="mb-2 block !text-sm col-span-full">
          {label}
        </label>
        <div className="col-span-10">
          <Input
            type="search"
            className="m-0 text-slate-900 !border-1 shadow-sm !border-gray-300 py-2 pl-5 h-10 w-full relative rounded-md text-base col-span-10"
            placeholder={placeholder}
            onChange={(e: any) => onChangeHandler(e)}
            value={searchInput || value}
            autoFocus
            maxLength={120}
            autoCapitalize="off"
          />
        </div>
        <Button
          disabled={!searchInput}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            onSubmitHandler(e)
          }
          type="submit"
          className="w-full col-span-2 bg-transparent !text-gray-800 hover:bg-slate-200/50 border border-slate-200"
        >
          Buscar
        </Button>
      </div>
      <Transition
        show={showResults}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Medicines
          data={medications}
          sorting={sorting}
          setSorting={setSorting}
          onClick={onClickHandler}
          height={height}
          refList={refList}
          onScroll={handleScroll}
        />
      </Transition>
    </form>
  );
};
