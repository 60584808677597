import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { AccountBanner } from '../../Suscription/card';
import {
  Form,
  Title,
  TextFieldForm,
  DatePicker,
  CheckboxForm,
  DropdownForm,
  Subtitle,
  Button,
} from '../../index';
import { useSetHeight } from '../../../hooks';
import { AccountFormT } from '#interfaces/components';

export const AccountForm: React.FC<AccountFormT> = ({
  data,
  onChange,
  plan,
  regions,
  modifying,
  modifyingHandler,
  focusElement,
  refTarget,
  webUser,
}) => {
  const { initialValues, validate } = useForm({ props: data, regions });
  const height = useSetHeight(180);
  const handleOnSubmit = (submitForm: any) => {
    focusElement();
    if (modifying) {
      submitForm();
      return modifyingHandler(false);
    }
    modifyingHandler(true);
    focusElement();
  };

  const handleOnCancel = () => {
    return modifyingHandler(false);
  };

  return (
    <div
      className="grid p-10 overflow-hidden overflow-y-scroll"
      style={{ height: `${height}px` }}
    >
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={useSubmit({ onChange, regions })}
        enableReinitialize
      >
        {({ submitForm }) => (
          <Form className="grid grid-cols-6 gap-10" showActions={false}>
            <div
              className={`${
                !modifying ? 'pointer-events-none' : ''
              } col-span-3 grid grid-cols-2 gap-x-10`}
            >
              <div className="col-span-2">
                <Title className="text-lg">Configuración General</Title>
                <Subtitle>Mi Perfil</Subtitle>
              </div>
              <TextFieldForm
                className="py-3"
                multiline
                label="Nombre"
                name="first_name"
                refTarget={refTarget}
              />
              <TextFieldForm
                className="py-3"
                multiline
                label="Apellido"
                name="last_name"
              />
              <DropdownForm
                name="country_iso3"
                data={regions}
                label="Pais"
                keyId="iso3"
                keyData="name"
              />
              <DatePicker
                fullWidth
                label="Fecha de nacimiento"
                name="birthday"
              />
              <TextFieldForm multiline={true} label="Teléfono" name="phone" />
              <CheckboxForm
                label="Utiliza WhatsApp"
                description="Indica si el teléfono se utiliza como medio de comunicación de WhatsApp"
                name="is_whatsapp"
              />
            </div>
            <div className="col-span-3 pt-12">
              <Title className="text-lg">Tu Plan</Title>
              <AccountBanner
                plan={plan}
                data={data}
                suscribed
                showAction
                webUser={webUser}
              />
            </div>
            <Button
              onClick={handleOnCancel}
              modifier="warning"
              disabled={!modifying}
            >
              Cancelar
            </Button>
            <Button onClick={() => handleOnSubmit(submitForm)}>
              {modifying ? 'Guardar' : 'Editar'}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
