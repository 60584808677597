import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { BenefitType } from '../../../Benefits/BenefitsForm/benefit-type';
import { ToothSection } from '../../../Benefits/BenefitsForm/tooth-section';
import { ToothFaces } from '../../../Benefits/BenefitsForm/tooth-faces';
import { ProfessionalDropdown } from '../../../Benefits/BenefitsForm/professional-dropdown';
import {
  Subtitle,
  DatePicker,
  DropdownForm,
  TextFieldForm,
  Form,
} from '../../../../../components';
import { BenefitPatientT } from '#interfaces/components';

export const BenefitForm: React.FC<BenefitPatientT> = ({
  userId,
  offices = [],
  patientId,
  medicalInsurance,
  professionals = [],
  benefitTypes = [],
  tooth,
  onChange,
}) => {
  const { initialValues, validate } = useForm(
    benefitTypes,
    offices,
    professionals,
    tooth,
  );
  const benefitTypeFieldName = 'benefit_type';
  const professionalFieldName = 'professional';
  const officeFieldName = 'office';
  const specialtyFieldName = 'specialty';
  const setColumns = () => {
    if (medicalInsurance?.cargar_por_periodo && offices?.length > 1) return '4';
    if (medicalInsurance?.cargar_por_periodo || offices?.length > 1) return '3';
    return '2';
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      validateOnBlur={false}
      onSubmit={useSubmit({ userId, patientId, onChange })}
      enableReinitialize
    >
      <Form className="grid grid-cols-2 gap-5">
        <div data-cols={setColumns()}>
          <Subtitle>Datos de la prestación</Subtitle>
          <div className="mt-3">
            <DatePicker label="Fecha" name="date" fullWidth className="mb-4" />
          </div>
          {offices?.length > 1 && (
            <DropdownForm
              name={officeFieldName}
              data={offices}
              label="Sucursal"
              keyId="id_sucursal"
              keyData="sucursal"
            />
          )}
          <ProfessionalDropdown
            name={professionalFieldName}
            professionals={professionals}
            specialtyFieldName={specialtyFieldName}
            officeFieldName={officeFieldName}
            label="Profesional"
            ignoreDateRange
            className="mb-4"
          />
          {medicalInsurance?.cargar_por_periodo && (
            <TextFieldForm label="Período" placeholder="202201" name="period" />
          )}
        </div>
        <div className="col-span-1 mt-14">
          <div className="mb-4">
            <p className="mb-2 block text-sm font-medium">Prestaciones</p>
            <BenefitType
              name={benefitTypeFieldName}
              benefitTypes={benefitTypes}
              professionals={professionals}
            />
          </div>
          <div>
            <TextFieldForm
              multiline
              label="Observaciones"
              name="notes"
              className="small"
            />
          </div>
        </div>
        <ToothSection
          name="tooth_section"
          benefitTypeFieldName={benefitTypeFieldName}
          benefitTypes={benefitTypes}
        />
        <ToothFaces
          name="tooth_faces"
          benefitTypeFieldName={benefitTypeFieldName}
          benefitTypes={benefitTypes}
        />
      </Form>
    </Formik>
  );
};
