import { useContext } from 'react';
import { validateMembership } from '../../../../services';
import { SnackbarContext, LoadingContext } from '../../../../context';
import { FormikHelpers } from 'formik';

export function useSubmit() {
  const { showErrorSnackbar, showSuccessSnackbar, showWarningSnackbar } =
    useContext(SnackbarContext);
  const { startLoading, stopLoading } = useContext(LoadingContext);

  return async (spec: any, formikActions: FormikHelpers<any>) => {
    try {
      startLoading();
      const {
        data: { membershipStatus },
      } = await axiosPromise(spec);
      formikActions.resetForm();
      if (membershipStatus.includes('NO HABILITADO')) {
        showWarningSnackbar(membershipStatus);
      } else {
        showSuccessSnackbar(membershipStatus);
      }
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
      stopLoading();
    }
  };
}

function axiosPromise(spec: any) {
  const apiSpec = transformSpec(spec);
  return validateMembership(apiSpec);
}

function transformSpec(spec: any) {
  const specs = {
    codCliente: spec.customer_code,
    codPrestador: spec.lender_code,
    codObraSocial: spec.medical_insutance_code,
    tipoDocumento: spec.document_type,
    nroDocumento: spec.document_number,
    nroAfiliado: spec.member_number,
    id_os: spec.id_medical_isurance,
    id_paciente: spec.id_patient,
  };

  return specs;
}
