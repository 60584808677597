import React from 'react';
import { Columns } from './columns';
import { Data } from './data';
import { timetableGridMapper } from '../../../../utils';

export const Table: React.FC<any> = ({ data, action, config }) => {
  return (
    <div className="mt-5 mx-auto w-full">
      <Data
        columns={Columns({ action, config })}
        data={timetableGridMapper(data)}
      />
    </div>
  );
};
