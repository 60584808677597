import React from 'react';
import { ControllerMedIns } from '../coontroller-med-ins';
import { LoadBenefit } from '../LoadBenefit';
import { GridBenefits } from '../LoadBenefit/grid-benefits';
import { useFieldProps } from '../../../../../hooks';
import { Button } from '#components/index';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from '#components/shadcn';
import { budgetBenefitListTotalValue } from '../../../../../utils';
import { SecondStepT } from '#interfaces/components';

export const SecondStep: React.FC<SecondStepT> = ({
  onClick,
  data: { medicalInsurances },
  resetProgressBar,
  viewLoadBenefit: { hiddenLoadBenefit, setHiddenLoadBenefit },
  config,
}) => {
  const {
    field: { value: listBenefitValue },
  } = useFieldProps({ name: 'benefits_list' });
  const {
    field: { value: benefitListChangeValue },
    setFieldValue: setBenefitListChangeValue,
  } = useFieldProps({ name: 'benefit_list_has_change' });
  const { setFieldValue: setTotalBenefitListValue } = useFieldProps({
    name: 'benefits_list_total_amount',
  });
  const { setFieldValue: setPreviousBudgetTotalAmount } = useFieldProps({
    name: 'previous_budget_total_amount',
  });
  const {
    field: { value: budgetTotalAmountValue },
  } = useFieldProps({ name: 'budget_total_amount' });
  const { setFieldValue: setValueBenefit } = useFieldProps({
    name: 'benefit_from_list',
  });
  const validationGrid = listBenefitValue.length > 0;
  const onLoadBenefit = () => {
    setHiddenLoadBenefit(!hiddenLoadBenefit);
    setBenefitListChangeValue(false);
    setValueBenefit({
      amount: 0,
      benefit_code: null,
      description: '',
      id_benefit: null,
      id_budget: 0,
      id_budget_benefit: 0,
      is_manual: false,
      needs_thoot: 'NIN',
      quantity: 1,
      thoot_face: false,
      tooth_section: '',
      total_amount: 0,
    });
  };
  React.useMemo(() => {
    if (!listBenefitValue.length) {
      resetProgressBar();
      return onClick();
    }
    const totalValue = budgetBenefitListTotalValue(listBenefitValue);
    setBenefitListChangeValue(false);
    setTotalBenefitListValue(totalValue);
    setPreviousBudgetTotalAmount(budgetTotalAmountValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benefitListChangeValue]);

  return !hiddenLoadBenefit ? (
    <Card className="mt-5">
      <CardHeader>
        <CardTitle>Datos de obra social</CardTitle>
        <CardDescription>
          Selecciona los datos de la obra social e incluye las prestaciones que
          formarán parte de tu presupuesto.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-12 gap-5 border-b-2 border-b-slate-200">
        <ControllerMedIns medicalInsurances={medicalInsurances} />
      </CardContent>
      <CardHeader>
        <CardTitle>Selección de prestación</CardTitle>
        <CardDescription>
          {validationGrid
            ? 'Edita o elimina tus prestaciones cargadas'
            : 'Todavía no posee ninguna prestación cargada'}
        </CardDescription>
      </CardHeader>
      <CardContent>
        {validationGrid && <GridBenefits onclick={setHiddenLoadBenefit} />}
        <div className="flex gap-5 mt-5">
          <Button
            className="bg-white !text-blue-500 shadow-md"
            onClick={onLoadBenefit}
          >
            Cargar prestación
          </Button>
          <Button disabled={!validationGrid} onClick={onClick} size="large">
            Siguiente
          </Button>
        </div>
      </CardContent>
    </Card>
  ) : (
    <LoadBenefit setHiddenLoadBenefit={setHiddenLoadBenefit} config={config} />
  );
};
