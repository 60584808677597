import React from 'react';
import { Button, CheckboxForm, Modal } from '../../../../../components';
import { useFieldProps, useForm } from '../../../../../hooks';
import { professionalsConfig } from '../../../../../services';

export const ScheduleCheckbox: React.FC<{ onChange?: () => void }> = ({
  onChange = () => {},
}) => {
  const name = 'no_schedule';
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name });
  const {
    field: { value: profValue },
  } = useFieldProps({ name: 'professionalSchedule' });
  const [prevValue, setPrevValue] = React.useState(value);
  const [prevProfId, setPrevProfId] = React.useState(null);
  const {
    isOpenForm: isOpenModal,
    closeForm: closeModal,
    openForm: openModal,
  } = useForm();
  const onSubmit = async () => {
    const { prof_horarios, prof_ausencias, prof_valores, ...restProps } =
      profValue;
    professionalsConfig({
      ...restProps,
      no_tiene_agenda: value,
      opcion: 'modificar',
    });
    setPrevValue(value);
    setTimeout(() => {
      onChange();
    }, 500);
    closeModal();
  };

  const onCancel = () => {
    setFieldValue(profValue.no_tiene_agenda);
    setPrevValue(profValue.no_tiene_agenda);
    closeModal();
  };

  React.useMemo(() => {
    if (prevValue !== value && profValue.id_prof === prevProfId) {
      return openModal();
    }
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  React.useMemo(() => {
    setPrevProfId(profValue.id_prof);
    setPrevValue(profValue.no_tiene_agenda);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profValue.id_prof]);

  return (
    <>
      <Modal
        title="Cambiar visibilidad"
        isOpenModal={isOpenModal}
        closeModal={onCancel}
        openModal={openModal}
      >
        <div className="flex flex-col">
          <p className="my-6 text-base font-regular text-neutral-500">
            ¿Desea cambiar la visibilidad de la agenda del profesional?
          </p>
          <div className="flex gap-5">
            <Button
              className="flex justify-center w-max px-10 h-10 bg-transparent !text-red-500 hover:bg-red-500 hover:!text-white border border-red-500"
              onClick={onCancel}
            >
              Cancelar
            </Button>
            <Button onClick={onSubmit}>Guardar</Button>
          </div>
        </div>
      </Modal>
      <CheckboxForm
        label="No tiene agenda"
        description="El profesional no tiene agenda disponible."
        small
        name={name}
        showError={false}
      />
    </>
  );
};
