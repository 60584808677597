import React from 'react';
import { CheckboxForm, TextFieldForm } from '../../../commons';
import { useFieldProps } from '../../../../hooks';

export const PatientMedicalOwner: React.FC = () => {
  const {
    field: { value: holderValue },
  } = useFieldProps({ name: 'holder' });

  return (
    <>
      <div className="self-end h-14 col-span-5 col-start-1">
        <CheckboxForm
          label="Es titular"
          description="Es titular de su obra social."
          name="holder"
          showError={false}
        />
      </div>
      {!holderValue && (
        <>
          <TextFieldForm
            name="name_owner"
            className="small col-span-3"
            label="Nombre del titular"
          />
          <TextFieldForm
            name="company_owner"
            className="small col-span-3"
            label="Titular empresa"
          />
        </>
      )}
    </>
  );
};
