import { useContext } from 'react';
import { professionalsConfig } from '../../../../../services';
import { FormContext, SnackbarContext } from '../../../../../context';
import { FormikHelpers } from 'formik';

import { ProfessionalT } from '#interfaces/general-values';

export function useSubmit(
  currentProfessional: ProfessionalT | undefined,
  onChange: () => void,
) {
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(spec, currentProfessional);
      formikActions.resetForm();
      showSuccessSnackbar('Cambio guardado correctamente');
      onChange();
      closeForm();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(
  spec: { [key: string]: any },
  currentProfessional: ProfessionalT | undefined,
) {
  const apiSpec = transformSpec(spec, currentProfessional);

  return professionalsConfig(apiSpec);
}

function transformSpec(
  spec: { [key: string]: any },
  currentProfessional: ProfessionalT | undefined,
) {
  const trimSpec = Object.keys(spec).reduce((acc: any, key: any) => {
    acc[key] = typeof spec[key] === 'string' ? spec[key].trim() : spec[key];
    return acc;
  }, {} as { [key: string]: any });

  let apiSpec: { [key: string]: any } = {
    id_prof: trimSpec.id_prof,
    nombre: trimSpec.name.toUpperCase(),
    domicilio: trimSpec.address,
    localidad: trimSpec.location,
    provincia: trimSpec.state,
    cuit: trimSpec.cuit,
    e_mail: trimSpec.email,
    telefono: trimSpec.phone,
    cond_fiscal: trimSpec.tax_category,
    matricula: trimSpec.plate,
    codigo_prof: trimSpec.code,
    observaciones: trimSpec.observations,
    habilitado: trimSpec.enabled,
    id_especialidad1: trimSpec.specialty_1,
    id_especialidad2: trimSpec.specialty_2,
    solamente: trimSpec.only_specialty,
    abreviatura: trimSpec.prefix,
    liquidar_todos: trimSpec.supervisor_view,
    cod_liquida: trimSpec.osde_id,
    color_agenda: trimSpec.color_agenda,
    comision: trimSpec.comision,
    comision_labo: trimSpec.comision_labo,
    comision_pac: trimSpec.comision_pac,
    comision_pagos: trimSpec.comision_pagos,
    no_tiene_agenda: trimSpec.no_tiene_agenda,
    tope_solp: trimSpec.tope_solp,
    es_guardia: trimSpec.is_guard,
    opcion: 'nuevo',
    signature: trimSpec.signature,
    licenceType: trimSpec.type_registration,
    licenceJurisdiction:
      trimSpec.type_registration === 'MP' ? trimSpec.jurisdiction : null,
    profession: trimSpec.profession || 'Odontólogo',
  };

  if (currentProfessional?.id_prof) {
    apiSpec = {
      ...apiSpec,
      rowversion: currentProfessional.rowversion,
      opcion: 'modificar',
    };
  }

  return apiSpec;
}
