import React from 'react';
import { CheckboxForm } from '#components/commons';
import { useFieldProps } from '../../../../../hooks';

export const ControllercheckBoxSelectAll: React.FC<{
  name: string;
  label?: string;
  description?: string;
  articles: { [index: string]: any };
}> = ({ name, label, description, articles }) => {
  const selectedArticlesFieldName = 'selected_articles';
  const { setFieldValue: setFieldValueArticles } = useFieldProps({
    name: selectedArticlesFieldName,
  });
  const {
    field: { value: selectAll },
  } = useFieldProps({ name });

  React.useEffect(() => {
    if (selectAll) {
      setFieldValueArticles(
        articles?.map((b: { [index: string]: any }) => b.id_concept),
      );
    } else {
      setFieldValueArticles([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  return (
    <CheckboxForm
      label={label}
      description={description}
      name={name}
      showError={false}
    />
  );
};
