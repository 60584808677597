import React from 'react';
import { CheckboxForm } from '../../../components';
import { ProfessionalDropdown } from '../Benefits/BenefitsForm/professional-dropdown';
import { useFieldProps } from '../../../hooks';
import { ProfessionalT } from '#interfaces/general-values';

export const ProfessionalsDropdown: React.FC<{
  professionals: ProfessionalT[];
}> = ({ professionals }) => {
  const checkboxFieldName = 'professionals_all';
  const {
    field: { value: checkboxValue },
  } = useFieldProps({ name: checkboxFieldName });

  return (
    <>
      <ProfessionalDropdown
        professionals={professionals}
        ignoreDateRange
        label="Profesionales"
        name="professional"
        officeFieldName="office"
        dateFieldName="date"
        specialtyFieldName="specialty"
        disabled={checkboxValue}
      />
      <div className="self-end">
        <CheckboxForm
          label="Todos los profesionales"
          description="Se incluirán todos los profesionales al reporte."
          name="professionals_all"
        />
      </div>
    </>
  );
};
