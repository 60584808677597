import { useCallback, useEffect, useState } from 'react';
import { MedicalInsurancesT, ProfessionalT } from '#interfaces/general-values';

export function useForm({
  professionals,
  medicalInsurances,
}: {
  professionals: ProfessionalT[];
  medicalInsurances: MedicalInsurancesT[];
}) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues({ professionals, medicalInsurances }),
  );

  useEffect(() => {
    setInitialValues(getInitialValues({ professionals, medicalInsurances }));
  }, [medicalInsurances, professionals]);

  const validate = useCallback((values: any) => {
    const errors: { [key: string]: any } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['period', 'medical_insurance'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    if (values['period'].length < 6) {
      errors['period'] = 'El período es inválido';
    }

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues({
  professionals,
  medicalInsurances,
}: {
  professionals: ProfessionalT[];
  medicalInsurances: MedicalInsurancesT[];
}) {
  const getProfessional = () =>
    professionals.filter((p) => p.habilitado === true)[0];
  const professional = getProfessional();
  const firstMedIns = medicalInsurances[0];

  const initialValues = {
    benefits_state: 'ALL',
    period: '',
    medical_insurance: firstMedIns.id_os,
    medical_insurance_group: firstMedIns.grupos[0]?.id_grupo || 1,
    professional: professional?.id_prof,
    groups_all: true,
    professionals_all: true,
    rebilling: false,
    page_number: 1,
    include_prof_code: false,
  };

  return initialValues;
}
