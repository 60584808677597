import { useMemo } from 'react';
import { usePaginationT } from '#interfaces/hooks';

const range = ({ start, end }: { start: number; end: number }) => {
  let length = end - start + 1;

  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
  totalCount,
  totalPages,
  siblingCount = 1,
  currentPage,
}: usePaginationT) => {
  const DOTS = '...';
  const paginationRange = useMemo(() => {
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPages) {
      return range({ start: 1, end: totalPages });
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages);
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;
    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range({ start: 1, end: leftItemCount });

      return [...leftRange, DOTS, totalPages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range({
        start: totalPages - rightItemCount + 1,
        end: totalPages,
      });
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range({
        start: leftSiblingIndex,
        end: rightSiblingIndex,
      });
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, siblingCount, currentPage]);

  return paginationRange;
};
