const arg = {
  medical_insurances: 'Obras sociales',
  medical_insurance: 'Obra social',
  medical_insurance_abbreviation: 'O.S.',
  medical_insurance_data: 'Datos de la obra social',
  see_only: 'Ver solo habilitadas',
  cod_mi: 'Cod. O.S.',
  amount_mi: 'Imp OS',
  money: '$',
  doc_types: [
    { id: 'DNI', text: 'DNI' },
    { id: 'LC', text: 'LC' },
    { id: 'LE', text: 'LE' },
    { id: 'PAS', text: 'PAS' },
    { id: 'OTR', text: 'Otro' },
  ],
  config: {
    med_ins_and_benefits: 'Obras sociales y prestaciones',
    med_ben_text:
      'Dar de alta una nueva obra social, modificar sus planes, grupos y prestaciones.',
    new_med_ins: 'Nueva obra social',
  },
};

export default arg;
