import { useContext } from 'react';
import { FormikHelpers } from 'formik';
import { updateSistemConfig } from '../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../context';

import { ParamsT } from '#interfaces/general-values';

export function useSubmit(params: ParamsT, onChange: () => void) {
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(spec, params);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Los datos se guardaron correctamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(err.message);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(spec: { [key: string]: any }, params: ParamsT) {
  const apiSpec = transformSpec(spec, params);

  return updateSistemConfig(apiSpec);
}

function transformSpec(spec: { [key: string]: any }, params: ParamsT) {
  const apiSpec = {
    ...params,
    what_template_turno: spec.what_template_turns,
    what_template_cumple: spec.what_template_birthday,
  };

  return apiSpec;
}
