import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../../redux/slices';
import { SnackbarContext } from '../../../context';
import { sendListMedins } from '../../../services';

export function useSubmit(open: () => void) {
  const dispatch = useDispatch();
  const { showErrorSnackbar } = useContext(SnackbarContext);

  return async (spec: any) => {
    try {
      const listImportMedinsIds: number[] = spec.medical_insurance_list.map(
        (e: string) => parseInt(e),
      );

      dispatch(startLoading());
      await axiosPromise(spec, listImportMedinsIds);
      open();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      dispatch(stopLoading());
    }
  };
}

function axiosPromise(spec: any, listImportMedinsIds: number[]) {
  const apiSpec = transformSpec(spec, listImportMedinsIds);

  return sendListMedins(apiSpec);
}

function transformSpec(values: any, listImportMedinsIds: number[]) {
  let apiSpec: {
    MedicalInsuranceList: number[];
    DatabaseName: string;
    ManagerCode: string;
    LenderCode: string;
  } = {
    MedicalInsuranceList: listImportMedinsIds,
    DatabaseName: values.data_base_name,
    ManagerCode: values.manager_code,
    LenderCode: values.lender_code,
  };

  return apiSpec;
}
