import React from 'react';
import { Icons } from '../';

export const About = () => {
  const year = new Date().getFullYear();
  return (
    <section className="grid grid-cols-2 justify-center w-[60%]">
      <div className="mt-8">
        <p className="text-xl font-bold text-slate-900 mb-4">Contacto</p>
        <div className="mb-4">
          <p className="text-base font-semibold text-neutral-600">Teléfono</p>
          <p className="text-sm font-regular text-neutral-500">
            (+54) 11 5263-2220
          </p>
        </div>
        <div className="mb-4">
          <p className="text-base font-semibold text-neutral-600">Email</p>
          <p className="text-sm font-regular text-neutral-500 hover:text-blue-600">
            <a
              href="mailto:info@bilog.com.ar"
              className="hover:text-blue-600"
              target="blank"
            >
              info@bilog.com.ar
            </a>
          </p>
        </div>
        <div className="mb-4">
          <p className="text-base font-semibold text-neutral-600">Website</p>
          <p className="text-sm font-regular text-neutral-500">
            <a
              href="https://www.bilog.com.ar"
              className="hover:text-blue-600"
              target="blank"
            >
              https://www.bilog.com.ar
            </a>
          </p>
        </div>
        <div className="flex flex-row mt-4">
          <a
            className="block mr-2"
            href="https://www.facebook.com/Bilog.Soluciones"
          >
            <Icons fill="#999" type="facebook" width={24} height={24} />
          </a>
          <a className="block" href="https://www.instagram.com/bilogsoftware">
            <Icons fill="#999" type="instagram" width={24} height={24} />
          </a>
        </div>
      </div>
      <div className="mt-8">
        <p className="text-xl font-bold text-slate-900 mb-4">Copywrite</p>
        <p className="text-sm font-regular text-neutral-500 mb-1">
          Bilog Soluciones Informáticas. © 2005 - {year}
        </p>
        <p className="text-sm font-regular text-neutral-500 mb-1">
          Reservados todos los derechos.
        </p>
        <p className="text-sm font-regular text-neutral-500 mb-8">
          Versión: 1.2.0
        </p>
        <p className="text-sm font-regular text-neutral-900">
          Este programa está protegido por las leyes de derechos de autor y
          otros tratados internacionales. La reproducción o distrubución no
          autorizada de este programa, o de cualquier parte del mismo, está
          penada por la ley con severas sanciones civiles y penales, y será
          objeto de todas las acciones judiciales que correspondan.
        </p>
      </div>
    </section>
  );
};
