import React from 'react';
import { DropdownForm } from '../../../../../components';
import { useFieldProps } from '../../../../../hooks';
import { TurnsT } from '#interfaces/general-values';

export const AppointmentTimeDropdown: React.FC<{
  availableAppointments: TurnsT[];
}> = ({ availableAppointments }) => {
  const fieldName = 'appointment_time';
  const { setFieldValue } = useFieldProps({ name: fieldName });

  React.useEffect(() => {
    setFieldValue(
      availableAppointments?.length ? availableAppointments[0].hora : null,
    );
  }, [availableAppointments, setFieldValue]);

  return (
    <DropdownForm
      name={fieldName}
      data={availableAppointments}
      label="Horario"
      keyId="hora"
      keyData="hora"
    />
  );
};
