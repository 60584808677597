import { useContext } from 'react';
import { FormikHelpers } from 'formik';
import { updateSpecialtiesConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';

export function useSubmit(
  onChange: () => void,
  specialties: { [key: string]: any }[],
  specialty: { [key: string]: any },
) {
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      if (spec.rowversion && specialty.cod_especialidad !== spec.code) {
        const specialtyFound = specialties?.find(
          (s) => s.cod_especialidad === spec.code,
        );
        if (specialtyFound?.cod_especialidad != null) {
          throw new Error('El código de especialidad esta en uso.');
        }
      }
      await axiosPromise(spec);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Especialidad guardada correctamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(spec: { [key: string]: any }) {
  const apiSpec = transformSpec(spec);

  return updateSpecialtiesConfig(apiSpec);
}

function transformSpec(spec: { [key: string]: any }) {
  const opcion = spec.rowversion ? 'modificar' : 'nuevo';

  let apiSpec: { [key: string]: any } = {
    cod_especialidad: spec.code,
    descripcion: spec.description.toUpperCase(),
    opcion,
  };

  if (spec.rowversion) {
    apiSpec = {
      ...apiSpec,
      id_especialidad: spec.id,
      rowversion: spec.rowversion,
    };
  }

  return apiSpec;
}
