import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import {
  CheckboxForm,
  TextFieldForm,
  DropdownForm,
  Form,
  EmptyState,
  Modal,
} from '#components/index';
import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
  CardHeader,
  Button,
} from '#components/shadcn';
import { GroupsDropdown } from './groups-dropdown';
import { ProfessionalsDropdown } from './professional-dropdown';
import { Auditor } from './reports';
import { benefitsState } from '../../../mocks';
import { AuditorFormT } from '#interfaces/components/forms';
import { useForm as useFormHook } from '../../../hooks';

export const AuditorForm: React.FC<AuditorFormT> = ({
  onChange,
  currentPage,
  professionals,
  medicalInsurances,
  setCurrentPage,
  reportData,
  hasData,
}) => {
  const [liquidationActions, setLiquidationActions] = React.useState({
    generate_document: false,
    close_liquidation: false,
  });
  const { initialValues, validate } = useForm({
    professionals,
    medicalInsurances,
  });
  const medicalInsuranceFieldName = 'medical_insurance';
  const generateDocument = (bool: boolean) =>
    setLiquidationActions({
      ...liquidationActions,
      generate_document: bool,
    });

  const closeLiquidation = (bool: boolean) =>
    setLiquidationActions({
      ...liquidationActions,
      close_liquidation: bool,
    });
  const { isOpenForm, closeForm, openForm } = useFormHook();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={useSubmit({
        onChange,
        currentPage,
        liquidationActions,
        generateDocument,
        closeLiquidation,
      })}
      validate={validate}
      enableReinitialize
    >
      {({ submitForm, isSubmitting, isValid, values }) => (
        <>
          <Form showActions={false}>
            <Card>
              <CardHeader>
                <CardTitle className="text-lg">
                  Liquidar al Auditor de Bilog
                </CardTitle>
                <CardDescription>
                  En esta sección podrás generar el reporte y liquidar un
                  período específico al Auditor de Bilog.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid lg:grid-cols-3 xl:grid-cols-4 gap-5">
                  <DropdownForm
                    className="md:col-span-2 lg:col-span-1"
                    name="benefits_state"
                    label="Prestaciones"
                    data={benefitsState}
                    keyId="id"
                    keyData="text"
                  />
                  <DropdownForm
                    name={medicalInsuranceFieldName}
                    label="Obra social"
                    data={medicalInsurances}
                    className="col-span-2"
                    keyId="id_os"
                    keyData="obra_social"
                  />
                  <TextFieldForm
                    multiline
                    label="Período"
                    name="period"
                    placeholder="AAAAMM"
                  />
                  <GroupsDropdown
                    medicalInsurances={medicalInsurances}
                    medInsFieldName={medicalInsuranceFieldName}
                  />
                  <CheckboxForm
                    label="Es refacturación"
                    description="Indica que ya se realizó una liquidación previa y se realizaron modificaciones."
                    name="rebilling"
                  />
                  <CheckboxForm
                    label="Usar código de profesional"
                    description="Genera el reporte con código de profesionales."
                    name="include_prof_code"
                  />
                  <ProfessionalsDropdown professionals={professionals} />
                </div>
                <div className="mt-8 flex gap-5">
                  <Button
                    className="h-[38px] flex px-4 truncate !text-blue-500 bg-transparent border border-blue-500 hover:bg-blue-600 hover:border-blue-600 hover:!text-white"
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                  >
                    Generar reporte
                  </Button>
                  {hasData && (
                    <div className="flex gap-5">
                      <Button
                        className="h-[38px] flex px-4 truncate"
                        onClick={openForm}
                        disabled={isSubmitting || !isValid}
                      >
                        Generar archivo
                      </Button>
                      <Button
                        className="h-[38px] flex px-4 truncate bg-slate-600 borde hover:bg-slate-700"
                        onClick={() => {
                          closeLiquidation(true);
                          submitForm();
                        }}
                        disabled={
                          isSubmitting ||
                          !isValid ||
                          values.benefits_state === 'LIQ'
                        }
                      >
                        Cerrar liquidación
                      </Button>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
          </Form>
          {hasData ? (
            <Auditor
              data={reportData}
              changePage={(page: number) => {
                setCurrentPage(page);
                submitForm();
              }}
              currentPage={currentPage}
            />
          ) : (
            <EmptyState
              title="No se generó ningún reporte"
              copy="Primero debes especificar los parámetros con los cuales se debe generar el reporte."
            />
          )}
          <Modal
            title="Aviso"
            isOpenModal={isOpenForm}
            closeModal={closeForm}
            openModal={openForm}
          >
            <p className="text-base font-semibold text-slate-500 mb-5">
              ¿Seguro que desea subir el archivo a la nube?
            </p>
            <div className="col-span-full grid grid-cols-4 gap-x-4">
              <Button
                className="h-[38px] flex px-4 bg-red-500 hover:bg-red-600 hover:!text-white"
                onClick={closeForm}
                disabled={isSubmitting}
              >
                Cancelar
              </Button>
              <Button
                className="h-[38px] flex px-4"
                onClick={() => {
                  generateDocument(true);
                  submitForm();
                  closeForm();
                }}
              >
                Subir archivo
              </Button>
            </div>
          </Modal>
        </>
      )}
    </Formik>
  );
};
