import React from 'react';
import classNames from 'classnames';

export const CashBalance: React.FC<{ [key: string]: any }> = (props) => {
  const { cash_balance } = props;
  const text = Number.isInteger(cash_balance) ? `$ ${cash_balance}` : '-';
  const classes = classNames(
    'cash_balance',
    Number.isInteger(cash_balance) && cash_balance < 0 && 'red',
    Number.isInteger(cash_balance) && cash_balance > 0 && 'green',
  );

  return <p className={classes}>{text}</p>;
};
