import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Copy, Form } from '#components/index';
import { CancelPaymentFormT } from '#interfaces/components';

export const DeletePaymentForm: React.FC<CancelPaymentFormT> = ({
  payment,
  isSupervisor,
  onChange,
}) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={useSubmit(payment, onChange)}
      enableReinitialize
    >
      <Form submitTitle="Eliminar" ignoreDirty>
        <Copy>
          {isSupervisor ? (
            'Esta acción borrará de forma permanente el pago seleccionado.'
          ) : (
            <>
              Esta intentando eliminar un pago del sistema, pero este se
              encuentra en un periodo de administración ya cerrado.
              <br />
              Debido a que Ud. es Supervisor, el sistema puede autorizarlo.
            </>
          )}
        </Copy>
      </Form>
    </Formik>
  );
};
