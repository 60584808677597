import { axiosInstance, platform } from '../utils';

export function getOdontogram(type: string, patientId: string | number) {
  const body = {
    paciente: {
      id_paciente: patientId || 0,
      opcion: 'buscar',
      quedatobuscar: type,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/aupaciente', body);
}
