import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { format } from 'date-fns';
import { Navigation } from 'swiper';
import classNames from 'classnames';
import {
  Button,
  Copy,
  Icons,
  Title,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Calendar,
  DateInput,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SwitchShadcn,
} from '../';
import { dataPreset, dataPresetCompare } from '../../mocks/statistics';
import { useFieldProps } from '../../hooks';
import { getPresetNames } from '../../utils';
import { DateRangePickerSCNT } from '#interfaces/components';

export const DatePickerWithRange: React.FC<DateRangePickerSCNT> = ({
  align,
  showCompare = true,
  name,
}) => {
  const swiperRef = React.useRef<any>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    field: { value: badgesValue },
    setFieldValue: setFieldValueBadge,
  } = useFieldProps({ name: 'badges' });
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name });
  const initialDate = (date: Date | string) =>
    typeof date === 'string' ? new Date(date) : date;
  const [originalRange, setOriginalRange] = React.useState<any>({
    from: initialDate(value.date_from),
    to: initialDate(value.date_to) || initialDate(value.date_from),
  });
  const [rangeCompare, setRangeCompare] = React.useState(
    (value.comparation_date_from && {
      from: initialDate(value.comparation_date_from),
      to:
        initialDate(value.comparation_date_to) ||
        initialDate(value.comparation_date_from),
    }) ||
      null,
  );
  const [switchCompare, setSwitchCompare] = React.useState(
    value.comparation_date_from ?? false,
  );
  const [selectedPreset, setSelectedPreset] = React.useState(
    getPresetNames(dataPreset, badgesValue.principal),
  );
  const [selectedPresetCompare, setSelectedPresetCompare] = React.useState(
    getPresetNames(dataPresetCompare, badgesValue.compare),
  );
  const calendars = ['Principal', 'Comparación'];
  const getPresetRange = (presetName: string | undefined) => {
    const from = new Date();
    const to = new Date();
    switch (presetName) {
      case 'last7':
        from.setDate(from.getDate() - 6);
        from.setHours(0, 0, 0, 0);
        to.setHours(21, 59, 59, 999);
        break;
      case 'last15':
        from.setDate(from.getDate() - 14);
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case 'last30':
        from.setDate(from.getDate() - 29);
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case 'last90':
        from.setDate(from.getDate() - 89);
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case 'personalizado':
        from.setDate(from.getDate());
        to.setDate(to.getDate());
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case 'lastperiod':
        from.setFullYear(originalRange.from.getFullYear());
        to.setFullYear(originalRange.to?.getFullYear());
        from.setMonth(originalRange?.from.getMonth() - 1);
        to.setMonth(originalRange.to?.getMonth() - 1);
        from.setDate(originalRange.from.getDate());
        to.setDate(originalRange.to?.getDate());
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;
      case 'sameperiodlastyear':
        from.setFullYear(originalRange.from.getFullYear() - 1);
        to.setFullYear(originalRange.to?.getFullYear() - 1);
        from.setMonth(originalRange.from.getMonth());
        to.setMonth(originalRange.to?.getMonth());
        from.setDate(originalRange.from.getDate());
        to.setDate(originalRange.to?.getDate());
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        break;

      default:
    }

    return { from, to };
  };
  const setPreset = (preset: string, compare?: boolean) => {
    const range = getPresetRange(preset);

    if (compare) {
      setSelectedPresetCompare(preset);
      setFieldValueBadge({
        ...badgesValue,
        compare: dataPresetCompare.find((d) => d.id === preset)?.label,
      });
      return setRangeCompare(range);
    }

    setSelectedPreset(preset);
    setFieldValueBadge({
      ...badgesValue,
      principal: dataPreset.find((d) => d.id === preset)?.label,
    });
    setOriginalRange(range);
  };
  const handleCalendarSelect = (value: any) => {
    value?.from && setOriginalRange({ from: value.from, to: value?.to });
    setSelectedPreset('personalized');
  };
  const handleCalendarSelectCompare = (value: any) => {
    value?.from && setRangeCompare({ from: value.from, to: value?.to });
    setSelectedPresetCompare('personalized');
  };
  const onDateApply = () => {
    if (originalRange?.to) {
      const objectDate = {
        date_from: originalRange?.from,
        date_to: originalRange?.to,
        comparation_date_from: rangeCompare?.from,
        comparation_date_to: rangeCompare?.to,
      };
      setIsOpen(false);
      setFieldValue(objectDate);
    }
  };
  const handleSwiperClick = (cmd: string) => {
    cmd === 'next'
      ? swiperRef.current.slideNext()
      : swiperRef.current.slidePrev();
  };
  const stylesContainerCalendar = classNames(
    'col-span-4',
    switchCompare ? 'w-[530px]' : 'w-auto ml-6',
  );
  const stylesSubContainerCalendar = classNames(
    'border border-slate-200 rounded p-2 h-64',
    rangeCompare && 'flex flex-row',
  );

  React.useEffect(() => {
    switchCompare && setRangeCompare(getPresetRange(selectedPresetCompare));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalRange]);

  return (
    <>
      <Popover
        open={isOpen}
        onOpenChange={(open: boolean) => {
          setIsOpen(open);
        }}
      >
        <PopoverTrigger asChild>
          <div className="py-1 flex flex-col gap-2">
            <div className="px-2 py-1 text-slate-400 flex justify-between border border-slate-200 rounded-md">
              <div>
                <span className="font-bold text-slate-500 text-base mx-2">
                  {format(new Date(originalRange.from), 'dd/MM/yyyy')}
                </span>
                {originalRange.to && (
                  <>
                    al
                    <span className="font-bold text-slate-500 text-base mx-2">
                      {format(new Date(originalRange.to), 'dd/MM/yyyy')}
                    </span>
                  </>
                )}
              </div>
              <Icons
                type="weekly"
                width={24}
                className="text-blue-500 hover:scale-110"
              />
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent align={align}>
          <div className="grid grid-cols-6 gap-2">
            <div className="col-span-2 flex flex-col h-80 w-60 overflow-hidden overflow-y-auto border border-slate-200 p-2 rounded">
              <Title className="text-sm mb-2">Selección de fecha</Title>
              <Select
                key={selectedPreset}
                defaultValue={selectedPreset}
                onValueChange={(value: string) => setPreset(value)}
              >
                <SelectTrigger className="mb-2">
                  <SelectValue placeholder="Seleccionar fecha" />
                </SelectTrigger>
                <SelectContent>
                  {dataPreset?.map((preset) => (
                    <SelectItem key={preset.id} value={preset.id}>
                      {preset.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <div>
                <Copy className="mb-1 text-sm">Desde</Copy>
                <DateInput
                  value={originalRange.from}
                  onChange={(date: Date | string) => {
                    const toDate =
                      originalRange.to == null || date > originalRange.to
                        ? date
                        : originalRange.to;
                    setSelectedPreset('personalized');
                    setOriginalRange(
                      (prevRange: {
                        from: string | Date;
                        to: string | Date;
                      }) => ({
                        ...prevRange,
                        from: date,
                        to: toDate,
                      }),
                    );
                  }}
                />
              </div>
              <div>
                <Copy className="mt-2 mb-1 text-sm">Hasta</Copy>
                <DateInput
                  value={originalRange.to}
                  onChange={(date: string | Date) => {
                    const fromDate =
                      date < originalRange.from ? date : originalRange.from;
                    setOriginalRange(
                      (prevRange: {
                        from: string | Date;
                        to: string | Date;
                      }) => ({
                        ...prevRange,
                        from: fromDate,
                        to: date,
                      }),
                    );
                  }}
                />
              </div>
              <div className="flex flex-row justify-between mt-4 w-52">
                {showCompare && (
                  <>
                    <Title className="text-sm mb-4">Comparar</Title>
                    <SwitchShadcn
                      defaultChecked={switchCompare}
                      onClick={(event: any) => {
                        const checked =
                          (event.target as HTMLInputElement).getAttribute(
                            'aria-checked',
                          ) === 'true';
                        if (!checked) {
                          setSwitchCompare(true);
                          if (!originalRange.to) {
                            setOriginalRange({
                              from: originalRange.from,
                              to: originalRange.from,
                            });
                          }
                          setRangeCompare({
                            from: new Date(
                              originalRange.from.getFullYear(),
                              originalRange.from.getMonth(),
                              originalRange.from.getDate() - 365,
                            ),
                            to: originalRange.to
                              ? new Date(
                                  originalRange.to.getFullYear() - 1,
                                  originalRange.to.getMonth(),
                                  originalRange.to.getDate(),
                                )
                              : new Date(
                                  originalRange.from.getFullYear() - 1,
                                  originalRange.from.getMonth(),
                                  originalRange.from.getDate(),
                                ),
                          });
                        } else {
                          setSwitchCompare(false);
                          setRangeCompare(null);
                        }
                      }}
                      id="compare-mode"
                    />
                  </>
                )}
              </div>
              <Select
                key={selectedPresetCompare}
                defaultValue={selectedPresetCompare}
                onValueChange={(value: string) => setPreset(value, true)}
                disabled={!rangeCompare}
              >
                <SelectTrigger className="mb-2">
                  <SelectValue placeholder="Seleccionar fecha" />
                </SelectTrigger>
                <SelectContent>
                  {dataPresetCompare.map((preset) => (
                    <SelectItem key={preset.id} value={preset.id}>
                      {preset.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Copy className="mb-1 text-sm">Desde</Copy>
              <DateInput
                disabled={!rangeCompare}
                value={rangeCompare?.from}
                onChange={(date: string | Date) => {
                  if (rangeCompare) {
                    const compareToDate =
                      rangeCompare.to == null || date > rangeCompare.to
                        ? date
                        : rangeCompare.to;
                    setSelectedPresetCompare('personalized');
                    setRangeCompare(
                      (prevRangeCompare: {
                        from: string | Date;
                        to: string | Date;
                      }) => ({
                        ...prevRangeCompare,
                        from: date,
                        to: compareToDate,
                      }),
                    );
                  }
                  setRangeCompare({
                    from: date,
                    to: new Date(),
                  });
                }}
              />
              <Copy className="mt-2 mb-1 text-sm">Hasta</Copy>
              <DateInput
                disabled={!rangeCompare}
                value={rangeCompare?.to}
                onChange={(date: string | Date) => {
                  rangeCompare &&
                    rangeCompare.from &&
                    setSelectedPresetCompare('personalized');
                  setRangeCompare({
                    ...rangeCompare,
                    from: date < rangeCompare.from ? date : rangeCompare.from,
                    to: date,
                  });
                }}
              />
            </div>
            <div className={stylesContainerCalendar}>
              <div className={stylesSubContainerCalendar}>
                {rangeCompare && (
                  <>
                    <Icons
                      type="arrow_back"
                      className="h-12 w-8 mr-6 mt-28 text-blue-500 bg-blue-100 hover:bg-blue-200 rounded"
                      onClick={() => handleSwiperClick('prev')}
                    />
                    <Swiper
                      spaceBetween={10}
                      slidesPerView="auto"
                      onSwiper={(swiper) => (swiperRef.current = swiper)}
                      navigation
                      modules={[Navigation]}
                    >
                      {calendars.map((calendarLabel, index) => (
                        <SwiperSlide
                          key={index}
                          style={{ flexShrink: 'initial' }}
                        >
                          <Title className="text-center mb-2">
                            {calendarLabel}
                          </Title>
                          <Calendar
                            mode="range"
                            focusDate={
                              index === 0 ? originalRange : rangeCompare
                            }
                            type={index === 0 ? 'principal' : 'compare'}
                            onSelect={
                              index === 0
                                ? handleCalendarSelect
                                : handleCalendarSelectCompare
                            }
                            selected={
                              index === 0 ? originalRange : rangeCompare
                            }
                            numberOfMonths={2}
                            month={
                              index === 0
                                ? new Date(originalRange.from)
                                : new Date(rangeCompare.from)
                            }
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <Icons
                      type="arrow_right"
                      className="h-12 w-8 ml-6 mt-28 text-green-500 bg-green-100 hover:bg-green-200 rounded"
                      onClick={() => handleSwiperClick('next')}
                    />
                  </>
                )}
                {!rangeCompare && (
                  <Calendar
                    mode="range"
                    focusDate={originalRange}
                    onSelect={handleCalendarSelect}
                    selected={originalRange}
                    numberOfMonths={2}
                    month={new Date(originalRange.from)}
                  />
                )}
              </div>
              {!originalRange?.to && (
                <span className="text-red-500">
                  Por favor seleccione un rango de fechas para continuar
                </span>
              )}
              <div className="mt-6 flex justify-end space-x-4">
                <Button
                  className="rounded shadow !w-28"
                  modifier="transparent"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  Cancelar
                </Button>
                <Button onClick={onDateApply} className="!w-28">
                  Aplicar
                </Button>
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
};
