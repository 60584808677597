import React from 'react';
import { Button } from '../../../components/shadcn/button';
import { useFieldProps } from '../../../hooks';

export const GoToToday: React.FC<{ name: string; layoutFieldName: string }> = ({
  name,
  layoutFieldName,
}) => {
  const { setFieldValue: setDateFieldValue } = useFieldProps({ name });
  const { setFieldValue: setLayoutFieldValue } = useFieldProps({
    name: layoutFieldName,
  });
  const onClickHandler = () => {
    setLayoutFieldValue('buscar');
    setDateFieldValue(new Date());
  };

  return (
    <Button onClick={onClickHandler} className="h-[38px] px-4">
      Hoy
    </Button>
  );
};
