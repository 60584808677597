import React from 'react';
import { NumericField } from '../../../../components';
import { useFieldProps } from '../../../../hooks';

export const InitialPayment: React.FC<{ name: string }> = ({ name }) => {
  const initPayPercentageFieldName = `${name}.percentage`;
  const initPayAmountFieldName = `${name}.amount`;
  const {
    field: { value: initialPercentageValue },
  } = useFieldProps({ name: initPayPercentageFieldName });
  const {
    field: { value: budgetTotalAmount },
  } = useFieldProps({ name: 'budget_total_amount' });
  const { setFieldValue } = useFieldProps({ name: initPayAmountFieldName });
  const initialPaymentFormatter = React.useMemo(() => {
    return initialPercentageValue / 100 || 0;
  }, [initialPercentageValue]);

  React.useMemo(() => {
    setFieldValue(Math.round(budgetTotalAmount * initialPaymentFormatter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetTotalAmount, initialPaymentFormatter]);

  return (
    <>
      <NumericField
        className="col-span-3"
        label="% Pago inicial"
        name={initPayPercentageFieldName}
        placeholder="0%"
      />
      <NumericField
        className="col-span-3"
        label="Valor pago inicial"
        name={initPayAmountFieldName}
        placeholder="$0"
        disabled
      />
    </>
  );
};
