import { createSlice } from '@reduxjs/toolkit';

const initialState: { [key: string]: any } = {
  newUser: false,
  oldUser: false,
  galery: false,
  newScheduleUi: false,
  scheduleLayouts: false,
  whatsappOnboarding: false,
  remindersOnboarding: false,
  statisticsOnboarding: false,
  recipes: false,
};

export const onboardingsSlice = createSlice({
  name: 'onboardings',
  initialState,
  reducers: {
    setOnboarding: (state, action) => {
      const onboardingFlow = action.payload;
      state[onboardingFlow] = true;
    },
  },
});

export const { setOnboarding } = onboardingsSlice.actions;

export default onboardingsSlice.reducer;
