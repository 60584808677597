import { useContext } from 'react';
import { deletePatient } from '../../../../services';
import {
  SnackbarContext,
  LoadingContext,
  FormContext,
} from '../../../../context';
import { PatientT } from '#interfaces/general-values';
import { FormikHelpers } from 'formik';

export function useSubmit(patient: PatientT, onChange: () => void) {
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);
  const { startLoading, stopLoading } = useContext(LoadingContext);

  return async (_spec: any, formikActions: FormikHelpers<any>) => {
    try {
      startLoading();
      await axiosPromise(patient);
      closeForm();
      formikActions.resetForm();
      onChange();
      showSuccessSnackbar('El paciente se eliminó exitosamente');
    } catch (err: any) {
      showErrorSnackbar('Ha ocurrido un error. Vuelve a intentarlo');
    } finally {
      formikActions.setSubmitting(false);
      stopLoading();
    }
  };
}

function axiosPromise(patient: PatientT) {
  const apiSpec = transformSpec(patient);

  return deletePatient(apiSpec);
}

function transformSpec(patient: PatientT) {
  const apiSpec: {
    id_paciente: number;
    rowversion: string;
    opcion: string;
  } = {
    id_paciente: patient.id_paciente,
    rowversion: patient.rowversion,
    opcion: 'eliminar',
  };
  return apiSpec;
}
