import React from 'react';
import { ControllerOffice } from '../controller-offices';
import { useFieldProps } from '../../../../../hooks';
import {
  TextFieldForm,
  DatePicker,
  CheckboxForm,
  DropdownForm,
  Button,
} from '#components/index';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from '#components/shadcn';
import { FirstStepT } from '#interfaces/components';

export const FirstStep: React.FC<FirstStepT> = ({
  data: { professionals, offices },
  onClick,
  isUsd,
}) => {
  const professionalFieldName = 'id_professional';
  const descriptionFieldName = 'description';
  const {
    field: { value: idOffice },
  } = useFieldProps({ name: 'id_office' });
  const {
    field: { value: descriptionValue },
  } = useFieldProps({ name: descriptionFieldName });
  const {
    field: { value: idProf },
  } = useFieldProps({ name: professionalFieldName });
  const {
    field: { value: idBudget },
  } = useFieldProps({ name: 'id_budget' });
  const {
    field: { value: benefitsList },
  } = useFieldProps({ name: 'benefits_list' });
  const filteredProfs = React.useMemo(() => {
    const profs = professionals.filter((p) => {
      if (idOffice) {
        const porfessionalsByOffice = p.prof_horarios.some(
          (prof: { [key: string]: any }) =>
            prof.id_sucursalNavigation?.id_sucursal === idOffice,
        );

        return p.habilitado && porfessionalsByOffice;
      }

      return p.habilitado;
    });

    return profs;
  }, [idOffice, professionals]);
  const buttonDisableHandler = !(
    idProf &&
    filteredProfs.length &&
    descriptionValue.trim().length
  );

  return (
    <Card className="mt-5">
      <CardHeader>
        <CardTitle>Datos del presupuesto</CardTitle>
        <CardDescription>
          Carga los datos referidos a la identificación del presupuesto.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-12 gap-5">
        <TextFieldForm
          name={descriptionFieldName}
          className="py-3 col-span-3"
          label="Descripción"
        />
        <DatePicker className="py-3.5 col-span-3" label="Fecha" name="date" />
        <DropdownForm
          name={professionalFieldName}
          data={filteredProfs}
          className="py-3 col-span-3 mt-px"
          label="Profesional"
          keyId="id_prof"
          keyData="nombre"
        />
        <div className="col-span-3 mt-9">
          {isUsd && (
            <CheckboxForm
              label="Es USD"
              name="is_usd"
              showError={false}
              description="Los importes se cargan en USD"
              disabled={!!benefitsList.length}
            />
          )}
        </div>
        {offices?.length > 0 && (
          <ControllerOffice offices={offices} idBudget={idBudget} />
        )}
        <Button
          size="large"
          className="col-start-1"
          onClick={onClick}
          disabled={buttonDisableHandler}
        >
          siguiente
        </Button>
      </CardContent>
    </Card>
  );
};
