import React from 'react';
import { Formik } from 'formik';
import { useForm } from './use-form';
import { useSubmit } from './use-submit';
import { ValuesToPayProf } from './ValuesToPayProf/values-to-pay-prof';
import { invoices, invoiceTypes, methods } from '../../../../mocks';
import { PaymentBudget } from './payment-budget';
import { PaymentMethods } from './PaymentMethods';
import { PaymentDropdown } from './payment-dropdown';
import { PaymentDebtField } from './payment-debt-field';
import {
  DatePicker,
  DropdownForm,
  TextFieldForm,
  NumericField,
  Form,
  CheckboxForm,
} from '#components/index';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  Button,
} from '#components/shadcn';
import { DetailPaymentsT } from '#interfaces/components';

export const DetailsPaymentForm: React.FC<DetailPaymentsT> = ({
  userId,
  payment,
  hasAdminModule,
  offices = [],
  patient,
  professionals = [],
  taxes,
  banks,
  cards,
  useUsd,
  onChange,
  onCancel,
  isSupervisor,
  permissions,
}) => {
  const budgets = patient?.presup;
  const eInvoice = !payment ? null : payment.id_fact_electronica;
  const isElectronicInvoice = eInvoice === null ? false : true;
  const professionalFieldName = 'professional';
  const officeFieldName = 'office';
  const filterBudgets = () => {
    if (useUsd.params && !useUsd.user) {
      return budgets?.filter((budget: any) => !budget.es_usd);
    }
    return budgets;
  };
  const { initialValues, validate } = useForm({
    payment,
    patient,
    offices,
    professionals,
    invoices,
    invoiceTypes,
    methods,
    budgets,
    banks,
    cards,
    taxes,
  });

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={useSubmit({
        userId,
        payment,
        patient,
        onChange,
        budgets,
        taxes,
      })}
    >
      {({ submitForm, isSubmitting, isValid }) => (
        <>
          <Form
            className="overflow-hidden overflow-y-scroll w-full grid grid-cols-12 gap-10"
            showActions={false}
          >
            <Card className="p-5 col-span-12 2xl:col-span-6">
              <CardHeader>
                <CardTitle>Datos generales del pago</CardTitle>
              </CardHeader>
              <CardContent className="grid grid-cols-12 gap-6">
                <DatePicker
                  className="col-span-4"
                  label="Fecha del pago"
                  name="date"
                  disabled={isElectronicInvoice}
                  fullWidth
                />
                <PaymentDropdown
                  officeFieldName={officeFieldName}
                  professionalFieldName={professionalFieldName}
                  professionals={professionals}
                  offices={offices}
                  payment={payment}
                  isElectronicInvoice={isElectronicInvoice}
                />
                {budgets && budgets.length > 0 && (
                  <PaymentBudget budgets={filterBudgets()} />
                )}
                <TextFieldForm
                  name="concept"
                  className="col-span-12"
                  label="Concepto"
                  placeholder="Escribe el concepto del pago aquí"
                  maxlength={50}
                />
                <DropdownForm
                  className="col-span-3"
                  name="invoice"
                  data={invoices}
                  label="Comprobante"
                  keyId="id"
                  keyData="text"
                  disabled={isElectronicInvoice}
                />
                <DropdownForm
                  className="col-span-3"
                  name="invoice_type"
                  data={invoiceTypes}
                  label="Tipo"
                  keyId="id"
                  keyData="text"
                  disabled={isElectronicInvoice}
                />
                <TextFieldForm
                  className="col-span-3"
                  name="invoice_number"
                  label="Número"
                  placeholder="1234"
                  maxlength={13}
                  disabled={isElectronicInvoice}
                />
                <PaymentMethods
                  paymentCards={cards}
                  hasAdminModule={hasAdminModule}
                  paymentBanks={banks}
                  eInvoice={isElectronicInvoice}
                />
                <NumericField
                  className="col-span-3"
                  name="paid"
                  label="Importe pagado"
                  placeholder="$ 0,00"
                  min={0}
                  type="number"
                  disabled={isElectronicInvoice}
                />
                <PaymentDebtField
                  name="to_pay"
                  invoiceFieldName="invoice"
                  className="col-span-3"
                  label="Importe adeudado"
                  placeholder="$ 0,00"
                  disabled={isElectronicInvoice}
                />
                {useUsd.params && useUsd.user && (
                  <div className="col-span-3 self-end">
                    <CheckboxForm
                      name="is_usd"
                      label="Es USD"
                      description="Los importes se cargaran en dolares"
                      showError={false}
                    />
                  </div>
                )}
                <DropdownForm
                  className="col-span-3"
                  name="tax"
                  data={taxes}
                  label="% I.V.A."
                  keyId="id"
                  keyData="tax"
                  disabled={isElectronicInvoice}
                />
                <TextFieldForm
                  label="Observaciones"
                  name="notes"
                  className="col-span-12"
                  maxlength={100}
                />
              </CardContent>
            </Card>
            <Card className="col-span-12 2xl:col-span-6 p-5">
              <CardHeader>
                <CardTitle>Valores del pago a pagar</CardTitle>
              </CardHeader>
              <CardContent className="grid grid-cols-12 gap-6">
                <ValuesToPayProf
                  enabled={
                    isSupervisor ||
                    permissions.hasOwnProperty('Modificar Comisión Pago')
                  }
                  name="values_to_pay_prof"
                />
              </CardContent>
            </Card>
          </Form>
          <div className="flex mt-5 space-x-5 justify-end">
            <Button
              className="px-10 h-10 bg-transparent !text-red-500 hover:bg-red-500 hover:!text-white border border-red-500"
              onClick={onCancel}
            >
              Cancelar
            </Button>
            <Button
              disabled={isSubmitting || !isValid}
              onClick={submitForm}
              type="submit"
              className="px-10 h-10 hover:bg-blue-600"
            >
              Guardar pago
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
};
