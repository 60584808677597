import React from 'react';
import { useDispatch } from 'react-redux';
import { FormContext, SnackbarContext } from '../../../../context';
import { startLoading, stopLoading } from '../../../../redux/slices';
import { deleteOrder } from '../../../../services';

export function useSubmit({
  selectedOrder,
  refresh,
}: {
  selectedOrder: any;
  refresh: (value?: string) => void;
}) {
  const dispatch = useDispatch();
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async () => {
    try {
      dispatch(startLoading());
      await axiosPromise({ selectedOrder });
      refresh();
      showSuccessSnackbar('Pedido eliminado correctamente');
    } catch (err) {
      let message;
      err instanceof Error ? (message = err.message) : (message = String(err));
      showErrorSnackbar(message);
    } finally {
      closeForm();
      dispatch(stopLoading());
    }
  };
}
function axiosPromise({ selectedOrder }: any) {
  return deleteOrder(selectedOrder);
}
