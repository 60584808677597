import React, { Fragment, useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Combobox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { Error } from './error';
import { useOnChange, useFieldProps } from '../../hooks';
import { DropdownFormT } from '#interfaces/components';

export const DropdownForm: React.FC<DropdownFormT> = ({
  name,
  data,
  className = '',
  placeholder = '',
  disabled,
  keyId = 'id',
  keyData = 'data',
  label,
  showError = true,
  clearable = false,
  classContainer = '',
}) => {
  const [selected, setSelected] = useState(null);
  const [query, setQuery] = useState('');
  const { field } = useFieldProps({ name });
  const onChange = useOnChange({ name });
  const currentValue = useMemo(
    () => data?.find((element: any) => element[keyId] === field.value) || null,
    [data, field.value, keyId],
  );

  useEffect(() => {
    if (clearable && !currentValue) {
      setSelected(currentValue);
    } else {
      setSelected(currentValue || data?.[0]);
    }
  }, [clearable, currentValue, data]);

  const onChangeHandler = (value: string) => {
    const findElement = data.find((element: any) => element[keyData] === value);
    onChange(value ? findElement[keyId] : null);
    setQuery('');
  };

  const ClearButton = () => (
    <div
      onClick={() => onChangeHandler('')}
      className="absolute inset-y-0 right-0 flex items-center pr-2"
    >
      <XCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </div>
  );

  const filtered =
    query === ''
      ? data
      : data?.filter((el: any) =>
          el[keyData]
            .toLowerCase()
            .replace(/\s+/g, '')
            .startsWith(query.toLowerCase().replace(/\s+/g, '')),
        );

  const comboCss = classNames(
    'w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:outline-none',
    disabled && 'bg-gray-100 text-gray-500 cursor-not-allowed',
  );

  return (
    <div className={`relative w-full ${className}`} id={`${name}-dropdown`}>
      {label && (
        <label htmlFor="input" className="mb-1.5 block text-sm font-medium">
          {label}
        </label>
      )}
      <Combobox
        value={selected?.[keyData] || ''}
        onChange={(e: any) => onChangeHandler(e)}
        nullable={false}
        disabled={disabled}
      >
        <div className="relative">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg border border-gray-300 bg-white text-left shadow-sm text-sm">
            <Combobox.Input
              className={comboCss}
              displayValue={(el: any) => el}
              onChange={(e) => setQuery(e.target.value)}
              placeholder={placeholder}
              readOnly
            />
            {selected && clearable ? (
              <Combobox.Button as={ClearButton}>
                <XCircleIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            ) : (
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            )}
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Combobox.Options
              className={classNames(
                'absolute z-50 mt-1 w-full max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                classContainer,
              )}
            >
              {filtered?.map((el: any) => (
                <Combobox.Option
                  key={el[keyId]}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-blue-600 text-white' : 'text-gray-900'
                    }`
                  }
                  value={el?.[keyData]}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {el[keyData]}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? 'text-white' : 'text-blue-600'
                          }`}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {showError && <Error name={name} />}
    </div>
  );
};
