import React from 'react';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import { attendanceOptions } from '../../../../mocks';
import {
  Icons,
  Tooltip,
  Badge,
  Select as BadgeSelect,
} from '#components/commons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '#components/shadcn';
import { setAppointment } from '../../../../redux/slices';
import { AppointmentT, TranslationsT } from '#interfaces/general-values';
import { CountriesT } from '#interfaces/components/commons';
import { AppDispatch } from '../../../../redux';
import { formatPhoneNumberWhatsapp } from '../../../../utils';

const DropdownActions: React.FC<{
  type: string;
  appointment: AppointmentT;
  actions: any;
  dispatch: AppDispatch;
  permissions: any;
}> = ({ type, appointment, actions, dispatch, permissions }) => {
  const { viewPatientData, giveOverturn, disableAppointment, editObservation } =
    permissions;
  const onSelectHandler = (type: string) => {
    actions({
      appointment,
      type,
    });
    dispatch(setAppointment(appointment));
  };
  const renderActions: { [key: string]: any } = {
    free: () => (
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuLabel>Turnos</DropdownMenuLabel>
        <DropdownMenuGroup>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <Icons type="today" className="mr-2 h-4 w-4" />
              <span>Nuevo turno</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem
                  className="hover:cursor-pointer"
                  onSelect={() =>
                    onSelectHandler('new_appointment_patient_exist')
                  }
                >
                  <Icons type="user" className="mr-2 h-4 w-4" />
                  <span>Paciente existente</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="hover:cursor-pointer"
                  onSelect={() =>
                    onSelectHandler('new_appointment_new_patient')
                  }
                >
                  <Icons type="user_plus" className="mr-2 h-4 w-4" />
                  <span>Paciente nuevo</span>
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          {giveOverturn && (
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onSelect={() => onSelectHandler('new_over_appointment')}
            >
              <Icons type="overturn" className="mr-2 h-4 w-4" />
              <span>Nuevo sobreturno</span>
            </DropdownMenuItem>
          )}
          {disableAppointment && (
            <DropdownMenuItem
              className="hover:cursor-pointer text-red-500"
              onSelect={() => onSelectHandler('disable_appointment')}
            >
              <Icons type="cancel" className="mr-2 h-4 w-4" />
              <span>Anular horario</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    ),
    patient_exist: () => (
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuLabel>Turnos</DropdownMenuLabel>
        <DropdownMenuGroup>
          {giveOverturn && (
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onSelect={() => onSelectHandler('new_over_appointment')}
            >
              <Icons type="overturn" className="mr-2 h-4 w-4" />
              <span>Nuevo sobreturno</span>
            </DropdownMenuItem>
          )}
          {editObservation && (
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onSelect={() => onSelectHandler('edit_observation')}
            >
              <Icons type="edit" className="mr-2 h-4 w-4" />
              <span>Editar observación</span>
            </DropdownMenuItem>
          )}
          {disableAppointment && (
            <DropdownMenuItem
              className="hover:cursor-pointer text-red-500"
              onSelect={() => onSelectHandler('delete_appointment')}
            >
              <Icons type="delete" className="mr-2 h-4 w-4" />
              <span>Eliminar turno</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
        {viewPatientData && (
          <>
            <DropdownMenuSeparator />
            <DropdownMenuLabel>Paciente</DropdownMenuLabel>
            <DropdownMenuGroup>
              <DropdownMenuItem
                className="hover:cursor-pointer"
                onSelect={() => onSelectHandler('preview_patient')}
              >
                <Icons type="patient_data" className="mr-2 h-4 w-4" />
                <span>Ir a datos del paciente</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </>
        )}
      </DropdownMenuContent>
    ),
    patient_first_time: () => (
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuLabel>Turnos</DropdownMenuLabel>
        <DropdownMenuGroup>
          {giveOverturn && (
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onSelect={() => onSelectHandler('new_over_appointment')}
            >
              <Icons type="overturn" className="mr-2 h-4 w-4" />
              <span>Nuevo sobreturno</span>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            className="hover:cursor-pointer"
            onSelect={() => onSelectHandler('new_appointment_new_patient')}
          >
            <Icons type="edit" className="mr-2 h-4 w-4" />
            <span>Modificar turno</span>
          </DropdownMenuItem>
          {disableAppointment && (
            <DropdownMenuItem
              className="hover:cursor-pointer text-red-500"
              onSelect={() => onSelectHandler('delete_appointment')}
            >
              <Icons type="delete" className="mr-2 h-4 w-4" />
              <span>Eliminar turno</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    ),
    canceled: () => (
      <DropdownMenuContent className="w-56" align="start">
        <DropdownMenuLabel>Turnos</DropdownMenuLabel>
        <DropdownMenuGroup>
          {giveOverturn && (
            <DropdownMenuItem
              className="hover:cursor-pointer"
              onSelect={() => onSelectHandler('new_over_appointment')}
            >
              <Icons type="overturn" className="mr-2 h-4 w-4" />
              <span>Nuevo sobreturno</span>
            </DropdownMenuItem>
          )}
          {disableAppointment && (
            <DropdownMenuItem
              className="hover:cursor-pointer text-green-500"
              onSelect={() => onSelectHandler('delete_appointment')}
            >
              <Icons type="check_clean" className="mr-2 h-4 w-4" />
              <span>Habilitar horario</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    ),
  };

  return renderActions[type]();
};

type RowDataT = {
  name: string;
  id_patient: number;
  hc_number: string;
  medical_insurance_name: string;
  id_medical_insurance: number;
  medical_insurance_plan: string;
  nro_afil: string;
  document: string;
  address: string;
  birth_date: string;
  getValue: (value: number | string) => any;
  original: any;
};

export const daily = ({
  actions,
  dispatch,
  translations,
  permissions,
  config,
}: {
  actions: any;
  dispatch: AppDispatch;
  translations: TranslationsT;
  permissions: any;
  config: {
    region: string;
    countries: CountriesT[];
  };
}) => {
  const { viewContactData } = permissions;
  const { region, countries } = config;

  return [
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { appointment_type } = row.original;
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="relative p-2 hover:cursor-pointer flex-none">
                <span className="sr-only">Abrir menu</span>
                <Icons
                  type="context"
                  className="text-gray-500 hover:text-gray-900 h-4 w-4"
                />
              </div>
            </DropdownMenuTrigger>
            <DropdownActions
              type={appointment_type.status}
              appointment={row.original}
              actions={actions}
              dispatch={dispatch}
              permissions={permissions}
            />
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: 'time',
      header: 'Hora',
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const { appointment_type, time, attendance, eta } = row.original;
        const showStatus =
          appointment_type.type === 'overturn' ||
          appointment_type.status === 'canceled';
        const statusCss = classNames(
          'rounded-full p-1 h-1.5 w-1.5 rounded-full',
          appointment_type.type === 'overturn' && 'bg-purple-400',
          appointment_type.status === 'canceled' && 'bg-red-500',
        );
        const appointmentCss = classNames(
          'font-regular text-base text-slate-800',
          showStatus && 'mr-2',
        );

        return (
          <div>
            <div className="flex items-center">
              <p className={appointmentCss}>{time}</p>
              {showStatus && <div className={statusCss} />}
            </div>
            {(attendance || eta) && (
              <div className="flex items-center mt-2">
                {eta && (
                  <Tooltip
                    align="bottom"
                    id={`${time}-tooltip`}
                    text="Hora de llegada"
                  >
                    <div className="flex items-center hover:underline">
                      <Icons
                        type="arrival"
                        className="w-5 h-5 text-slate-400"
                      />
                      <p className="ml-1 text-sm text-slate-500">{eta}</p>
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'attendance',
      header: 'Presentismo',
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const attendance = attendanceOptions.find(
          (att) => att.id === row.getValue('attendance'),
        );

        if (
          (!row.original.id_patient && !row.original.patient_name) ||
          row.original.dont_give
        ) {
          return null;
        }

        const badgeCss = classNames(
          attendance?.styles,
          'md:min-w-[160px] lg:w-full md:justify-between',
        );

        return (
          <BadgeSelect
            text={attendance?.text || ''}
            className={badgeCss}
            options={attendanceOptions}
            onSelect={(e) =>
              actions({
                appointment: row.original,
                type: 'change_attendance',
                attendanceId: e.target.id,
              })
            }
          />
        );
      },
      filterFn: (row: RowDataT, id: string, value: any) =>
        value.includes(row.getValue(id)),
    },
    {
      accessorKey: 'patient_name',
      header: 'Paciente',
      enableHiding: false,
      cell: ({ row }: { row: RowDataT }) => {
        const {
          patient_name,
          date,
          professional_name,
          time,
          patient_phone,
          clinic_history_number,
        } = row.original;

        const messageDate = new Date(date).toLocaleDateString('es', {
          day: 'numeric',
          year: 'numeric',
          month: 'short',
        });
        const message = `Hola, ${patient_name}! Te recordamos tu turno con *${professional_name}* el dia *${messageDate}* a las *${time}hs*. _Para cancelar o modificar el turno no dudes en contactarnos._ Te esperamos!`;
        const areaNumber =
          countries?.find(
            (country: any) => country.iso3 === region?.toUpperCase(),
          )?.phoneCode || '54';

        const onClickHandler = () => {
          const baseUrl = 'https://wa.me/';
          const VALIDATE_NUMBER = formatPhoneNumberWhatsapp(
            patient_phone,
            areaNumber,
          );
          const completeUrl = `${baseUrl}${VALIDATE_NUMBER}?text=${message}`;
          window.open(completeUrl, '_blank');
        };

        return (
          <div className="md:min-w-[200px]">
            <div className="flex flex-col-reverse items-start 2xl:flex-row 2xl:items-center">
              <p className="font-bold lg:text-sm xl:text-base text-slate-800 my-1">
                {patient_name}
              </p>
              {clinic_history_number && (
                <Badge
                  className="w-auto 2xl:ml-3"
                  text={`H.C: ${clinic_history_number}`}
                />
              )}
            </div>
            {patient_phone && viewContactData && (
              <span className="flex items-center">
                <p className="font-bold text-sm text-slate-400">
                  Tel: {patient_phone}
                </p>
                <span onClick={onClickHandler}>
                  <Icons
                    type="whatsapp"
                    className="ml-2 w-4 h-4 hover:cursor-pointer"
                    fill="#35c13e"
                  />
                </span>
              </span>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'medical_insurance',
      header: translations.medical_insurance,
      cell: ({ row }: { row: RowDataT }) => {
        const {
          medical_insurance: { name, plan, affiliate_number },
        } = row.original;

        return (
          name && (
            <div>
              <p className="font-bold text-base leading-6 text-slate-800 my-1">
                {name}
              </p>
              <p className="font-bold text-sm text-slate-400">
                Plan: {plan} {affiliate_number && `- ${affiliate_number}`}
              </p>
            </div>
          )
        );
      },
    },
    {
      accessorKey: 'observation',
      header: 'Observación',
      cell: ({ row }: { row: RowDataT }) => {
        const { observation } = row.original;

        return (
          observation && (
            <p className="text-base leading-6 text-slate-800 my-1">
              {observation}
            </p>
          )
        );
      },
    },
    {
      accessorKey: 'mod_by',
      header: 'Dado/Modificado',
      cell: ({ row }: { row: RowDataT }) => {
        const { mod_by, given_date } = row.original;

        return (
          mod_by && (
            <>
              <div className="flex items-center">
                <Icons type="user" className="h-4 w-4 mr-2 text-slate-600" />
                <p className="text-sm font-bold text-slate-800 my-1">
                  {mod_by}
                </p>
              </div>
              <div className="flex items-center">
                <Icons
                  type="schedule"
                  className="h-4 w-4 mr-2 text-slate-600"
                />
                <p className="text-sm text-slate-600 my-1">
                  {given_date && format(parseISO(given_date), 'dd/MM/yyyy')}
                </p>
              </div>
            </>
          )
        );
      },
    },
  ];
};
