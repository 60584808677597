import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CarouselT } from '#interfaces/components';
import 'swiper/css';

export const Carousel: React.FC<CarouselT> = ({
  Component,
  data,
  properties,
}) => {
  return (
    <Swiper
      className="pt-2 pb-10 px-0"
      spaceBetween={10}
      slidesPerView={4}
      updateOnWindowResize
    >
      {data &&
        data.map((e: any, i: number) => (
          <SwiperSlide key={i}>
            <Component {...e} {...properties} />
          </SwiperSlide>
        ))}
    </Swiper>
  );
};
