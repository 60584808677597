import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://api.logsnag.com/v1',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_LOGSNAG_TOKEN}`,
  },
});

export const logErrors = (params: any) => {
  const { user, plan, message, isOwner, supervisor, info } = params;

  const body = {
    project: 'bilog_log_api',
    channel: 'web-logger-errors',
    event: 'New Error - Flow Error',
    description: `Error: ${message}`,
    icon: '❌',
    notify: true,
    tags: {
      usuario: user,
      PlanVersion: plan,
      Owner: isOwner,
      Supervisor: supervisor,
      Tracking: info,
    },
  };

  return instance.post('/log', body);
};
