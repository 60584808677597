import React, { useEffect, useState } from 'react';
import ReactAutocomplete from 'react-select';
import classNames from 'classnames';
import { AutocompleteT } from '#interfaces/components/commons';
import { useOnChange, useFieldProps } from '../../hooks';
import { Error } from './error';

export const Autocomplete: React.FC<AutocompleteT> = ({
  name,
  filterKey,
  dataSource,
  placeholder,
  noRecordsMessage,
  showError = true,
  customMapper,
  className,
}) => {
  const {
    field: { value },
  } = useFieldProps({ name });

  const [label, setLabel] = useState(null);
  const onChange = useOnChange({ name });
  const styles = classNames('mt-2', className);

  useEffect(() => {
    if (!value) {
      setLabel(null);
    } else {
      const element = dataSource.find((el) => el[filterKey] === value);
      setLabel(element ? customMapper([element])?.[0] : null);
    }
  }, [customMapper, dataSource, value, filterKey]);

  const handleChange = (e: any) => {
    const currentElement: any = e;
    onChange(currentElement ? currentElement[filterKey] : null);
  };

  const noOptionsMessage = () => `${noRecordsMessage}`;

  return (
    <>
      <ReactAutocomplete
        className={styles}
        autoFocus
        isClearable
        isSearchable
        placeholder={placeholder}
        value={label}
        onChange={handleChange}
        noOptionsMessage={noOptionsMessage}
        options={customMapper(dataSource)}
      />
      {showError && <Error name={name} />}
    </>
  );
};
