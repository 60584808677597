import * as React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Checkbox } from '#components/shadcn';

export const columns: ColumnDef<any>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && 'indeterminate')
        }
        onCheckedChange={(value) => {
          table.toggleAllPageRowsSelected(!!value);
        }}
        aria-label="Seleccionar todo"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Seleccionar fila"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: 'paciente',
    accessorKey: 'paciente',
    header: 'Paciente',
    cell: ({ row }: { row: any }) => {
      const { paciente } = row.original;

      return (
        <p className="text-base font-semibold leading-6 text-slate-800 my-1">
          {paciente}
        </p>
      );
    },
  },
  {
    accessorKey: 'telefono',
    header: 'Teléfono',
    cell: ({ row }: { row: any }) => {
      const { telefono } = row.original;

      return <p className="leading-6 text-slate-800 my-1">{telefono}</p>;
    },
  },
];
