import React from 'react';
import { Snackbar } from '../components';
import { useSnackbar } from '../hooks';

export const SnackbarContext = React.createContext({
  showSuccessSnackbar: (message: string) => {},
  showErrorSnackbar: (message: string) => {},
  showWarningSnackbar: (message: string) => {},
});

export const SnackbarProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    isOpenSnackbar,
    snackbarMessage,
    snackbarType,
    showErrorSnackbar,
    showSuccessSnackbar,
    showWarningSnackbar,
    hideSnackbar,
  } = useSnackbar();

  return (
    <SnackbarContext.Provider
      value={{ showSuccessSnackbar, showErrorSnackbar, showWarningSnackbar }}
    >
      {children}
      {isOpenSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onChange={hideSnackbar}
        />
      )}
    </SnackbarContext.Provider>
  );
};
