import * as React from 'react';
import classNames from 'classnames';
import { ColumnsT } from '#interfaces/tables/benefit';
import { Icons } from '#components/commons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '#components/shadcn';

export const Columns = ({ action, config }: ColumnsT) => {
  const { offices, specialties } = config;

  let columns = [
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }: { row: any }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="relative p-2 hover:cursor-pointer flex-none">
              <Icons
                type="context"
                className="text-gray-500 hover:text-gray-900 h-4 w-4"
              />
              <span className="sr-only">Abrir menu</span>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56" align="start">
            <DropdownMenuGroup>
              <DropdownMenuItem
                className="hover:cursor-pointer"
                onSelect={() => action(row.original, 'edit')}
              >
                <Icons type="edit" className="mr-2 h-4 w-4" />
                <span>Editar</span>
              </DropdownMenuItem>
              <DropdownMenuItem
                className="hover:cursor-pointer text-red-500"
                onSelect={() => action(row.original, 'delete')}
              >
                <Icons type="delete" className="mr-2 h-4 w-4" />
                <span>Eliminar</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
    {
      accessorKey: 'day',
      header: 'Día',
      cell: ({ row }: { row: any }) => {
        const { day } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {day}
          </p>
        );
      },
    },
    {
      accessorKey: 'from',
      header: 'Hora desde',
      cell: ({ row }: { row: any }) => {
        const { from } = row.original;

        return (
          <p className="text-base font-semibold leading-6 text-slate-800 my-1">
            {from}
          </p>
        );
      },
    },
    {
      accessorKey: 'to',
      header: 'Hora hasta',
      cell: ({ row }: { row: any }) => {
        const { to } = row.original;

        return (
          <p className="text-base font-semibold leading-6 text-slate-800 my-1">
            {to}
          </p>
        );
      },
    },
    {
      accessorKey: 'interval',
      header: 'Intervalo',
      cell: ({ row }: { row: any }) => {
        const { interval } = row.original;

        return (
          <div className="flex rounded-full justify-center gap-x-2 px-4 py-1 bg-amber-50 ring-1 ring-inset ring-amber-600/10">
            <Icons type="clock" className="text-amber-500 h-4 w-4" />
            <p className="text-sm font-semibold text-amber-500">
              {interval} min
            </p>
          </div>
        );
      },
    },
    {
      accessorKey: 'format_date_from',
      header: 'Fecha desde',
      cell: ({ row }: { row: any }) => {
        const { format_date_from } = row.original;

        return (
          <p
            className={classNames('my-1 text-sm leading-6 text-slate-500', {
              '!text-blue-600': format_date_from === 'Fecha abierta',
            })}
          >
            {format_date_from}
          </p>
        );
      },
    },
    {
      accessorKey: 'format_date_to',
      header: 'Fecha hasta',
      cell: ({ row }: { row: any }) => {
        const { format_date_to } = row.original;

        return (
          <p
            className={classNames('my-1 text-sm leading-6 text-slate-500', {
              '!text-blue-600': format_date_to === 'Fecha abierta',
            })}
          >
            {format_date_to}
          </p>
        );
      },
    },
  ];

  if (specialties) {
    columns.push({
      accessorKey: 'specialties',
      header: 'Espcialidad',
      cell: ({ row }: { row: any }) => {
        const { id_especialidad } = row.original;
        const [specialty] = specialties?.filter(
          (s: { [key: string]: any }) => s.id_especialidad === id_especialidad,
        );

        return (
          <p className="text-sm leading-6 text-slate-500 my-1">
            {specialty?.descripcion || 'TODAS'}
          </p>
        );
      },
    });
  }

  if (offices?.length > 0) {
    columns.push({
      accessorKey: 'office',
      header: 'Sucursal',
      cell: ({ row }: { row: any }) => {
        const { id_sucursal } = row.original;

        const [office] = offices?.filter(
          (s: { [key: string]: any }) => s.id_sucursal === id_sucursal,
        );

        return (
          <p className="text-sm leading-6 text-slate-500 my-1">
            {office.descripcion}
          </p>
        );
      },
    });
  }

  return columns;
};
