import React from 'react';
import { Title, TextFieldForm } from '../../../components';

export const Card: React.FC<{
  data: {
    title: string;
    fieldName: string;
    description: string;
  }[];
}> = ({ data }) => {
  return data.map((item: any, index: number) => (
    <div className="col-span-3 pr-10" key={index}>
      <Title className="text-base font-regular">{item.title}</Title>
      <TextFieldForm name={item.fieldName} placeholder="BIL093" />
      <p className="pt-5 text-slate-500">{item.description}</p>
    </div>
  ));
};
