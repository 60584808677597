import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { BenefitCheckbox } from './checkbox';
import { BenefitDropdown } from './dropdown';
import {
  Copy,
  Subtitle,
  CheckboxForm,
  DropdownForm,
  TextFieldForm,
  NumericField,
  Form,
} from '#components/index';
import { BenefitFormT } from '#interfaces/components';
import { benefitFormFields } from '../../../../../../mocks';
import { useSetHeight } from '../../../../../../hooks';
import {
  Card,
  CardDescription,
  CardTitle,
  CardHeader,
  CardContent,
  CardFooter,
  Button,
} from '#components/shadcn';

export const BenefitForm: React.FC<BenefitFormT> = ({
  benefit,
  groups,
  specialties,
  selectedMed,
  selectedPlan,
  onChange,
  onCancel,
  odontogramList,
}) => {
  const height = useSetHeight(450);
  const { initialValues, validate } = useForm(benefit);
  const { ageField, unitField, toothField } = benefitFormFields;

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={useSubmit(selectedMed, selectedPlan, onChange)}
      enableReinitialize
    >
      {({ submitForm }) => (
        <Form
          height={`${height}px`}
          ignoreDirty
          className="overflow-hidden overflow-y-scroll"
          showActions={false}
        >
          <Card>
            <CardHeader>
              <CardTitle>
                {benefit ? 'Modificar prestación' : 'Nueva prestación'}
              </CardTitle>
              <CardDescription>
                Cargá todos los datos referidos a la prestación.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-6 gap-10 mb-4">
                <TextFieldForm
                  name="code_medical_insurance"
                  label="Código prest. O.S."
                  placeholder="00001"
                  className="col-span-2"
                />
                <TextFieldForm
                  name="code_medical_insurance_bill"
                  label="Código prest. O.S. Liq."
                  placeholder="00001"
                  className="col-span-2"
                />
                <TextFieldForm
                  name="description"
                  label="Descripción"
                  className="col-span-2"
                />
              </div>
              <div className="grid grid-cols-6 gap-10">
                <NumericField
                  name="billing_medical_insurance"
                  label="Importe O.S. ($)"
                  placeholder="$ 00.00"
                  min={0}
                  className="col-span-2"
                />
                <NumericField
                  name="billing_patient"
                  label="Importe Paciente ($)"
                  placeholder="$ 00.00"
                  min={0}
                  className="col-span-2"
                />
                <CheckboxForm
                  name="is_usd"
                  label="Importe en USD"
                  description="Indica si el importe de esta prestación es en USD."
                  showError={false}
                  className="col-span-2"
                />
              </div>
              <span className="h-px my-6 w-full bg-gray-300 block" />
              <Subtitle>Opciones</Subtitle>
              <Copy>Configura las opciones de la prestación.</Copy>
              <div className="items-end grid grid-cols-6 gap-10">
                <DropdownForm
                  name="tooth"
                  label="Acerca de diente / sector"
                  data={toothField}
                  keyId="id"
                  keyData="text"
                  className="col-span-2"
                />
                <CheckboxForm
                  name="tooth_face"
                  small
                  label="Pide cara"
                  showError={false}
                />
                <CheckboxForm
                  name="catastral"
                  small
                  label="Es catastral"
                  showError={false}
                />
                <CheckboxForm
                  name="intern"
                  small
                  label="Es interna"
                  showError={false}
                />
                <CheckboxForm
                  name="enabled"
                  small
                  label="Habilitada"
                  showError={false}
                />
              </div>
              <span className="h-px my-9 w-full bg-gray-300 block" />
              <Subtitle>Auditoría</Subtitle>
              <Copy>
                Configura las opciones para la auditoría de la prestación.
              </Copy>
              <div className="grid grid-cols-12 gap-10 mb-4">
                <BenefitDropdown
                  dropdownName="unit"
                  dropdownData={unitField}
                  dropdownKeyId="id"
                  dropdownKeyData="text"
                  dropdownPlaceholder="Unidad de carencia"
                  numericName="amount"
                  numericPlaceholder="Tiempo de carencia"
                />
                <BenefitDropdown
                  dropdownName="age_text"
                  dropdownData={ageField}
                  dropdownKeyId="id"
                  dropdownKeyData="text"
                  dropdownPlaceholder="Edad válida"
                  numericName="age"
                  numericPlaceholder="Edad"
                />
              </div>
              <div className="grid grid-cols-6 gap-10 mb-4">
                <NumericField
                  name="amount_patient"
                  label="Cantidad máxima por paciente y período"
                  hint="(9999 = Sin límites)"
                  min={0}
                  className="col-span-2"
                />

                <NumericField
                  name="amount_unit"
                  label="Cantidad máxima por paciente, período y diente/sector"
                  hint="(9999 = Sin límites)"
                  min={0}
                  className="col-span-2"
                />
              </div>
              <div>
                <BenefitCheckbox
                  checkboxName="enabled_draw"
                  label="Sin representación en odontograma"
                  dropdownName="draw"
                  dropdownData={odontogramList}
                  dropdownKeyId="id"
                  dropdownKeyData="nombre"
                  placeholder="Representación en odontograma"
                />
                <BenefitCheckbox
                  checkboxName="enable_group_belongs"
                  label="No pertenece a un grupo"
                  dropdownName="id_group"
                  dropdownData={groups}
                  dropdownKeyId="id_grupo"
                  dropdownKeyData="descripcion"
                  placeholder="Grupos"
                />
                {specialties && (
                  <BenefitCheckbox
                    checkboxName="enable_specialty"
                    label="No pertenece a una especialidad"
                    dropdownName="id_specialty"
                    dropdownData={specialties}
                    dropdownKeyId="id_especialidad"
                    dropdownKeyData="descripcion"
                    placeholder="Especialidades"
                  />
                )}
              </div>
            </CardContent>
            <CardFooter className="gap-5">
              <Button
                onClick={onCancel}
                className="px-10 h-10 bg-red-500 cursor-pointer hover:bg-red-600 text-white"
              >
                Cancelar
              </Button>
              <Button
                className="px-10 h-10 bg-blue-500 cursor-pointer hover:bg-blue-600 text-white"
                onClick={submitForm}
              >
                Guardar
              </Button>
            </CardFooter>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
