import React from 'react';
import { DeleteAbsenceForm } from './DeleteAbsenceForm';
import { AbsenceForm } from './AbsenceForm';
import { EmptyState, Modal } from '#components/index';
import { AbsencesFormT } from '#interfaces/components';
import { SettingsProfAbsences } from '#components/Tables';
import { useForm } from '../../../../../hooks';

const AbsencesForm: React.FC<AbsencesFormT> = ({
  professionals,
  selectedProf,
  onChange,
  openModal,
  closeModal,
  isOpenModal,
}) => {
  const [absences, setAbsences] = React.useState<any>([]);
  const [absenceRow, setAbsenceRow] = React.useState<any>(false);
  const {
    isOpenForm: isOpenDelete,
    closeForm: closeDelete,
    openForm: openDelete,
  } = useForm();
  const currentProfessional = React.useMemo(
    () =>
      professionals?.find((p) => p.id_prof === selectedProf) ||
      professionals?.[0],
    [professionals, selectedProf],
  );

  React.useEffect(
    () => setAbsences(currentProfessional.prof_ausencias),
    [currentProfessional],
  );

  const onCancelHandler = () => {
    setAbsenceRow(false);
    closeModal();
    closeDelete();
  };

  const actions = (absence: [key: string], action: string) => {
    const events: { [key: string]: any } = {
      delete: openDelete,
      edit: openModal,
    };
    setAbsenceRow(absence);

    return !!events[action] ? events[action]() : setAbsenceRow(false);
  };

  return (
    <>
      {absences.length ? (
        <SettingsProfAbsences data={absences} action={actions} />
      ) : (
        <EmptyState
          title="Ups!"
          className="w-full"
          copy="El profesional seleccionado no tiene ausencias cargadas."
        />
      )}
      <Modal
        title="Eliminar ausencia"
        isOpenModal={isOpenDelete}
        closeModal={onCancelHandler}
        openModal={openDelete}
      >
        <DeleteAbsenceForm
          absence={absenceRow}
          selectedProf={currentProfessional}
          onChange={onChange}
        />
      </Modal>
      <Modal
        title={absenceRow ? 'Modificar ausencia' : 'Nueva ausencia'}
        isOpenModal={isOpenModal}
        closeModal={onCancelHandler}
        openModal={openModal}
      >
        <AbsenceForm
          absence={absenceRow}
          selectedProf={currentProfessional}
          onChange={onChange}
          absences={absences}
        />
      </Modal>
    </>
  );
};

export default AbsencesForm;
