import React from 'react';
import { SuscriptionPriceT } from '#interfaces/components';

export const Subscription: React.FC<SuscriptionPriceT> = ({
  data,
  plan,
  suscribed,
}) => {
  if (plan === 'freemium') {
    return <h4 className="mt-10 "> / mes</h4>;
  }
  if (plan === 'premium' && suscribed === false) {
    return <h4 className="mt-10">por miembro / mes</h4>;
  }
  if (plan === 'premium' && suscribed === true) {
    return (
      <h4 className="mt-10 flex flex-row ">
        para
        <h4 className="text-sky-600 ml-1 mr-1">
          {`${data.pack_usuarios} ${
            data.pack_usuarios > 1 ? 'miembros' : 'miembro'
          } `}
        </h4>
        / mes
      </h4>
    );
  }
};
