import React from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarContext, FormContext } from '../../../../context';
import { createPayment, editPayment } from '../../../../services';
import { startLoading, stopLoading } from '../../../../redux/slices';
import { splitDate } from '../../../../utils';
import { AppDispatch } from '../../../../redux';
import { PaymentsT } from '#interfaces/components/forms';
import { BudgetT, PatientT, TaxesT } from '#interfaces/general-values';

export function useSubmit({
  userId,
  payment,
  patient,
  onChange,
  taxes,
  budgets,
}: {
  userId: number;
  payment: PaymentsT;
  patient: PatientT;
  onChange: () => void;
  taxes: TaxesT[];
  budgets: BudgetT[];
}) {
  const dispatch: AppDispatch = useDispatch();
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (spec: any) => {
    try {
      dispatch(startLoading());
      if (spec.id_budget && spec.with_budget) {
        const [selectedBudget] = budgets.filter(
          (b) => b.id_presup === spec.id_budget,
        );

        if (selectedBudget.es_usd !== spec.is_usd) {
          throw new Error(
            'Ups! El pago debe estar en la misma moneda que el presupuesto.',
          );
        }
      }

      await axiosPromise({ spec, userId, payment, patient, taxes });

      showSuccessSnackbar(
        payment
          ? 'El pago se ha actualizado con éxito'
          : 'El pago se ha creado con éxito',
      );
      closeForm();
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      dispatch(stopLoading());
    }
  };
}

function axiosPromise({
  spec,
  userId,
  payment,
  patient,
  taxes,
}: {
  spec: any;
  userId: number;
  payment: PaymentsT;
  patient: PatientT;
  taxes: TaxesT[];
}) {
  const patientName = patient.ape_nom;
  const apiSpec = transformSpec({ spec, userId, payment, taxes });

  return payment
    ? editPayment(apiSpec, patientName)
    : createPayment(apiSpec, patientName);
}

function transformSpec({
  spec,
  userId,
  payment,
  taxes,
}: {
  spec: any;
  userId: number;
  payment: PaymentsT;

  taxes: TaxesT[];
}) {
  const comi_payment = spec.values_to_pay_prof.professional_percentage;
  const comi_payment_amount = spec.values_to_pay_prof.professional_amount;
  const iva = taxes.find((t) => t.id === spec.tax);

  const date = splitDate(spec.date);

  let apiSpec: any = {
    id_pago: spec.id_payment,
    id_paciente: spec.id_patient,
    id_prof: spec.professional,
    fecha: date,
    concepto: spec.concept,
    comprobante: spec.invoice,
    tipo: spec.invoice_type,
    numero: spec.invoice_number,
    forma: spec.payment_method,
    debe: parseFloat(spec.to_pay),
    haber: parseFloat(spec.paid),
    es_usd: spec.is_usd || null,
    comi_pago: comi_payment ? parseFloat(comi_payment) : null,
    comi_pago_imp: comi_payment_amount ? parseFloat(comi_payment_amount) : null,
    iva: iva?.tax,
    id_presup: spec.with_budget ? spec.id_budget : null,
    anulado: spec.canceled,
    id_usuario: userId,
    id_sucursal: spec.office,
    id_os: spec.id_insurance,
    obs: spec.notes,
    interes: spec.interest,
    interes_porc: spec.interest_perc,
    cant_cuotas: spec.installments,
    id_banco: null,
    id_liq_prof: spec.id_prof_liq,
    importe_liq_prof: spec.amount_prof_liq,
    id_fact_electronica: spec.id_electronic_invoice,
    urlpdf: spec.url_pdf,
    estado_comprob: spec.invoice_state,
    mensaje_AFIP: spec.msg_AFIP,
    guardar_caja: false,
    caja: spec.register,
    tarjetas_mov: null,
    cheques: null,
  };

  if (payment) {
    apiSpec = {
      ...apiSpec,
      rowversion: payment.rowversion,
    };
  }

  if (spec.payment_method === 'Tarjeta') {
    const tarjetasMov = [
      {
        id_tarjeta: spec.id_card,
        nro_tarjeta: spec.mov_cards.card_number,
        nro_aut: spec.mov_cards.card_number_auth,
      },
    ];

    apiSpec = {
      ...apiSpec,
      tarjetas_mov: tarjetasMov,
      cant_cuotas: spec.installments,
      interes: spec.interest,
      interes_porc: spec.interest_perc,
    };
  }

  if (spec.payment_method === 'TarjetaD') {
    const tarjetasMov = [
      {
        id_tarjeta: spec.id_card,
        nro_tarjeta: spec.mov_cards.card_number,
        nro_aut: spec.mov_cards.card_number_auth,
      },
    ];
    apiSpec = {
      ...apiSpec,
      tarjetas_mov: tarjetasMov,
    };
  }

  if (spec.payment_method === 'Transf.') {
    apiSpec = {
      ...apiSpec,
      id_banco: spec.id_bank,
    };
  }

  if (spec.payment_method === 'Cheque') {
    const chequesDetails = [
      {
        fecha_dife: spec.paychecks.paycheck_date,
        banco: spec.paychecks.bank_emitter,
        nro_cheque: spec.paychecks.paycheck_number,
      },
    ];
    apiSpec = {
      ...apiSpec,
      cheques: chequesDetails,
    };
  }

  if (spec.payment_method === 'Otro') {
    apiSpec = {
      ...apiSpec,
      guardar_caja: spec.save_register,
    };
  }

  return apiSpec;
}
