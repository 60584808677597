import React from 'react';
import { DeletePlanForm } from './DeletePlanForm';
import { PlanForm } from './PlanForm';
import { EmptyState, Modal } from '../../../..';
import { useForm } from '../../../../../hooks';
import { PlansFormT } from '#interfaces/components';
import { SettingsPlans } from '#components/Tables';

const PlansForm: React.FC<PlansFormT> = ({
  medicalInsurances,
  selectedMed,
  onChange,
  openModal,
  closeModal,
  isOpenModal,
}) => {
  const defaultPlans = {
    id: 0,
    id_os: selectedMed?.id_os,
    code: '',
    name: '',
    enabled: true,
  };
  const [plans, setPlans] = React.useState<any>([]);
  const [plan, setPlan] = React.useState<any>(defaultPlans);
  const {
    isOpenForm: isOpenDelete,
    closeForm: closeDelete,
    openForm: openDelete,
  } = useForm();
  const current = React.useMemo(
    () => medicalInsurances?.find((p) => p.id_os === selectedMed?.id_os),
    [medicalInsurances, selectedMed],
  );

  React.useEffect(() => {
    setPlans(current?.planes);
  }, [current]);

  const onCancelHandler = () => {
    setPlan(defaultPlans);
    closeModal();
    closeDelete();
  };

  const actions = (plan: [key: string], action: string) => {
    const events: { [key: string]: any } = {
      delete: openDelete,
      edit: openModal,
    };

    setPlan(plan);
    return !!events[action] ? events[action]() : setPlan(defaultPlans);
  };

  return (
    <div className="overflow-hidden overflow-y-scroll">
      {plans?.length ? (
        <SettingsPlans data={plans} action={actions} />
      ) : (
        <EmptyState
          title="Ups!"
          copy="La obra social seleccionada no tiene planes definidos."
        />
      )}
      <Modal
        title="Eliminar plan"
        isOpenModal={isOpenDelete}
        closeModal={onCancelHandler}
        openModal={openDelete}
      >
        <DeletePlanForm
          plan={plan}
          selectedMed={selectedMed}
          onChange={onChange}
        />
      </Modal>
      <Modal
        title={plan ? 'Modificar plan' : 'Nuevo plan'}
        isOpenModal={isOpenModal}
        closeModal={onCancelHandler}
        openModal={openModal}
      >
        <PlanForm
          plan={plan}
          selectedMed={selectedMed}
          onChange={onChange}
          plans={plans}
        />
      </Modal>
    </div>
  );
};

export default PlansForm;
