import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { saveHc } from '../../../../services';
import { startLoading, stopLoading } from '../../../../redux/slices';
import { SnackbarContext, FormContext } from '../../../../context';

import { SelectedHisCliT } from '#interfaces/general-values';

export function useSubmit({
  clinicHistory,
  patientId,
  userId,
  onChange,
}: {
  clinicHistory: SelectedHisCliT;
  patientId: number;
  userId: string;
  onChange: () => void;
}) {
  const dispatch = useDispatch();
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async (spec: any) => {
    try {
      dispatch(startLoading());
      await axiosPromise({
        spec,
        clinicHistory,
        patientId,
        userId,
      });
      showSuccessSnackbar('Historia clínica guardada con éxito');
      onChange();
      closeForm();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      dispatch(stopLoading());
    }
  };
}

function axiosPromise({
  spec,
  clinicHistory,
  patientId,
  userId,
}: {
  spec: {
    date: string;
    hist_cli: string;
    professional: string;
    office: string;
    id_paciente: number;
    id_usuario: string;
  };
  clinicHistory: SelectedHisCliT;
  patientId: number;
  userId: string;
}) {
  const apiSpec = transformSpec({ spec, clinicHistory, patientId, userId });

  return saveHc(apiSpec);
}

function transformSpec({
  spec,
  clinicHistory,
  patientId,
  userId,
}: {
  spec: {
    date: string;
    hist_cli: string;
    professional: string;
    office: string;
    id_paciente: number;
    id_usuario: string;
  };
  clinicHistory: SelectedHisCliT;
  patientId: number;
  userId: string;
}) {
  const apiSpec: {
    his_cli: [
      {
        fecha: string;
        hist_cli: string;
        id_prof: string;
        id_sucursal: string;
        id_paciente: number;
        id_usuario: string;
        id_his_cli?: number;
        rowversion?: string;
      },
    ];
    opcion: string;
  } = {
    his_cli: [
      {
        fecha: spec.date,
        hist_cli: spec.hist_cli,
        id_prof: spec.professional,
        id_sucursal: spec.office,
        id_paciente: patientId,
        id_usuario: userId,
      },
    ],
    opcion: 'agregarhc',
  };

  if (clinicHistory?.id_clinic_record) {
    apiSpec.his_cli[0].id_his_cli = clinicHistory?.id_clinic_record;
    apiSpec.his_cli[0].rowversion = clinicHistory?.rowversion;
    return {
      ...apiSpec,
      opcion: 'modificarhc',
    };
  }

  return apiSpec;
}
