import { useFormikContext } from 'formik';
import { identity } from '../utils';
import { useOnChangeT } from '#interfaces/hooks';

export function useOnChange({ name, transform = identity }: useOnChangeT) {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (value: any) => {
    setFieldValue(name, value && transform(value));
    setFieldTouched(name, true);
  };
}
