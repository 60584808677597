import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Form } from '../../../../components';
import { PatientT } from '#interfaces/general-values';

const DeletePatientForm: React.FC<{
  patient: PatientT;
  onChange: (e?: any) => void;
}> = ({ patient, onChange }) => {
  return (
    <Formik initialValues={{}} onSubmit={useSubmit(patient, onChange)}>
      <Form submitTitle="Aceptar" ignoreDirty>
        <p>
          Estás por eliminar un paciente y esta acción es irreversible, el
          paciente se eliminará definitivamente.
        </p>
      </Form>
    </Formik>
  );
};

export default DeletePatientForm;
