import Imagen006 from '../static/imagesSuccefull/Imagen006.png';
import Imagen007 from '../static/imagesSuccefull/Imagen007.png';
import Imagen008 from '../static/imagesSuccefull/Imagen008.png';

export const typeLenders: {
  title: string;
  fieldName: string;
  description: string;
}[] = [
  {
    title: 'Código de prestador',
    fieldName: 'lender_code',
    description:
      'Acá debes ingresar el código que te ha dado tu gerenciador. Es único e irrepetible.',
  },
  {
    title: 'Código de gerenciador',
    fieldName: 'manager_code',
    description:
      ' Acá debes ingresar el código de tu gerenciador, círculo o asociación. Si tienes dudas de cual es, puedes consultarle a tu gerenciador.',
  },
];
export const succefullCard: {
  [key: string]: {
    title: string;
    img: string;
    button: string;
    description: string;
    route: string;
  };
} = {
  lender: {
    title: '¡Felicitaciones!',
    description:
      'Ya eres prestador. Ahora vamos a importar las obras sociales que necesitas.',
    button: 'Importar obras sociales',
    img: Imagen007,
    route: '/lenders/import-medical-insurances',
  },
  importMedIns: {
    title: '¡Has importado las obras sociales con éxito!!',
    description: 'Tus obras sociales y prestaciones están actualizadas.',
    button: 'Volver al inicio',
    img: Imagen008,
    route: '/home',
  },
  emptyState: {
    title: 'No se han encontrado obras sociales',
    description:
      'En este momento tu gerenciadora no cuenta con obras sociales disponibles para importar.',
    button: 'Volver al inicio',
    img: Imagen006,
    route: '/home',
  },
};
