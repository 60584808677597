import React from 'react';
import { getDefaultPeriod, roundNumber } from '../../../../../utils';
import { MedicalInsurancesT } from '#interfaces/general-values';

export function useForm({
  userId,
  benefit,
  patientId,
  budget,
  findMedicalInsurance,
}: {
  userId: string;
  benefit: { [key: string]: any } | null;
  patientId: string;
  budget: { [key: string]: any };
  findMedicalInsurance: MedicalInsurancesT | undefined;
}) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues({
      benefit,
      patientId,
      userId,
      budget,
      findMedicalInsurance,
    }),
  );

  React.useEffect(() => {
    setInitialValues(
      getInitialValues({
        benefit,
        patientId,
        userId,
        budget,
        findMedicalInsurance,
      }),
    );
  }, [benefit, patientId, userId, budget, findMedicalInsurance]);

  const validate = React.useCallback(
    (values: { [key: string]: any }) => {
      let errors: {
        todos?: { tooth_section: string | null; tooth_faces: string | null }[];
      } = {};
      const requiredMsg = 'Campo requerido';
      const benefitsArray = values.todos;
      let oneWithErrors = false;
      for (let i = 0; i < benefitsArray.length; i++) {
        if (!errors.todos) {
          errors.todos = [];
        }
        errors.todos.push({ tooth_section: null, tooth_faces: null });
        const toothFacesObject = values.todos[i]?.tooth_faces;

        if (benefit?.thoot_face && !toothFacesObject) {
          errors['todos'][i].tooth_faces = requiredMsg;
          oneWithErrors = values.todos.find(
            (todo: { [key: string]: any }) => !todo.tooth_faces,
          );
        }

        if (toothFacesObject) {
          const validation = !Object.values(toothFacesObject).some(
            (value: any) => value === true,
          );

          if (validation) {
            errors['todos'][i].tooth_faces = requiredMsg;
            oneWithErrors = true;
          }
        }
        if (
          ['DIE', 'ALG'].includes(benefit?.needs_thoot) &&
          !values.todos[i].tooth_section
        ) {
          errors['todos'][i].tooth_section = requiredMsg;
          oneWithErrors = values.todos.find(
            (todo: { [key: string]: any }) => !todo.tooth_section,
          );
        }
      }

      if (!oneWithErrors) {
        errors = {};
      }

      return errors;
    },
    [benefit],
  );

  return { initialValues, validate };
}

function getInitialValues({
  userId,
  benefit,
  patientId,
  budget,
  findMedicalInsurance,
}: {
  userId: string;
  benefit: { [key: string]: any } | null;
  patientId: string;
  budget: { [key: string]: any };
  findMedicalInsurance: MedicalInsurancesT | undefined;
}) {
  const totalBenefitsValue = (() => {
    let total = 0;
    budget?.prest_presup.forEach((benefit: { [key: string]: any }) => {
      total += benefit.imp_prest * benefit.cant;
    });

    return roundNumber(total);
  })();
  const benefitFromArray = budget?.prest_presup.find(
    (b: { [key: string]: any }) => b.id_prestacion === benefit?.id_benefit,
  );
  const todos = [];

  function calculateBenfitTotal({
    benefit,
    budget,
    totalBenefitsValue,
  }: {
    benefit: { [key: string]: any } | null;
    budget: { [key: string]: any };
    totalBenefitsValue: number;
  }) {
    const iva = budget.iva_porc ?? 0;
    const discountAmount = budget.desc_val ?? 0;
    const discountRate = budget.desc_por ?? 0;
    const interestRate = budget.interes_por ?? 0;
    const benefitValue = benefit?.amount;
    let totalValue, eqDiscount;
    totalValue = benefitValue + roundNumber((benefitValue * iva) / 100);

    if (discountAmount > 0 && totalBenefitsValue > 0) {
      eqDiscount = roundNumber(
        (discountAmount * totalValue) /
          (totalBenefitsValue + roundNumber((totalBenefitsValue * iva) / 100)),
      );
      totalValue -= eqDiscount;
    } else {
      totalValue -= roundNumber((totalValue * discountRate) / 100);
    }
    totalValue += roundNumber((totalValue * interestRate) / 100);

    return { totalValue: roundNumber(totalValue), eqDiscount };
  }

  const { totalValue, eqDiscount } = calculateBenfitTotal({
    benefit,
    budget,
    totalBenefitsValue,
  });

  for (let i = 0; i < benefit?.selected_quantity; i++) {
    todos.push({
      period: findMedicalInsurance?.cargar_por_periodo
        ? getDefaultPeriod()
        : null,
      date: new Date(), // revisar este campo
      id_professional: budget.id_prof,
      id_patient: patientId,
      id_benefit: benefit?.id_benefit,
      id_user: userId,
      id_office: budget.id_sucursal,
      tooth_faces: benefit?.tooth_faces || null,
      tooth_section: benefit?.tooth_section,
      is_usd: budget?.es_usd ?? false,
      values_to_charge: {
        from_medical_insurance:
          benefitFromArray.id_prestacionNavigation.importe_os,
        from_patient: totalBenefitsValue > 0 ? totalValue : 0,
      },
    });
  }

  return {
    todos,
    benefit_type: benefit?.id_benefit,
    imports: {
      amount: benefit?.amount,
      total_amount: totalBenefitsValue > 0 ? totalValue : 0,
      discount_amount: eqDiscount,
      discount_percentage: benefit?.discount?.percentage_value,
      interest_rate: benefit?.interest_rate,
      iva: budget.iva_porc,
    },
  };
}
