import React from 'react';
import { FormikHelpers } from 'formik';
import { updatePlansConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';
import { PlansT } from '#interfaces/mocks';

export function useSubmit({
  plan,
  selectedMed,
  onChange,
  plans,
}: {
  plan: PlansT;
  selectedMed: { [key: string]: any };
  onChange: () => void;
  plans: PlansT[];
}) {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      const validationCodePlan = plans.some(
        (plan) => plan.codigo_plan === spec.code,
      );

      if (!plan.id && validationCodePlan) {
        return showErrorSnackbar(
          'El Código de plan ya existe, escriba otro para continuar.',
        );
      }
      await axiosPromise({ spec, plan, selectedMed });
      closeForm();
      formikActions.resetForm();

      showSuccessSnackbar(
        !plan.id
          ? 'Plan guardado correctamente'
          : 'Plan actualizado correctamente',
      );
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise({
  spec,
  plan,
  selectedMed,
}: {
  spec: { [key: string]: any };
  plan: { [key: string]: any };
  selectedMed: { [key: string]: any };
}) {
  const apiSpec = transformSpec({ spec, plan, selectedMed });

  return updatePlansConfig(apiSpec);
}

function transformSpec({
  spec,
  plan,
  selectedMed,
}: {
  spec: { [key: string]: any };
  plan: { [key: string]: any };
  selectedMed: { [key: string]: any };
}) {
  const opcion = plan?.rowversion ? 'modificar' : 'nuevo';

  let apiSpec: { [key: string]: any } = {
    id_os: selectedMed.id_os,
    codigo_os: selectedMed.codigo_os,
    obra_social: selectedMed.obra_social,
    opcion,
    planes: [
      {
        id_plan: spec.id,
        codigo_plan: spec.code,
        nombre: spec.name.toUpperCase(),
        id_os: spec.id_os,
        inhabilitada: !spec.enabled,
      },
    ],
  };

  if (plan?.rowversion) {
    apiSpec.planes[0].rowversion = plan.rowversion;
  }

  return apiSpec;
}
