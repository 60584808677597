import React from 'react';
import { ProfessionalT } from '#interfaces/general-values';

export function useForm(props: ProfessionalT | undefined, provinces: any) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues(props, provinces),
  );

  React.useEffect(() => {
    setInitialValues(getInitialValues(props, provinces));
  }, [props, provinces]);

  const validate = React.useCallback((values: { [key: string]: any }) => {
    const errors: { [key: string]: any } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['code', 'name', 'tax_category'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues(
  professional: ProfessionalT | undefined,
  provinces: any,
) {
  return {
    id_prof: professional?.id_prof || 0,
    code: professional?.codigo_prof || '',
    prefix: professional?.abreviatura || 'Dra.',
    name: professional?.nombre || '',
    address: professional?.domicilio || '',
    location: professional?.localidad || '',
    state: professional?.provincia || '',
    phone: professional?.telefono || '',
    email: professional?.e_mail || '',
    cuit: professional?.cuit || '',
    tax_category: professional?.cond_fiscal || 'MO',
    plate: professional?.matricula || '',
    observations: professional?.observaciones || '',
    specialty_1: professional?.id_especialidad1 || null,
    specialty_2: professional?.id_especialidad2 || null,
    osde_id: professional?.cod_liquida || '',
    only_specialty: professional?.solamente || false,
    supervisor_view: professional?.liquidar_todos || false,
    enabled: professional?.habilitado || false,
    color_agenda: professional?.color_agenda || null,
    comision: professional?.comision || null,
    comision_labo: professional?.comision_labo || null,
    comision_pac: professional?.comision_pac || null,
    comision_pagos: professional?.comision_pagos || null,
    no_tiene_agenda: professional?.no_tiene_agenda || null,
    tope_solp: professional?.tope_solp || null,
    is_guard: professional?.es_guardia || false,
    signature: professional?.signature || null,
    type_registration: professional?.licenceType || 'MN',
    jurisdiction: professional?.licenceJurisdiction || provinces[0]?.name || '',
    profession: professional?.profession,
  };
}
