import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useFieldProps } from '../../hooks';
import { Error } from './error';
import { RadioButtonT } from '#interfaces/components';

export const RadioButton: React.FC<RadioButtonT> = ({
  name,
  className,
  disabled,
  showError = true,
  label,
  onChange,
  checked = false,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const { field } = useFieldProps({ name });

  useEffect(() => {
    if (field.value) {
      setIsChecked(field.value);
    } else {
      setIsChecked(checked || false);
    }
  }, [checked, field.value]);

  const iconClass = classNames(
    'shrink-0 mx-2 h-6 w-6 rounded-full p-1 ring-1 group-hover:cursor-pointer',
    isChecked
      ? 'text-white ring-blue-500 bg-blue-500 text-opacity-1'
      : 'text-white ring-slate-300 text-opacity-0',
  );

  const containerClass = classNames(
    'rounded-lg group hover:cursor-pointer shadow-md border border-slate-200',
    isChecked
      ? 'ring-1 bg-slate-900 ring-blue ring-offset ring-offset-blue-500 border-blue-300'
      : 'bg-white',
    disabled && 'opacity-50',
    className,
  );

  const pClass = classNames(
    'group-hover:cursor-pointer px-3 py-4',
    isChecked ? 'text-white' : 'text-slate-900',
  );

  return (
    <div className={containerClass} onClick={onChange}>
      <div className="flex group-hover:cursor-pointer items-center">
        <div className={iconClass}>
          <CheckIcon className="h-4 w-4" />
        </div>
        <input
          {...field}
          className="appearance-none group-hover:cursor-pointer"
          type="radio"
          disabled={disabled}
          id={name}
          name={name}
          checked={field.value}
        />
        <p className={pClass}>{label}</p>
      </div>
      {showError && <Error name={name} />}
    </div>
  );
};
