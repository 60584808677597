import { useCallback, useEffect, useState } from 'react';
import { getDefaultPeriod } from '../../../../utils';
import { BenefitT, OfficesT, ProfessionalT } from '#interfaces/general-values';

export const useForm = ({
  benefit,
  patientId,
  benefitTypes,
  offices,
  professionals,
}: {
  benefit: any;
  patientId: number;
  benefitTypes: BenefitT[];
  offices: OfficesT[];
  professionals: ProfessionalT[];
}) => {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues({ benefit, patientId, offices, professionals }),
  );

  useEffect(() => {
    setInitialValues(
      getInitialValues({ benefit, patientId, offices, professionals }),
    );
  }, [benefit, patientId, offices, professionals]);

  const validate = useCallback(
    (values: any) => {
      const errors: { [key: string]: string } = {};
      const requiredMsg = 'Campo requerido';
      const requiredFields = ['benefit_type', 'professional', 'date'];
      requiredFields.forEach((requiredField) => {
        if (!values[requiredField]) {
          errors[requiredField] = requiredMsg;
        }
      });

      if (offices?.length) {
        requiredFields.push('office');
      }

      if (values.benefit_type) {
        const benefitType = benefitTypes.find(
          (el) => el.id_prestacion === values.benefit_type,
        );

        if (['DIE', 'ALG'].includes(benefitType?.diente || '')) {
          requiredFields.push('tooth_section');
          if (!values.tooth_section) {
            errors.tooth_section = 'Debes especificar pieza o sector';
          }
        }

        if (benefitType?.pide_cara) {
          requiredFields.push('tooth_faces');
          const checkedToothFacesAmount =
            values.tooth_faces &&
            Object.values(values.tooth_faces).filter(Boolean).length;
          if (checkedToothFacesAmount < 1) {
            errors.tooth_faces =
              'Debes seleccionar entre un mínimo de una y un máximo de cinco caras';
          }
          if (checkedToothFacesAmount > 5) {
            errors.tooth_faces =
              'La prestación seleccionada no puede tener más de 5 caras';
          }
        }
      }

      return errors;
    },
    [benefitTypes, offices?.length],
  );

  return { initialValues, validate };
};

function getInitialValues({
  benefit,
  patientId,
  offices,
  professionals,
}: {
  benefit: any;
  patientId: number;
  offices: OfficesT[];
  professionals: ProfessionalT[];
}) {
  const office = offices && offices?.[0];
  const getProfessional = () => {
    if (offices?.length) {
      return professionals.find(
        (prof) =>
          prof.prof_horarios.filter((ph: any) => {
            return ph.id_sucursal === office.id_sucursal;
          }).length > 0,
      );
    }

    return professionals.filter((p) => p.habilitado === true)?.[0];
  };

  const professional = getProfessional();

  if (benefit) {
    const toothFaces = (benefit.cara ?? '').split('').reduce(
      (acc: any, face: string) => ({
        ...acc,
        [face.toLowerCase()]: true,
      }),
      {},
    );

    const response = {
      id_patient: benefit?.id_paciente || patientId,
      date: new Date(benefit.fecha),
      period: benefit.periodo || getDefaultPeriod(),
      professional: benefit.id_prof,
      tooth_section: benefit.diente_sector?.trim(),
      tooth_faces: toothFaces,
      notes: benefit.obs?.trim(),
      rowversion: benefit.rowversion,
      benefit_type: benefit.id_prestacion,
      is_usd: benefit?.es_usd !== null || false,
      values_to_charge: {
        from_medical_insurance: benefit.importe_os,
        from_patient: benefit.importe_pac,
      },
      pay_patient: benefit.importe_pac,
      pay_medIns: benefit.importe_os,
      values_to_pay: {
        medical_insurance_amount: benefit.comi_os_imp,
        medical_insurance_percentage: benefit.comi_os,
        patient_amount: benefit.comi_pac_imp,
        patient_percentage: benefit.comi_pac,
        can_edit: false,
      },
      office: null,
    };

    if (office) {
      return {
        ...response,
        office: benefit.id_sucursal || office.id_sucursal,
      };
    }

    return response;
  }

  return {
    id_patient: benefit?.id_paciente || patientId,
    date: new Date(),
    office: office?.id_sucursal,
    professional: professional?.id_prof,
    period: getDefaultPeriod(),
    values_to_pay: {
      medical_insurance_amount: null,
      medical_insurance_percentage: null,
      patient_amount: null,
      patient_percentage: null,
      can_edit: false,
    },
    tooth_faces: {},
    tooth_section: null,
    is_usd: false,
    values_to_charge: {
      from_medical_insurance: benefit?.importe_os || 0,
      from_patient: 0,
    },
    benefit_type: null,
  };
}
