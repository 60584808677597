import { axiosInstance, platform } from '../utils';

export async function getTableParams(tableParam: {}) {
  const body = {
    tablas: {
      opcionbuscar: tableParam,
    },
    plataforma: platform,
  };

  try {
    return await axiosInstance.post('/autablas', body);
  } catch (err: any) {
    const error = err.message;
    return error;
  }
}

export async function getOSPre(params: string, osIdPlan: number) {
  const body = {
    tablas: {
      opcionbuscar: params,
      OSPRE_Id_Plan: osIdPlan,
    },
    plataforma: platform,
  };

  try {
    const {
      data: { listaObraSociales, listaProfesionales, listaSucursales },
    } = await axiosInstance.post('/autablas', body);
    const [obrasSociales] = listaObraSociales;
    const [plan] = obrasSociales.planes;

    return {
      obrasSociales,
      prestaciones: plan.prestaciones,
      profesionales: listaProfesionales,
      sucursales: listaSucursales,
    };
  } catch (err: any) {
    return err.message;
  }
}

export async function getRegions() {
  try {
    return await axiosInstance.get('/wappregiones');
  } catch (err: any) {
    return err.message;
  }
}
