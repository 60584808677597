import React from 'react';
import { DropdownForm } from '../../../../../components';
import { useFieldProps } from '../../../../../hooks';
import { MedicalInsurancesT } from '#interfaces/general-values';

export const Controller: React.FC<{
  name: string;
  showEnabledFieldName: string;
  medicalInsurances: MedicalInsurancesT[];
  label?: string;
  onMedInsChange: (value: MedicalInsurancesT | undefined) => void;
}> = ({
  name,
  showEnabledFieldName,
  medicalInsurances,
  label,
  onMedInsChange,
}) => {
  const {
    field: { value: enabled },
  } = useFieldProps({ name: showEnabledFieldName });
  const {
    field: { value: medicalInsurance },
  } = useFieldProps({ name });

  const medInsToShow = React.useMemo(() => {
    if (enabled)
      return medicalInsurances.filter(
        (el: { [index: string]: any }) => !el.inhabilitada,
      );
    return medicalInsurances;
  }, [enabled, medicalInsurances]);

  const current = React.useMemo(
    () =>
      medicalInsurances.find(
        (m: { [index: string]: any }) => m.id_os === medicalInsurance,
      ),
    [medicalInsurances, medicalInsurance],
  );

  React.useEffect(() => {
    onMedInsChange(current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <>
      <div className="mb-5 col-span-3">
        <DropdownForm
          name={name}
          data={medInsToShow}
          className="col-span-4"
          keyId="id_os"
          keyData="obra_social"
          label={label}
        />
      </div>
    </>
  );
};
