import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Form, Copy } from '../../../../..';
import { DeleteProfFormT } from '#interfaces/components';

export const DeleteProfForm: React.FC<DeleteProfFormT> = ({
  profesional,
  onChange,
}) => {
  return (
    <Formik initialValues={{ profesional }} onSubmit={useSubmit(onChange)}>
      <Form submitTitle="Eliminar" ignoreDirty>
        <Copy>
          Estás por eliminar un/a profesional y esta acción es irreversible,
          el/la profesional se eliminará definitivamente.
        </Copy>
      </Form>
    </Formik>
  );
};
