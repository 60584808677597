import { useContext } from 'react';
import { cancelPayment } from '../../../../services';
import {
  SnackbarContext,
  LoadingContext,
  FormContext,
} from '../../../../context';
import { FormikHelpers } from 'formik';

import { PaymentsT } from '#interfaces/components/forms/payments';

export function useSubmit(paymentSpec: PaymentsT, onChange: () => void) {
  const { closeForm } = useContext(FormContext);
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const { showSuccessSnackbar, showErrorSnackbar } =
    useContext(SnackbarContext);

  return async (_spec: any, formikActions: FormikHelpers<any>) => {
    try {
      startLoading();
      await axiosPromise(paymentSpec);
      closeForm();
      showSuccessSnackbar(
        !paymentSpec.anulado
          ? 'Pago anulado con éxito'
          : 'Pago habilitado con éxito',
      );
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.resetForm();
      formikActions.setSubmitting(false);
      stopLoading();
    }
  };
}

function axiosPromise(paymentSpec: any) {
  const apiSpec = transformSpec(paymentSpec);

  return cancelPayment(apiSpec);
}

function transformSpec(paymentSpec: PaymentsT) {
  const apiSpec: PaymentsT = {
    id_pago: paymentSpec.id_pago,
    id_paciente: paymentSpec.id_paciente,
    id_prof: paymentSpec.id_prof,
    fecha: paymentSpec.fecha,
    concepto: paymentSpec.concepto,
    comprobante: paymentSpec.comprobante,
    tipo: paymentSpec.tipo,
    numero: paymentSpec.numero,
    forma: paymentSpec.forma,
    debe: paymentSpec.debe,
    haber: paymentSpec.haber,
    es_usd: paymentSpec.es_usd,
    comi_pago: paymentSpec.comi_pago,
    comi_pago_imp: paymentSpec.comi_pago_imp,
    iva: paymentSpec.iva,
    id_presup: paymentSpec.id_presup,
    anulado: !paymentSpec.anulado,
    id_usuario: paymentSpec.id_usuario,
    id_sucursal: paymentSpec.id_sucursal,
    id_os: paymentSpec.id_os,
    obs: paymentSpec.obs,
    interes: paymentSpec.interes,
    interes_porc: paymentSpec.interes_porc,
    cant_cuotas: paymentSpec.cant_cuotas,
    id_banco: paymentSpec.id_banco,
    id_liq_prof: paymentSpec.id_liq_prof,
    importe_liq_prof: paymentSpec.importe_liq_prof,
    id_fact_electronica: paymentSpec.id_fact_electronica,
    urlpdf: paymentSpec.urlpdf,
    estado_comprob: paymentSpec.estado_comprob,
    mensaje_AFIP: paymentSpec.mensaje_AFIP,
    guardar_caja: paymentSpec.guardar_caja,
    caja: paymentSpec.caja,
    tarjetas_mov: paymentSpec.tarjetas_mov,
    cheques: paymentSpec.cheques,
    bancos_mov: paymentSpec.bancos_mov,
    rowversion: paymentSpec.rowversion,
  };

  return apiSpec;
}
