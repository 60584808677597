import React from 'react';
import { FormikHelpers } from 'formik';
import { updateGroupsConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';

export function useSubmit(
  selectedMed: { [key: string]: any },
  onChange: () => void,
) {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(spec, selectedMed);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Grupo guardado correctamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(
  spec: { [key: string]: any },
  selectedMed: { [key: string]: any },
) {
  const apiSpec = transformSpec(spec, selectedMed);

  return updateGroupsConfig(apiSpec);
}

function transformSpec(
  spec: { [key: string]: any },
  selectedMed: { [key: string]: any },
) {
  const opcion = spec.rowversion ? 'modificar' : 'nuevo';

  let apiSpec: {
    codigo_os: string;
    id_os: number;
    obra_social: string;
    opcion: 'modificar' | 'nuevo' | 'eliminar';
    grupos: {
      id_grupo: number;
      id_os: number;
      cod_grupo: string;
      descripcion: string;
      rowversion?: string;
    }[];
  } = {
    codigo_os: selectedMed.codigo_os,
    id_os: selectedMed.id_os,
    obra_social: selectedMed.obra_social,
    opcion,
    grupos: [
      {
        id_grupo: spec.id,
        id_os: spec.id_os,
        cod_grupo: spec.code.toUpperCase(),
        descripcion: spec.description.toUpperCase(),
      },
    ],
  };

  if (spec.rowversion) {
    apiSpec.grupos[0].rowversion = spec.rowversion;
  }

  return apiSpec;
}
