import React from 'react';
import { Formik } from 'formik';
import { useForm } from './use-form';
import { useSubmit } from './use-submit';
import { TextFieldForm, Form } from '../../../../components';
import { ControllerMedicalInsurance } from './controller-med-ins';
import { FirstTimeFormT } from '#interfaces/components';

const FirstTimeForm: React.FC<FirstTimeFormT> = ({
  appointment,
  userId,
  date,
  offices,
  onChange,
  medicalInsurances,
  translations,
}) => {
  const focused = React.useRef<any>(null);
  const { initialValues, validate } = useForm({
    appointment,
    userId,
    date,
    offices,
    medicalInsurances,
  });

  React.useEffect(() => {
    focused.current.focus();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={useSubmit({ appointment, onChange, medicalInsurances })}
      enableReinitialize
    >
      <Form>
        <div className="grid grid-cols-2 gap-5 mt-4">
          <TextFieldForm
            refTarget={focused}
            label="Apellido y nombre"
            name="patient"
            className="col-span-2"
          />
          <ControllerMedicalInsurance
            medicalInsurances={medicalInsurances}
            translations={translations}
          />
          <TextFieldForm
            label="Nº de afiliado"
            placeholder="0 / 00000"
            name="afiliate_number"
            className="col-span-1"
          />
          <TextFieldForm
            label="Teléfono"
            placeholder="00 0000-0000"
            name="phone"
            className="col-span-1"
          />
          <TextFieldForm
            label="Observaciones"
            name="notes"
            className="col-span-2 mb-5"
            placeholder="Ingrese observaciones"
          />
        </div>
      </Form>
    </Formik>
  );
};

export default FirstTimeForm;
