import React from 'react';
import { Columns } from './columns';
import { Data } from './data';

export const Table: React.FC<{
  data: any[];
  onClick: (value: number) => void;
  sorting: any;
  setSorting: any;
  height: string;
  refList?: any;
  onScroll?: any;
}> = ({ data, onClick, sorting, setSorting, height, refList, onScroll }) => {
  return (
    <div className="mx-auto w-full bg-white">
      <Data
        onClick={onClick}
        columns={Columns()}
        data={data}
        sorting={sorting}
        setSorting={setSorting}
        height={height}
        refList={refList}
        onScroll={onScroll}
      />
    </div>
  );
};
