import React from 'react';
import { useDispatch } from 'react-redux';
import './index.scss';
import { BenefitForm } from './BenefitForm';
import BenefitList from './BenefitList';
import { OdontogramDropdown } from './odontogram-dropdown';
import { OdontogramLayout } from './odontogram-layout';
import { SuccesFull as Empty, Modal } from '../../../../components';
import { useForm as useModal, useSetHeight } from '../../../../hooks';
import { filterTodosByTooth } from '../../../../utils';
import { fetchOdontogram } from '../../../../redux/slices/patients';
import { startLoading, stopLoading } from '../../../../redux/slices';
import { emptyStateNotPatient } from '../../../../mocks';
import { OdontogramFormT } from '#interfaces/components';
import { AppDispatch } from '../../../../redux';

const OdontogramaForm: React.FC<OdontogramFormT> = ({
  patient: { odontograma, id_paciente, id_planNavigation, todos },
  userId,
  translations,
  offices = [],
  professionals = [],
  benefitTypes = [],
  onChange,
  handlerFocus,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const medicalInsurance = id_planNavigation?.id_osNavigation;
  const [selectedTooth, setSelectedTooth] = React.useState<number | null>(null);
  const [filteredTodos, setFilteredTodos] = React.useState<any>([]);
  const height = useSetHeight(id_paciente ? 420 : 220);
  const {
    isOpenForm: isOpenModalBenefit,
    closeForm: closeModalBenefit,
    openForm: openModalBenefit,
  } = useModal();
  const {
    isOpenForm: isOpenModalList,
    closeForm: closeModalList,
    openForm: openModalList,
  } = useModal();

  function onToothClickHandler(action: string, tooth: number) {
    setSelectedTooth(tooth);
    if (action === 'new') return openModalBenefit();
    if (action === 'details') {
      setFilteredTodos(filterTodosByTooth(todos, tooth));
      return openModalList();
    }
  }

  const handleOnChange = async (type: string) => {
    const patientId = id_paciente;
    dispatch(startLoading());
    await dispatch(fetchOdontogram({ type, patientId }));

    dispatch(stopLoading());
  };

  const currentLayout = odontograma?.length === 32 ? 'per' : 'pri';

  return (
    <section className="mt-5" style={{ height: `${height}px` }}>
      {id_paciente && odontograma && odontograma.length > 0 ? (
        <>
          <div className="odontogram-type">
            <OdontogramDropdown
              onChange={handleOnChange}
              currentLayout={currentLayout}
            />
          </div>
          <OdontogramLayout
            odontogram={odontograma}
            onClick={onToothClickHandler}
          />
          <Modal
            title="Cargar nueva prestación"
            isOpenModal={isOpenModalBenefit}
            closeModal={closeModalBenefit}
            openModal={openModalBenefit}
            width="100%"
          >
            <BenefitForm
              userId={userId}
              offices={offices}
              patientId={id_paciente}
              medicalInsurance={medicalInsurance}
              professionals={professionals}
              benefitTypes={benefitTypes}
              tooth={selectedTooth}
              onChange={onChange}
            />
          </Modal>
          <Modal
            title="Lista de prestaciones"
            isOpenModal={isOpenModalList}
            closeModal={closeModalList}
            openModal={openModalList}
            width="80%"
          >
            <BenefitList todos={filteredTodos} translations={translations} />
          </Modal>
        </>
      ) : (
        <Empty
          typeSucces="emptyState"
          dataMocks={emptyStateNotPatient}
          onClick={handlerFocus}
        />
      )}
    </section>
  );
};

export default OdontogramaForm;
