import React from 'react';
import { useDispatch } from 'react-redux';
import { FormikHelpers } from 'formik';
import {
  startLoading,
  stopLoading,
  listBudgets,
  setCurrentStatistics,
} from '../../../../redux/slices';
import { SnackbarContext, FormContext } from '../../../../context';
import { splitDate, groupByCategory } from '../../../../utils';
import { AppDispatch } from '../../../../redux';

export function useSubmit() {
  const dispatch: AppDispatch = useDispatch();
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (spec: any, formikActions: FormikHelpers<any>) => {
    try {
      dispatch(startLoading());
      await axiosPromise(spec, dispatch);
      dispatch(setCurrentStatistics({ budgetsCurrent: spec }));
      showSuccessSnackbar('Estadísticas generadas correctamente');
      closeForm();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
      dispatch(stopLoading());
    }
  };
}

async function axiosPromise(spec: any, dispatch: AppDispatch) {
  const apiSpec = transformSpec(spec);

  return await dispatch(listBudgets(apiSpec));
}

function transformSpec(spec: any) {
  const from = splitDate(spec.date_from);
  const to = splitDate(spec.date_to);

  const apiSpec = {
    DateFrom: from,
    DateTo: to,
    GroupDateBy: groupByCategory(spec.group_date_by),
    GroupByMedicalInsurances: groupByCategory(spec.group_by_med_ins),
    MedicalInsurancesDisabled: spec.show_disabled_med_ins,
    ProfessionalDisabled: spec.show_disabled_prof,
    ProfessionalId: spec.id_prof,
    MedicalInsuranceId: spec.id_med_ins,
    SubsidiaryId: spec.id_subsidiary,
    BenefitCode: spec.benefit_code,
  };
  return apiSpec;
}
