import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Copy,
  SuccesFull as Empty,
  Modal,
  Button as NormalButton,
} from '#components/index';
import { Button } from '#components/shadcn';
import { Recipes } from '#components/Tables';
import { RecipeModal } from '#components/Products/recipe-modal';
import { RecipesFormT } from '#interfaces/components/forms/patient';
import { useForm, useSetHeight } from '../../../hooks';
import { emptyStateRecipe, emptyStateNotPatient } from '../../../mocks';
import {
  completeProgressBarRecipe,
  openSectionInBudget,
  resetProgressBarRecipe,
  setSelectedRecipe,
  setOnboarding,
} from '../../../redux/slices';
import { validatePack } from '../../../services/recipes';
import { ModalContext, SnackbarContext } from '../../../context';
import { TermsAndConditions } from './terms_conditions';

export const RecipesForm: React.FC<RecipesFormT> = ({
  patient,
  handlerFocus,
  medicalInsurances,
  offices,
  termsConditions,
  onChange,
  onboarding,
  permissions,
  isSupervisor,
  config: { cuit, direccion, nombre },
  countries,
  region,
  showPack,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [layout, setLayout] = React.useState('expanded');
  const [sorting, setSorting] = React.useState([]);
  const { showErrorSnackbar } = React.useContext(SnackbarContext);
  const { showModal, hideModal } = React.useContext(ModalContext);
  const height = useSetHeight(patient?.id_paciente ? 420 : 220);
  const { prescriptions } = patient;
  const { isOpenForm, openForm, closeForm } = useForm();
  const { isOpenForm: isOpenOnboarding, closeForm: closeOnboarding } =
    useForm(true);
  const {
    'Descargar / Imprimir': download,
    'Ver Recetas': view,
    'Emitir Receta': to_emit,
  } = permissions;
  const areaNumber =
    countries?.find((country) => country.iso3 === region?.toUpperCase())
      ?.phoneCode || 54;
  const casesAction: { [key: string]: (recipe: any) => any } = {
    view_details: (recipe) => {
      const selected = patient.prescriptions?.find(
        (r: { [key: string]: any }) =>
          r.prescriptionId === recipe.prescriptionId,
      );
      dispatch(openSectionInBudget('patient-recipes'));
      dispatch(completeProgressBarRecipe());
      dispatch(setSelectedRecipe(selected));
      navigate('/recipes');
    },
    download_pdf: (recipe) => window.open(recipe.fileLink, '_blank'),
    send: (recipe) => {
      const url = 'https://wa.me/';
      if (patient.tel_celu) {
        const phone = patient.tel_celu
          .trim()
          .replace(/\s+/g, '')
          .replace(/-/g, '');

        if (patient.tel_celu.includes(`+${areaNumber}`)) {
          return window.open(`${url}${phone}`, '_blank');
        }
        return window.open(
          `${url}+${areaNumber}${phone}?text=Ingresa%20al%20siguiente%20link%20para%20descargar%20tu%20receta:%20${recipe.fileLink}`,
          '_blank',
        );
      }
    },
  };
  const validationPackRecipe = async () => {
    try {
      await validatePack();
      return null;
    } catch (err: any) {
      return err;
    }
  };
  const handleNewRecipeOnClick = async () => {
    if (patient?.documento.length < 6)
      return showErrorSnackbar(
        'El documento del paciente es inválido. Debe tener un mínimo de 6 números.',
      );
    if (/[0-9!@#$%^&*(),.?":{}|<>]/.test(patient?.ape_nom))
      return showErrorSnackbar(
        'El nombre del paciente no puede contener numeros y/o caracteres especiales.',
      );
    if (!cuit || !direccion || !nombre)
      return showErrorSnackbar(
        'La clínica no tiene alguno o todos los parámetros: CUIT, dirección o nombre. Por favor, complete los datos en Configuración/Datos Generales.',
      );
    if (isSupervisor || to_emit) {
      if (termsConditions) {
        dispatch(openSectionInBudget('patient-recipes'));
        dispatch(setSelectedRecipe(null));
        dispatch(resetProgressBarRecipe());
        const validate = await validationPackRecipe();
        if (validate) {
          showModal(
            'Contratar Pack de Recetas',
            <RecipeModal onCancel={hideModal} />,
          );
          return showErrorSnackbar(validate.message);
        }
        navigate('/recipes');
      }
      return openForm();
    }
    showErrorSnackbar(
      'Ud. no posee permiso para emitir receta. Póngase en contacto con su administrador',
    );
  };

  const handlerEmpty = {
    dataMocks: !patient.id_paciente ? emptyStateNotPatient : emptyStateRecipe,
    onClick: !patient.id_paciente ? handlerFocus : handleNewRecipeOnClick,
  };
  const actions = (recipe: { [key: string]: string }, action: string) => {
    casesAction[action](recipe);
  };
  const onCloseOnboarding = () => {
    dispatch(setOnboarding('recipes'));
    closeOnboarding();
  };

  return (
    <section className="mt-5" style={{ height: `${height}px` }}>
      {patient.prescriptions && patient.prescriptions.length ? (
        <>
          <Recipes
            data={{
              recipes: prescriptions,
              medicalInsurances,
              offices,
              permissions: { download, view, to_emit, isSupervisor },
            }}
            action={actions}
            layout={layout}
            setLayout={setLayout}
            sorting={sorting}
            setSorting={setSorting}
            showPack={showPack}
          />
          {patient.id_paciente && (
            <div className="flex mt-5 space-x-5 justify-end">
              <Button
                onClick={handleNewRecipeOnClick}
                className="px-10 h-10 hover:bg-blue-600"
              >
                Nueva receta
              </Button>
            </div>
          )}
        </>
      ) : (
        <Empty
          typeSucces="emptyState"
          dataMocks={handlerEmpty.dataMocks}
          onClick={handlerEmpty.onClick}
        />
      )}
      <Modal
        title="Términos y condiciones para el uso del módulo de recetas"
        isOpenModal={isOpenForm}
        closeModal={closeForm}
        openModal={openForm}
        width="600px"
      >
        <TermsAndConditions onCancel={closeForm} onChange={onChange} />
      </Modal>
      <Modal
        title="👏🏼 ¡Prueba el nuevo módulo de Recetas! 👏🏼🖋️"
        isOpenModal={!onboarding && isOpenOnboarding}
        closeModal={closeOnboarding}
        width="600px"
      >
        <div className="mt-5">
          <Copy>
            ¡Estamos emocionados de presentar nuestro nuevo pack de Recetas!
            <br /> Te ofrecemos{' '}
            <strong className="text-slate-900">
              acceso libre y gratuito
            </strong>{' '}
            hasta <strong className="text-slate-900">el 31 de octubre</strong> .
          </Copy>
          <Copy>
            💊 Realiza recetas farmacológicas con facilidad.
            <br /> 📩 Envía las recetas a tus pacientes.
            <br /> 👌🏼 Configura tus permisos y datos en la sección de
            configuración.
          </Copy>
          <Copy>
            Disfruta ahora de todas las funcionalidades de Recetas sin límites.
          </Copy>
          <div className="flex justify-center">
            <NormalButton
              modifier="transparent"
              size="large"
              onClick={onCloseOnboarding}
            >
              Aceptar
            </NormalButton>
          </div>
        </div>
      </Modal>
    </section>
  );
};
