import React from 'react';
import { ToothFaces } from '../../../../Benefits/BenefitsForm/tooth-faces';
import { ToothSection } from '../../../../Benefits/BenefitsForm/tooth-section';
import { BenefitT } from '#interfaces/general-values';

export const BenefitRow: React.FC<{
  name: string;
  position: number;
  benefits: BenefitT[];
}> = ({ name, position, benefits }) => {
  const benefitTypeFieldName = 'benefit_type';

  return (
    <>
      <ToothSection
        name={`${name}[${position}].tooth_section`}
        benefitTypeFieldName={benefitTypeFieldName}
        benefitTypes={benefits}
      />
      <div>
        <ToothFaces
          name={`${name}[${position}].tooth_faces`}
          benefitTypeFieldName={benefitTypeFieldName}
          benefitTypes={benefits}
        />
      </div>
    </>
  );
};
