import React from 'react';
import { DatePicker, TextFieldForm, CheckboxForm } from '../../../commons';
import { useFieldProps } from '../../../../hooks';

export const ReciteTextfield = () => {
  const reciteDateFieldName = 'recite_date';
  const motiveFieldName = 'motive';
  const reciteieldName = 'recite';
  const { setFieldValue: setReciteDate } = useFieldProps({
    name: reciteDateFieldName,
  });
  const { setFieldValue: setMotive } = useFieldProps({ name: motiveFieldName });
  const {
    field: { value },
  } = useFieldProps({ name: reciteieldName });

  React.useEffect(() => {
    if (!value) {
      setReciteDate(null);
      setMotive(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      <div className="col-span-3 flex items-center col-start-1">
        <CheckboxForm name={reciteieldName} />
        <DatePicker
          label="Fecha de recitación"
          className="ml-4 w-full"
          name={reciteDateFieldName}
          disabled={!value}
        />
      </div>
      <TextFieldForm
        name={motiveFieldName}
        label="Motivo"
        className="col-span-9"
        disabled={!value}
      />
    </>
  );
};
