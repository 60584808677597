import { useDispatch } from 'react-redux';
import { FormikHelpers } from 'formik';
import { tableScheduleMapper } from '../../../../../utils';
import { TurnsT } from '#interfaces/general-values';

export function useSubmit({
  availableAppointments,
  setAppointment,
  openFirstTimeAppointmentForm,
  openCreateAppointmentForm,
  closeForm,
}: {
  availableAppointments: TurnsT[];
  setAppointment: (value: TurnsT) => void;
  openFirstTimeAppointmentForm: () => void;
  openCreateAppointmentForm: () => void;
  closeForm: () => void;
}) {
  const dispatch: any = useDispatch();
  return (spec: any, formikActions: FormikHelpers<any>) => {
    const mapped = tableScheduleMapper(availableAppointments);
    const appointment: any = mapped?.find(
      (a) => a.time === spec.appointment_time,
    );
    if (appointment) {
      dispatch(setAppointment(appointment));
    }
    formikActions.setSubmitting(false);
    formikActions.resetForm();
    closeForm();
    spec.patient_type === 'new'
      ? openFirstTimeAppointmentForm()
      : openCreateAppointmentForm();
  };
}
