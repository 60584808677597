import React from 'react';
import classNames from 'classnames';
import { Icons } from './icons';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../shadcn/dropdown';
import { SelectedBadgeT } from '#interfaces/components';

export const Select: React.FC<SelectedBadgeT> = ({
  text,
  options,
  onSelect,
  className,
}) => {
  const badgeCss = classNames(
    'inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-sm font-bold text-blue-600 ring-1 ring-inset ring-blue-600/10 hover:cursor-pointer',
    className,
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <div className={badgeCss}>
          <p>{text}</p>
          <Icons type="arrow_down" className="ml-2 w-4 h-4" />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {options.map((option: any) => {
          return (
            <DropdownMenuItem
              key={option.id}
              id={option.id}
              className="hover:cursor-pointer"
              onSelect={onSelect}
            >
              {option.text}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
