import React from 'react';
import { updateUsersConfig } from '../../../../../../services';
import {
  SnackbarContext,
  FormContext,
  ModalContext,
} from '../../../../../../context';
import { FreemiumForm } from '../../../../../../components';
import { FormikHelpers } from 'formik';

import { UserT } from '#interfaces/general-values';

export const useSubmit = ({
  user,
  isDemo,
  onChange,
}: {
  user: UserT | null;
  isDemo: boolean;
  onChange: () => void;
}) => {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);
  const { showModal, hideModal } = React.useContext(ModalContext);

  return async (
    _spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      if (isDemo)
        throw new Error(
          'Este es un usuario demo, por favor contactese con Bilog.',
        );
      await axiosPromise(user);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Usuario eliminado correctamente');
      onChange();
    } catch (err: any) {
      err.message.includes('40000')
        ? showModal(
            'Suscribirse a Premium',
            <FreemiumForm onCancel={hideModal} />,
          )
        : showErrorSnackbar(
            `${err.message} Error status code: ${err.statusCode}`,
          );
    } finally {
      formikActions.setSubmitting(false);
    }
  };
};

function axiosPromise(user: UserT | null) {
  const apiSpec = transformSpec(user);

  return updateUsersConfig(apiSpec);
}

function transformSpec(user: UserT | null) {
  let apiSpec = user && {
    id_prof: user.id_prof,
    usuario: user.user,
    nombre: user.name,
    clave: user.password,
    es_odontologo: user.dentist,
    es_odontologo_agenda: user.dentist_schedule,
    es_odontologo_datos: user.dentist_data,
    es_supervisor: user.supervisor,
    habilitado: user.enabled,
    id_sucursal: user.office,
    token: null,
    permisos: user.permissions,
    id_usuario: user.id,
    rowversion: user.rowversion,
    opcion: 'eliminar',
  };

  return apiSpec;
}
