import React from 'react';
import { updateOfficesConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';
import { FormikHelpers } from 'formik';

export function useSubmit(
  office: { [key: string]: any },
  onChange: () => void,
) {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    _spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(office);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Sucursal eliminada correctamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(office: { [key: string]: any }) {
  const apiSpec = transformSpec(office);

  return updateOfficesConfig(apiSpec);
}

function transformSpec(prop: { [key: string]: any }) {
  const { rowversion } = prop;

  let apiSpec = {
    id_sucursal: prop.id,
    sucursal: prop.office,
    descripcion: prop.description,
    habilitada: prop.enabled,
    pventa: prop.bill_point,
    fact_usu: prop.bill_user,
    fact_pas: prop.bill_pass,
    fact_empresa: prop.bill_company,
    saldo_caja: prop.cash_balance,
    fecha_cierre_caja: prop.cash_closure,
    id_sucursal_nueva: prop.id_new_office,
    rowversion,
    opcion: 'eliminar',
  };

  return apiSpec;
}
