import { useContext } from 'react';
import { FormikHelpers } from 'formik';
import { LaboratoryT } from '#interfaces/components/forms';
import { newLab, updateLab } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';

export function useSubmit(onChange: any, current: LaboratoryT | undefined) {
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async (spec: any, formikActions: FormikHelpers<any>) => {
    try {
      await axiosPromise(spec, current);
      await onChange();
      current
        ? showSuccessSnackbar('Cambio guardado correctamente')
        : showSuccessSnackbar('El laboratorio se ha creado con éxito.');
      formikActions.resetForm();
      closeForm();
    } catch (err: any) {
      let message = 'Unknown Error';
      if (err instanceof Error) message = err.message;
      showErrorSnackbar(message);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(spec: any, current: any) {
  const apiSpec = transformSpec(spec, current);

  return !!current?.id_labo ? updateLab(apiSpec) : newLab(apiSpec);
}

function transformSpec(spec: any, current: any) {
  let apiSpec: {
    id_labo: number;
    nombre: string;
    contacto: string;
    direccion: string;
    localidad: string;
    provincia: string;
    telefono: string;
    e_mail: string;
    saldo: number | null;
    saldo_usd: number | null;
    cuit: string;
    observaciones: string;
    habilitado: boolean | null;
    rowVersion: string | null;
  } = {
    id_labo: spec.id_labo,
    nombre: spec.name,
    contacto: spec.contact,
    direccion: spec.address,
    localidad: spec.location,
    provincia: spec.city,
    telefono: spec.phone,
    cuit: spec.cuit,
    e_mail: spec.e_mail,
    saldo: spec.balance,
    saldo_usd: spec.balance_usd,
    observaciones: spec.observations,
    habilitado: spec.enabled,
    rowVersion: current?.rowversion || null,
  };

  return apiSpec;
}
