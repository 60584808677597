import React from 'react';
import { updateBenefitsConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';
import { FormikHelpers } from 'formik';

export function useSubmit({
  benefit,
  selectedMed,
  selectedPlan,
  onChange,
}: {
  benefit: { [key: string]: any };
  selectedMed: { [key: string]: any };
  selectedPlan: number;
  onChange: (value: number) => void;
}) {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    _spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(benefit, selectedMed, selectedPlan);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Prestación eliminada correctamente');
      onChange(selectedPlan);
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(
  benefit: { [key: string]: any },
  selectedMed: { [key: string]: any },
  selectedPlan: number,
) {
  const apiSpec = transformSpec(benefit, selectedMed, selectedPlan);

  return updateBenefitsConfig(apiSpec);
}

function transformSpec(
  benefit: { [key: string]: any },
  selectedMed: { [key: string]: any },
  selectedPlan: number,
) {
  const { rowversion } = benefit;
  const { codigo_plan, nombre } = selectedMed?.planes?.find(
    (p: { [key: string]: any }) => p.id_plan === selectedPlan,
  );

  let apiSpec = {
    codigo_os: selectedMed.codigo_os,
    obra_social: selectedMed.obra_social,
    opcion: 'eliminar',
    planes: [
      {
        codigo_plan,
        nombre,
        prestaciones: [
          {
            id_prestacion: benefit.id_benefit,
            id_plan: benefit.id_plan,
            descripcion: benefit.description,
            importe_os: benefit.billing_medical_insurance,
            importe_pac: benefit.billing_patient,
            dibujo: benefit.draw,
            diente: benefit.tooth,
            pide_cara: benefit.tooth_face,
            interno: benefit.intern,
            inhabilitada: !benefit.enabled,
            unidad: benefit.unit,
            cantidad: benefit.amount,
            codigo_prest_os: benefit.code_medical_insurance,
            codigo_prest_os_liq: benefit.code_medical_insurance_bill,
            es_odontograma: benefit.catastral,
            edad: benefit.age,
            por_paciente: benefit.amount_patient,
            por_pieza_sector: benefit.amount_unit,
            id_grupo: benefit.id_group,
            id_prestacion_a_realizar: benefit.id_benefit_to_do,
            id_especialidad: benefit.id_specialty,
            rowversion,
          },
        ],
      },
    ],
  };

  return apiSpec;
}
