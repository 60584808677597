import { Carousel } from './normal-carousel';
import { useFieldProps } from '../../hooks';
import { WhatsappCarouselT } from '#interfaces/components';

export const WhatsappCarousel = ({
  name,
  data,
  component: Component,
}: WhatsappCarouselT) => {
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name });

  return Carousel({
    Component,
    data,
    properties: {
      currentValue: value,
      onClick: setFieldValue,
    },
  });
};
