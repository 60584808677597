import React from 'react';
import classNames from 'classnames';
import { Checkbox, Modal, TextField, Icons, Copy } from '#components/index';
import { Card, CardDescription, Button } from '#components/shadcn';
import {
  checkFileType,
  getSourceFromFileType,
  checkUrl,
  formateDateToString,
} from '../../../../../utils';
import { ArchivesT } from '#interfaces/components';

const Archive: React.FC<ArchivesT> = ({
  archive,
  index,
  onChange,
  onEdit,
  onDelete,
}) => {
  const fileType = checkFileType(archive.ruta);
  const imgSource = getSourceFromFileType(fileType);
  const [checked, setChecked] = React.useState<boolean>(false);
  const [editEnabled, setEditEnabled] = React.useState<boolean>(false);
  const [isOpenArchiveModal, setIsOpenArchiveModal] =
    React.useState<boolean>(false);
  const [isOpenModalDelete, setIsOpenModalDelete] =
    React.useState<boolean>(false);
  const [description, setDescription] = React.useState(
    archive.descripcion || '',
  );

  const openArchiveModal = () => setIsOpenArchiveModal(true);
  const closeArchiveModal = () => {
    setEditEnabled(false);
    setIsOpenArchiveModal(false);
  };

  const openModalDelete = () => setIsOpenModalDelete(true);
  const closeModalDelete = () => setIsOpenModalDelete(false);

  const onChangeHandler = () => {
    setChecked(!checked);
    onChange(archive);
  };

  const onEditHandler = () => {
    const newArchive = { ...archive, descripcion: description };
    onEdit(newArchive);
    closeArchiveModal();
  };

  const onDeleteHandler = () => {
    onDelete([archive]);
    closeModalDelete();
    closeArchiveModal();
  };

  const icon: { [index: string]: any } = {
    img: { type: 'image', style: 'text-purple-500' },
    pdf: { type: 'file', style: 'text-green-500' },
    mp4: { type: 'video', style: 'text-amber-500' },
    txt: { type: 'file_unknown', style: 'text-slate-500' },
  };

  return (
    <>
      <Card
        className={classNames(
          'hover:cursor-pointer hover:bg-slate-50',
          checked ? 'border-blue-500' : 'border-slate-200',
        )}
        key={index}
      >
        <div
          className="p-4 flex justify-between items-center"
          onClick={(e: any) => {
            if (e.target.type !== 'checkbox') return openArchiveModal();
          }}
        >
          <div className="flex gap-x-4 items-center">
            <Icons
              className={classNames(
                'w-10 h-10',
                icon[imgSource?.toLocaleLowerCase() || 'txt'].style,
              )}
              type={icon[imgSource?.toLocaleLowerCase() || 'txt'].type}
            />
            <div>
              <div className="flex gap-x-2 items-center">
                <h4 className="text-lg font-bold whitespace-nowrap">
                  {description}
                </h4>
                <CardDescription>
                  {archive.size ? `(${archive.size} MB)` : '(>1 MB)'}
                </CardDescription>
              </div>
              <CardDescription>
                {formateDateToString(archive.fecha)}
              </CardDescription>
            </div>
          </div>
          <Checkbox
            checked={checked}
            onChange={onChangeHandler}
            className="align-self-end"
          />
        </div>
      </Card>
      <Modal
        title="Detalles del archivo"
        isOpenModal={isOpenArchiveModal}
        closeModal={closeArchiveModal}
        openModal={openArchiveModal}
      >
        <div className="mt-10">
          {checkUrl(archive.ruta) ? (
            imgSource === 'IMG' ? (
              <img
                src={archive.large}
                alt={description}
                className="mt-5 max-h-[500px] w-full object-contain"
              />
            ) : (
              <iframe
                id="fileFrame"
                title={description}
                width="800"
                height="590"
                src={archive.ruta}
              />
            )
          ) : (
            <div className="flex-col px-20">
              <Icons
                type="file_broken"
                className="w-8 h-8 mx-auto mb-2 text-blue-600"
              />
              <p className="text-center text-slate-600">
                Este archivo no se puede previsualizar en este dipositivo
              </p>
            </div>
          )}
        </div>
        <div className="grid grid-cols-12 gap-x-4 mt-10">
          <div className="col-span-6">
            <TextField
              value={description}
              disabled={!editEnabled}
              onChange={setDescription}
              className="h-10 w-full"
            />
          </div>
          {editEnabled ? (
            <Button className="px-4 py-2 col-span-3" onClick={onEditHandler}>
              Guardar
            </Button>
          ) : (
            <Button
              className="px-4 py-2 col-span-3"
              onClick={() => setEditEnabled(!editEnabled)}
            >
              Editar
            </Button>
          )}
          <Button
            className="px-4 py-2 col-span-3 bg-red-500"
            onClick={openModalDelete}
          >
            Eliminar
          </Button>
        </div>
      </Modal>
      <Modal
        title="Eliminar archivo/s"
        isOpenModal={isOpenModalDelete}
        closeModal={closeModalDelete}
        openModal={openModalDelete}
      >
        <Copy className="mb-0">¿Desea eliminar el archivo seleccionado?</Copy>
        <Copy className="mb-6 text-red-500 font-bold">
          Esta acción es irreversible y los archivos se perderán.
        </Copy>
        <div className="flex gap-x-4">
          <Button
            className="px-4 py-2 border border-blue-500 !text-blue-500 bg-transparent"
            onClick={closeModalDelete}
          >
            Cancelar
          </Button>
          <Button
            className="px-4 py-2 border border-red-500 bg-red-500"
            onClick={onDeleteHandler}
          >
            Eliminar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Archive;
