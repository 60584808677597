import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getNewPatients,
  getPatientAttended,
  getPatientScheduled,
  getPaymentsEntered,
  getBenefitAmounts,
  getBudgets,
  getBenefits,
  getFinances,
} from '../../services';
import { PaymentsT } from '#interfaces/components/forms';
import { BenefitT, PatientT } from '#interfaces/general-values';

const initialState: { [key: string]: any } = {
  newPatients: [],
  patientAttended: [],
  patientScheduled: [],
  paymentsEntered: [],
  benefitAmounts: [],
  budgets: [],
  benefits: [],
  finances: [],
  currentSearch: {
    financesCurrent: null,
    controlPanelCurrent: null,
    benefitscurrent: null,
    budgetsCurrent: null,
  },
};

export const getFullStatistics = createAsyncThunk(
  'home/getFullStatistics',
  async (props: any) => {
    try {
      const {
        data: { statistics: newPatientsStats },
      } = await getNewPatients(props);
      const {
        data: { statistics: attendedPatientsStats },
      } = await getPatientAttended(props);
      const {
        data: { statistics: scheduledPatientsStats },
      } = await getPatientScheduled(props);
      const {
        data: { statistics: paymentsStats },
      } = await getPaymentsEntered(props);
      const {
        data: { statistics: benefitsStats },
      } = await getBenefitAmounts(props);

      return {
        newPatientsStats,
        attendedPatientsStats,
        scheduledPatientsStats,
        paymentsStats,
        benefitsStats,
      };
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const listNewPatients = createAsyncThunk(
  'statistics/listNewPatients',
  async (props: PatientT) => {
    try {
      const { data } = await getNewPatients(props);

      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const listPatientAttended = createAsyncThunk(
  'statistics/listPatientAttended',
  async (props: PatientT) => {
    try {
      const { data } = await getPatientAttended(props);

      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const listPatientScheduled = createAsyncThunk(
  'statistics/listPatientSheduled',
  async (props: PatientT) => {
    try {
      const { data } = await getPatientScheduled(props);

      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const listPaymentsEntered = createAsyncThunk(
  'statistics/listPaymentsEntered',
  async (props: PaymentsT) => {
    try {
      const { data } = await getPaymentsEntered(props);

      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const listBenefitAmount = createAsyncThunk(
  'statistics/listBenefitAmount',
  async (props: BenefitT) => {
    try {
      const { data } = await getBenefitAmounts(props);

      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);
export const listBudgets = createAsyncThunk(
  'statistics/listBudgets',
  async (props: any) => {
    try {
      const { data } = await getBudgets(props);

      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);
export const listBenefits = createAsyncThunk(
  'statistics/listBenefits',
  async (props: any) => {
    try {
      const { data } = await getBenefits(props);
      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);
export const listFinances = createAsyncThunk(
  'statistics/listFinances',
  async (props: any) => {
    try {
      const { data } = await getFinances(props);
      return data;
    } catch (err: any) {
      throw new Error(err);
    }
  },
);
export const statisticsSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setCurrentStatistics: (state, action) => {
      state.currentSearch = { ...state.currentSearch, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listNewPatients.fulfilled, (state, action) => {
      const { statistics } = action.payload;

      state.newPatients = statistics;
    });
    builder.addCase(listPatientAttended.fulfilled, (state, action) => {
      const { statistics } = action.payload;

      state.patientAttended = statistics;
    });
    builder.addCase(listPatientScheduled.fulfilled, (state, action) => {
      const { statistics } = action.payload;

      state.patientScheduled = statistics;
    });
    builder.addCase(listPaymentsEntered.fulfilled, (state, action) => {
      const { statistics } = action.payload;

      state.paymentsEntered = statistics;
    });
    builder.addCase(listBenefitAmount.fulfilled, (state, action) => {
      const { statistics } = action.payload;

      state.benefitAmounts = statistics;
    });
    builder.addCase(getFullStatistics.fulfilled, (state, action) => {
      const {
        newPatientsStats,
        attendedPatientsStats,
        scheduledPatientsStats,
        paymentsStats,
        benefitsStats,
      } = action.payload;

      state.newPatients = newPatientsStats;
      state.patientAttended = attendedPatientsStats;
      state.patientScheduled = scheduledPatientsStats;
      state.paymentsEntered = paymentsStats;
      state.benefitAmounts = benefitsStats;

      if (paymentsStats.length === 0) {
        state.paymentsEntered = [{ ...benefitsStats[0], quantity: 0 }];
      }
      if (benefitsStats.length === 0) {
        state.benefitsStats = [{ ...paymentsStats[0], quantity: 0 }];
      }
    });
    builder.addCase(listBudgets.fulfilled, (state, action) => {
      const { budgetsStatistics } = action.payload;

      state.budgets = budgetsStatistics;
    });
    builder.addCase(listBenefits.fulfilled, (state, action) => {
      const { statistics } = action.payload;
      state.benefits = statistics;
    });
    builder.addCase(listFinances.fulfilled, (state, action) => {
      const { statisticsResponse } = action.payload;

      state.finances = statisticsResponse;
    });
    builder.addCase(listFinances.rejected, (_state, action) => {
      throw new Error(action.error.message);
    });
  },
});
export const { setCurrentStatistics } = statisticsSlice.actions;

export default statisticsSlice.reducer;
