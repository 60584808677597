import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from '#components/index';
import { CardTitle, CardDescription } from '#components/shadcn';
import { SideScrollingCardsT } from '#interfaces/components';
import { BudgetServices } from '#components/Tables';
import { ServicePerformed } from './services-performed';
import { SnackbarContext } from '../../../../../context';
import { useFieldProps, useModal } from '../../../../../hooks';
import { insertBenefit } from '../../../../../redux/slices';

export const SideScrollingCards: React.FC<SideScrollingCardsT> = ({
  hiddenView,
  benefit,
  viewLoadBenefit: { setHiddenLoadBenefit },
  setTabIndex,
  budget,
}) => {
  const dispatch = useDispatch();
  const { isOpenModal, showModal, hideModal } = useModal();
  const { showErrorSnackbar } = React.useContext(SnackbarContext);
  const {
    field: { value: benefitsList },
    setFieldValue: setListBenefitValue,
  } = useFieldProps({ name: 'benefits_list' });
  const { setFieldValue: setBenefitValue } = useFieldProps({
    name: 'benefit_from_list',
  });

  const handleClickEdit = (service: any) => {
    setHiddenLoadBenefit(true);
    // TODO: Revisar esto, se settea en el estado y
    // en Formik, deberia de settearse en un solo lado
    setBenefitValue(service);
    dispatch(insertBenefit(service));
    setTabIndex(1);
  };
  const handleClickDelete = (service: any) => {
    const benefitsFiltered = benefitsList?.filter(
      (b: { [key: string]: number | string }) =>
        b.id_budget_benefit !== service.id_budget_benefit ||
        b.id_benefit !== service.id_benefit,
    );
    setListBenefitValue(benefitsFiltered);
  };
  const handleMarkAsCompleted = (service: any) => {
    const benefitSelect: any = budget?.prest_presup.find(
      (b: any) => b.id_prest_presu === service.id_budget_benefit,
    );
    if (benefitSelect?.cant !== service.quantity) {
      return showErrorSnackbar(
        'La prestacion ha sido modificada, guarde el presupuesto para continuar.',
      );
    }
    dispatch(insertBenefit(service));
    return showModal();
  };

  const actions = (service: number, action: string) => {
    if (action === 'edit') {
      return handleClickEdit(service);
    } else if (action === 'delete') {
      return handleClickDelete(service);
    } else if (action === 'mark_done') {
      return handleMarkAsCompleted(service);
    }
  };

  return (
    <>
      <div className="mt-10 mb-4">
        <CardTitle>Prestaciones del presupuesto</CardTitle>
        <CardDescription className="xl:max-w-2xl mt-2">
          Abajo se encuentran la lista de prestaciones incluidas en tu
          presupuesto, puedes editarlas o marcar como realizada las prestaciones
          no manuales, y se incluirán en el odontograma de tu paciente.
        </CardDescription>
      </div>
      <BudgetServices data={benefitsList} action={actions} />
      <Modal
        title="¿Cuantas prestaciones realizaste?"
        isOpenModal={isOpenModal}
        closeModal={hideModal}
        openModal={showModal}
      >
        <ServicePerformed hiddenView={hiddenView} benefit={benefit} />
      </Modal>
    </>
  );
};
