import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Patient from '../../domains/Patient';
import { parseOdontogramType } from '../../utils';
import {
  searchPatientsList,
  searchPatient,
  getOSPre,
  deleteHc,
  getPatientHc,
  getOdontogram,
  savePatient,
  getCardsAndBanks,
} from '../../services';
import { SelectedHisCliT } from '#interfaces/general-values';

const initialState = {
  patient: { ...Patient.data },
  patientBenefits: {},
  countryCode: [],
  MedInsPlans: [],
  patientTurns: [],
  patientsList: [],
  listOfCards: [],
  listOfBanks: [],
  selectedInput: null,
  selectedPlan: null,
  selectedMedIns: null,
  selectedProfessional: null,
  showPatientAlert: true,
  budget: null,
};

export const fetchPatientsList = createAsyncThunk(
  'patients/fetchPatientsList',

  async (params: {
    input: string;
    isContained?: boolean;
    errSnk: (errorMessage: string) => void;
  }) => {
    const { input, isContained = false, errSnk } = params;
    try {
      const {
        data: { listaPacientes },
      } = await searchPatientsList(input, isContained);
      if (listaPacientes.length === 0) {
        errSnk(
          'No se encontraron pacientes con ese nombre, por favor intentá de nuevo',
        );
      }
      return { patientsList: listaPacientes };
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const fetchPatient = createAsyncThunk(
  'patients/fetchPatient',
  async (params: { patientId: number }) => {
    const { patientId } = params;
    try {
      const {
        data: { paciente, listaAgenda },
      } = await searchPatient(patientId);
      const {
        id_planNavigation: { id_os, id_plan },
        presup,
      } = paciente;
      const MedInsBenefits = await getOSPre('PROFE, OSPRE', id_plan);

      return {
        patient: paciente,
        patientTurns: listaAgenda,
        patientBenefits: MedInsBenefits,
        selectedMedIns: id_os,
        selectedPlan: id_plan,
        budget: presup,
      };
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const deleteClinicHistory = createAsyncThunk(
  'patients/deleteClinicHistory',
  async (params: {
    patientId: number;
    selectedHc: SelectedHisCliT | undefined;
  }) => {
    const { patientId, selectedHc } = params;
    try {
      await deleteHc(selectedHc);

      const {
        data: {
          paciente: { his_cli },
        },
      } = await getPatientHc(patientId);

      return {
        his_cli,
      };
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const fetchOdontogram = createAsyncThunk(
  'patients/fetchOdontogram',
  async (params: { type: string; patientId: number }) => {
    const { type, patientId } = params;
    const parsedType = parseOdontogramType(type);

    try {
      const {
        data: {
          paciente: { odontograma },
        },
      } = await getOdontogram(parsedType, patientId);

      return { odontograma };
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const fetchCardsAndBanks = createAsyncThunk(
  'patients/fetchCardsAndBanks',
  async () => {
    try {
      const {
        data: { listaBancos, listaTarjetas },
      } = await getCardsAndBanks();

      return { listaBancos, listaTarjetas };
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const setSavePatient = createAsyncThunk(
  'patients/setSavePatient',
  async (patient: {
    id_paciente: number;
    ape_nom: string;
    f_nacimiento: string;
    domicilio: string;
    localidad: string;
    provincia: string;
    tel_part: string;
    tel_off: string;
    tel_celu: string;
    email: string;
    documento: string;
    sexo: string;
    id_plan: string;
    nro_afil: string;
    hepatitis: boolean;
    embarazo: boolean;
    diabetes: boolean;
    alergia: boolean;
    cardiaco: boolean;
    renal: boolean;
    alfa: boolean;
    f_alta: string;
    f_ultima_at: string;
    es_titular: boolean;
    hc_nro: number;
    condicion: any;
    titular_nom: string;
    titular_emp: string;
    obs_varias: string;
    foto: string;
    id_socio_tit: number;
    alerta: string;
    id_usuario: number;
    face: string;
    twitter: string;
    f_recitar: string | null;
    est_civil: string;
    ocupacion: string;
    cuit: string;
    cond_fiscal: any;
    tipo_doc: string;
    opcion: string;
    instagram: string;
    quedatobuscar: string;
    edad: string;
    saldo: number;
    cta_cte: any[];
    rowversion?: string;
  }) => {
    Patient.sanitizeData(patient);

    try {
      const {
        data: { paciente },
      } = await savePatient(patient);
      const {
        id_planNavigation: { id_os, id_plan },
        presup,
      } = paciente;
      const MedInsBenefits = await getOSPre('PROFE, OSPRE', id_plan);

      return {
        patient: paciente,
        benefits: MedInsBenefits,
        selectedMedIns: id_os,
        selectedPlan: id_plan,
        budget: presup,
      };
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    resetPatientList: (state) => {
      state.patientsList = [];
    },
    setMedInsPlans: (state, action) => {
      const MedInsPlans = action.payload;
      state.MedInsPlans = MedInsPlans;
    },
    setSelectedMedIns: (state, action) => {
      const { selectedMedIns } = action.payload;
      state.selectedMedIns = selectedMedIns;
    },
    setSelectedPlan: (state, action) => {
      const { selectedPlan } = action.payload;
      state.selectedPlan = selectedPlan;
    },
    setPatientAlert: (state, action) => {
      state.showPatientAlert = action.payload;
    },
    resetSelectedMedIns: (state) => {
      state.selectedMedIns = null;
    },
    resetSelectedPlan: (state) => {
      state.selectedPlan = null;
    },
    resetPatientsForm: (state) => {
      state.patient = { ...Patient.data };
      state.patientBenefits = {};
      state.MedInsPlans = [];
      state.patientTurns = [];
      state.selectedMedIns = null;
      state.selectedPlan = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPatientsList.fulfilled, (state, action) => {
      const { patientsList } = action.payload;
      state.patientsList = patientsList;
    });
    builder.addCase(fetchPatient.fulfilled, (state, action) => {
      const {
        patient,
        patientTurns,
        patientBenefits,
        selectedMedIns,
        selectedPlan,
        budget,
      } = action.payload;
      state.patient = patient;
      state.patientTurns = patientTurns;
      state.patientBenefits = patientBenefits;
      state.selectedMedIns = selectedMedIns;
      state.selectedPlan = selectedPlan;
      state.budget = budget;
    });
    builder.addCase(deleteClinicHistory.fulfilled, (state, action) => {
      const { his_cli } = action.payload;

      state.patient = { ...state.patient, his_cli };
    });
    builder.addCase(fetchOdontogram.fulfilled, (state, action) => {
      const { odontograma } = action.payload;

      state.patient = { ...state.patient, odontograma };
    });
    builder.addCase(fetchCardsAndBanks.fulfilled, (state, action) => {
      const { listaBancos, listaTarjetas } = action.payload;

      state.listOfCards = listaTarjetas;
      state.listOfBanks = listaBancos;
    });
    builder.addCase(setSavePatient.fulfilled, (state, action) => {
      const { patient, benefits, selectedMedIns, selectedPlan, budget } =
        action.payload;
      const { todos, his_cli, presup, odontograma, ...rest } = patient;
      state.patient = { ...state.patient, ...rest };
      state.patientBenefits = benefits;
      state.selectedMedIns = selectedMedIns;
      state.selectedPlan = selectedPlan;
      state.budget = budget;
    });
  },
});

export const {
  setMedInsPlans,
  setSelectedMedIns,
  setSelectedPlan,
  resetSelectedMedIns,
  resetSelectedPlan,
  resetPatientsForm,
  setPatientAlert,
  resetPatientList,
} = patientsSlice.actions;

export default patientsSlice.reducer;
