import { useCallback, useEffect, useState } from 'react';
import { ParamsT, RegionsT } from '#interfaces/general-values';

export function useForm({
  params,
  regions,
}: {
  params: ParamsT;
  regions: RegionsT[];
}) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues({ params, regions }),
  );

  useEffect(() => {
    setInitialValues(getInitialValues({ params, regions }));
  }, [params, regions]);

  const validate = useCallback((values: { [key: string]: any }) => {
    const errors: { [key: string]: any } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['name', 'what_region'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues({
  params,
  regions,
}: {
  params: ParamsT;
  regions: RegionsT[];
}) {
  const {
    nombre,
    direccion,
    cuit,
    email,
    telefono,
    cond_fiscal,
    what_region,
    doble_moneda,
  } = params;

  const [region] =
    regions.filter((r: { [key: string]: any }) => {
      if (what_region) return r.iso3 === what_region.toUpperCase();
      return r.iso3 === 'ARG';
    }) || regions;

  return {
    name: nombre,
    address: direccion,
    phone: telefono,
    email,
    cuit,
    tax_category: cond_fiscal ?? 'RI',
    what_region: region?.idCountry || 1,
    use_usd: doble_moneda || false,
  };
}
