import React from 'react';
import { FormikHelpers } from 'formik';
import { updateOfficesConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';
import { dateToISO } from '../../../../../../utils';

export function useSubmit(onChange: () => void) {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(spec);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Sucursal guardada correctamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(spec: { [key: string]: any }) {
  const apiSpec = transformSpec(spec);

  return updateOfficesConfig(apiSpec);
}

function transformSpec(spec: { [key: string]: any }) {
  const opcion = spec.rowversion ? 'modificar' : 'nuevo';

  let apiSpec: { [key: string]: any } = {
    id_sucursal: spec.id,
    sucursal: spec.office.toUpperCase(),
    descripcion: spec.description,
    habilitada: spec.enabled,
    pventa: spec.bill_point,
    fact_usu: spec.bill_user,
    fact_pas: spec.bill_pass,
    fact_empresa: spec.bill_company,
    saldo_caja: spec.cash_balance,
    saldo_caja_usd: spec.cash_balance_usd,
    fecha_cierre_caja: spec.bill_enabled ? dateToISO(spec.cash_closure) : null,
    id_sucursal_nueva: spec.id_new_office,
    opcion,
  };

  if (spec.rowversion) {
    apiSpec = {
      ...apiSpec,
      id_sucursal: spec.id,
      rowversion: spec.rowversion,
    };
  }

  return apiSpec;
}
