import React from 'react';
import { MedicalInsurancesT, PatientT } from '#interfaces/general-values';

type useFormT = {
  patient: PatientT;
  medicalIsurance: MedicalInsurancesT | undefined;
  lenderCode: string;
};
export function useForm({ patient, medicalIsurance, lenderCode }: useFormT) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues({ patient, medicalIsurance, lenderCode }),
  );

  React.useEffect(() => {
    setInitialValues(
      getInitialValues({ patient, medicalIsurance, lenderCode }),
    );
  }, [patient, medicalIsurance, lenderCode]);

  return { initialValues };
}

function getInitialValues({ patient, medicalIsurance, lenderCode }: useFormT) {
  const specs = {
    customer_code: medicalIsurance?.cliente_ftp,
    lender_code: lenderCode,
    medical_insutance_code: medicalIsurance?.codigo_os,
    document_type: patient.tipo_doc,
    document_number: patient.documento,
    member_number: patient.nro_afil,
    id_medical_isurance: medicalIsurance?.id_os,
    id_patient: patient.id_paciente,
  };

  return specs;
}
