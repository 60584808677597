import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import { Icons } from './icons';
import { useKeypress } from '../../hooks';
import { FormContext } from '../../context/form';
import { ModalT } from '#interfaces/components';

export const Modal: React.FC<ModalT> = ({
  isOpenModal,
  closeModal,
  openModal = () => {},
  title,
  children,
  width = '100%',
}) => {
  const modalStyle = classNames(
    `z-40 opacity-1 p-10 bg-white shadow-xl rounded-lg items-center relative mx-auto`,
    {
      'transform translate-y-1': isOpenModal,
      'transform translate-y-full': !isOpenModal,
    },
  );

  useKeypress({ key: 'Escape', action: closeModal });

  return (
    <Transition.Root show={isOpenModal} as={Fragment} appear>
      <div className="fixed inset-0 z-40">
        <div
          className={classNames(
            isOpenModal
              ? 'fixed inset-0 bg-white/5 backdrop-blur-sm fade-in-0 animate-in'
              : 'animate-out fade-out-0',
          )}
        />
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            enter="transition-transform duration-300 ease-out"
            enterFrom="translate-y-full"
            enterTo={classNames('translate-y-0', `!w-[${width}]`)}
            leave={classNames(
              'transition-transform duration-300 ease-in',
              `!w-[${width}]`,
            )}
            leaveFrom="translate-y-0"
            leaveTo="translate-y-full"
          >
            <div className={modalStyle} style={{ width: width }}>
              <span onClick={closeModal}>
                <Icons
                  className="w-6 h-6 absolute right-6 hover:cursor-pointer top-6 text-neutral-600"
                  type="close"
                />
              </span>
              <h3 className="text-lg font-semibold">{title}</h3>
              <FormContext.Provider
                value={{
                  isOpenForm: isOpenModal,
                  openForm: openModal,
                  closeForm: closeModal,
                }}
              >
                {children}
              </FormContext.Provider>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  );
};
