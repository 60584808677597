import React from 'react';
import { Formik } from 'formik';
import { Copy, Form } from '../../../../../../components';
import { useSubmit } from './use-submit';
import { DeleteGroupFormT } from '#interfaces/components';

export const DeleteGroupForm: React.FC<DeleteGroupFormT> = ({
  group,
  selectedMed,
  onChange,
}) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={useSubmit(group, selectedMed, onChange)}
    >
      <Form submitTitle="Eliminar" ignoreDirty>
        <Copy>
          Estás por eliminar este grupo y esta acción es irreversible, el grupo
          se eliminará definitivamente.
        </Copy>
      </Form>
    </Formik>
  );
};
