import React from 'react';
import { FormikHelpers } from 'formik';
import { updateSpecialtiesConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';

export function useSubmit(
  specialty: { [key: string]: any },
  onChange: () => void,
) {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    _spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(specialty);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Ausencia eliminada correctamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(specialty: { [key: string]: any }) {
  const apiSpec = transformSpec(specialty);

  return updateSpecialtiesConfig(apiSpec);
}

function transformSpec(specialty: { [key: string]: any }) {
  const { id_especialidad, cod_especialidad, descripcion, rowversion } =
    specialty;
  let apiSpec = {
    id_especialidad,
    cod_especialidad,
    descripcion,
    rowversion,
    opcion: 'eliminar',
  };

  return apiSpec;
}
