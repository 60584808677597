import React from 'react';
import { useFieldProps } from '../hooks';
import { useBenefitTypeT } from '#interfaces/hooks';

export function useBenefitType({
  benefitTypeFieldName,
  benefitTypes,
}: useBenefitTypeT) {
  const { field } = useFieldProps({ name: benefitTypeFieldName });

  const benefitType = React.useMemo(() => {
    if (!field.value) {
      return null;
    }

    return benefitTypes.find(
      (element) => element.id_prestacion === field.value,
    );
  }, [field.value, benefitTypes]);

  return { benefitType };
}
