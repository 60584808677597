import React from 'react';
import { Button } from '#components/index';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from '#components/shadcn';
import { MedicinesPatient } from '#components/Tables';
import { LoadMedicine } from '../../LoadMedicine';
import { useFieldProps } from '../../../../../hooks';
import { tableMedicinesMapper } from '../../../../../utils';

export const SecondStep: React.FC<any> = ({
  onClick,
  resetProgressBar,
  viewLoadMedicine: { hiddenLoadMedicine, setHiddenLoadMedicine },
}: {
  onClick: () => void;
  resetProgressBar: () => void;
  viewLoadMedicine: {
    hiddenLoadMedicine: boolean;
    setHiddenLoadMedicine: React.Dispatch<React.SetStateAction<boolean>>;
  };
}) => {
  const {
    field: { value: medicineList },
    setFieldValue: setListMedicineValue,
  } = useFieldProps({ name: 'medicines_list' });
  const { setFieldValue: setMedicine } = useFieldProps({
    name: 'medicine_from_list',
  });
  const validationTable = medicineList.length > 0;
  const actions: any = {
    edit: (selectedRow: { [key: string]: any }) => {
      const medicineSelected = medicineList?.find(
        (medicine: any) =>
          medicine.id_medicine_recipe === selectedRow.id_medicine_recipe,
      );

      setMedicine(medicineSelected);
      setHiddenLoadMedicine(!hiddenLoadMedicine);
    },
    delete: (selectedRow: { [key: string]: any }) => {
      const filterMedicines = medicineList?.filter(
        (medicine: any) =>
          medicine.id_medicine_recipe !== selectedRow.id_medicine_recipe,
      );

      setListMedicineValue(filterMedicines);
    },
  };
  const validationChargeMedicine = medicineList.length >= 3;
  const action = (medicine: { [key: string]: string }, action: string) => {
    actions[action](medicine);
  };
  const onLoadMedicine = () => {
    setHiddenLoadMedicine(!hiddenLoadMedicine);
    setMedicine({
      code: null,
      productName: '',
      presentation: '',
      id_medicine_recipe: Math.random(),
      quantity: 1,
      only_generic: false,
      not_replace: false,
      diagnosis: { description: '', code: '' },
      observations: '',
      prolonged_treatment: false,
      dosage: '',
    });
  };

  React.useMemo(() => {
    if (!medicineList.length) {
      resetProgressBar();
      return onClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="mt-5">
      <CardHeader>
        <CardTitle>Lista de medicamentos</CardTitle>
        <CardDescription>
          Carga los medicamentos relacionados a esta receta. Al guardar, podrás
          visualizar la lista completa de medicamentos.
        </CardDescription>
      </CardHeader>
      {!hiddenLoadMedicine ? (
        <>
          <CardContent>
            {validationTable && (
              <MedicinesPatient
                data={tableMedicinesMapper(medicineList).reverse()}
                action={action}
              />
            )}
          </CardContent>
          <CardDescription className="flex justify-center">
            {!validationTable && 'Todavía no posee ningún medicamento cargado'}
          </CardDescription>
          <CardContent>
            <div className="flex gap-5 mt-5">
              <Button
                modifier="transparent"
                onClick={onLoadMedicine}
                disabled={validationChargeMedicine}
              >
                Cargar medicamentos
              </Button>
              <Button
                disabled={!validationTable}
                onClick={onClick}
                size="large"
              >
                Siguiente
              </Button>
            </div>
          </CardContent>
        </>
      ) : (
        <LoadMedicine setHiddenLoadMedicine={setHiddenLoadMedicine} />
      )}
    </Card>
  );
};
