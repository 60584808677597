import * as React from 'react';
import { ColumnsT } from '#interfaces/tables/benefit';
import { Checkbox } from '#components/shadcn';
import { useFieldProps } from '../../../../hooks';

export const Columns = ({ config: { translations, canSelect } }: ColumnsT) => {
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name: 'selected_benefits' });

  const handleOnChange = (selected: number) => {
    const arr = value;
    if (!arr.includes(selected)) {
      arr.push(selected);
      setFieldValue(arr);
    } else if (arr.includes(selected)) {
      const index = arr.indexOf(selected);
      arr.splice(index, 1);
      setFieldValue(arr);
    }
  };

  let columns = [
    {
      id: 'select',
      header: ({ table }: any) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && 'indeterminate')
          }
          onCheckedChange={(value) => {
            const allIds = table
              .getRowModel()
              .rows.map((row: any) => row.original.id_benefit);

            setFieldValue(value ? allIds : []);

            table.toggleAllPageRowsSelected(!!value);
          }}
          aria-label="Seleccionar todo"
          className="translate-y-[2px]"
        />
      ),
      cell: ({ row }: any) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            handleOnChange(row.original.id_benefit);
            row.toggleSelected(!!value);
          }}
          aria-label="Seleccionar fila"
          className="translate-y-[2px]"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'code_medical_insurance',
      header: `Código prestación ${translations.medical_insurance_abbreviation}`,
      cell: ({ row }: { row: any }) => {
        const { code_medical_insurance } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {code_medical_insurance}
          </p>
        );
      },
    },
    {
      accessorKey: 'code_medical_insurance_bill',
      header: `Código prestación ${translations.medical_insurance_abbreviation} Liq.`,
      cell: ({ row }: { row: any }) => {
        const { code_medical_insurance_bill } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {code_medical_insurance_bill}
          </p>
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Descripción',
      cell: ({ row }: { row: any }) => {
        const { description } = row.original;

        return (
          <p className="text-sm font-semibold leading-6 text-slate-800 my-1">
            {description}
          </p>
        );
      },
    },
    {
      accessorKey: 'billing_medical_insurance',
      header: `Importe ${translations.medical_insurance_abbreviation}`,
      cell: ({ row }: { row: any }) => {
        const { billing_medical_insurance } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            $ {billing_medical_insurance}
          </p>
        );
      },
    },
    {
      accessorKey: 'billing_patient',
      header: 'Importe paciente',
      cell: ({ row }: { row: any }) => {
        const { billing_patient } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            $ {billing_patient}
          </p>
        );
      },
    },
  ];

  !canSelect && columns.shift();

  return columns;
};
