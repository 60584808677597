import * as React from 'react';

export const columns = [
  {
    accessorKey: 'fecha',
    header: 'Fecha del pedido',
    cell: ({ row }: { row: any }) => {
      const { fecha } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500 my-1">
          {fecha}
        </p>
      );
    },
  },
  {
    accessorKey: 'laboratorio',
    header: 'Laboratorio',
    cell: ({ row }: { row: any }) => {
      const { laboratorio } = row.original;

      return (
        <p className="text-base min-w-[200px] font-semibold leading-6 text-slate-800 my-1">
          {laboratorio}
        </p>
      );
    },
  },
  {
    accessorKey: 'telefono_laboratorio',
    header: 'Tel. Lab.',
    cell: ({ row }: { row: any }) => {
      const { telefono_laboratorio } = row.original;

      return (
        <p className="font-bold min-w-[150px] text-sm leading-6 text-gray-500 my-1">
          {telefono_laboratorio}
        </p>
      );
    },
  },
  {
    accessorKey: 'email_laboratorio',
    header: 'Email Lab.',
    cell: ({ row }: { row: any }) => {
      const { email_laboratorio } = row.original;

      return (
        <p className="font-bold text-sm leading-6 text-gray-500 my-1">
          {email_laboratorio}
        </p>
      );
    },
  },
  {
    accessorKey: 'profesional',
    header: 'Profesional',
    cell: ({ row }: { row: any }) => {
      const { profesional } = row.original;

      return (
        <p className="font-bold text-sm min-w-[200px] leading-6 text-gray-500 my-1">
          {profesional}
        </p>
      );
    },
  },
  {
    accessorKey: 'paciente',
    header: 'Paciente',
    cell: ({ row }: { row: any }) => {
      const { paciente } = row.original;

      return (
        <p className="font-bold text-sm min-w-[200px] leading-6 text-gray-500 my-1">
          {paciente}
        </p>
      );
    },
  },
  {
    accessorKey: 'telefono',
    header: 'Teléfono',
    cell: ({ row }: { row: any }) => {
      const { telefono } = row.original;

      return (
        <p className="font-bold text-sm min-w-[150px] leading-6 text-gray-500 my-1">
          {telefono}
        </p>
      );
    },
  },
];
