import React from 'react';
import { DropdownForm } from '../../../commons';
import { useFieldProps } from '../../../../hooks';
import { PatientMedicalInsuranceT } from '#interfaces/components';

export const PatientMedicalInsurance: React.FC<PatientMedicalInsuranceT> = ({
  name,
  translations,
  medicalInsurances,
  nameMedicalInsurancePlan,
  medicalInsurancePatient,
}) => {
  const {
    field: { value: medicalInsuranceValue },
  } = useFieldProps({ name });
  const { setFieldValue } = useFieldProps({ name: nameMedicalInsurancePlan });
  const plans = React.useMemo(() => {
    if (!medicalInsuranceValue) {
      return [];
    }
    const medicalInsurance = medicalInsurances.find(
      (m) => m.id_os === medicalInsuranceValue,
    );
    medicalInsurancePatient !== medicalInsuranceValue &&
      medicalInsurance &&
      medicalInsurance.planes?.length &&
      setFieldValue(medicalInsurance?.planes[0].id_plan);
    return medicalInsurance?.planes ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicalInsuranceValue, medicalInsurances]);

  return (
    <>
      <DropdownForm
        className="col-span-6"
        name={name}
        data={medicalInsurances}
        label={translations.medical_insurances}
        keyId="id_os"
        keyData="obra_social"
      />
      <DropdownForm
        className="col-span-6"
        name={nameMedicalInsurancePlan}
        data={plans}
        label="Plan"
        keyId="id_plan"
        keyData="nombre"
      />
    </>
  );
};
