import React from 'react';

export function useLoading() {
  const [isLoading, setIsLoading] = React.useState(false);
  const startLoading = React.useCallback(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
  }, [setIsLoading]);
  const stopLoading = React.useCallback(
    () => setIsLoading(false),
    [setIsLoading],
  );

  return {
    stopLoading,
    startLoading,
    isLoading,
  };
}
