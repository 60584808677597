import React from 'react';
import { FormikHelpers } from 'formik';
import { FormContext, SnackbarContext } from '../../../../../../../context';
import { deleteLab } from '../../../../../../../services';

export function useSubmit(onChange: () => void) {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(spec.laboratory);
      closeForm();
      await onChange();
      formikActions.resetForm();
      showSuccessSnackbar('Laboratorio eliminado correctamente');
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(spec: { [key: string]: any }) {
  return deleteLab(spec);
}
