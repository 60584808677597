import React from 'react';
import { RadioButton, NumericField } from '../../../../components';
import { useFieldProps } from '../../../../hooks';
import { SelectDiscountTypeT } from '#interfaces/components';

export const SelectDiscountType: React.FC<SelectDiscountTypeT> = ({
  name,
  label,
  textfieldLabel,
  discountType,
  maxAmount,
}) => {
  const fieldName = `${name}.${discountType}`;
  const showOppositeFieldName =
    discountType === 'percentage' ? `${name}.amount` : `${name}.percentage`;
  const valueOppositeFieldName =
    discountType === 'percentage'
      ? `${name}.amount_value`
      : `${name}.percentage_value`;
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name: fieldName });
  const { setFieldValue: setShowOppositeFieldValue } = useFieldProps({
    name: showOppositeFieldName,
  });
  const { setFieldValue: setValueOppositeFieldValue } = useFieldProps({
    name: valueOppositeFieldName,
  });

  const handleOnClick = () => {
    if (value) setFieldValue(false);
  };

  const handleOnChange = () => {
    setFieldValue(true);
    setShowOppositeFieldValue(false);
    setValueOppositeFieldValue(0);
  };

  return (
    <>
      <div className="col-span-3 self-end bg-white" onClick={handleOnClick}>
        <RadioButton
          checked={false}
          label={label}
          name={fieldName}
          onChange={handleOnChange}
        />
      </div>
      <NumericField
        className="col-span-3"
        label={textfieldLabel}
        name={`${fieldName}_value`}
        placeholder="0"
        disabled={!value}
        max={maxAmount}
      />
    </>
  );
};
