import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { registerLicense } from '@syncfusion/ej2-base';
import { ErrorBoundary } from './pages/error';
import { NotFound } from './pages/404';
import { Loading, WithAuth } from './components';
import { Toaster } from '#components/shadcn/toaster';

const LoginView = lazy(() => import('./pages/login'));
const ReportsView = lazy(() => import('./pages/reports'));
const HomeView = lazy(() => import('./pages/home'));
const ScheduleView = lazy(() => import('./pages/schedule'));
const PatientProfileView = lazy(() => import('./pages/patient-profile'));
const ConfigView = lazy(() => import('./pages/config'));
const AboutView = lazy(() => import('./pages/about'));
const ProfessionalsView = lazy(() => import('./pages/config-professionals'));
const AccountManagerView = lazy(() => import('./pages/account-manager'));
const RemindersView = lazy(() => import('./pages/reminders'));
const StatisticsView = lazy(() => import('./pages/statistics'));
const ImportMedInsView = lazy(() => import('./pages/import-med-ins'));
const LenderView = lazy(() => import('./pages/lenders'));
const MedicalInsurancesView = lazy(
  () => import('./pages/config-medical-insurances'),
);
const BudgetsView = lazy(() => import('./pages/budgets'));
const LaboratoriesView = lazy(() => import('./pages/config-laboratories'));
const OrderLaboratoryView = lazy(() => import('./pages/laboratories'));
const RecipesView = lazy(() => import('./pages/recipes'));

registerLicense(
  'Mgo+DSMBaFt/QHRqVVhkWFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSHxRd0xgXXxWdnRXQg==;Mgo+DSMBPh8sVXJ0S0J+XE9AclRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Td0dhWHZddHddQGFVVQ==;ORg4AjUWIQA/Gnt2VVhkQlFacltJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRhW35Xc3BWTmZfUkA=;OTg1NDY2QDMyMzAyZTM0MmUzMFhnSFZBMCtiTGJKdlNjM0xaYmhSdG1YSWVSOVU0OTlzTGhSWnpTejh5eXM9;OTg1NDY3QDMyMzAyZTM0MmUzMElGU1I5alRDcDZXL09ZZFF2ZzdVS2dSekx5RGljQkR5MWo1VjdnTmJ5Qlk9;NRAiBiAaIQQuGjN/V0Z+WE9EaFtGVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUViW31feXdRRWhbUUx3;OTg1NDY5QDMyMzAyZTM0MmUzMEhVcGFVcmxUQWFvZWNhZ0t3d2FETk1HSVZoNEN4WURGdlFYNVJ3c3VnS2M9;OTg1NDcwQDMyMzAyZTM0MmUzMFlhVktJakxVNEUrTGd4c3NPSXQyeVpCeTU5WjB5RjVqbURyWDY5MDgvNHc9;Mgo+DSMBMAY9C3t2VVhkQlFacltJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRhW35Xc3BWTmdbWUY=;OTg1NDcyQDMyMzAyZTM0MmUzMG41NVRDdGJ5cWlhSmZVUzd0MHdpYitRR0xFODMwRmh0OFNWbEZHTmFyV009;OTg1NDczQDMyMzAyZTM0MmUzMFlqUmFEa2JYVUlqTlJWTVVXUFYrZE1rc3VQTjZmYXVtNnh2TDVsckcwNnc9;OTg1NDc0QDMyMzAyZTM0MmUzMEhVcGFVcmxUQWFvZWNhZ0t3d2FETk1HSVZoNEN4WURGdlFYNVJ3c3VnS2M9',
);

const App = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-MC6WS8K' });
  }, []);

  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<LoginView />} />
            <Route
              path="/home"
              element={
                <WithAuth>
                  <HomeView />
                </WithAuth>
              }
            />
            <Route
              path="/schedule"
              element={
                <WithAuth>
                  <ScheduleView />
                </WithAuth>
              }
            />
            <Route
              path="/patient-profile"
              element={
                <WithAuth>
                  <PatientProfileView />
                </WithAuth>
              }
            />
            <Route
              path="/config"
              element={
                <WithAuth>
                  <ConfigView />
                </WithAuth>
              }
            />
            <Route
              path="/about"
              element={
                <WithAuth>
                  <AboutView />
                </WithAuth>
              }
            />
            <Route
              path="/config-professionals"
              element={
                <WithAuth>
                  <ProfessionalsView />
                </WithAuth>
              }
            />
            <Route
              path="/config-medical-insurances"
              element={
                <WithAuth>
                  <MedicalInsurancesView />
                </WithAuth>
              }
            />
            <Route
              path="/reminders"
              element={
                <WithAuth>
                  <RemindersView />
                </WithAuth>
              }
            />
            <Route
              path="/account-manager"
              element={
                <WithAuth>
                  <AccountManagerView />
                </WithAuth>
              }
            />
            <Route
              path="/reports"
              element={
                <WithAuth>
                  <ReportsView />
                </WithAuth>
              }
            />
            <Route
              path="/statistics"
              element={
                <WithAuth>
                  <StatisticsView />
                </WithAuth>
              }
            />
            <Route
              path="/lenders/import-medical-insurances"
              element={
                <WithAuth>
                  <ImportMedInsView />
                </WithAuth>
              }
            />
            <Route
              path="/lenders"
              element={
                <WithAuth>
                  <LenderView />
                </WithAuth>
              }
            />
            <Route
              path="/budgets"
              element={
                <WithAuth>
                  <BudgetsView />
                </WithAuth>
              }
            />
            <Route
              path="/config-laboratories"
              element={
                <WithAuth>
                  <LaboratoriesView />
                </WithAuth>
              }
            />
            <Route
              path="/laboratories"
              element={
                <WithAuth>
                  <OrderLaboratoryView />
                </WithAuth>
              }
            />
            <Route
              path="/recipes"
              element={
                <WithAuth>
                  <RecipesView />
                </WithAuth>
              }
            />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <Toaster />
      </ErrorBoundary>
    </Router>
  );
};

export default App;
