import React from 'react';
import { Row } from './reminders-grid-row';
import { SwitchForm } from './';
import { useFieldProps } from '../../hooks';
import { ReminderGridT } from '#interfaces/components';

export const RemindersGrid: React.FC<ReminderGridT> = ({
  data,
  titlesGrids,
  keyId,
  fieldName,
  switchFieldName,
}) => {
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name: fieldName });
  const {
    field: { value: sendAllValue },
  } = useFieldProps({ name: switchFieldName });

  React.useEffect(() => {
    if (sendAllValue) {
      setFieldValue(data);
      return;
    }
    setFieldValue([]);
  }, [sendAllValue]);

  const onChangeHandler = (dataOnchange: { [key: string]: any }) => {
    const id = dataOnchange[keyId];

    if (value.some((t: { [key: string]: any }) => t[keyId] === id)) {
      const selected = value?.filter((t: any) => t[keyId] !== id);
      setFieldValue(selected);
    } else {
      const selected = data.find(
        (t: { [key: string]: any }) => t[keyId] === id,
      );
      setFieldValue([...value, selected]);
    }
  };

  return (
    <div className="text-sm max-h-96 overflow-auto border shadow-md mt-12 p-4 rounded-md">
      <div className="font-semibold mb-4 flex items-center px-8">
        <SwitchForm name={switchFieldName} />
        {titlesGrids.map((item) => (
          <p key={item.key} className="mx-8">
            {item.title}
          </p>
        ))}
      </div>
      {data &&
        data.map((r: { [key: string]: any }, i: string) => (
          <Row
            data={r}
            key={i}
            switchFieldName={switchFieldName}
            onChange={onChangeHandler}
            keys={titlesGrids}
          />
        ))}
    </div>
  );
};
