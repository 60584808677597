import { useCallback, useState } from 'react';

export function useModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState<any>(null);

  const showModalFn = useCallback(
    (title?: string, content?: { [key: string]: any }) => {
      setContent(content);
      setIsOpen(true);
      setTitle(title || '');
    },
    [setIsOpen, setTitle],
  );

  const hideModal = useCallback(() => {
    setIsOpen(false);
    setTitle('');
    setContent(null);
  }, [setIsOpen]);

  return {
    hideModal,
    isOpenModal: isOpen,
    modalTitle: title,
    modalContent: content,
    showModal: showModalFn,
  };
}
