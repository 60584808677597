import React from 'react';
import { CheckboxForm, DropdownForm } from '../../../../components';
import { useFieldProps } from '../../../../hooks';
import { BudgetT } from '#interfaces/general-values';

export const PaymentBudget: React.FC<{
  budgets: BudgetT[];
  electronicInvoice?: any;
}> = ({ budgets, electronicInvoice }) => {
  const {
    field: { value },
  } = useFieldProps({ name: 'with_budget' });
  const isBudgetAndEInvoice = value && electronicInvoice;

  return (
    <>
      <div className="col-span-4 col-start-1">
        <CheckboxForm
          name="with_budget"
          label="Posee presupuesto"
          description="Indica si el pago corresponde a un presupuesto emitido"
          showError={false}
          disabled={isBudgetAndEInvoice}
        />
      </div>
      <div className="col-span-8">
        <DropdownForm
          name="id_budget"
          data={budgets}
          keyId="id_presup"
          keyData="descripcion"
          disabled={!value}
        />
      </div>
    </>
  );
};
