import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarContext, FormContext } from '../../../../../context';
import { createBenefit } from '../../../../../services';
import { startLoading, stopLoading } from '../../../../../redux/slices';

export function useSubmit({
  userId,
  patientId,
  onChange,
}: {
  userId: number;
  patientId: number;
  onChange: () => void;
}) {
  const dispatch = useDispatch();
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async (spec: any) => {
    try {
      dispatch(startLoading());
      await axiosPromise({ userId, patientId, spec });
      showSuccessSnackbar('La prestación se ha creado con éxito');
      closeForm();
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      dispatch(stopLoading());
    }
  };
}

function axiosPromise({
  userId,
  patientId,
  spec,
}: {
  userId: number;
  patientId: number;
  spec: any;
}) {
  const apiSpec = transformSpec({ spec, userId, patientId });

  return createBenefit(apiSpec);
}

function transformSpec({
  spec,
  userId,
  patientId,
}: {
  userId: number;
  patientId: number;
  spec: any;
}) {
  const apiSpec = [
    {
      periodo: spec.period,
      fecha: new Date(spec.date).toISOString(),
      id_prof: spec.professional,
      id_paciente: patientId,
      id_prestacion: spec.benefit_type,
      diente_sector: spec.tooth_section,
      cara:
        spec.tooth_faces &&
        Object.keys(spec.tooth_faces).join('').toUpperCase(),
      obs: spec.notes,
      id_usuario: userId,
      id_sucursal: spec.office || null,
      ignorarAuditoria: true,
    },
  ];

  return apiSpec;
}
