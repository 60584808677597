import React from 'react';
import { NumericField } from '../../../commons';
import { useBenefitType, useFieldProps } from '../../../../hooks';
import { ValuesToChargeFromMedInsT } from '#interfaces/components';

export const ValuesToChargeFromMedicalInsurance: React.FC<ValuesToChargeFromMedInsT> =
  ({ name, benefitTypeFieldName, benefitTypes }) => {
    const { benefitType } = useBenefitType({
      benefitTypeFieldName,
      benefitTypes,
    });
    const { setFieldValue } = useFieldProps({ name });

    React.useEffect(() => {
      setFieldValue(benefitType ? benefitType.importe_os : 0);
    }, [benefitType, setFieldValue]);

    return (
      <NumericField
        label="Importe a cargo de la O.S. ($)"
        placeholder="$ 0"
        name={name}
        disabled
      />
    );
  };
