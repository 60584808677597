import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { deleteTurn } from '../../../../services';
import { SnackbarContext, FormContext } from '../../../../context';
import { useAppointmentError } from '../../../../hooks';
import { DeleteTurnFormT } from '#interfaces/components';
import { FormikHelpers } from 'formik';
import { OfficesT, TurnsCombinationT } from '#interfaces/general-values';
import { startLoading, stopLoading } from '../../../../redux/slices';

type DataTurnT = {
  appointment: TurnsCombinationT;
  userId: number;
  date: Date | string;
  offices: OfficesT[];
};
export function useSubmit({
  appointment,
  userId,
  date,
  offices,
  onChange,
}: DeleteTurnFormT) {
  const { closeForm } = useContext(FormContext);
  const { showSuccessSnackbar } = useContext(SnackbarContext);
  const { handleAppointmentError } = useAppointmentError(onChange);
  const dispatch = useDispatch();
  return async (_spec: any, formikActions: FormikHelpers<any>) => {
    try {
      dispatch(startLoading());
      await axiosPromise({ appointment, userId, date, offices });
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Turno deshabilitado con éxito.');
      onChange();
    } catch (err: any) {
      handleAppointmentError(err, formikActions);
    } finally {
      dispatch(stopLoading());
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise({ appointment, userId, date, offices }: DataTurnT) {
  const apiSpec = transformSpec({ appointment, userId, date, offices });

  return deleteTurn(apiSpec);
}

function transformSpec({ appointment, userId, date, offices }: DataTurnT) {
  const office = offices && offices.length > 1 ? offices[0].id_sucursal : null;

  return {
    hora: appointment.time,
    opcion: 'anularTurno',
    es_sobreturno: appointment.is_overturn,
    id_prof: appointment.id_professional,
    id_paciente: appointment.is_overturn ? null : appointment.id_patient,
    fecha: appointment?.date || date,
    id_usuario: userId,
    no_dar: true,
    obs: null,
    paciente: null,
    telefono: null,
    asistencia: null,
    obra_social: null,
    plan_nombre: null,
    nro_afil: null,
    fecha_dado: new Date().toISOString(),
    hora_llegada: null,
    id_sucursal: appointment.id_office || office,
    id_agenda: appointment.id_appointment,
    rowversion: appointment.rowversion,
    id_profNavigation: null,
    id_sucursalNavigation: null,
    id_usuarioNavigation: null,
    id_pacienteNavigation: null,
  };
}
