import React from 'react';
import classNames from 'classnames';
import { ButtonT, TextButtonT } from '#interfaces/components';

export const ButtonText: React.FC<TextButtonT> = ({ children }) => {
  return <span>{children}</span>;
};

export const Normal: React.FC<ButtonT> = ({
  className,
  children,
  size,
  modifier = 'filled',
  href = null,
  type = 'button',
  disabled,
  loading,
  fullWidth,
  onClick,
}) => {
  const isDisabled = disabled || loading;

  const variants: { [key: string]: any } = {
    warning: classNames(
      'px-10 h-10 bg-transparent text-red-500 hover:bg-red-500 hover:text-white border border-red-500',
      { '!text-slate-500': isDisabled },
    ),
    filled: 'bg-[#007aff] text-white',
    transparent: classNames(
      'bg-transparent text-[#007aff] border border-[#007aff]',
      { '!text-slate-500': isDisabled },
    ),
    dark: 'flex bg-[#222] text-[white]',
    whatsapp: 'text-white bg-[#25d366] flex',
  };
  const sizes: { [key: string]: any } = {
    small: 'text-xs h-6',
    medium: 'text-xs h-8',
    large: 'text-xs !w-44',
  };
  const classes = classNames(
    'justify-center flex w-auto px-4 items-center min-w-[18px] outline-none text-xs h-10 font-normal leading-none text-center rounded-sm hover:shadow-md hover:cursor-pointer hover:-translate-y-0.5 hover:transition-transform hover:duration-300',
    modifier && variants[modifier],
    fullWidth && `justify-center w-full`,
    className,
    size && sizes[size],
    isDisabled &&
      'bg-slate-400 text-white pointer-events-none border border-slate-400',
  );

  const content = (
    <span className="w-auto text-center items-center">{children}</span>
  );

  const sharedProps = {
    className: classes,
    disabled: isDisabled,
  };

  return href ? (
    <a role="button" href={href} {...sharedProps} onClick={onClick}>
      {content}
    </a>
  ) : (
    <button type={type} {...sharedProps} onClick={onClick}>
      {children}
    </button>
  );
};
