/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  SwitchForm,
  DropdownForm,
  TextFieldForm,
  NumericField,
} from '../../../../../components';
import { useFieldProps } from '../../../../../hooks';
import { installmentsMapper, ccFormatNumber } from '../../../../../utils';
import { SnackbarContext } from '../../../../../context';
import { CardsT } from '#interfaces/general-values';

const CreditCardMethod: React.FC<{ cards: CardsT[] }> = ({ cards }) => {
  const { showErrorSnackbar } = React.useContext(SnackbarContext);
  const [installmentsList, setInstallmentsList] = React.useState([]);
  const {
    field: { value: withInstallments },
    setFieldValue: setValueWithInstallments,
  } = useFieldProps({ name: 'with_installments' });
  const {
    field: { value: paid },
  } = useFieldProps({ name: 'paid' });
  const {
    field: { value: interest },
    setFieldValue: setInterestValue,
  } = useFieldProps({ name: 'interest' });
  const {
    field: { value: cardId },
    setFieldValue: setCardIdValue,
  } = useFieldProps({ name: 'id_card' });
  const {
    field: { value: cardNumber },
    setFieldValue: setCardNumberValue,
  } = useFieldProps({ name: 'mov_cards.card_number' });
  const {
    field: { value: interestPercentaje },
    setFieldValue: setInterestPercentajeValue,
  } = useFieldProps({ name: 'interest_perc' });
  const {
    field: { value: installmentsId },
    setFieldValue: setInstallmentsIdValue,
  } = useFieldProps({ name: 'installments' });
  const totalToPay = parseFloat(paid) + parseFloat(interest);

  function setInterest() {
    if (paid && paid > 0) {
      const interest = (paid * interestPercentaje) / 100;
      setInterestValue(interest);
    } else {
      setInterestValue(0);
    }
  }

  const [card] = React.useMemo(
    () =>
      cards?.filter((c: { [key: string]: any }) => c?.id_tarjeta === cardId) ||
      [],
    [cards, cardId],
  );

  React.useEffect(() => {
    if (withInstallments) {
      setInterestPercentajeValue(card?.tarjetas_cuotas[0]?.interes || 0);
      setInstallmentsList(installmentsMapper(card));
      setInstallmentsIdValue(card?.tarjetas_cuotas[0]?.id_tar_cuo);
      setInterest();
    } else if (card && !withInstallments) {
      setInterestPercentajeValue(0);
      setInstallmentsList([]);
    } else {
      const defaultCard = cards[0];
      setCardIdValue(card?.id_tarjeta || defaultCard?.id_tarjeta || null);
    }
  }, [withInstallments, card]);

  React.useEffect(() => {
    if (card) {
      const [installment] = card.tarjetas_cuotas.filter(
        (i: { [key: string]: any }) => i.id_tar_cuo === installmentsId,
      );
      setInterestPercentajeValue(
        installment?.interes || card.tarjetas_cuotas[0]?.interes,
      );
    }
  }, [installmentsId]);

  React.useEffect(() => {
    setInterest();
  }, [paid, interestPercentaje]);

  React.useEffect(() => {
    if (cardNumber) {
      setCardNumberValue(ccFormatNumber(cardNumber));
    } else {
      setCardNumberValue('');
    }
  }, [cardNumber]);

  React.useMemo(() => {
    if (!paid) {
      showErrorSnackbar('Debe colocar primero un importe pagado');
      setValueWithInstallments(false);
    }
  }, [withInstallments]);

  return (
    <>
      <DropdownForm
        name="id_card"
        data={cards}
        className="col-span-3"
        label="Tarjetas de crédito"
        keyId="id_tarjeta"
        keyData="tarjeta"
      />
      <TextFieldForm
        name="mov_cards.card_number"
        label="Nro. Tarjeta"
        className="col-span-3"
        placeholder="0000 0000 0000 0000"
        maxlength={16}
      />
      <TextFieldForm
        name="mov_cards.card_number_auth"
        label="Nro. Autorización"
        className="col-span-3"
        placeholder="123"
        maxlength={20}
      />
      <div className="col-span-2">
        <label htmlFor="input" className="mb-3 block text-sm font-medium">
          Con cuotas
        </label>
        <SwitchForm name="with_installments" />
      </div>
      <DropdownForm
        name="installments"
        data={installmentsList}
        className="col-span-3"
        label="Cantidad de cuotas"
        keyId="id"
        keyData="text"
        disabled={!withInstallments}
      />
      <NumericField
        name="interest_perc"
        label="% Interes"
        className="col-span-3"
        placeholder="% 00"
        min={0}
        type="number"
        disabled={!withInstallments}
      />
      <NumericField
        name="interest"
        label="Interes"
        className="col-span-3"
        placeholder="$ 0,00"
        min={0}
        type="number"
        disabled={!withInstallments}
      />
      <div className="flex flex-col justify-end col-span-3">
        <p className="text-sm font-regular text-slate-600 mb-2">
          Total a Pagar
        </p>
        <p className="text-2xl font-bold text-slate-600">${totalToPay}</p>
      </div>
    </>
  );
};

export default CreditCardMethod;
