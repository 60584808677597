import React from 'react';
import classNames from 'classnames';
import { Icons } from './icons';
import { NormalCardT } from '#interfaces/components';

export const Card: React.FC<NormalCardT> = ({
  className,
  children,
  action,
  onClick,
  disabled = false,
}) => {
  const classes = classNames('shadow-md rounded bg-white', className);
  const actionCss = {
    button: classNames(
      'py-4 px-10 w-full flex items-center border-t hover:bg-gray-100 justify-between',
      disabled
        ? 'pointer-events-none cursor-not-allowed'
        : 'hover:cursor-pointer',
    ),
    text: classNames(
      'text-left text-sm font-semibold',
      disabled ? 'text-slate-500' : 'text-blue-500',
    ),
    icon: classNames('w-6 h-6', disabled ? 'text-slate-500' : 'text-blue-500'),
  };

  const Actions: { [key: string | number | symbol]: JSX.Element } = {
    edit: (
      <button className={actionCss.button} onClick={onClick}>
        <p className={actionCss.text}>{action && action.text}</p>
        <Icons className={actionCss.icon} type="arrow_right" />
      </button>
    ),
  };

  return (
    <div className={classes}>
      <div className="rounded-md p-8">{children}</div>
      {action && Actions[action.type]}
    </div>
  );
};
