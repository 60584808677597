import React from 'react';
import { useSelector } from 'react-redux';
import { useJwt } from 'react-jwt';
import { Button, Copy } from '../../components';

export const RecipeModal: React.FC<{ onCancel: () => void }> = ({
  onCancel,
}) => {
  const {
    login: { token },
  } = useSelector((state: any) => state);
  const [userData, setUserData] = React.useState<any>({
    user: '',
    web_user: '',
  });
  const { decodedToken }: { decodedToken: any } = useJwt(token);

  React.useMemo(() => {
    if (decodedToken)
      setUserData({ user: decodedToken.User, web_user: decodedToken.WebUser });
  }, [decodedToken]);

  return (
    <div className="mt-5">
      <Copy>
        <strong className="text-slate-900">
          El periodo de prueba libre y gratuita ha terminado.
        </strong>{' '}
        <br />
        <br /> Adquiriendo el pack de recetas tendrás accesos a: <br />
        <strong className="text-slate-900">
          • Realizar recetas sin límites.
          <br />• Enviar a tus pacientes sus recetas.
        </strong>{' '}
      </Copy>
      <Copy>
        💊 Realiza recetas farmacológicas con facilidad.
        <br /> 📩 Envía las recetas a tus pacientes.
        <br /> 👌🏼 Configura tus permisos y datos en la sección de configuración.
      </Copy>
      <Copy>Disfruta de todas las funcionalidades de Recetas sin límites.</Copy>
      <div className="flex items-center gap-x-4">
        <Button
          size="large"
          onClick={() => {
            onCancel();
            window.open(
              `https://wa.me/+5491128504255?text=Hola!%20quisiera%20contratar%20el%20servicio%20de%20recetas.%20Mi%20usuario%20Bilog%20es:%20${userData.web_user}`,
              '_blank',
            );
          }}
        >
          Contratar ahora
        </Button>
        <Button onClick={onCancel} modifier="warning" size="large">
          Cancelar
        </Button>
      </div>
    </div>
  );
};
