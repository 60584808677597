import React from 'react';
import './index.scss';
import { PatientResultsT } from '#interfaces/components';

const SearchResults: React.FC<PatientResultsT> = ({
  results,
  onClickHandler,
  translations,
}) => {
  return (
    <React.Fragment>
      <div className="searchbar--search-for__labels schedule">
        <p className="row-label label-mobile">Nombre y apellido</p>
        <p className="row-label label-mobile">Nº de HC</p>
        <p className="row-label">Documento</p>
        <p className="row-label">{translations.medical_insurance}</p>
        <p className="row-label">Plan</p>
      </div>
      {results.map((item) => {
        const {
          id_paciente,
          documento,
          ape_nom,
          hc_nro,
          id_planNavigation: { codigo_plan, id_osNavigation },
        } = item;

        return (
          <div
            className="searchbar--search-for__patient-row schedule py-1 flex items-center"
            onClick={() => onClickHandler(id_paciente)}
            key={id_paciente}
          >
            <p className="patient-row--item label-mobile patient-name">
              {ape_nom}
            </p>
            <p className="patient-row--item label-mobile ch-number">{hc_nro}</p>
            <p className="patient-row--item">{documento}</p>
            <p className="patient-row--item">{id_osNavigation.obra_social}</p>
            <p className="patient-row--item">{codigo_plan}</p>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default SearchResults;
