import React from 'react';
import { CheckboxForm } from '#components/commons';
import { useFieldProps } from '../../../../../hooks';

export const SpeciatlyCheckbox: React.FC<{
  specialty1FieldName: string;
  specialty2FieldName: string;
  className: string;
}> = ({ specialty1FieldName, specialty2FieldName, className }) => {
  const {
    field: { value: specialty1 },
  } = useFieldProps({ name: specialty1FieldName });
  const {
    field: { value: specialty2 },
  } = useFieldProps({ name: specialty2FieldName });

  return (
    <CheckboxForm
      label="Solamente se podrá cargar prestaciones referidas de su/s especialidad/es"
      name="only_specialty"
      classNameContainer={className}
      small
      disabled={!specialty1 && !specialty2}
      showError={false}
    />
  );
};
