import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Input, Button } from '#components/shadcn';
import { Diagnosis } from '#components/Tables';
import {
  startLoading,
  stopLoading,
  getListDiagnosis,
} from '../../../../redux/slices';
import { AppDispatch } from '../../../../redux';
import { useFieldProps, useOnClickOutside } from '../../../../hooks';

export const SearchDiagnosis: React.FC<{
  searchResults: any;
  placeholder: string;
  label: string;
  name: string;
  disabled: boolean;
}> = ({ searchResults, placeholder, label, name, disabled }) => {
  const dispatch: AppDispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [sorting, setSorting] = useState([]);
  const pageNumber = useRef<number>(1);
  const refWrapper = useRef<any>(null);

  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name });

  useOnClickOutside({
    ref: refWrapper,
    handler: () => {
      setIsOpen(false);
      setSearchInput('');
    },
  });

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsOpen(false);
    setFieldValue(e.target.value);
    setSearchInput(e.target.value);
    setHasSearched(false);
  };

  const onSubmitHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (searchInput.trim() !== '' && !hasSearched) {
      dispatch(startLoading());
      pageNumber.current = 1;
      await dispatch(getListDiagnosis(searchInput));
      dispatch(stopLoading());
      setIsOpen(true);
      setHasSearched(true);
    }
  };

  const onClickHandler = async (selectedDiagnosis: number) => {
    dispatch(startLoading());
    setFieldValue(selectedDiagnosis);
    setIsOpen(false);
    setSearchInput('');
    dispatch(stopLoading());
  };

  const showResults = isOpen && searchResults && searchResults.length >= 1;

  const searchBarContainerCss = classNames(
    'rounded-md will-change-[left] transition-all duration-100 ease-out w-full group col-span-6',
    isOpen && 'searchbar-container--is_open relative z-10',
  );

  return (
    <form ref={refWrapper} className={searchBarContainerCss}>
      <div className="grid grid-cols-12 gap-x-4 rounded-md">
        <label htmlFor="input" className="mb-2 block !text-sm col-span-full">
          {label}
        </label>
        <div className="col-span-10">
          <Input
            type="search"
            className="m-0 text-slate-900 !border-1 shadow-sm !border-gray-300 py-2 pl-5 h-10 w-full relative rounded-md text-base col-span-10"
            placeholder={placeholder}
            onChange={(e: any) => onChangeHandler(e)}
            value={searchInput || value?.description}
            maxLength={120}
            autoCapitalize="off"
            disabled={disabled}
          />
        </div>
        <Button
          disabled={!searchInput}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            onSubmitHandler(e)
          }
          type="submit"
          className="w-full col-span-2 bg-transparent !text-gray-800 hover:bg-slate-200/50 border border-slate-200"
        >
          Buscar
        </Button>
      </div>
      <Transition
        show={showResults}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Diagnosis
          data={searchResults}
          sorting={sorting}
          setSorting={setSorting}
          onClick={onClickHandler}
          height="280px"
        />
      </Transition>
    </form>
  );
};
