import React from 'react';
import { splitOdontogram } from '../../../../utils';

export const OdontogramLayout: React.FC<{
  odontogram: any;
  onClick: (str: string, tooth: number) => void;
}> = ({ odontogram, onClick }) => {
  const [clicked, setClicked] = React.useState<{
    quadrant: number | null;
    tooth: number | null;
  }>({ quadrant: null, tooth: null });
  const splitted = odontogram.length && splitOdontogram(odontogram);

  function openMenuHandler(qi: any, i: number) {
    setClicked({ quadrant: qi, tooth: i });
  }

  return splitted && splitted.length ? (
    <div className="odontogram">
      {splitted.map((e: any, qi: number) => (
        <div
          className="odontogram-quadrant"
          data-quadrant={qi + 1}
          key={qi}
          onMouseLeave={() => setClicked({ quadrant: null, tooth: null })}
        >
          {e.map((o: any, i: number) => (
            <div className="tooth" onClick={() => openMenuHandler(qi, i)}>
              {o.imageSourcer ? (
                <img
                  className="tooth-image"
                  key={i}
                  alt="odontograma"
                  src={`data:image/jpeg;base64,${o.imageSourcer}`}
                />
              ) : (
                <div className="h-[60px] w-10 hover:cursor-pointer" />
              )}
              <p className="tooth-number">{o.diente}</p>
              <div
                className={`tooth-submenu${
                  clicked.quadrant === qi && clicked.tooth === i ? ' show' : ''
                }`}
                onMouseLeave={() => setClicked({ quadrant: null, tooth: null })}
              >
                <p onClick={() => onClick('new', o.diente.toString())}>
                  Crear nueva prestación
                </p>
                <p onClick={() => onClick('details', o.diente)}>Ver detalles</p>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  ) : null;
};
