import React from 'react';
import { copyPlanPrest } from '../../../../../services';
import {
  SnackbarContext,
  FormContext,
  LoadingContext,
} from '../../../../../context';
import { FormikHelpers } from 'formik';

export function useSubmit() {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);
  const { startLoading, stopLoading } = React.useContext(LoadingContext);

  return async (
    spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      startLoading();
      const {
        request: { response },
      } = await axiosPromise(spec);
      const msg = JSON.parse(response);
      formikActions.resetForm();
      showSuccessSnackbar(msg.successMessage);
      closeForm();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      stopLoading();
    }
  };
}

function axiosPromise(spec: { [key: string]: any }) {
  const apiSpec = transformSpec(spec);

  return copyPlanPrest(apiSpec);
}

function transformSpec(spec: { [key: string]: any }) {
  let apiSpec = {
    originId: spec.plan_origin,
    destinationId: spec.plan_destination,
    replaceItems: spec.replace_destination,
    itemsIds: spec.selected_benefits,
  };

  return apiSpec;
}
