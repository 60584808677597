import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Button, Title } from '../..';
import { useForm } from './use-form';
import { useSubmit } from './use-submit';
import { LenderGridFormT } from '#interfaces/components';

export const LenderGridForm: React.FC<LenderGridFormT> = ({
  codes,
  ListMedIns,
  dataBaseName,
  open,
}) => {
  const enabledMedIns = ListMedIns.filter((os) => !os.inhabilitada);
  const allObrasSocialesIds: string[] = enabledMedIns.map((e) =>
    e.id_os.toString(),
  );
  const { initialValues } = useForm(codes, dataBaseName);

  return (
    <div className="px-10">
      <Title className="mt-4 text-2xl font-black tracking-wide">
        Importación de obras sociales
      </Title>
      <p className="my-6 text-slate-400">
        Selecciona las obras sociales que posee tu gerenciadora, y que deseas
        importar. Estas remplazarán a las que ya posea.
      </p>
      <Formik initialValues={initialValues} onSubmit={useSubmit(open)}>
        {({ submitForm, values }) => (
          <Form className="grid grid-cols-12 gap-2">
            <div className="shadow-lg rounded-md bg-slate-100 col-span-12">
              {enabledMedIns.map((e) => (
                <div className="border-b-2 p-1">
                  <Field
                    key={e.id_os}
                    type="checkbox"
                    name="medical_insurance_list"
                    value={e.id_os.toString()}
                    className="mr-4 ml-7"
                  />
                  {e.obra_social}
                </div>
              ))}
            </div>
            <div className="mt-6 col-span-12 flex gap-5">
              <Button
                className="bg-white border border-blue-500 !text-blue-500 font-semibold "
                onClick={() => {
                  values.medical_insurance_list = allObrasSocialesIds;
                  return submitForm();
                }}
              >
                Importar todas
              </Button>
              <Button onClick={submitForm}>Importar seleccionadas</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
