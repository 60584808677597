import React from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Resize,
  ContextMenu,
} from '@syncfusion/ej2-react-grids';
import { CashBalance, CashClosure, Enabled } from './templates';
import { OfficeForm } from './OfficeForm';
import { DeleteOfficeForm } from './DeleteOfficeForm';
import { EmptyState, Button, Modal } from '../../../../../components';
import { useForm } from '../../../../../hooks';
import { gridContextMenu } from '../../../../../mocks';
import { officesGridMapper } from '../../../../../utils';
import { OfficesFormT } from '#interfaces/components';

export const OfficesForm: React.FC<OfficesFormT> = ({
  offices,
  useUsd,
  userUseUsd,
  onChange,
  onClose,
}) => {
  const grid = React.useRef<any>(null);
  const [officeRow, setOfficeRow] = React.useState<any>(false);
  const canUseUsd = userUseUsd && useUsd;
  const {
    isOpenForm: isOpenDelete,
    closeForm: closeDelete,
    openForm: openDelete,
  } = useForm();
  const {
    isOpenForm: isOpenEdit,
    closeForm: closeEdit,
    openForm: openEdit,
  } = useForm();

  React.useEffect(() => {
    onChange('offices');
  }, [onChange]);

  const onChangeHandler = () => {
    onChange('offices');
    onClose();
  };

  const onCancelHandler = () => {
    setOfficeRow(false);
    closeEdit();
    closeDelete();
  };

  const contextMenuClickHandler = (e: { [key: string]: any }) => {
    const [selectedRow] = grid.current.getSelectedRecords();
    setOfficeRow(selectedRow);
    const events: { [key: string]: any } = {
      delete: openDelete,
      edit: openEdit,
    };
    if (!!events[e.item.id]) {
      return events[e.item.id](e);
    }
  };

  return (
    <section className="specialties-form">
      {offices?.length ? (
        <GridComponent
          ref={grid}
          dataSource={officesGridMapper(offices)}
          pageSettings={{ pageSize: 15 }}
          allowResizing={true}
          allowTextWrap={true}
          height="100%"
          contextMenuItems={gridContextMenu}
          textWrapSettings={{ wrapMode: 'Content' }}
          contextMenuClick={(e) => contextMenuClickHandler(e)}
        >
          <Inject services={[Resize, ContextMenu]} />
          <ColumnsDirective>
            <ColumnDirective field="id" visible={false} />
            <ColumnDirective field="id_new_office" visible={false} />
            <ColumnDirective field="option" visible={false} />
            <ColumnDirective field="rowversion" visible={false} />
            <ColumnDirective field="enabled" visible={false} />
            <ColumnDirective field="cash_closure" visible={false} />
            <ColumnDirective field="office" headerText="Sucursal" />
            <ColumnDirective
              field="enabled"
              width="100px"
              headerText="Habilitada"
              template={Enabled}
            />
            <ColumnDirective
              field="description"
              width="195px"
              headerText="Descripción"
            />
            <ColumnDirective
              field="cash_balance"
              headerText="Saldo de caja"
              width="115px"
              template={CashBalance}
            />
            <ColumnDirective
              field="cash_closure_show"
              headerText="Ult. cierre de caja"
              width="145px"
              template={CashClosure}
            />
            <ColumnDirective
              field="bill_user"
              headerText="Facturación Usuario"
            />
            <ColumnDirective
              field="bill_company"
              headerText="Facturación Empresa"
            />
            <ColumnDirective field="bill_point" headerText="Punto de venta" />
            <ColumnDirective field="bill_pass" visible={false} />
          </ColumnsDirective>
        </GridComponent>
      ) : (
        <EmptyState title="Ups!" copy="No existen sucursales cargadas." />
      )}
      <Button className="mt-10" onClick={openEdit}>
        Nueva sucursal
      </Button>
      <Modal
        title={officeRow ? 'Editar sucursal' : 'Nueva sucursal'}
        isOpenModal={isOpenEdit}
        closeModal={onCancelHandler}
        openModal={openEdit}
        width="50%"
      >
        <OfficeForm
          office={officeRow}
          offices={offices}
          useUsd={canUseUsd}
          onCancel={onCancelHandler}
          onChange={onChangeHandler}
        />
      </Modal>
      <Modal
        title="Eliminar sucursal"
        isOpenModal={isOpenDelete}
        closeModal={onCancelHandler}
        openModal={openDelete}
      >
        <DeleteOfficeForm office={officeRow} onChange={onChangeHandler} />
      </Modal>
    </section>
  );
};
