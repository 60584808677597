import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '#src/redux';
import { Button } from '../components';
import errorImage from '../static/error_network.svg';
import { logErrors } from '../services/logs';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
  return (
    <div className="flex items-center mt-40 flex-col">
      <img
        className="w-44"
        src={errorImage}
        alt="Crying thoot for error page"
      />
      <h2 className="text-xl font-bold mt-4 mb-2">Ups! Algo salió mal</h2>
      <p className="text-sm mb-8 font-mono bg-orange-100 py-1 px-2 rounded text-orange-900">
        {error.message}
      </p>
      <Button onClick={resetErrorBoundary}>Ir al inicio</Button>
    </div>
  );
};

export const ErrorBoundary: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const {
    login: { user },
  } = useSelector((state: RootState) => state);

  const handleError = (error: Error, info: any) => {
    logErrors({
      user: user.usuario,
      plan: user.plan_version,
      isOwner: user.is_owner,
      supervisor: user.es_supervisor,
      message: error.message,
      info: window ? window.location.href : 'url descoconocida',
    });
  };

  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback} onError={handleError}>
      {children}
    </ReactErrorBoundary>
  );
};
