import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { format } from 'date-fns';
import { AvailableAppointmentsForm } from './AvailableAppointmentsForm';
import { SelectByCriteria } from './SelectByCriteria';
import { addDaysToDate } from '../days-of-week';
import { Modal } from '../../../../components';
import { Button } from '../../../../components/shadcn/button';
import { useFieldProps, useOnChange } from '../../../../hooks';
import { setFindNextAvailableAppointment } from '../../../../redux/slices';
import { OfficesT, TurnsT } from '#interfaces/general-values';

export const FindNextAvailableAppointment: React.FC<{
  schedule: any;
  professionalFieldName: string;
  specialtyFieldName: string;
  officeFieldName: string;
  dateFieldName: string;
  setAppointment: any;
  filterProfessionalByDayFieldName: string;
  openFirstTimeAppointmentForm: () => void;
  openCreateAppointmentForm: () => void;
  offices: OfficesT[];
}> = ({
  schedule,
  professionalFieldName,
  specialtyFieldName,
  officeFieldName,
  dateFieldName,
  setAppointment,
  filterProfessionalByDayFieldName,
  openFirstTimeAppointmentForm,
  openCreateAppointmentForm,
  offices,
}) => {
  const dispatch = useDispatch();
  const { isSubmitting } = useFormikContext();
  const [showCriteriaModal, setShowCriteriaModal] = useState(false);
  const [showNextAppointmentModal, setShowNextAppointmentModal] =
    useState(false);
  const {
    field: { value: professional },
  } = useFieldProps({ name: 'professional' });
  const {
    field: { value: date },
    setFieldValue: setDateFieldValue,
  } = useFieldProps({ name: dateFieldName });

  const {
    field: { value: office },
  } = useFieldProps({ name: officeFieldName });
  const {
    field: { value: specialty },
  } = useFieldProps({ name: specialtyFieldName });
  const { field: searchByCriteria, setFieldValue: setSearchByCriteria } =
    useFieldProps({ name: 'search_by_criteria' });
  const { setFieldValue: setLastProfessionalSearch } = useFieldProps({
    name: 'last_professional_search',
  });
  const {
    field: { value: nextAvailableAppointmentCounter },
    setFieldValue: setNextAvailableAppointmentCounter,
  } = useFieldProps({ name: 'next_available_appointment_counter' });
  const {
    field: { value: criteria },
    setFieldValue: setCriteria,
  } = useFieldProps({ name: 'criteria' });
  const onChange = useOnChange({ name: filterProfessionalByDayFieldName });
  const formattedDate = format(date, 'yyyy/MM/dd');

  const findNextAvailableAppointments = useCallback(() => {
    setNextAvailableAppointmentCounter(nextAvailableAppointmentCounter + 1);
    if (!criteria?.by_specialty && nextAvailableAppointmentCounter > 0) {
      setDateFieldValue(addDaysToDate(formattedDate, 1));
    } else if (nextAvailableAppointmentCounter === 0) {
      setDateFieldValue(addDaysToDate(format(new Date(), 'yyyy/MM/dd'), 1));
    }
    if (criteria?.by_professional) {
      onChange(false);
    }
    const id_prof = schedule && schedule[0];
    const id = id_prof?.id_prof || professional;
    setLastProfessionalSearch(id);
    dispatch(setFindNextAvailableAppointment(true));
  }, [
    setNextAvailableAppointmentCounter,
    nextAvailableAppointmentCounter,
    criteria?.by_specialty,
    criteria?.by_professional,
    schedule,
    professional,
    setLastProfessionalSearch,
    dispatch,
    setDateFieldValue,
    formattedDate,
    onChange,
  ]);

  const findFirstAvailableAppointments = useCallback(() => {
    setShowCriteriaModal(false);
    findNextAvailableAppointments();
    setShowNextAppointmentModal(true);
  }, [findNextAvailableAppointments]);

  const availableAppointments = useMemo(() => {
    if (!schedule) return [];
    return schedule.filter(
      (appointment: TurnsT) => appointment.id_agenda === -1,
    );
  }, [schedule]);

  const hiddenModal = useMemo(() => {
    return (
      isSubmitting ||
      (!searchByCriteria &&
        schedule &&
        schedule.filter(
          (appointment: TurnsT) =>
            format(appointment.fecha, 'yyyy/MM/dd') !== formattedDate,
        ).length > 0)
    );
  }, [isSubmitting, schedule, searchByCriteria, formattedDate]);

  const handleNextAvailableAppointmentClick = () => {
    if (office || specialty) {
      setSearchByCriteria(true);
      return setShowCriteriaModal(true);
    } else {
      return findFirstAvailableAppointments();
    }
  };

  const closeModal = () => {
    setNextAvailableAppointmentCounter(0);
    setCriteria({
      by_office: true,
      by_offices: false,
      by_specialty: false,
      by_professional: true,
    });
    setShowNextAppointmentModal(false);
    setShowCriteriaModal(false);
    dispatch(setFindNextAvailableAppointment(false));
    setSearchByCriteria(false);
  };

  const [matchedOffice] = offices
    ? offices?.filter((s) => s.id_sucursal === office)
    : [];

  return (
    <>
      <Modal
        title="Buscar siguiente turno disponible"
        isOpenModal={showCriteriaModal && !isSubmitting}
        closeModal={closeModal}
      >
        <SelectByCriteria
          onChange={findFirstAvailableAppointments}
          officeValue={office}
          specialtyValue={specialty}
        />
      </Modal>
      <Modal
        title="Próximo turnos disponibles"
        isOpenModal={showNextAppointmentModal && !hiddenModal}
        closeModal={closeModal}
        width="auto"
      >
        <p className="text-base font-regular mb-4">
          El siguiente turno disponible es el{' '}
          <b>{format(date, 'dd/MM/yyyy')}</b>
          {matchedOffice && ` en la sucursal ${matchedOffice.sucursal}.`}
        </p>
        <AvailableAppointmentsForm
          availableAppointments={availableAppointments}
          setAppointment={setAppointment}
          openFirstTimeAppointmentForm={openFirstTimeAppointmentForm}
          openCreateAppointmentForm={openCreateAppointmentForm}
          next={findNextAvailableAppointments}
        />
      </Modal>
      <div id="schedule-view-find-appointment">
        <Button
          onClick={handleNextAvailableAppointmentClick}
          className="h-[38px] flex px-4 truncate bg-slate-200 !text-slate-800 hover:bg-slate-300 ml-2"
        >
          Buscar turno disponible
        </Button>
      </div>
    </>
  );
};
