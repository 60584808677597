const all = {
  medical_insurances: 'Seguros médicos',
  medical_insurance: 'Seguro médico',
  medical_insurance_abbreviation: 'S.M.',
  medical_insurance_data: 'Datos del seguro médico',
  see_only: 'Ver solo habilitados',
  cod_mi: 'Cod. S.M.',
  amount_mi: 'Imp SM',
  money: '$',
  doc_types: [
    { id: 'CI', text: 'CI' },
    { id: 'LC', text: 'LC' },
    { id: 'LE', text: 'LE' },
    { id: 'PAS', text: 'PAS' },
    { id: 'OTR', text: 'Otro' },
  ],
  config: {
    med_ins_and_benefits: 'Seguros médicos y prestaciones',
    med_ben_text:
      'Dar de alta un nuevo seguro médico, modificar sus planes, grupos y prestaciones.',
    new_med_ins: 'Nuevo seguro médico',
  },
};

export default all;
