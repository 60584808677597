import React from 'react';
import { useFieldProps } from '../../../../../hooks';
import { Title } from '../../../../../components';

export const AmountsBar: React.FC = () => {
  const {
    field: {
      value: {
        amount,
        total_amount,
        discount_amount,
        discount_percentage,
        interest_rate,
        iva,
      },
    },
  } = useFieldProps({ name: 'imports' });
  const discountValue = discount_amount
    ? `$${discount_amount}`
    : `${discount_percentage}%`;

  const importsObj = [
    {
      label: 'Importe unitario',
      value: `$${amount}`,
    },
    {
      label: 'IVA',
      value: `${iva}%`,
    },
    {
      label: 'Descuento',
      value: discountValue,
    },
    {
      label: 'Interés',
      value: `${interest_rate}%`,
    },
  ];

  return (
    <div>
      <div className="pb-5">
        <Title className="text-slate-600 font-bold">
          Importe unitario final:{' '}
          <span className="text-lg text-green-600">${total_amount}</span>
        </Title>
      </div>
      <div className="col-span-12 grid grid-cols-12 border border-slate-200 mb-10 bg-white border-r-0">
        {importsObj.map((item, index) => {
          return (
            <span
              key={index}
              className="col-span-3 p-3 text-sm text-slate-500 border-r border-r-slate-200"
            >
              {item.label}:
              <strong className="font-semibold pl-2">{item.value}</strong>
            </span>
          );
        })}
      </div>
    </div>
  );
};
