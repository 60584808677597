import * as React from 'react';
import { ColumnsT } from '#interfaces/tables/benefit';
import { Icons } from '#components/commons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '#components/shadcn';
import classNames from 'classnames';

export const Columns = ({ action, config: { translations } }: ColumnsT) => {
  return [
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }: { row: any }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="relative p-2 hover:cursor-pointer flex-none">
              <Icons
                type="context"
                className="text-gray-500 hover:text-gray-900 h-4 w-4"
              />
              <span className="sr-only">Abrir menu</span>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56" align="start">
            <DropdownMenuGroup>
              <DropdownMenuItem
                className="hover:cursor-pointer"
                onSelect={() => action(row.original, 'details')}
              >
                <Icons type="plus" className="mr-2 h-4 w-4" />
                <span>Ver detalles</span>
              </DropdownMenuItem>
              <DropdownMenuItem
                className="hover:cursor-pointer text-red-500"
                onSelect={() => action(row.original, 'delete')}
              >
                <Icons type="delete" className="mr-2 h-4 w-4" />
                <span>Eliminar</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      ),
    },
    {
      accessorKey: 'code_medical_insurance',
      header: `Código prestación ${translations.medical_insurance_abbreviation}`,
      cell: ({ row }: { row: any }) => {
        const { code_medical_insurance } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {code_medical_insurance}
          </p>
        );
      },
    },
    {
      accessorKey: 'code_medical_insurance_bill',
      header: `Código prestación ${translations.medical_insurance_abbreviation} Liq.`,
      cell: ({ row }: { row: any }) => {
        const { code_medical_insurance_bill } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {code_medical_insurance_bill}
          </p>
        );
      },
    },
    {
      accessorKey: 'description',
      header: 'Descripción',
      cell: ({ row }: { row: any }) => {
        const { description } = row.original;

        return (
          <p className="text-base font-semibold leading-6 text-slate-800 my-1">
            {description}
          </p>
        );
      },
    },
    {
      accessorKey: 'is_usd',
      header: 'En usd',
      cell: ({ row }: { row: any }) => {
        const { is_usd } = row.original;

        return (
          <Icons
            type={is_usd ? 'check_clean' : 'no_simbol'}
            className={classNames(
              'h-4 w-4',
              is_usd ? 'text-green-500' : 'text-slate-500',
            )}
          />
        );
      },
      filterFn: (row: any, id: string, value: any) =>
        value.includes(row.getValue(id)),
    },
    {
      accessorKey: 'billing_medical_insurance',
      header: `Importe ${translations.medical_insurance_abbreviation}`,
      cell: ({ row }: { row: any }) => {
        const { billing_medical_insurance } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            $ {billing_medical_insurance}
          </p>
        );
      },
    },
    {
      accessorKey: 'billing_patient',
      header: 'Importe paciente',
      cell: ({ row }: { row: any }) => {
        const { billing_patient } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            $ {billing_patient}
          </p>
        );
      },
    },
  ];
};
