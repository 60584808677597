import React from 'react';
import { DropdownForm } from '../../../components';

export const SpecialtyDropdown: React.FC<{
  name: string;
  specialties: { [key: string]: any };
  label?: string;
  className?: string;
}> = ({ name, specialties, label, className }) => {
  return (
    <DropdownForm
      name={name}
      data={specialties}
      clearable
      label={label}
      keyId="id_especialidad"
      keyData="descripcion"
      placeholder="Selecciona una especialidad"
      className={className}
    />
  );
};
