import React from 'react';
import { addDays } from 'date-fns';
import {
  TextFieldForm,
  DatePicker,
  DropdownForm,
  Button,
} from '#components/index';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from '#components/shadcn';
import { FinanciersT, RecipeT } from '#interfaces/components/forms';
import { useFieldProps } from '../../../../../hooks';
import { SnackbarContext } from '../../../../../context';
import { OfficesT, PatientT, ProfessionalT } from '#interfaces/general-values';

export const FirstStep: React.FC<{
  data: {
    financiers: FinanciersT[];
    offices: OfficesT[];
    professionals: ProfessionalT[];
    patient: PatientT;
    recipe: RecipeT;
  };
  onClick: () => void;
  resetProgressBar?: () => void;
  viewLoadMedicine?: {
    hiddenLoadMedicine: boolean;
    setHiddenLoadMedicine: React.Dispatch<React.SetStateAction<boolean>>;
  };
  handlerClickProgressBar?: (value: number) => void;
  onSubmit?: (data: FormData) => void;
}> = ({ data: { financiers, offices, professionals }, onClick }) => {
  const { showWarningSnackbar } = React.useContext(SnackbarContext);
  const {
    field: { value: idOffice },
  } = useFieldProps({ name: 'office' });
  const {
    field: { value: idProf },
  } = useFieldProps({ name: 'professional' });
  const {
    field: { value: idFinancier },
  } = useFieldProps({ name: 'id_financier' });
  const {
    field: { value: dateOrderValue },
  } = useFieldProps({
    name: 'date_order',
  });
  const {
    field: { value: affiliateNumber },
  } = useFieldProps({
    name: 'patient.affiliate_number',
  });
  const { setFieldValue: setDateEstRecivedValue } = useFieldProps({
    name: 'date_estimated_received',
  });

  React.useMemo(() => {
    const dateOrder = new Date(dateOrderValue);
    const newDate = addDays(dateOrder, 3);
    setDateEstRecivedValue(newDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateOrderValue]);

  // TODO: Mover esto a un util que sea filters.tsx
  const filteredProf = React.useMemo(() => {
    const profs = professionals.filter((p: any) => {
      if (idOffice) {
        const porfessionalsByOffice = p.prof_horarios.some(
          (prof: { [key: string]: any }) =>
            prof.id_sucursalNavigation?.id_sucursal === idOffice,
        );
        return p.habilitado && porfessionalsByOffice;
      }
      return p.habilitado;
    });
    return profs;
  }, [idOffice, professionals]);
  const professional = React.useMemo(() => {
    return filteredProf?.find((prof: any) => prof.id_prof === idProf);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProf]);

  const actionButtonNext = () => {
    if (professional) {
      const {
        abreviatura,
        profession,
        matricula,
        licenceJurisdiction,
        signature,
        licenceType,
        cuit,
      } = professional;
      const validationJuridiction =
        licenceType === 'MP' ? licenceJurisdiction : true;

      return abreviatura &&
        profession &&
        matricula &&
        licenceType &&
        validationJuridiction &&
        // Remove cuit obligation for recepies,
        // left the line for the future
        // cuit &&
        signature
        ? onClick()
        : showWarningSnackbar(
            'Los datos del profesional son insuficientes para realizar una receta. Complete el perfil en Configuración/Profesionales.',
          );
    }
  };
  const buttonDisableHandler =
    idFinancier !== -1 &&
    !(filteredProf.length && affiliateNumber && affiliateNumber.trim().length);

  return (
    <Card className="mt-5">
      <CardHeader>
        <CardTitle>Datos de la receta</CardTitle>
        <CardDescription>
           Añade los datos de tu obra social y profesional a la receta. Estos se
          guardarán para futuros usos.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid grid-cols-12 gap-5">
        <TextFieldForm
          name="patient.name"
          className="col-span-3"
          label="Nombre y Apellido"
          maxlength={100}
          disabled
        />
        <TextFieldForm
          name="patient.document"
          className="col-span-3"
          label="DNI"
          disabled
          maxlength={100}
        />
        <DropdownForm
          name="id_financier"
          data={financiers}
          className="col-span-3 mt-px"
          label="Obra Social"
          keyId="financierId"
          keyData="name"
          classContainer="h-44"
        />
        <TextFieldForm
          name="type_of_plan"
          className="col-span-3"
          label="Tipo de plan"
          maxlength={100}
          placeholder="Ingrese tipo de plan"
          disabled={idFinancier === -1}
        />
        <TextFieldForm
          name="patient.affiliate_number"
          className="col-span-3"
          label="Nro de afiliado*"
          maxlength={100}
          placeholder="Ingresa nro de afiliado completo"
          disabled={idFinancier === -1}
        />
        {offices?.length > 0 && (
          <DropdownForm
            name="office"
            data={offices}
            className="col-span-3 mt-px"
            label="Sucursal"
            keyId="id_sucursal"
            keyData="sucursal"
            classContainer="h-24"
          />
        )}
        <DatePicker
          className="col-span-3 mt-0.5"
          label="Fecha de receta"
          name="date_recipe"
        />
        <DropdownForm
          name="professional"
          data={filteredProf}
          className="col-span-3 mt-0.5"
          classContainer="h-24"
          label="Profesional"
          keyId="id_prof"
          keyData="nombre"
        />
        <Button
          size="large"
          className="col-start-1"
          onClick={actionButtonNext}
          disabled={buttonDisableHandler}
        >
          Siguiente
        </Button>
      </CardContent>
    </Card>
  );
};
