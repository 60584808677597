import { PlansT } from '#interfaces/mocks';

export const plans: PlansT = {
  freemium: {
    title: 'Gratuito',
    description:
      'Disfrutá de la mejor agenda mensual, odontograma digital, historia clínica onlina para un solo usuario, de forma gratuíta.',
    price: { currency: 'U$D', money: 0 },
    time: ' / mes',
  },
  premium: {
    title: 'Premium',
    description:
      'Potencia tu negocio con la agenda sin límites, gestión de profesionales y la carga ilimitada de pacientes.',
    price: { currency: 'U$D', money: 25 },
    time: ' / mes',
  },
  lender: {
    title: 'Soy prestador',
    description:
      'Si eres prestador de una gerenciadora, asociación o circulo odontológico que utiliza el Auditor de Bilog, puedes activar tu suscripción Prestador.',
    btnText: 'Activar suscripción ',
  },
};
