import React from 'react';
import classNames from 'classnames';
import { Icons } from '../';
import { useFieldProps } from '../../hooks';
import { nameFormatter } from '../../utils';
import { ProgressBarT } from '#interfaces/components';

export const ProgressBar: React.FC<ProgressBarT> = ({
  step: { id, name, status },
  position,
  length,
  canEdit,
  data: { professionals, medicalInsurances, laboratories },
}) => {
  const {
    field: { value: idProf },
  } = useFieldProps({ name: 'id_professional' });
  const {
    field: { value: idMedIns },
  } = useFieldProps({ name: 'id_medical_insurance' });
  const {
    field: { value: idPlan },
  } = useFieldProps({ name: 'id_plan' });
  const {
    field: { value: idLabo },
  } = useFieldProps({ name: 'id_labo' });
  const professionalName =
    idProf && professionals
      ? nameFormatter(professionals?.find((p) => p.id_prof === idProf)?.nombre)
      : '-';

  const laboratoryName =
    idLabo && laboratories
      ? laboratories?.find((l) => l.id_labo === idLabo)?.nombre
      : '-';
  const selectMedIns = medicalInsurances?.find((m) => m.id_os === idMedIns);
  const selectPlan = selectMedIns?.planes?.find(
    (m: any) => m.id_plan === idPlan,
  );
  const medInsName = `${selectMedIns?.obra_social} - ${selectPlan?.nombre}`;
  const showLast = position + 1 !== length;

  const descriptionsSteps = [
    idProf && `Profesional: ${professionalName}`,
    idMedIns && medInsName,
    idLabo && `Laboratorio: ${laboratoryName}`,
  ].filter((item) => item);

  const containerCss = classNames(
    'grid grid-cols-12 py-2 h-full items-center px-4 print:hidden',
    status !== 'upcoming' && canEdit
      ? 'cursor-pointer'
      : 'pointer-events-none grayscale',
  );
  const iconCss = classNames(
    'text-center border-2 rounded-full col-span-2 h-7 w-7',
    status === 'current' && 'border-blue-500 text-blue-500 pb-0.5',
    status === 'upcoming' && 'text-slate-300 border-slate-300',
    status === 'complete' && 'bg-blue-500 text-white pb-0.5 border-blue-500',
  );
  const textCss = classNames(
    'font-bold text-sm col-span-8 lg:scale-100 md:scale-75',
    status === 'current' && 'text-blue-500',
    status === 'upcoming' && 'text-slate-300',
  );

  return (
    <div className={containerCss}>
      <div className={iconCss}>
        {status === 'complete' ? (
          <Icons type="check" />
        ) : (
          <div className="font-extrabold">{id}</div>
        )}
      </div>
      <div className={textCss}>
        <p className="truncate">{name}</p>
        <p className="text-xs">{descriptionsSteps[position]}</p>
      </div>
      {showLast && (
        <div className="rotate-45 col-span-2 h-12 w-12 rounded-tr-lg border-slate-300 border-r border-t" />
      )}
    </div>
  );
};
