import { useContext } from 'react';
import { updateMedInsConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';
import { MedicalInsurancesT } from '#interfaces/general-values';
import { FormikHelpers } from 'formik';

export function useSubmit(
  current: MedicalInsurancesT | string | undefined,
  onChange: () => void,
) {
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async (spec: any, formikActions: FormikHelpers<any>) => {
    try {
      await axiosPromise(spec, current);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Cambio guardado correctamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(
  spec: any,
  current: MedicalInsurancesT | string | undefined,
) {
  const apiSpec = transformSpec(spec, current);

  return updateMedInsConfig(apiSpec);
}

function transformSpec(
  spec: any,
  current: MedicalInsurancesT | string | undefined,
) {
  let apiSpec: {
    id_os: number;
    codigo_os: string;
    obra_social: string;
    domicilio: string;
    localidad: string;
    provincia: string;
    telefono: string;
    fax: string;
    e_mail: string;
    cuit: string;
    observaciones: string;
    cant_prest: number;
    iva_ob: number;
    iva_ad: number;
    cargar_por_periodo: string;
    cliente_ftp: string;
    inhabilitada: boolean;
    opcion: 'nuevo' | 'modificar' | 'eliminar';
    rowversion?: string;
  } = {
    id_os: spec.id,
    codigo_os: spec.code,
    obra_social: spec.name,
    domicilio: spec.address,
    localidad: spec.location,
    provincia: spec.state,
    telefono: spec.phone,
    fax: spec.fax,
    e_mail: spec.email,
    cuit: spec.cuit,
    observaciones: spec.observations,
    cant_prest: spec.benefits_amount,
    iva_ob: spec.iva_ob,
    iva_ad: spec.iva_ad,
    cargar_por_periodo: spec.period_format,
    cliente_ftp: spec.ftp,
    inhabilitada: !spec.enabled,
    opcion: 'nuevo',
  };

  if (typeof current === 'object' && current?.id_os) {
    apiSpec = {
      ...apiSpec,
      rowversion: current.rowversion,
      opcion: 'modificar',
    };
  }

  return apiSpec;
}
