import React from 'react';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { StepConfig } from '../mocks';
import { OnboardingT } from '#interfaces/components';

export const Onboarding: React.FC<OnboardingT> = ({
  show,
  onExit,
  section,
}) => {
  const [enabled, setEnabled] = React.useState(show);
  const { steps, initialStep } = StepConfig[section];

  const options = {
    hideNext: false,
    nextLabel: 'Siguiente',
    prevLabel: 'Atrás',
    doneLabel: 'Listo',
    keyboardNavigation: true,
  };

  function handleOnExit() {
    setEnabled(false);
    onExit();
  }

  return (
    <Steps
      enabled={enabled}
      steps={steps}
      initialStep={initialStep}
      onExit={handleOnExit}
      options={options}
    />
  );
};
