import React from 'react';
import { Formik } from 'formik';
import { Message } from './Message';
import { useSubmit } from './useSubmit';
import { useForm } from './useForm';
import { messagesListReducer } from '../../../../utils';
import {
  EmptyState,
  Loading,
  DatePicker,
  DropdownForm,
  Form,
} from '../../../../components';
import { ReminderListT } from '#interfaces/components';

const dropdown = [
  { id: 'buscarwapps', text: 'Todos' },
  { id: 'buscarwappsnoprocesados', text: 'No respondidos' },
  { id: 'buscarwappsprocesados', text: 'Respondidos' },
];

export const RemindersList: React.FC<ReminderListT> = ({
  onChange,
  list,
  setAssistance,
}) => {
  const { initialValues } = useForm();
  const dateFieldName = 'date';
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  return (
    <Formik initialValues={initialValues} onSubmit={useSubmit(onChange)}>
      {({ isSubmitting }) => (
        <Form className="reminders-form" showActions={false} autoSubmit>
          {isSubmitting && <Loading />}
          <div data-cols="4" className="reminders-form__actions">
            <div className="reminders-form__date-picker">
              <DatePicker
                label="Fecha"
                maxDate={tomorrow}
                name={dateFieldName}
                fullWidth
              />
            </div>
            <div className="offices-dropdown">
              <DropdownForm
                name="status"
                data={dropdown}
                label="Estado"
                keyId="id"
                keyData="text"
              />
            </div>
          </div>
          {list.length ? (
            messagesListReducer(list).map((m, i) => (
              <Message
                message={m}
                key={i}
                dateFieldName={dateFieldName}
                setAssistance={setAssistance}
              />
            ))
          ) : (
            <EmptyState title="Ups!" copy="No hay mensajes para mostrar." />
          )}
        </Form>
      )}
    </Formik>
  );
};
