import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { deleteBenefit } from '../../../../services';
import { SnackbarContext, FormContext } from '../../../../context';
import { startLoading, stopLoading } from '../../../../redux/slices';

export function useSubmit({
  benefit,
  onChange,
}: {
  benefit: { [key: string]: any };
  onChange: () => void;
}) {
  const dispatch = useDispatch();
  const { closeForm } = useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    useContext(SnackbarContext);

  return async () => {
    try {
      dispatch(startLoading());
      await axiosPromise({ benefit });
      onChange();
      closeForm();
      showSuccessSnackbar('La prestación se ha eliminado con éxito');
    } catch (err: any) {
      showErrorSnackbar(err.message);
    } finally {
      dispatch(stopLoading());
    }
  };
}

function axiosPromise({ benefit }: { [key: string]: any }) {
  return deleteBenefit(benefit);
}
