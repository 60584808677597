import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { saveTurn } from '../../services';
import { setArrivalHour } from '../../utils';
import { AppointmentT } from '#interfaces/general-values';

const initialState = {
  appointment: {},
  changeLayout: false,
  current: null, // Esto por ahora es null por la validacion en el useForm
  forceRefresh: false,
  findNextAvailableAppointment: false,
  refreshSchedule: null,
  schedule: [],
  emptyMessage: 'No se encontraron turnos disponibles.',
  dataPatientNextView: null,
};

export const saveAttendance = createAsyncThunk(
  'schedule/saveAttendance',
  async ({
    appointment,
    userId,
    date,
    attendanceId,
  }: {
    appointment: AppointmentT;
    userId: number;
    date: Date | string;
    attendanceId: string | null;
  }) => {
    const body = {
      id_prof: appointment.id_professional,
      id_usuario: userId,
      id_paciente: appointment.id_patient,
      id_sucursal: appointment.id_office,
      id_agenda: appointment.id_appointment,
      opcion: 'modificarAsistencia',
      hora: appointment.time,
      fecha_dado: new Date().toISOString(),
      fecha: appointment?.date ?? new Date(date).toISOString(),
      obs: appointment.observation,
      es_sobreturno: appointment.is_overturn,
      no_dar: false,
      asistencia: attendanceId !== 'DEL' ? attendanceId : null,
      paciente: !appointment.id_patient ? appointment.patient_name : null,
      telefono: appointment.patient_phone,
      obra_social: appointment.medical_insurance.name,
      plan_nombre: appointment.medical_insurance.plan,
      nro_afil: appointment.medical_insurance.affiliate_number,
      hora_llegada: setArrivalHour(attendanceId),
      rowversion: appointment.rowversion,
    };

    try {
      await saveTurn(body);
    } catch (err: any) {
      throw new Error(err);
    }
  },
);

export const scheduleSlice = createSlice({
  name: 'scheduleSlice',
  initialState,
  reducers: {
    setCurrentParams: (state, action) => {
      state.current = {
        ...action.payload,
      };
    },
    setAppointment: (state, action) => {
      state.appointment = action.payload;
    },
    setSchedule: (state, action) => {
      state.schedule = action.payload;
    },
    setScheduleEmptyError: (state, action) => {
      state.emptyMessage = action.payload;
    },
    resetSchedule: (state) => {
      state.schedule = [];
    },
    setForceRefresh: (state, action) => {
      state.forceRefresh = action.payload;
    },
    setFindNextAvailableAppointment: (state, action) => {
      state.findNextAvailableAppointment = action.payload;
    },
    setRefreshSchedule: (state, action) => {
      const { forceRefresh, findNextAvailableAppointment, refresh } =
        action.payload;

      state.forceRefresh = forceRefresh;
      state.findNextAvailableAppointment = findNextAvailableAppointment;
      state.refreshSchedule = refresh;
    },
    setDataPatientNextView: (state, action) => {
      state.dataPatientNextView = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveAttendance.rejected, (_state, action) => {
      throw new Error(action.error.message);
    });
  },
});

export const {
  setAppointment,
  setCurrentParams,
  setSchedule,
  setScheduleEmptyError,
  resetSchedule,
  setForceRefresh,
  setFindNextAvailableAppointment,
  setRefreshSchedule,
  setDataPatientNextView,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
