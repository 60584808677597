import React from 'react';

export function useForm(absence: { [key: string]: any }) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues(absence),
  );
  const validate = React.useCallback((values: any) => {
    const errors: { [key: string]: any } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['from', 'to'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  React.useEffect(() => {
    setInitialValues(getInitialValues(absence));
  }, [absence]);

  return { initialValues, validate };
}

function getInitialValues(absence: { [key: string]: any }) {
  const { rowversion } = absence;
  let defaultDay = new Date().getDay();
  defaultDay = defaultDay === 0 ? 7 : defaultDay;

  return {
    id: absence?.id || null,
    day_num: absence?.day_num || defaultDay,
    from: absence?.from || new Date(),
    to: absence?.to || new Date(),
    rowversion,
  };
}
