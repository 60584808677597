import React from 'react';
import Archive from './archive';
import { ArchivesPreviewT } from '#interfaces/components';

export const ArchivesPreview: React.FC<ArchivesPreviewT> = ({
  archives,
  selectedHandler,
  editHandler,
  deleteHandler,
}) => {
  return (
    <section className="grid grid-cols-4 gap-5 mb-10">
      {archives.map((a: any, i: number) => (
        <Archive
          archive={a}
          index={i}
          onChange={selectedHandler}
          onEdit={editHandler}
          onDelete={deleteHandler}
        />
      ))}
    </section>
  );
};
