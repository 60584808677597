import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Button, Title } from '../';
import { useKeypress } from '../../hooks';
import { SuccesfullT } from '#interfaces/components';

export const SuccesFull: React.FC<SuccesfullT> = ({
  closeCard = false,
  typeSucces,
  dataMocks,
  onClick,
  className,
}) => {
  const containerCss = classNames(
    'w-full grid grid-cols-12 items-center mx-auto max-w-7xl my-0 gap-10',
    className,
  );
  const navigate = useNavigate();
  const backAction = () => {
    if (closeCard) {
      navigate('/home');
    }
  };
  useKeypress({ key: 'Escape', action: backAction });

  const handlerClick =
    onClick ||
    (() =>
      dataMocks[typeSucces]?.route &&
      navigate(dataMocks[typeSucces]?.route || '/'));

  return (
    <div className={containerCss}>
      <div className="col-start-3 col-span-3">
        <Title className="font-bold text-2xl mb-5">
          {dataMocks[typeSucces]?.title}
        </Title>
        <p className="text-base text-slate-500">
          {dataMocks[typeSucces]?.description}
        </p>
        {dataMocks[typeSucces]?.button && (
          <div className="pt-4">
            <Button onClick={handlerClick}>
              {dataMocks[typeSucces]?.button}
            </Button>
          </div>
        )}
      </div>
      <div className="col-span-6">
        <img
          src={dataMocks[typeSucces]?.img}
          width={315}
          height={315}
          alt="Logo Succes"
        />
      </div>
    </div>
  );
};
