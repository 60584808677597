import React from 'react';
import classNames from 'classnames';
import { MedicinesPatient } from '#components/Tables';
import { Button, Copy, Icons, Title } from '#components/commons';
import { tableMedicinesMapper } from '../../../../../utils';
import { RecipeT } from '#interfaces/components/forms';

export const SummarySteps = ({
  data,
  handlerClickProgressBar,
}: {
  data: {
    [key: string]: {
      viewLoadMedicine: {
        hiddenLoadMedicine: boolean;
        setHiddenLoadMedicine: React.Dispatch<React.SetStateAction<boolean>>;
      };
      medicineList: {
        productName: string;
        presentation: string;
        quantity: number;
        diagnosis: string;
        dosage: string;
        prolonged_treatment: number;
        id_medicine_recipe: string;
        drugName: string;
        observations: string;
      }[];
      setListMedicineValue: (value: any) => void;
      setMedicine: (value: any) => void;
      diagnosisGeneral: { description: string; code: string };
      userName: string;
      recipe: RecipeT;
    };
  };

  handlerClickProgressBar: (value: number) => void;
}) => {
  const {
    viewLoadMedicine: { hiddenLoadMedicine, setHiddenLoadMedicine },
    medicineList,
    setListMedicineValue,
    setMedicine,
    diagnosisGeneral,
    userName,
    recipe,
  } = data['summarySteps'];

  const actions: any = {
    edit: (selectedRow: { [key: string]: any }) => {
      const medicineSelected = medicineList?.find(
        (medicine: any) =>
          medicine.id_medicine_recipe === selectedRow.id_medicine_recipe,
      );
      handlerClickProgressBar(1);
      setMedicine(medicineSelected);
      setHiddenLoadMedicine(!hiddenLoadMedicine);
    },
    delete: (selectedRow: { [key: string]: any }) => {
      const filterMedicines = medicineList?.filter(
        (medicine: any) =>
          medicine.id_medicine_recipe !== selectedRow.id_medicine_recipe,
      );

      setListMedicineValue(filterMedicines);
    },
  };
  const action = (medicine: { [key: string]: string }, action: string) => {
    actions[action](medicine);
  };

  const cardsData = [
    {
      title: 'Lista de medicamentos',
      isTable: true,
      className: 'text-2xl truncate mb-5 print:mt-5',
      Content: () => (
        <MedicinesPatient
          data={tableMedicinesMapper(medicineList).reverse()}
          action={action}
          recipe={recipe}
        />
      ),
      tabIndex: 1,
      stylesContainer:
        'flex flex-col justify-between mb-5 print:shadow-md print:rounded-md',
      stylesHeader: 'flex justify-between print:ml-4',
    },
    {
      title: 'Datos adicionales',
      className:
        'border-b border-b-slate-300 p-5 text-2xl rounded-t-md bg-white truncate mb-4',
      content: [
        {
          label: 'Diagnostico general:',
          value: diagnosisGeneral?.description || '-',
        },
        {
          label: 'Emitido por:',
          value: userName,
        },
      ],
      tabIndex: 2,
      stylesContainer:
        'bg-white flex flex-col justify-between rounded-md shadow-md mb-5',
    },
  ];

  return (
    <div className="mt-6 mb-5">
      {cardsData.map((Item: any, index: number) => (
        <div key={index} className={Item.stylesContainer}>
          <div className={Item.stylesHeader || ''}>
            <Title className={Item.className}>{Item.title}</Title>
            {Item.isTable && (
              <Button
                size="large"
                modifier="transparent"
                onClick={() => {
                  handlerClickProgressBar(Item.tabIndex);
                  setHiddenLoadMedicine(false);
                }}
                disabled={!!recipe || medicineList?.length === 3}
              >
                Cargar medicamento
              </Button>
            )}
          </div>
          <div className="flex">
            {!Item.isTable ? (
              Item.content.map((content: any, i: number) => (
                <div key={i} className="flex px-5">
                  <Copy>{content.label}</Copy>
                  <Copy className="ml-2 !text-slate-900">{content.value}</Copy>
                </div>
              ))
            ) : (
              <Item.Content />
            )}
          </div>
          {!Item.isTable && (
            <p
              className={classNames(
                'group flex text-base print:hidden text-blue-500 justify-between p-4 px-8 border-t border-t-slate-300 hover:cursor-pointer',
                {
                  'text-gray-400 cursor-not-allowed pointer-events-none':
                    recipe,
                },
              )}
              onClick={() => handlerClickProgressBar(Item.tabIndex)}
            >
              Editar
              <Icons
                type="arrow_right"
                className={classNames(
                  'h-5 text-sm text-blue-500 transition-transform duration-500 group-hover:translate-x-3',
                  {
                    'text-gray-400 cursor-not-allowed': recipe,
                  },
                )}
              />
            </p>
          )}
        </div>
      ))}
    </div>
  );
};
