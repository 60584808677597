import React from 'react';
import { useDispatch } from 'react-redux';
import { ParamsT, ProfessionalT } from '#interfaces/general-values';
import { FinanciersT } from '#interfaces/components/forms';
import { newRecipe } from '../../../../services';
import { startLoading, stopLoading } from '../../../../redux/slices';
import { SnackbarContext, FormContext } from '../../../../context';
import { formatRecipeDate, SplitNameFormatter } from '../../../../utils';

export const useSubmit = (
  refresh: () => void,
  professionals: ProfessionalT[],
  financiers: FinanciersT[],
  params: ParamsT,
) => {
  const dispatch = useDispatch();
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (spec: { [key: string]: any }) => {
    try {
      dispatch(startLoading());
      await axiosPromise({ spec, professionals, financiers, params });
      closeForm();
      showSuccessSnackbar('Receta creada correctamente');
      refresh();
    } catch (err: any) {
      showErrorSnackbar(err.message);
    } finally {
      dispatch(stopLoading());
    }
  };
};
const axiosPromise = ({
  spec,
  professionals,
  financiers,
  params,
}: {
  spec: { [key: string]: any };
  professionals: ProfessionalT[];
  financiers: FinanciersT[];
  params: ParamsT;
}) => {
  const apiSpec = transformSpec({ spec, professionals, financiers, params });
  return newRecipe(apiSpec);
};

const transformSpec = ({
  spec,
  professionals,
  financiers,
  params,
}: {
  spec: any;
  professionals: ProfessionalT[];
  financiers: FinanciersT[];
  params: ParamsT;
}) => {
  const medicines = spec.medicines_list?.map((m: { [key: string]: any }) => {
    let medicine = {
      productName: m.productName,
      drugName: m.drugName,
      presentation: m.presentation,
      registerNumber: m.registerNumber,
      quantity: m.quantity,
      allowSubstitution: m.not_replace ? 'S' : 'N',
      treatment: m.prolonged_treatment ? 1 : 0,
      diagnosis: m.diagnosis.description,
      diagnosisCode: spec.is_diagnosis_manual ? null : m.diagnosis.code,
      dosage: m.dosage,
      observations: m.observations,
    };
    return medicine;
  });
  const professional =
    professionals?.find(
      (p: { [key: string]: any }) => p.id_prof === spec.professional,
    ) || professionals?.[0];
  const filterFinancier =
    spec.id_financier !== -1
      ? financiers?.find((f: any) => f.financierId === spec.id_financier)
      : null;
  const nameProfParts = professional?.nombre.split(' ');
  const { firstName, lastName } = SplitNameFormatter(nameProfParts);

  let apiSpec = {
    apiData: {
      patient: {
        lastName: spec.patient.name,
        firstName: spec.patient.last_name,
        documentType: spec.patient.document_type,
        document: spec.patient.document,
        sex: spec.patient.sexo,
        birthday: new Date(spec.patient.date_birth),
        email: spec.patient.email,
        phoneNumber: spec.patient.phone || '',
        country: '',
        coverage: {
          financierNumber: filterFinancier?.number || '',
          plan: spec.type_of_plan,
          affiliateNumber: spec.patient.affiliate_number,
          isPrivate: !filterFinancier,
          insuredDocument: spec.patient.document,
        },
        locality: spec.patient.locality,
        province: spec.patient.province,
      },
      professional: {
        lastName: firstName,
        firstName: lastName,
        documentType: '',
        document: '',
        sex: professional?.abreviatura === 'Dra.' ? 'F' : 'M',
        birthday: new Date(),
        taxCode: professional?.cuit || '',
        email: '',
        phoneNumber: '',
        country: '',
        speciality: professional?.profession,
        signatureLink: '',
        signatureBase64: professional?.signature,
        license: {
          type: professional?.licenceType,
          number: professional?.matricula,
          province: professional?.licenceJurisdiction,
        },
      },
      medications: medicines,
      diagnosis: spec.diagnosis_general.description,
      appointmentHour: '',
      appointmentDay: '',
      contactInformation: '',
      clinicName: params.nombre,
      clinicAddress: params.direccion,
      email: '',
      additionalInformation: '',
      patientSol: 0,
      clienteId: 0,
      printDiagnosis: 'Sí',
      issueDate: formatRecipeDate(spec.date_recipe),
      legend: '',
      prescriber: spec.prescriber,
    },
    data: {
      patientId: spec.patient?.id_patient,
      professionalId: professional.id_prof,
      medicalInsuranceId: spec.patient.medical_insurance_id,
      financierId: filterFinancier?.financierId || null,
    },
  };

  return apiSpec;
};
