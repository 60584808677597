import { useState } from 'react';

export function useForm() {
  const [initialValues] = useState(() => getInitialValues());

  return { initialValues };
}

function getInitialValues() {
  return {
    date: new Date(),
    status: 'buscarwappsnoprocesados',
  };
}
