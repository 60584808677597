import React from 'react';
import classNames from 'classnames';
import { Card, Title, Copy, Switch } from '../../../../../../components';
import { useFieldProps } from '../../../../../../hooks';
import { userPermissionsT } from '#interfaces/components';

export const UserPermission: React.FC<userPermissionsT> = ({
  permissions,
  permissionsFieldName,
}) => {
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name: permissionsFieldName });
  const {
    field: { value: isSupervisor },
  } = useFieldProps({ name: 'supervisor' });

  const handleOnChange = (id: string) => {
    const arr = value;
    if (!arr.includes(id)) {
      arr.push(id);
      setFieldValue(arr);
    } else if (arr.includes(id)) {
      const index = arr.indexOf(id);
      arr.splice(index, 1);
      setFieldValue(arr);
    }
  };

  return (
    <div
      className={`grid grid-cols-3 gap-4 p-5 ${
        isSupervisor ? ' disabled' : ''
      }`}
    >
      {permissions?.map((p: { [key: string]: any }, i: number) => {
        const { descripcion, opcion, id_proceso } = p;
        const checked = value?.find(
          (v: { [key: string]: any }) => v === id_proceso,
        );
        const cardCss = classNames(
          'border-2 shadow-none',
          checked ? 'on border-blue-500' : 'border-gray-200',
        );

        return (
          <Card key={i} className={cardCss}>
            <Title className="text-lg">{opcion}</Title>
            <Copy>{descripcion}</Copy>
            <Switch
              className="switch"
              checked={checked}
              disabled={isSupervisor}
              onChange={() => handleOnChange(id_proceso)}
            />
          </Card>
        );
      })}
    </div>
  );
};
