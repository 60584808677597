import React from 'react';
import { DropdownForm, TextFieldForm } from '../../../../../components';
import { useFieldProps } from '../../../../../hooks';
import { CardsT } from '#interfaces/general-values';

const DebitCardMethod: React.FC<{ cards: CardsT[] }> = ({ cards }) => {
  const {
    field: { value: cardId },
    setFieldValue: setCardIdValue,
  } = useFieldProps({ name: 'id_card' });

  React.useEffect(() => {
    if (!cardId && cards.length > 0) {
      setCardIdValue(cards[0].id_tarjeta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DropdownForm
        name="cards"
        data={cards}
        className="col-span-3"
        label="Tarjetas de débito"
        keyId="id_tarjeta"
        keyData="tarjeta"
      />
      <TextFieldForm
        name="mov_cards.card_number"
        label="Nro. Tarjeta"
        className="col-span-3"
        placeholder="0000 0000 0000 0000"
        maxlength={16}
      />
      <TextFieldForm
        name="mov_cards.card_number_auth"
        label="Nro. Autorización"
        className="col-span-3"
        placeholder=""
        maxlength={20}
      />
    </>
  );
};

export default DebitCardMethod;
