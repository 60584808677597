import React from 'react';
import { Columns } from './columns';
import { Data } from './data';
import { tablePatientResultMapper } from '../../../utils';
import { PatientT, TranslationsT } from '#interfaces/general-values';

export const Table: React.FC<{
  data: PatientT[];
  onClick: (value: number) => void;
  translations: TranslationsT;
  sorting: any;
  setSorting: any;
  height: string;
}> = ({ data, onClick, translations, sorting, setSorting, height }) => {
  return (
    <div className="mx-auto w-full bg-white">
      <Data
        onClick={onClick}
        columns={Columns({ translations })}
        data={tablePatientResultMapper(data)}
        sorting={sorting}
        setSorting={setSorting}
        height={height}
      />
    </div>
  );
};
