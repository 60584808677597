import { axiosInstance, platform } from '../utils';

export const newLab = (data: { [key: string]: any }) => {
  const body = {
    laboratory: {
      ...data,
    },
    plataforma: platform,
  };
  return axiosInstance.post('/laboratories/new', body);
};

export const updateLab = (data: { [key: string]: any }) => {
  const body = {
    laboratory: {
      ...data,
    },
    plataforma: platform,
  };
  return axiosInstance.post('/laboratories/update', body);
};

export const deleteLab = (data: { [key: string]: any }) => {
  const body = {
    laboratory: {
      ...data,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/laboratories/delete', body);
};
export const newArticle = (data: { [key: string]: any }) => {
  const body = {
    laboratoryItem: {
      ...data,
    },
    plataforma: platform,
  };

  return axiosInstance.post('laboratories/item/new', body);
};

export const updateArticle = (data: { [key: string]: any }) => {
  const body = {
    laboratoryItem: {
      ...data,
    },
    plataforma: platform,
  };

  return axiosInstance.post('laboratories/item/update', body);
};
export const copyArticles = (data: { [key: string]: any }) => {
  const body = {
    CopyItems: {
      ...data,
    },
    plataforma: platform,
  };

  return axiosInstance.post('laboratories/item/copy', body);
};

export const deleteArticle = (data: { [key: string]: any }) => {
  const body = {
    laboratoryItem: {
      ...data,
    },
    plataforma: platform,
  };

  return axiosInstance.post('laboratories/item/delete', body);
};

export const getConcepts = (labId: number) => {
  return axiosInstance.get(`/laboratories/concepts/${labId}`);
};
