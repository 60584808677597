import React from 'react';
import { WithIcon } from '../../../../components';

export const WhatsappList: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <div className="mr-2">
      <WithIcon
        className="h-6 w-6"
        onClick={onClick}
        text="Ver mensajes enviados"
        type="email_list"
      />
    </div>
  );
};
