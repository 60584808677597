import React from 'react';
import { Card, Button, Icons } from '../../../../../../components';
import { useFieldProps } from '../../../../../../hooks';
import { ActionPermissionsFormT } from '#interfaces/components';

export const ActionsPermissions: React.FC<ActionPermissionsFormT> = ({
  openAutoTab,
  openManualTab,
  closeManualTab,
  closeAutoTab,
  profToCopyFieldName,
  usersToCopy,
  isOpenManualTab,
  supervisorFieldName,
}) => {
  const [selectedProf, setSelectedProf] = React.useState<any>(null);
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name: profToCopyFieldName });
  const {
    field: { value: isSupervisor },
  } = useFieldProps({ name: supervisorFieldName });

  React.useEffect(() => {
    setFieldValue(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function deleteSelection() {
    setFieldValue(null);
  }

  React.useEffect(() => {
    if (value) {
      const prof: { [key: string]: string }[] = usersToCopy.filter(
        (u: { [key: string]: string }) => u.id === value,
      );
      return setSelectedProf(prof);
    }
    setSelectedProf(null);
  }, [usersToCopy, value]);

  const disabledClass = 'opacity-50 cursor-not-allowed pointer-events-none';

  const CardItem = ({ children }: { children: React.ReactNode }) => (
    <p className="text-base text-slate-600 mt-2">{children}</p>
  );

  const Actions = () => (
    <div className={`element ${isSupervisor && disabledClass}`}>
      <div className="grid grid-cols-2 gap-10 pb-8">
        <div className="item">
          <Card
            action={{ text: 'Modificar', type: 'edit' }}
            onClick={() => {
              openAutoTab();
              closeManualTab();
            }}
            disabled={!usersToCopy.length}
          >
            <div className="item-subtitle">
              <Icons type="copy_automatic" className="w-8 h-8 text-blue-500" />
              <h3 className="text">Copia automática</h3>
            </div>
            <CardItem>
              Con la copia automática vas a poder copiar permisos de otro
              usuario, a este nuevo usuario.
            </CardItem>
          </Card>
        </div>
        <div className="item">
          <Card
            action={{ text: 'Modificar', type: 'edit' }}
            onClick={() => {
              openManualTab();
              closeAutoTab();
            }}
          >
            <div className="item-subtitle">
              <Icons type="copy_manual" className="w-8 h-8 text-blue-500" />
              <h3 className="text">Selección manual</h3>
            </div>
            <CardItem>
              Seleccioná manualmente en el listado los permisos que querés
              otorgar a este usuario.
            </CardItem>
          </Card>
        </div>
      </div>
    </div>
  );

  if (isOpenManualTab) {
    return null;
  } else if (!value) {
    return <Actions />;
  } else if (value && !isOpenManualTab) {
    return (
      <Card className="copy-card">
        <Icons type="warning" fill="#f59e0b" />
        <h4 className="copy-card-title">
          Se copiaran los permisos del profesional seleccionado, en caso de no
          quererlo, podés eliminar la selección.
        </h4>
        <p className="copy-card-text">
          Profesional seleccionado: <span>{selectedProf?.name || ''}</span>
        </p>
        <Button size="small" modifier="warning" onClick={deleteSelection}>
          Eliminar selección
        </Button>
      </Card>
    );
  }
};
