import React from 'react';
import { SideScrollingCards } from '../SideScrollingCards';
import { CardHeader } from '../card-header';
import { CardSummary } from '../card-steps';
import { Title, Copy, Button } from '../../../../../components';
import { useFieldProps } from '../../../../../hooks';
import {
  nameFormatter,
  updateTotalAmount,
  calculateBudgetNewBalance,
} from '../../../../../utils';
import { FourthStepT } from '#interfaces/components';

export const FourthStep: React.FC<FourthStepT> = ({
  data: { professionals, medicalInsurances, benefit, taxes, budget },
  onSubmit,
  setTabIndex,
  hiddenView,
  resetProgressBar,
  onClick,
  viewLoadBenefit,
  canEdit,
}) => {
  const {
    field: { value: idProf },
  } = useFieldProps({ name: 'id_professional' });
  const { setFieldValue: setTotalAmount } = useFieldProps({
    name: 'budget_total_amount',
  });
  const {
    field: { value: prevTotalValue },
  } = useFieldProps({ name: 'previous_budget_total_amount' });
  const {
    field: { value: balanceValue },
    setFieldValue: setBalance,
  } = useFieldProps({ name: 'budget_balance' });
  const {
    field: { value: listBenefitsValue },
  } = useFieldProps({ name: 'benefits_list' });
  const {
    field: { value: totalValueBenefits },
  } = useFieldProps({ name: 'benefits_list_total_amount' });
  const {
    field: { value: description },
  } = useFieldProps({ name: 'description' });
  const {
    field: {
      value: { percentage, percentage_value, amount_value },
    },
  } = useFieldProps({ name: 'discount' });
  const {
    field: { value: interestRateValue },
  } = useFieldProps({ name: 'interest_rate' });
  const {
    field: { value: iva },
  } = useFieldProps({ name: 'iva' });

  const professionalName =
    idProf && professionals.length > 0
      ? nameFormatter(professionals?.find((p) => p.id_prof === idProf)?.nombre)
      : '-';

  React.useEffect(() => {
    if (!listBenefitsValue.length) {
      resetProgressBar();
      onClick();
      setTabIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listBenefitsValue, totalValueBenefits]);

  React.useMemo(() => {
    const IVAAmount =
      taxes?.find((t: { [key: string]: any }) => t.id === iva)?.tax || 0;
    const total = updateTotalAmount({
      totalValueBenefits,
      IVAAmount:
        typeof IVAAmount === 'string' ? parseFloat(IVAAmount) : IVAAmount,
      percentage,
      percentage_value,
      amount_value,
      interestRateValue,
    });
    const newBalance = calculateBudgetNewBalance({
      total,
      previousTotal: prevTotalValue,
      balance: balanceValue,
    });

    setTotalAmount(total);
    setBalance(newBalance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalValueBenefits]);

  return (
    <>
      <Title className="text-lg mt-6">Resumen del presupuesto cargado</Title>
      <Copy>Revisa y edita la información del presupuesto.</Copy>
      <CardHeader professionalName={professionalName} />
      <SideScrollingCards
        setTabIndex={setTabIndex}
        hiddenView={hiddenView}
        benefit={benefit}
        viewLoadBenefit={viewLoadBenefit}
        budget={budget}
      />
      <div className="grid grid-cols-12 mt-4 gap-5">
        <CardSummary
          data={{ description, professionalName, medicalInsurances, taxes }}
          setTabIndex={setTabIndex}
          canEdit={canEdit}
        />
      </div>
      <div className="flex justify-start mt-12 mb-5">
        <Button onClick={onSubmit}>Guardar Presupuesto</Button>
      </div>
    </>
  );
};
