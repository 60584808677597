import { useEffect } from 'react';

export function useLockScreen(loading: boolean) {
  const body = document.getElementsByTagName('body')[0];

  useEffect(() => {
    if (loading) body.style.overflow = 'hidden';

    return () => {
      body.style.overflow = 'scroll';
    };
  }, [loading, body]);
}
