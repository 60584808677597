import React from 'react';
import { Formik } from 'formik';
import * as Tabs from '@radix-ui/react-tabs';
import { UserPassword } from './user-pass';
import { UserPermission } from './user-permission';
import { UserOffice } from './user-office';
import { IsDentis } from './user-is-dentis';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { CopyPermission } from './copy-permission';
import { ActionsPermissions } from './actions-permission';
import {
  CheckboxForm,
  Modal,
  TabSection,
  TextFieldForm,
  Form,
} from '../../../../../../components';
import { useDemoName, useForm as useTab } from '../../../../../../hooks';
import { renderTabs } from '../../../../../../utils';
import { UserFormT } from '#interfaces/components';

export const UserForm: React.FC<UserFormT> = ({
  userId,
  userName,
  user,
  usersToCopy,
  useUsd,
  mappedPermissions,
  permissions,
  professionals,
  offices,
  planVersion,
  onChange,
}) => {
  const { initialValues, validate } = useForm({
    current: user,
    loggedUserId: userId,
  });

  const {
    isOpenForm: isOpenManualTab,
    closeForm: closeManualTab,
    openForm: openManualTab,
  } = useTab();
  const {
    isOpenForm: isOpenAutoTab,
    closeForm: closeAutoTab,
    openForm: openAutoTab,
  } = useTab();
  const { isDemo } = useDemoName(userName);
  const supervisorFieldName = 'supervisor';
  const profToCopyFieldName = 'id_prof_to_copy';
  const permissionsFieldName = 'permissions';
  const isFreemium = planVersion === 'freemium';

  React.useEffect(() => {
    if (user && user.permissions?.length > 0) {
      openManualTab();
    }
  }, [openManualTab, user]);

  const tabs = () => {
    const tabsArr = [
      {
        text: 'Inicio',
        value: 'start',
      },
      {
        text: 'Administración',
        value: 'admin',
      },
      {
        text: 'Agenda de turnos',
        value: 'schedule',
      },
      {
        text: 'Informes',
        value: 'reports',
      },
      {
        text: 'Pacientes',
        value: 'patients',
      },
      {
        text: 'Sistema',
        value: 'system',
      },
      {
        text: 'Tablas',
        value: 'tables',
      },
    ];

    if (mappedPermissions['socios']) {
      tabsArr.splice(6, 0, {
        text: 'Socios',
        value: 'partners',
      });
    }
    return tabsArr;
  };
  const openTab = () => {
    if (user?.name === 'Demo') {
      return false;
    }
    return isOpenManualTab;
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      validateOnChange
      onSubmit={useSubmit({ permissions, isDemo, onChange })}
      enableReinitialize
    >
      <Form className="overflow-hidden overflow-y-scroll px-3" ignoreDirty>
        <h4 className="mt-2 text-base font-semibold">Datos del usuario</h4>
        <p className="mb-4 text-base font-regular text-neutral-500">
          En esta sección vas a encontrar toda la configuración básica del
          usuario.
        </p>
        <div className="grid grid-cols-4 gap-10">
          <TextFieldForm label="Nombre" name="name" className="small" />
          <TextFieldForm
            disabled={!!user?.name}
            label="Usuario"
            name="user"
            className="small"
          />
          <CheckboxForm
            label="Es supervisor"
            description="Un usuario supervisor cuenta con todos los permisos."
            name={supervisorFieldName}
            showError={false}
            disabled={isFreemium}
          />
          <CheckboxForm
            label="Habilitado"
            description="Indica si este usuario puede acceder o no a la aplicación."
            name="enabled"
            showError={false}
          />
        </div>
        <div className="grid grid-cols-4 gap-10 my-8">
          <UserPassword />
        </div>
        <div className="grid grid-cols-4 gap-10 my-8">
          <IsDentis
            data={professionals}
            supervisorFieldName={supervisorFieldName}
          />
        </div>
        <div className="grid grid-cols-4 gap-10 my-8">
          {useUsd && (
            <CheckboxForm
              label="Trabaja con doble moneda"
              description="Este usuario cargar pagos y prestaciones en USD."
              name="use_usd"
              showError={false}
            />
          )}
          {offices?.length > 0 && (
            <UserOffice
              data={offices}
              supervisorFieldName={supervisorFieldName}
            />
          )}
        </div>
        <span className="h-px my-9 w-full bg-gray-300 block" />
        <div className="pb-8">
          <h4 className="mt-2 text-base font-semibold">Permisos</h4>
          <p className="mb-4 text-base font-regular text-neutral-500">
            Definí los permisos que va a tener este usuario o copiá los permisos
            de otro usuario.
          </p>
          <ActionsPermissions
            openAutoTab={openAutoTab}
            openManualTab={openManualTab}
            closeManualTab={closeManualTab}
            closeAutoTab={closeAutoTab}
            profToCopyFieldName={profToCopyFieldName}
            supervisorFieldName={supervisorFieldName}
            isOpenManualTab={openTab()}
            usersToCopy={usersToCopy}
          />
        </div>
        <TabSection
          title="Selección manual"
          isOpen={openTab()}
          close={closeManualTab}
          open={openManualTab}
        >
          <Tabs.Root
            defaultValue="start"
            className="flex flex-col mb-5 shadow-md bg-white rounded-tl-md rounded-tr-md"
          >
            <Tabs.List className="shrink-0 flex border-b border-slate-300">
              {renderTabs(tabs(), 'flex-1 flex')}
            </Tabs.List>
            <Tabs.Content value="start">
              <UserPermission
                permissionsFieldName={permissionsFieldName}
                permissions={mappedPermissions.tablas}
              />
            </Tabs.Content>
            <Tabs.Content value="admin">
              <UserPermission
                permissionsFieldName={permissionsFieldName}
                permissions={mappedPermissions.administracion}
              />
            </Tabs.Content>
            <Tabs.Content value="schedule">
              <UserPermission
                permissionsFieldName={permissionsFieldName}
                permissions={mappedPermissions.agendaturnos}
              />
            </Tabs.Content>
            <Tabs.Content value="reports">
              <UserPermission
                permissionsFieldName={permissionsFieldName}
                permissions={mappedPermissions.informes}
              />
            </Tabs.Content>
            <Tabs.Content value="patients">
              <UserPermission
                permissionsFieldName={permissionsFieldName}
                permissions={mappedPermissions.pacientes}
              />
            </Tabs.Content>
            <Tabs.Content value="system">
              <UserPermission
                permissionsFieldName={permissionsFieldName}
                permissions={mappedPermissions.sistema}
              />
            </Tabs.Content>
            {mappedPermissions['socios'] && (
              <Tabs.Content value="partners">
                <UserPermission
                  permissionsFieldName={permissionsFieldName}
                  permissions={mappedPermissions.socios}
                />
              </Tabs.Content>
            )}
            <Tabs.Content value="tables">
              <UserPermission
                permissionsFieldName={permissionsFieldName}
                permissions={mappedPermissions.tablas}
              />
            </Tabs.Content>
          </Tabs.Root>
        </TabSection>
        <Modal
          title="Copia automática"
          isOpenModal={isOpenAutoTab}
          closeModal={closeAutoTab}
          openModal={openAutoTab}
        >
          <CopyPermission
            profToCopyFieldName={profToCopyFieldName}
            permissionsFieldName={permissionsFieldName}
            usersToCopy={usersToCopy}
            closeAutoTab={closeAutoTab}
          />
        </Modal>
      </Form>
    </Formik>
  );
};
