import React from 'react';
import { updateHolidaysConfig } from '../../../../../../services';
import { SnackbarContext, FormContext } from '../../../../../../context';
import { FormikHelpers } from 'formik';

export function useSubmit(
  holiday: { [key: string]: any } | boolean,
  onChange: () => void,
) {
  const { closeForm } = React.useContext(FormContext);
  const { showErrorSnackbar, showSuccessSnackbar } =
    React.useContext(SnackbarContext);

  return async (
    _spec: { [key: string]: any },
    formikActions: FormikHelpers<any>,
  ) => {
    try {
      await axiosPromise(holiday);
      closeForm();
      formikActions.resetForm();
      showSuccessSnackbar('Feriado eliminado correctamente');
      onChange();
    } catch (err: any) {
      showErrorSnackbar(`${err.message} Error status code: ${err.statusCode}`);
    } finally {
      formikActions.setSubmitting(false);
    }
  };
}

function axiosPromise(holiday: { [key: string]: any } | boolean) {
  const apiSpec = transformSpec(holiday);

  return updateHolidaysConfig(apiSpec);
}

function transformSpec(holiday: { [key: string]: any } | boolean) {
  const { id, from, to, rowversion, id_prof } =
    holiday && typeof holiday === 'object'
      ? holiday
      : {
          id: null,
          id_prof: null,
          from: new Date(),
          to: new Date(),
          rowversion: undefined,
        };
  let apiSpec = {
    id_feriado: id,
    id_prof,
    desde: from,
    hasta: to,
    dia: null,
    rowversion,
    opcion: 'eliminar',
  };

  return apiSpec;
}
