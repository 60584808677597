import { ProductObjectT } from '#interfaces/components';

export const products: ProductObjectT = {
  Whatsapp: {
    title: 'Whatsapp',
    description:
      'Reducí el ausentismo de tu clínica o consultorio con la nueva función de recordatorio de turnos con Whats App.',
    price: { currency: 'U$D', money: 0 },
    time: ' / mes',
  },
  Cloud: {
    title: 'Galeria',
    description:
      'Sube todos los archivos de un paciente a la nube para tenerlo disponible en todo momento y lugar.',
    price: { currency: 'U$D', money: 25 },
    time: ' / mes',
  },
};
