import React from 'react';
import { GroupForm } from './GroupForm';
import { DeleteGroupForm } from './DeleteGroupForm';
import { EmptyState, Modal } from '../../../../../components';
import { useForm, useSetHeight } from '../../../../../hooks';
import { GroupsFormT } from '#interfaces/components';
import { SettingsGroups } from '#components/Tables';

const GroupsForm: React.FC<GroupsFormT> = ({
  medicalInsurances,
  selectedMed,
  onChange,
  openModal,
  closeModal,
  isOpenModal,
}) => {
  const defaultGroup = {
    id: 0,
    id_os: selectedMed?.id_os,
    code: '',
    description: '',
  };
  const [groups, setGroups] = React.useState<any>([]);
  const [groupRow, setGroupRow] = React.useState<any>(defaultGroup);
  const height = useSetHeight(290);
  const {
    isOpenForm: isOpenDelete,
    closeForm: closeDelete,
    openForm: openDelete,
  } = useForm();
  const current = React.useMemo(
    () => medicalInsurances.find((p) => p.id_os === selectedMed.id_os),
    [medicalInsurances, selectedMed],
  );

  React.useEffect(() => setGroups(current?.grupos), [current]);

  const onCancelHandler = () => {
    setGroupRow(null);
    closeModal();
    closeDelete();
  };

  const actions = (group: [key: string], action: string) => {
    const events: { [key: string]: any } = {
      delete: openDelete,
      edit: openModal,
    };
    setGroupRow(group);

    return !!events[action] ? events[action]() : setGroupRow(defaultGroup);
  };

  return (
    <div
      className="overflow-hidden overflow-y-scroll"
      style={{ height: `${height}px` }}
    >
      {groups?.length ? (
        <SettingsGroups data={groups} action={actions} />
      ) : (
        <EmptyState
          title="Ups!"
          copy="La obra social seleccionada no tiene grupos definidos."
        />
      )}
      <Modal
        title="Eliminar grupo"
        isOpenModal={isOpenDelete}
        closeModal={onCancelHandler}
        openModal={openDelete}
      >
        <DeleteGroupForm
          group={groupRow}
          selectedMed={selectedMed}
          onChange={onChange}
        />
      </Modal>
      <Modal
        title={groupRow ? 'Modificar grupo' : 'Nuevo grupo'}
        isOpenModal={isOpenModal}
        closeModal={onCancelHandler}
        openModal={openModal}
      >
        <GroupForm
          group={groupRow}
          selectedMed={selectedMed}
          onChange={onChange}
        />
      </Modal>
    </div>
  );
};

export default GroupsForm;
