import React from 'react';
import { Checkbox } from '#components/commons';
import { useFieldProps } from '../../../hooks';

export const SelectArticles: React.FC<{
  selectedArticle: { [key: string]: any };
  className?: string;
}> = ({ selectedArticle, className }) => {
  const selectedArticlesFieldName = 'selected_articles';
  const {
    field: { value },
    setFieldValue,
  } = useFieldProps({ name: selectedArticlesFieldName });
  const {
    field: { value: selectAll },
  } = useFieldProps({ name: 'select_all' });

  const handleOnChange = () => {
    if (!value?.includes(selectedArticle)) {
      value.push(selectedArticle);
      setFieldValue(value);
    } else if (value?.includes(selectedArticle)) {
      const index = value.indexOf(selectedArticle);
      value.splice(index, 1);
      setFieldValue(value);
    }
  };

  const checked = selectAll ? selectAll : value?.includes(selectedArticle);

  return (
    <Checkbox
      className={className}
      checked={checked}
      onChange={handleOnChange}
    />
  );
};
