import React from 'react';
import { Loading } from '../components';
import { useLoading } from '../hooks';

export const LoadingContext = React.createContext({
  stopLoading: () => {},
  startLoading: () => {},
});
export function LoadingProvider({ children }: { children: React.ReactNode }) {
  const { stopLoading, startLoading, isLoading } = useLoading();

  return (
    <LoadingContext.Provider value={{ stopLoading, startLoading }}>
      {children}
      {isLoading && <Loading />}
    </LoadingContext.Provider>
  );
}
