import * as React from 'react';
import { format, parseISO } from 'date-fns';
import { ColumnsT } from '#interfaces/tables/benefit';
import { Icons } from '#components/commons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../shadcn';

export const Columns = ({ action }: ColumnsT) => {
  return [
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="relative p-2 hover:cursor-pointer flex-none">
                <Icons
                  type="context"
                  className="text-gray-500 hover:text-gray-900 h-4 w-4"
                />
                <span className="sr-only">Abrir menu</span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start">
              <DropdownMenuGroup>
                <DropdownMenuItem
                  className="hover:cursor-pointer"
                  onSelect={() => action(row.original, 'edit')}
                >
                  <Icons type="edit" className="mr-2 h-4 w-4" />
                  <span>Modificar historia clínica</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="hover:cursor-pointer text-red-500"
                  onSelect={() => action(row.original, 'delete')}
                >
                  <Icons type="delete" className="mr-2 h-4 w-4" />
                  <span>Eliminar historia clínica</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: 'date',
      header: 'Fecha',
      cell: ({ row }: { row: any }) => {
        const { date } = row.original;

        return (
          <p className="font-bold text-sm leading-6 text-gray-500 my-1">
            {format(parseISO(date), 'dd/MM/yyyy')}
          </p>
        );
      },
    },
    {
      accessorKey: 'text',
      header: 'Historia clínica',
      cell: ({ row }: { row: any }) => {
        const { text } = row.original;

        return (
          <p className="max-w-[700px] font-bold text-sm leading-6 text-gray-500 my-1 hover:cursor-pointer">
            {text}
          </p>
        );
      },
    },
    {
      accessorKey: 'professional',
      header: 'Profesional',
      cell: ({ row }: { row: any }) => {
        const { professional } = row.original;

        return (
          <p className="text-base font-semibold leading-6 text-slate-800 my-1">
            {professional}
          </p>
        );
      },
    },
  ];
};
