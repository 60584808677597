import React, { useMemo } from 'react';
import { ValueToPay } from '../../../Benefits/BenefitsForm/ValuesToPay/value-to-pay';
import { CheckboxForm } from '#components/index';
import { useFieldProps } from '../../../../../hooks';

import './ValuesToPayProf.scss';

export const ValuesToPayProf: React.FC<{ name: string; enabled: boolean }> = ({
  name,
  enabled,
}) => {
  const { field } = useFieldProps({ name });
  const canEditFieldName = `${name}.can_edit`;
  const disabled = useMemo(() => !field.value, [field.value]);

  return (
    <div className="col-span-12 grid grid-cols-12 gap-x-8">
      <div className="col-span-12">
        <CheckboxForm
          label="Modificar valores a pagar al profesional"
          description="Estos son los valores que se usaran para la liquidación del profesional"
          name={canEditFieldName}
          disabled={!enabled || disabled}
          showError={false}
        />
      </div>
      <ValueToPay
        name={name}
        label="Porcentaje a pagar (Prof.) (%):"
        entity="professional"
        paymentOption="percentage"
        maxLength={100}
      />
      <ValueToPay
        name={name}
        label="Importe a pagar (Prof.) ($):"
        entity="professional"
        paymentOption="amount"
      />
    </div>
  );
};
