import { useEffect, useState } from 'react';

export const useForm = ({
  medicalInsurances,
  selectedMedOrigin,
  selectedMedDestination,
}: {
  medicalInsurances: { [key: string]: any }[];
  selectedMedOrigin: { [key: string]: any };
  selectedMedDestination: { [key: string]: any };
}) => {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues({
      medicalInsurances,
      selectedMedOrigin,
      selectedMedDestination,
    }),
  );

  useEffect(() => {
    setInitialValues(
      getInitialValues({
        medicalInsurances,
        selectedMedOrigin,
        selectedMedDestination,
      }),
    );
  }, [medicalInsurances, selectedMedDestination, selectedMedOrigin]);

  return { initialValues };
};

function getInitialValues({
  medicalInsurances,
  selectedMedOrigin,
  selectedMedDestination,
}: {
  medicalInsurances: { [key: string]: any }[];
  selectedMedOrigin: { [key: string]: any };
  selectedMedDestination: { [key: string]: any };
}) {
  const defaultMedIns =
    medicalInsurances.filter((el) => !el.inhabilitada)[0]?.id_os || null;

  const defaultPlan =
    medicalInsurances.filter((el) => !el.inhabilitada)[0]?.planes[0].id_plan ||
    null;

  return {
    medical_insurance_origin: selectedMedOrigin?.id_os || defaultMedIns,
    medical_insurance_destination:
      selectedMedDestination?.id_os || defaultMedIns,
    plan_origin: selectedMedOrigin?.planes[0].id_plan || defaultPlan,
    plan_destination: selectedMedDestination?.planes[0].id_plan || defaultPlan,
    replace_destination: false,
    selected_benefits: [],
    selectAll: false,
  };
}
