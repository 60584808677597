import { useState, useEffect, useCallback } from 'react';

export function useForm(
  plan: { [key: string]: any },
  selectedMed: { [key: string]: any },
) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues(plan, selectedMed),
  );

  useEffect(() => {
    setInitialValues(getInitialValues(plan, selectedMed));
  }, [plan, selectedMed]);

  const validate = useCallback((values: { [key: string]: any }) => {
    const errors: { [key: string]: any } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['code', 'name'];

    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues(
  plan: { [key: string]: any },
  selectedMed: { [key: string]: any },
) {
  return {
    id: plan?.id,
    id_os: plan?.id_os,
    code: plan?.code,
    name: plan?.name,
    enabled: !!plan?.enabled,
  };
}
