import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Item } from './menu-item';
import { sanitizeItemMenuString } from '../../utils';
import { supMenu, menuIcons } from '../../mocks';
import { logOut, resetPatientsForm } from '../../redux/slices';

export const Menu = ({ section }: { section: string }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    login: {
      user: { es_supervisor, is_owner: isOwner, plan_version },
      permissions,
      region,
    },
  } = useSelector((state: any) => state);
  const isSelected = (menuItem: string) => {
    if (menuItem === 'Tablas') menuItem = 'Configuración';
    if (menuItem === 'Informes') menuItem = 'Liquidaciones';
    return section === menuItem;
  };

  const onClickHandler = (section: string) => {
    const paths: { [key: string]: string } = {
      Inicio: '/home',
      'Agenda Turnos': '/schedule',
      Pacientes: '/patient-profile',
      Informes: '/reports',
      Tablas: '/config',
      'Acerca de': '/about',
      Salir: '/',
      Account: '/account-manager',
      Estadisticas: '/statistics',
    };

    if (section === 'Salir') {
      dispatch(logOut({}));
      dispatch(resetPatientsForm());
    }

    navigate(paths[section]);
  };

  const renderItems = () => {
    if (!es_supervisor && permissions) {
      return Object.keys(permissions).map((permission, index) => {
        if (menuIcons[permission]) {
          return (
            <span key={index}>
              <Item
                item={menuIcons[permission].name}
                icon={menuIcons[permission].icon}
                selected={isSelected(menuIcons[permission].name)}
                onClickHandler={() => onClickHandler(permission)}
              />
            </span>
          );
        }
        return null;
      });
    } else {
      return (
        <React.Fragment>
          {supMenu.map((e: any, i: number) => {
            return (
              <React.Fragment key={i}>
                <Item
                  item={menuIcons[sanitizeItemMenuString(e)].name}
                  icon={menuIcons[sanitizeItemMenuString(e)].icon}
                  selected={isSelected(e)}
                  onClickHandler={() => onClickHandler(e)}
                />
              </React.Fragment>
            );
          })}
        </React.Fragment>
      );
    }
  };

  const liquidationItem =
    region === 'arg' && (es_supervisor || permissions?.['Liquidacion Bilog']);
  const validPlanVersion = ['premium', 'standard', 'full'].includes(
    plan_version,
  );
  const statisticsItem =
    (validPlanVersion && es_supervisor) ||
    (validPlanVersion &&
      (permissions['Estadísticas'] ||
        (permissions['Informes'] &&
          (permissions['Indicadores'] ||
            permissions['Presupuestos'] ||
            permissions['Prestaciones']))));

  return (
    <div className="bg-slate-800 print:hidden flex flex-col justify-between py-6">
      <div>
        <Item
          item="Inicio"
          icon="home"
          selected={isSelected('Inicio')}
          onClickHandler={() => onClickHandler('Inicio')}
        />
        {renderItems()}
        {statisticsItem && (
          <Item
            item="Estadísticas"
            icon="statistics"
            selected={isSelected('Estadisticas')}
            onClickHandler={() => onClickHandler('Estadisticas')}
          />
        )}
        {liquidationItem && (
          <Item
            item="Liquidaciones"
            icon="document"
            selected={isSelected('Informes')}
            onClickHandler={() => onClickHandler('Informes')}
          />
        )}
        {isOwner &&
          ['premium', 'freemium', 'lender'].includes(plan_version) && (
            <Item
              item="Vista general de la cuenta"
              icon="identification"
              selected={isSelected('Account')}
              onClickHandler={() => onClickHandler('Account')}
            />
          )}
      </div>
      <div>
        <Item
          item="Acerca de"
          icon="help"
          selected={isSelected('Acerca de')}
          onClickHandler={() => onClickHandler('Acerca de')}
        />
        <Item
          item="Salir"
          icon="logout"
          selected={isSelected('Salir')}
          onClickHandler={() => onClickHandler('Salir')}
        />
      </div>
    </div>
  );
};
