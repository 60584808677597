import React from 'react';

export function useForm({
  time,
  selectedProf,
  offices,
}: {
  time: { [key: string]: any };
  offices: { [key: string]: any }[];
  selectedProf: { [key: string]: any };
}) {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues({ time, selectedProf, offices }),
  );

  React.useEffect(() => {
    setInitialValues(getInitialValues({ time, selectedProf, offices }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, selectedProf]);

  const validate = React.useCallback((values: { [key: string]: any }) => {
    const errors: { [key: string]: any } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['interval', 'from', 'to'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues({
  time,
  selectedProf,
  offices,
}: {
  time: { [key: string]: any };
  offices: { [key: string]: any }[];
  selectedProf: { [key: string]: any };
}) {
  const {
    id,
    id_especialidad,
    id_sucursal,
    interval,
    rowversion,
    day_num,
    date_to,
    date_from,
    from,
    to,
  } = time;

  return {
    id,
    specialty: id_especialidad,
    office: id_sucursal || (offices && offices[0]?.id_sucursal) || null,
    day_num: 1 || day_num,
    date_to,
    date_from,
    from,
    to,
    interval,
    rowversion,
    all_specialties: !time?.id_especialidad,
    no_schedule: selectedProf?.no_tiene_agenda || false,
    check_to: !!date_to,
    check_from: !!date_from,
  };
}
