import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Form, Copy } from '../../../../../..';
import { DeleteOsFormT } from '#interfaces/components';

export const DeleteOsForm: React.FC<DeleteOsFormT> = ({
  medicalInsurance,
  onChange,
}) => {
  return (
    <Formik initialValues={{ medicalInsurance }} onSubmit={useSubmit(onChange)}>
      <Form submitTitle="Eliminar" ignoreDirty>
        <Copy>
          Estás por eliminar una obra social y esta acción es irreversible, la
          obra social se eliminará definitivamente.
        </Copy>
      </Form>
    </Formik>
  );
};
