import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './useSubmit';
import { Form, Copy } from '../../..';

export const DeleteOrderForm: React.FC<any> = ({ selectedOrder, refresh }) => {
  return (
    <Formik initialValues={{}} onSubmit={useSubmit({ selectedOrder, refresh })}>
      <Form submitTitle="Aceptar" ignoreDirty>
        <Copy>
          Estás por eliminar un pedido y esta acción es irreversible, el pedido
          se eliminará definitivamente.
        </Copy>
      </Form>
    </Formik>
  );
};
