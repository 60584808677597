import { useEffect } from 'react';
import { UseKeyPressT } from '#interfaces/hooks';

export function useKeypress({ key, action }: UseKeyPressT) {
  useEffect(() => {
    function onKeyup(e: any) {
      if (e.key === key) action(e);
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [action, key]);
}
