import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { useForm } from './use-form';
import { DatePicker, Form } from '../../../../../../components';
import { HolidayFormT } from '#interfaces/components';

export const HolidayForm: React.FC<HolidayFormT> = ({ holiday, onChange }) => {
  const { initialValues, validate } = useForm(holiday);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={useSubmit(onChange)}
      enableReinitialize
    >
      <Form ignoreDirty>
        <p className="mb-4 text-base font-regular text-neutral-500">
          Especificá desde que fecha a que fecha se aplicará el nuevo feriado.
        </p>
        <div data-cols="2">
          <DatePicker name="from" label="Fecha desde" />
          <DatePicker name="to" label="Fecha hasta" />
        </div>
      </Form>
    </Formik>
  );
};
