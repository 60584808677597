import { useEffect } from 'react';
import { useOnClickOutsideT } from '#interfaces/hooks';

export function useOnClickOutside({ ref, handler }: useOnClickOutsideT) {
  useEffect(() => {
    const listener = (e: MouseEvent | TouchEvent) => {
      if (!ref?.current || ref?.current.contains(e.target)) {
        return;
      }
      handler(e);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}
