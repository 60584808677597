import React from 'react';
import classNames from 'classnames';
import { FormContext } from '../../context/form';
import { Title, Icons } from '..';
import { TabSectionT } from '#interfaces/components';

export const TabSection: React.FC<TabSectionT> = ({
  isOpen,
  close,
  open,
  title,
  hiddeBack = false,
  children,
  className = '',
}) => {
  if (!isOpen) {
    return null;
  }

  const containerCss = classNames('flex mb-4', className);

  return (
    <div>
      {!hiddeBack && (
        <div className={containerCss}>
          <span
            className="cursor-pointer flex items-center text-blue-500 transition-transform hover:translate-x-[-10px] mr-2"
            onClick={close}
          >
            <Icons className="w-4 h-4 mr-px" type="arrow_back" />
            <small>Volver</small>
          </span>
          {title && <Title className="text-lg">{title}</Title>}
        </div>
      )}
      <FormContext.Provider
        value={{
          isOpenForm: isOpen,
          openForm: open || (() => {}),
          closeForm: close,
        }}
      >
        {children}
      </FormContext.Provider>
    </div>
  );
};
