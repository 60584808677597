import React from 'react';

export const terms_and_conditions = (
  <p>
    El presente documento establece los términos y condiciones generales que
    serán aplicables a los usuarios de la plataforma digital de BILOG S.R.L.
    C.U.I.T. N° 30-71728569-3, con domicilio en José Hernández 2276 Piso 4°
    Oficina “A” de la Ciudad Autónoma de Buenos Aires, a través de su sitio web
    como de cualquier otro portal, desarrollo y/o programa adicional que utilice
    para bridar sus servicios.
    <br />
    Los presentes Términos y Condiciones están dirigidos a los usuarios de la
    Plataforma y son aplicables y resultan vinculantes en todo momento para los
    usuarios.
    <br />
    BILOG S.R.L., ante la implementación, a través de la Ley 27553 y el Decreto
    Reglamentario 98/2023, del sistema de Recetas Electrónicas o Digitales,
    brinda a los usuarios de sus servicios, acceso a la aplicación para la
    generación de éstas a través del software y servicio interactivo QBI2.
    <br />
    Los Términos y Condiciones que seguidamente se detallan, deberán ser
    aceptados previamente por los Usuarios del servicio adicional que brinda
    BILOG S.R.L.
    <br />
    QBI2 (la App) es un software y servicio interactivo destinado a
    profesionales de la medicina (genéricamente denominados en el presente como
    los Médicos, término que abarca asimismo a sus equipos de trabajo y/o a los
    Centros Médicos) y a los pacientes (los Pacientes), cuyo objeto es que los
    médicos realicen (las Recetas médicas) a través de la App y también que los
    pacientes puedan recepcionarlas de esta forma, con el objetivo final de
    mejorar la inmediatez en el expendio de recetas médicas, los cuales serán
    utilizados por los Médicos y Pacientes (denominados en conjunto como los
    Usuarios).
    <br />
    La App se ha desarrollado adaptando su estructura y funcionamiento según lo
    dispuesto en las Leyes Nº25.326 - LEY DE PROTECCIÓN DE LOS DATOS PERSONALES
    y Nº26.529 DERECHOS DEL PACIENTE, HISTORIA CLINICA Y CONSENTIMIENTO
    INFORMADO y demás leyes y decretos accesorios. De esta manera, además de
    procurar el resguardo de la información sobre la salud de los pacientes, se
    proyecta que el paciente pueda acceder, dirigiéndose a tal efecto a su
    Médico, a la información que pueda generar la App sobre el mismo (recetas
    generadas u otra, información que se haya cargado). La App constituye un
    servicio para lograr agilizar el proceso de recetar por parte de los
    Usuarios, siendo éstos quienes ingresan, bajo su propia responsabilidad, los
    datos volcados en la misma. En consecuencia, el Titular no asume
    responsabilidad alguna por: (i) los productos que se receten; (ii) la
    identidad de los emisores de la Información; (iii) la identidad de los
    Usuarios (iv) el uso dado a la Información; (v) título habilitante de los
    médicos o profesionales de la salud; (vi) actividad profesional llevada a
    cabo por los médicos o profesionales de la salud (vii) Así como ningún otro
    tipo de responsabilidad que exceda el objetivo de la App. Todo ello, habida
    cuenta que la App funciona como un mero soporte digital de la Información,
    la que es ingresada y utilizada por los Usuarios, quienes serán los únicos y
    exclusivos responsables.
    <br />
    La APP brinda una herramienta de Software para que los médicos puedan
    realizar recetas en forma digital, pudiendo observar marcas comerciales,
    presentaciones, farmacias más cercanas, etc. En la misma se verá el
    encabezado y pie de página con los datos del médico ingresante y este podrá
    modificarlos las veces que sea necesario.
    <br />
    El Titular se reserva el derecho de establecer patrocinios pagos para ocupar
    lugares destacados en la app, sin que ello implique recomendación alguna por
    parte del Titular, ni genere derecho a reclamo alguno de los Usuarios y/o
    terceros.
    <br />
    El Titular no será responsable por ninguna publicidad (que no corresponda
    exclusivamente a los espacios por él comercializados en la App),
    comercialización, mención, recomendación y/u otras actividades que se
    realicen en el marco de la App y no intermediará en la misma, siendo por
    tanto ajeno a cualquier efecto, consecuencia o responsabilidad derivado de
    ella. Tampoco será responsable de las actuaciones llevadas a cabo
    directamente por los fabricantes, distribuidores o proveedores de productos
    o servicios ofertados.
    <br />
    El Titular no será responsable por ninguna falla en los productos recetados,
    como tampoco lo será en el caso de efectos colaterales de los productos y/o
    efectos adversos de los mismos y/o cualquier otra circunstancia que se
    derive de los mismos.
    <br />
    FALLAS TÉCNICAS EN LA APLICACIÓN: El Propietario no se responsabiliza por
    cualquier daño, perjuicio o pérdida al prescriptor causados por fallas
    técnicas en la Aplicación, en el servidor o en Internet. El Propietario
    tampoco será responsable por cualquier virus, malware, ransomware y/o
    similar que pudiera infectar el equipo del Médico como consecuencia del
    acceso o a raíz de cualquier transferencia de datos, archivos, imágenes,
    textos o audio contenidos en el mismo. <br /> El Propietario no garantiza el
    acceso y uso continuado o interrumpido de su sitio. La Aplicación puede
    eventualmente no estar disponible debido a dificultades técnicas o fallas de
    Internet, o por cualquier otra circunstancia ajena al Propietario; en tales
    casos se procurará restablecer con la mayor celeridad posible sin que por
    ello pueda imputársele algún tipo de responsabilidad. El Propietario no será
    responsable por ningún error u omisión contenidos en su aplicación.
    <br />
    Desde la perspectiva de los pacientes, QBI2 tendrá que ofrecer el acceso a
    los datos más importantes desde el punto de vista clínico, los cuales están
    contenidos en la app, generadas a través de QBI2, que son responsabilidad de
    los distintos médicos o consultorios en donde se le hayan prestado
    asistencia médica a lo largo de su vida, constituyendo lo que se denomina un
    Registro Personal de Salud (en adelante RPS). Acorde con el Capítulo IV de
    la Ley 26.529 QBI2 es parte fundamental de la Historia Clínica Médica del
    Paciente, la cual además es de su propiedad y cuyo resguardo recae en la
    responsabilidad de cada médico/profesional de la Salud según el artículo 8
    de la Ley 25.326. Esa parte, destinada a que se incluyan en la misma los
    datos de salud más relevantes, puede ser accesible para el paciente o para
    todo aquel médico/profesional de la Salud a quien éste le permita acceder.
    El acceso a QBI2, ya sea para la consulta como para el agregado de
    información lo efectúa el profesional de la Salud a través de un acceso Web
    seguro mediante validación de usuario y contraseña. Así QBI2 de acuerdo a
    los cumplimientos que dictan la ley 25.326, es la encargada del tratamiento
    de los archivos que son responsabilidad de su médico/profesional de la
    Salud.
    <br />
    Información Médica: El médico/profesional de la Salud, así como el paciente,
    deben ser conscientes de que QBI2 es una herramienta cuyo objeto es
    facilitar una mejor información y una más adecuada asistencia sanitaria.
    Esta herramienta debe ser utilizada adecuadamente, no responsabilizándose
    QBI2 de un mal uso de la misma. QBI2 no examina los contenidos registrados
    y/o transmitidos por los Usuarios de la app y, por tanto, no puede
    garantizar la legalidad, veracidad, honestidad, rigurosidad, lealtad,
    integridad y/o calidad de los mismos, ni asume ninguna responsabilidad
    derivada del incumplimiento por parte de los Usuarios de los presentes
    términos y condiciones. Sin prejuicio de lo mencionado con anterioridad,
    QBI2 se reserva el derecho de rechazar, eliminar y/o bloquear el acceso a
    cualquier contenido que viole estos términos y condiciones y/o que es
    manifiestamente ilegal, así como archivar y/o difundir cuando sea solicitado
    por cualquier autoridad judicial o administrativa en virtud de la ley.
    <br />
    <br />
    <br />
    <strong>Obligaciones de utilización</strong>
    <br />
    El usuario es responsable de todos los contenidos, información, datos y/u
    otros que registre y/o transmita a través de la app, por lo que está de
    acuerdo en evaluar y asumir todos los riesgos asociados a su comunicación.
    El Usuario de la app se compromete a: No suplantar o hacerse pasar por otro
    o hacer un uso indebido de su relación con otros; No infringir, con o sin
    intención, cualquier ley nacional, regional o internacional; No amenazar ,
    insultar o acosar a cualquier persona o grupo de personas; No causar, de
    ninguna forma, algún daño, incluido a menores; No registrar y/o transmitir,
    en el app o por correo electrónico (e-mail), contenido ilegal, amenazante,
    provocador, impropio, difamatorio, obsceno, pornográfico, violador de la
    privacidad, racista o discriminatorio contra cualquier persona o grupo de
    personas; No registrar y/o transmitir, sin tener derecho, ningún contenido
    bajo ley o contrato, incluyendo información confidencial obtenida en el
    ámbito de relaciones de trabajo o profesionales, concretamente
    médico-paciente, o información que viole cualquier patente, derecho de autor
    u otro derecho de propiedad de cualquier persona; No registrar y/o
    transmitir ningún anuncio o mensaje para grupos de personas no autorizados o
    no solicitados, material promocional, plan de negocio o cualquier otra forma
    de solicitud; No registrar y/o transmitir ningún software que contenga virus
    o cualquier otro código, fichero o programa de ordenador diseñados para
    interrumpir, destruir, dañar o limitar la funcionalidad de cualquier
    equipamiento de hardware, software o telecomunicaciones; No copiar,
    transmitir, modificar, distribuir, mostrar, ejecutar públicamente o crear
    ningún trabajo derivado de cualquier contenido que esté disponible en el
    app; No obtener o almacenar, sin autorización del respectivo titular, datos
    personales de otro u otros usuarios.
    <br />
    <br />
    <br />
    <strong>Deslinde: </strong>
    <br />
    QBI2 no es responsable de cualquier daño directo o indirecto que pueda
    ocasionar la exactitud, veracidad o exhaustividad de la información o
    artículos aquí recogidos, o el uso que de ellos pueda hacerse. El Usuario se
    compromete a utilizar el sistema y los servicios de conformidad con la Ley,
    así como con la moral y buenas costumbres generalmente aceptadas y el orden
    público. El usuario se obliga a usar los contenidos de forma diligente,
    correcta y lícita, y en particular, se compromete a abstenerse de utilizar
    los Contenidos de forma, con fines o efectos contrarios a la moral y las
    buenas costumbres generalmente aceptadas o al orden público. QBI2 brinda la
    posibilidad de realizar el resguardo de los datos en medio magnético de toda
    la información remitida por los Usuarios, por tal motivo es responsabilidad
    de los mismos mantener una copia de seguridad (backup) actualizada de los
    datos personales de los Pacientes. QBI2 no se hace responsable del
    incumplimiento de cualquier norma aplicable en que pueda incurrir el Usuario
    en su acceso a esta app y/o en el uso de las informaciones y servicios
    contenidos en el mismo. Por lo tanto, QBI2 no se hace responsable por el uso
    indebido de sus servicios, ni de perjuicios o daños causados por ese uso
    incorrecto. QBI2 no se hace responsable del uso ilegítimo que terceras
    personas puedan hacer de los nombres de marcas, nombres de productos, marcas
    comerciales registradas que, no siendo propiedad de dicha entidad, aparezcan
    la esta app. Al mismo tiempo que QBI2 no se responsabiliza de los daños que
    puedan ocurrir debido a las relaciones que el usuario establezca con
    entidades que se anuncien en la app. La app puede contener enlaces a páginas
    externas sobre las cuales QBI2 no se responsabiliza ni de los contenidos ni
    de cualquier otro aspecto relacionado con dichos apps o sitios web. Los
    enlaces e hipertexto que posibiliten, a través de esta app acceder al
    usuario a prestaciones y servicios ofrecidos por terceros, no pertenecen ni
    se encuentran bajo el control de QBI2. Los derechos de propiedad intelectual
    del contenido de las páginas web, su diseño gráfico y códigos son
    titularidad de BILOG S.R.L. y, por tanto, queda prohibida su reproducción,
    distribución, comunicación pública y transformación. Igualmente, todos los
    nombres comerciales, marcas o signos distintos de cualquier clase contenidos
    en las páginas web de BILOG S.R.L. están protegidos por ley. La información
    presentada de la app ha sido reunida y es mostrada por QBI2 con el celo y
    cuidados exigidos. Con todo ello, QBI2 no garantiza que la información
    ofrecida esté exenta de errores - humanos y/o informáticos - retrasos,
    interrupciones, pérdida de datos, omisiones u otros defectos. Del mismo
    modo, y a pesar de las medidas técnicas y de seguridad adoptadas, QBI2 no
    garantiza que sus archivos estén exentos de virus, “gusanos” y “troyanos”
    que contengan o manifiesten características contaminantes y/o destructivas.
    <br />
    <br />
    <br />
    QBI2 podrá modificar sin previo aviso la información contenida en la app
    Web, así como su configuración y presentación, así como la inclusión de
    diferentes productos. El usuario acepta y reconoce que no debe basarse en
    cualquier información y/o servicio disponible en la app para justificar o
    apoyar decisiones, actos (por acción u omisión) sin previa verificación o
    análisis crítico. BILOG S.R.L. no asume responsabilidad alguna por la
    información contenida en páginas web de terceros a las que se pueda acceder
    por enlaces (links) o buscadores de las páginas web de BILOG S.R.L. La
    presencia de enlaces (links) en las páginas web de BILOG S.R.L. tiene
    finalidad meramente informativa y en ningún caso supone sugerencia,
    invitación o recomendación sobre los mismos. BILOG S.R.L. no será
    responsable de posibles daños o perjuicios que se pudieran derivar de
    interferencias omisiones, interrupciones, virus informáticos, averías
    telefónicas o desconexiones en el funcionamiento operativo de este sistema
    electrónico, motivadas por causas ajenas; de retrasos o bloqueos en el uso
    del presente sistema electrónico causados por deficiencias o sobrecargas de
    líneas telefónicas, en el sistema de Internet o en otros sistemas
    electrónicos, así como de daños que puedan ser causados por terceras
    personas mediante intromisiones ilegítimas fuera del control de BILOG S.R.L.
    En tal sentido el Usuario reconoce que internet es una red pública y que
    como tal no puede atribuirse a QBI2 ninguna responsabilidad por daños
    resultantes del uso de la misma.
    <br />
    El acceso a la app y su uso no implica o establece, por sí mismo, alguna
    relación comercial, incluido de clientes, consultoría, obligación o
    cualquier otra relación con QBI2 con personas relacionadas con ella.
    <br />
    <br />
    <br />
    <strong>Rescisión</strong>
    <br />
    El usuario acepta que QBI2 pueda, por iniciativa propia, cancelar el acceso
    a los servicios disponibles en la app en los términos descriptos,
    concretamente por morosidad en el pago, por infracción de los derechos de
    propiedad intelectual de QBI2 o de terceros, por violación de los presentes
    términos y condiciones de acceso y por decisión de cualquier autoridad
    administrativa o judicial en términos de ley, no siendo QBI2 responsable de
    la pérdida de acceso a los servicios disponibles en la app. A raíz de la
    situación descripta, QBI2 podrá denegar cualquier solicitud nueva de
    subscripción a los servicios de productos QBI2®, y/o solicitudes a QBI2.
    <br />
    Disposiciones finales QBI2 se reserva el derecho de, en cualquier momento y
    sin previo aviso, modificar los presentes términos y condiciones de acceso y
    uso, con efecto a partir de la fecha de su publicación. En caso de que
    alguna de las disposiciones de los presentes términos y condiciones se
    considere ilegal o no aplicable, en su totalidad o en parte, no tendrá que
    considerarse la misma, manteniéndose la validez y aplicabilidad de las
    disposiciones restantes.
    <br />
    <br />
    <br />
    BILOG S.R.L. establece que el Usuario en oportunidad de usar, acceder,
    descargar, instalar y/o brindar desde y/o hacia la Plataforma y/o
    Aplicación, ha comprendido y aceptado los Términos y Condiciones plenamente.
    <br />
    <br />
    <br />
    Los Términos y Condiciones generan vínculo y obligaciones. El Usuario que no
    acepte estos Términos y Condiciones deberá abstenerse de utilizar la
    Plataforma y/o Aplicación y/o Servicios.
    <br />
    <br />
    <br />
    Al registrarse en el Sitio y/o haciendo click en el botón de Acepto, el
    Usuario confirma que ha leído en forma íntegra y comprendido los presentes
    Términos y Condiciones y cualquier otro documento al que se haga referencia,
    como así también que acepta quedar vinculado por el presente acuerdo.
    <br />
    <br />
    <br />
    BILOG S.R.L. podrá modificar los Términos y Condiciones en cualquier
    momento. Cuando introduzca modificaciones y/o cambios, éstos serán
    publicados en su sitio web y/o Aplicación, informando la fecha de su última
    actualización. La forma y naturaleza de los servicios prestados por BILOG
    S.R.L. podrán variar en cualquier momento sin necesidad de aviso previo. En
    caso de que los cambios introducidos a los Términos y Condiciones sean
    sustanciales, los mismos serán notificados a los Usuarios por medios
    digitales o a través del portal.
    <br />
    <br />
    <br />
    <strong>Limitación del sistema en modo de prueba gratuito</strong>
    <br />
    El Usuario tendrá habilitada la utilización del servicio de QBI2, en las
    condiciones descriptas, en modo de prueba de manera gratuita única y
    exclusivamente hasta el 31 de Agosto de 2024. A partir de dicha fecha, el
    servicio tendrá costo.
    <br />
    <br />
    <br />
    <strong>Costos y cobros</strong>
    <br />
    El Usuario podrá probar el sistema durante los primeros tres días de uso.
    Luego, el usuario deberá pagar el monto de la cuenta Premium en forma
    mensual, utilizando cualquiera de los medios electrónicos de pago en línea
    acordados con el representante de ventas. El monto mensual se seguirá
    cobrando hasta que alguna de las partes cancele este acuerdo.
    <br />
    BILOG S.R.L. podrá variar la tarifa del Servicio en el futuro, dando aviso a
    los Usuarios con una antelación de 30 días por medio del sitio y/o a través
    de correo electrónico.
    <br />
    Se considera que el Usuario acepta la nueva tarifa y BILOG S.R.L. tendrá
    derecho a cobrar al Usuario el nuevo monto establecido, excepto que el
    Usuario decida cancelar el servicio utilizando el procedimiento descripto en
    la sección de “Cancelación de Servicio”.
    <br />
    La acreditación de los pagos mensuales en la cuenta del Usuario se realiza
    una vez que los menos de pago en línea acrediten los pagos en la cuenta
    bancaria de BILOG S.R.L., los que tienen una demora de aproximadamente 48
    hs.
    <br />
    Si el pago mensual del servicio no aparece registrado luego de la fecha de
    vencimiento que indica el sistema, el mismo restringe el ingreso de nuevos
    pacientes o nuevos datos clínicos y se bloquea la agenda. Luego de
    registrarse y acreditarse el pago, el sistema levanta los bloqueos y
    restricciones al ingreso de nuevos datos.
    <br />
    <br />
    <br />
    <strong>Notificaciones al Usuario</strong>
    <br />
    BILOG S.R.L. utiliza dos mecanismos principales para comunicarse con los
    Usuarios.
    <br />
    La pantalla de inicio al ingresar al sistema, cuenta con un espacio dedicado
    para informar al Usuario las principales noticias del sistema.
    <br />
    Correo electrónico del Usuario. Es indispensable que el Usuario mantenga un
    correo electrónico válido y funcional registrado en el sistema. Además es
    requerido que el Usuario revise periódicamente sus mensajes para recibir y
    atender las notificaciones de BILOG S.R.L.
    <br />
    <br />
    <br />
    <strong>Duración</strong>
    <br />
    Este acuerdo tiene una duración indefinida hasta la cancelación del servicio
    (descripta en la sección de “Cancelación del Servicio”) ejecutada por alguna
    de las partes.
    <br />
    <br />
    <br />
    <strong>Modificaciones del acuerdo</strong>
    <br />
    BILOG S.R.L. podrá modificar total o parcialmente este Acuerdo, incluyendo
    los costos y tarifas aplicables. Cualquier modificación se comunicará al
    Usuario por el sitio web y mediante correo electrónico. El uso por parte del
    Usuario se considerará una aceptación tácita de los nuevos términos y
    condiciones, que en adelante regirán entre las partes.
    <br />
    <br />
    <br />
    <strong>Cancelación del servicio</strong>
    <br />
    El usuario podrá cancelar el servicio en el momento en que lo desee. Sin
    embargo, no se harán devoluciones de dinero por los días restantes del mes
    en curso en que se realiza la cancelación. Para cancelar el servicio, el
    Usuario debe enviar un pedido a nuestra Mesa de Ayuda en línea, solicitando
    la baja del servicio. En el ticket o pedido debe especificar, además, la
    razón de la cancelación del servicio. Al registrar la solicitud de
    cancelación del servicio, el servicio se dará por terminado y el Usuario no
    podrá volver a ingresar al sistema.
    <br />
    Por su parte BILOG S.R.L. podrá desactivar o cancelar la cuenta de un
    Usuario en cualquier momento, sin ninguna responsabilidad de su parte,
    cuando a su exclusivo criterio, razonablemente compruebe que el Usuario ha
    quebrantado cualquier lineamiento definido en este Acuerdo. A tal efecto,
    BILOG S.R.L. dará un aviso al Usuario por medio del sitio y por correo
    electrónico, informándole de los motivos que originan la cancelación del
    servicio.
    <br />
    Asimismo, BILOG S.R.L. podrá desactiva o cancelar la cuenta de un usuario en
    cualquier momento, sin ninguna responsabilidad de su parte, dando un aviso
    previo al Usuario de al menos dos (2) meses de antelación a la fecha
    efectiva de desactivación o cancelación, por medio del sitio y por correo
    electrónico.
    <br />
    <br />
    <br />
    <strong>Impuestos</strong>
    <br />
    Corresponderá al Usuario el pago de cualesquiera impuestos aplicables al
    servicio aquí contratado, incluyendo el impuesto al valor agregado – IVA-,
    por remesas al exterior, o cualquier otro similar que resulte aplicable. Lo
    anterior, independientemente de si BILOG S.R.L. no recauda o cobra los
    impuestos aplicables al momento de proveer y/o cobrar por los servicios aquí
    acordados. Quedan excluidos de la anterior obligación, los impuestos que
    corresponda pagar a BILOG S.R.L. por la naturaleza de su actividad.
    <br />
    <br />
    <br />
    <strong>Limitación de responsabilidad</strong>
    <br />
    BILOG S.R.L. no es responsable de cualquier daño directo o indirecto que
    pueda ocasionar la exactitud, veracidad o exhaustividad de la información, o
    el uso que de ellos pueda hacerse.
    <br />
    BILOG S.R.L. no garantiza ni representa que el Servicio se prestará de
    manera ininterrumpida, libre de errores, o completamente segura.
    <br />
    BILOG S.R.L. proporciona el servicio “tal cual” y “como está disponible”.
    BILOG S.R.L. excluya toda garantía implícita, incluidas las de
    comercialidad, idoneidad para un fin particular, competencia profesional y
    ausencia de infracciones.
    <br />
    <br />
    <br />
    <strong>Indemnización</strong>
    <br />
    El Usuario se compromete a indemnizar, defender y mantener indemne a BILOG
    S.R.L., así como a sus afiliados, directores, socios, accionistas y
    empleados de cualquier responsabilidad, pérdida, daños, perjuicios, multas,
    reclamación y gastos (incluyendo gastos legales), daños punitivos o
    ejemplarizantes, intereses de cualquier clase, reclamados por terceros con
    base en cualquier argumento de responsabilidad legal que surja o se derive o
    se relaciones con el Servicio; incluyendo, sin limitación alguna, aquellos
    reclamos que se refieran o aleguen que el contenido de la información
    ingresada por el Usuario al Sistema viola o infringe disposiciones legales
    vigentes referentes a la propiedad intelectual o derechos de terceros.
    <br />
    Ninguna de las partes será responsable hacia la otra de pérdidas o daños y
    perjuicios indirectos, especiales, incidentales o punitivos de ninguna
    clase, ni por daños que pudieran haber sido evitados con el uso de una
    diligencia razonable, que surjan en relación con los términos de uso del
    Servicio, aun cuando la parte haya sido advertida o debía estar enterada de
    la posibilidad de ocurrencia de esos daños.
    <br />
    <br />
    <br />
    <strong>Fuerza mayor</strong>
    <br />
    BILOG S.R.L. no estará en incumplimiento del presente Acuerdo si se presenta
    una falla u ocurre una falta fuera de su alcance y posibilidad de control,
    incluyendo, sin estar limitado a fallos eléctricos, fallos de la red de
    internet, fallas en los proveedores de servicios de red, desastres
    naturales, paros o cualquier otra actividad laboral organizada, o por
    cualquier otro tipo de eventos para los cuales no es usual en la industria
    la toma de precauciones al respecto.
    <br />
    <br />
    <br />
    <strong>Otras estipulaciones</strong>
    <br />
    La relación entre el Usuario y BILOG S.R.L. es comercial y bajo un esquema
    de contratistas independientes, por lo que no existe ni existirá entre las
    partes ninguna sociedad, joint venture, ni relación laboral.
    <br />
    El Usuario no podrá transferir ni ceder sus derechos y/u obligaciones según
    este Acuerdo, sin el consentimiento previo y por escrito de BILOG S.R.L.
    <br />
    Por su parte, BILOG S.R.L. podrá ceder y/o transferir sus derechos y/u
    obligaciones según este Acuerdo sin requerír el consentimiento del Usuario.
    <br />
  </p>
);
