import React from 'react';
import { CheckboxForm, DatePicker } from '../../../../../../components';
import { useFieldProps } from '../../../../../../hooks';

export const TimeDatePicker: React.FC<{
  dateName: string;
  checkboxName: string;
  placeholder: string;
}> = ({ dateName, checkboxName, placeholder }) => {
  const {
    field: { value: checkBoxValue },
  } = useFieldProps({ name: checkboxName });
  const {
    field: { value: dateFromValue },
  } = useFieldProps({ name: 'date_from' });
  const { setFieldValue } = useFieldProps({ name: dateName });
  React.useMemo(() => checkBoxValue ? setFieldValue(new Date()) : setFieldValue(null), [checkBoxValue]);

  return (
    <div className="flex items-end">
      <CheckboxForm className="mr-2" name={checkboxName} showError={false} />
      <DatePicker
        label={placeholder}
        name={dateName}
        fullWidth
        disabled={!checkBoxValue}
        minDate={dateName === 'date_to' && dateFromValue}
      />
    </div>
  );
};
