import React from 'react';
import { LaboratoryT } from '#interfaces/components/forms';

export const useForm = ({
  laboratories,
  labOrigin,
  labDestination,
}: {
  laboratories: LaboratoryT[];
  labOrigin: LaboratoryT;
  labDestination: LaboratoryT;
}) => {
  const [initialValues, setInitialValues] = React.useState(() =>
    getInitialValues({
      laboratories,
      labOrigin,
      labDestination,
    }),
  );

  React.useEffect(() => {
    setInitialValues(
      getInitialValues({
        laboratories,
        labOrigin,
        labDestination,
      }),
    );
  }, [labOrigin, labDestination, laboratories]);

  return { initialValues };
};

const getInitialValues = ({
  laboratories,
  labOrigin,
  labDestination,
}: {
  laboratories: LaboratoryT[];
  labOrigin: LaboratoryT;
  labDestination: LaboratoryT;
}) => {
  const defaultLab = laboratories?.filter((l) => l.habilitado)?.[0]?.id_labo;
  return {
    laboratory_origin: labOrigin?.id_labo || defaultLab,
    laboratory_destination: labDestination?.id_labo || defaultLab,
    replace_destination: false,
    selected_articles: [],
    select_all: false,
  };
};
