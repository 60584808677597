import React from 'react';

const CategoryPreview: React.FC<{
  subtitle: string;
  index: number;
  children: React.ReactNode;
}> = ({ subtitle, index, children }) => {
  return (
    <div key={index}>
      <h4 className="text-xl mb-4 font-bold">{subtitle}</h4>
      {children}
    </div>
  );
};

export default CategoryPreview;
