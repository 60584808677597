import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getTableParams,
  getRegions as getRegionsService,
  getWappTemplates,
  getOSPre,
  getToothFaceField,
} from '../../services';
import { templatesMapper, sistemPermissionsMapper } from '../../utils';

const initialState: { [key: string]: any } = {
  params: [],
  specialties: [],
  holidays: [],
  templates: [],
  templatesMapper: [],
  offices: [],
  users: [],
  permissions: [],
  mappedPermissions: [],
  regions: [],
  benefits: [],
  benefitsOrigin: [],
  selectedProf: null,
  odontogramList: [],
};

export const updateParams = createAsyncThunk(
  'config/updateParams',
  async () => {
    try {
      const {
        data: { parametros: params },
      } = await getTableParams('PARAM');

      return { params };
    } catch (err: any) {
      let message = 'Unknown Error';
      if (err instanceof Error) message = err.message;

      throw new Error(message);
    }
  },
);

export const updateSpecialties = createAsyncThunk(
  'config/updateSpecialties',
  async () => {
    try {
      const {
        data: { listaEspecialidades: specialties },
      } = await getTableParams('ESPEC');

      return { specialties };
    } catch (err: any) {
      let message = 'Unknown Error';
      if (err instanceof Error) message = err.message;

      throw new Error(message);
    }
  },
);

export const updateHolidays = createAsyncThunk(
  'config/updateHolidays',
  async () => {
    try {
      const {
        data: { listaFeriados: holidays },
      } = await getTableParams('FERIA');

      return { holidays };
    } catch (err: any) {
      let message = 'Unknown Error';
      if (err instanceof Error) message = err.message;

      throw new Error(message);
    }
  },
);

export const getTemplates = createAsyncThunk(
  'config/getTemplates',
  async (region: string) => {
    try {
      const {
        data: { listaWappTemplates: templates },
      } = await getWappTemplates(region);
      const mapperTemplates = templatesMapper(templates);

      return { templates, mapperTemplates };
    } catch (err: any) {
      let message = 'Unknown Error';
      if (err instanceof Error) message = err.message;

      throw new Error(message);
    }
  },
);

export const updateOffices = createAsyncThunk(
  'config/updateOffices',
  async () => {
    try {
      const {
        data: { listaSucursales: offices },
      } = await getTableParams('SUCUR');

      return { offices };
    } catch (err: any) {
      let message = 'Unknown Error';
      if (err instanceof Error) message = err.message;

      throw new Error(message);
    }
  },
);

export const updateUsers = createAsyncThunk('config/updateUsers', async () => {
  try {
    const {
      data: { listaUsuarios: users },
    } = await getTableParams('USUAR');

    return { users };
  } catch (err: any) {
    let message = 'Unknown Error';
    if (err instanceof Error) message = err.message;

    throw new Error(message);
  }
});

export const getPermissions = createAsyncThunk(
  'config/getPermissions',
  async () => {
    try {
      const {
        data: { listaPermisos: permissions },
      } = await getTableParams('PERMI');
      const mapped = sistemPermissionsMapper(permissions);

      return { permissions, mapped };
    } catch (err: any) {
      let message = 'Unknown Error';
      if (err instanceof Error) message = err.message;

      throw new Error(message);
    }
  },
);

export const getRegions = createAsyncThunk('config/getRegions', async () => {
  try {
    const {
      data: { listaRegionesWapp: regions },
    } = await getRegionsService();

    return { regions };
  } catch (err: any) {
    let message = 'Unknown Error';
    if (err instanceof Error) message = err.message;

    throw new Error(message);
  }
});

export const getBenefitsConfig = createAsyncThunk(
  'config/getBenefitsConfig',
  async (planId: number) => {
    try {
      const { prestaciones } = await getOSPre('OSPRE', planId);

      return { prestaciones };
    } catch (err: any) {
      let message = 'Unknown Error';
      if (err instanceof Error) message = err.message;

      throw new Error(message);
    }
  },
);

export const getOdontogramList = createAsyncThunk(
  'config/getOdontogramList',
  async () => {
    try {
      const {
        data: { listaOdontograma },
      } = await getToothFaceField();

      return { listaOdontograma };
    } catch (err: any) {
      let message = 'Unknown Error';
      if (err instanceof Error) message = err.message;

      throw new Error(message);
    }
  },
);

export const getBenefitsConfigOrigin = createAsyncThunk(
  'config/getBenefitsConfigOrigin',
  async (planId: number) => {
    try {
      const { prestaciones } = await getOSPre('OSPRE', planId);

      return { prestaciones };
    } catch (err: any) {
      let message = 'Unknown Error';
      if (err instanceof Error) message = err.message;

      throw new Error(message);
    }
  },
);

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setSelectedProf: (state, action) => {
      state.selectedProf = action.payload;
    },
    setSelectedLab: (state, action) => {
      state.selectedLab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateParams.fulfilled, (state, action) => {
      const { params } = action.payload;
      state.params = params;
    });
    builder.addCase(updateSpecialties.fulfilled, (state, action) => {
      const { specialties } = action.payload;
      state.specialties = specialties;
    });
    builder.addCase(updateHolidays.fulfilled, (state, action) => {
      const { holidays } = action.payload;
      state.holidays = holidays;
    });
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      const { templates, mapperTemplates } = action.payload;
      state.templates = templates;
      state.templatesMapper = mapperTemplates;
    });
    builder.addCase(updateOffices.fulfilled, (state, action) => {
      const { offices } = action.payload;
      state.offices = offices;
    });
    builder.addCase(updateUsers.fulfilled, (state, action) => {
      const { users } = action.payload;
      state.users = users;
    });
    builder.addCase(getPermissions.fulfilled, (state, action) => {
      const { permissions, mapped } = action.payload;
      state.permissions = permissions;
      state.mappedPermissions = mapped;
    });
    builder.addCase(getRegions.fulfilled, (state, action) => {
      const { regions } = action.payload;
      state.regions = regions;
    });
    builder.addCase(getBenefitsConfig.fulfilled, (state, action) => {
      const { prestaciones } = action.payload;
      state.benefits = prestaciones;
    });
    builder.addCase(getBenefitsConfigOrigin.fulfilled, (state, action) => {
      const { prestaciones } = action.payload;
      state.benefitsOrigin = prestaciones;
    });
    builder.addCase(getOdontogramList.fulfilled, (state, action) => {
      const { listaOdontograma } = action.payload;
      state.odontogramList = listaOdontograma;
    });
  },
});
export const { setSelectedProf, setSelectedLab } = configSlice.actions;
export default configSlice.reducer;
