import React from 'react';
import { DropdownForm, CheckboxForm } from '../../../../components';
import { useFieldProps } from '../../../../hooks';
import { sentencesAndArticles } from '../../../../mocks/translate';
import { MedicalInsurancesT, TranslationsT } from '#interfaces/general-values';

export const ControllerMedIns: React.FC<{
  medicalInsurances: MedicalInsurancesT[];
  translations: TranslationsT;
  fieldNameCheckAllProf?: string;
  fieldNameAllMedIns: string;
  variant?: string;
}> = ({
  medicalInsurances,
  translations,
  fieldNameCheckAllProf,
  fieldNameAllMedIns,
  variant = 'grid',
}) => {
  const fieldNameMedInsId = 'id_med_ins';
  const {
    field: { value: checkAllMedIns },
  } = useFieldProps({ name: fieldNameAllMedIns });
  const {
    field: { value: checkDisabledMedIns },
  } = useFieldProps({ name: 'show_disabled_med_ins' });
  const { setFieldValue: setCheckAllProf } = useFieldProps({
    name: fieldNameCheckAllProf,
  });
  const { setFieldValue: setFieldValueIdMedIns } = useFieldProps({
    name: fieldNameMedInsId,
  });
  const { setFieldValue: setRadioButton } = useFieldProps({
    name: 'group_by_med_ins',
  });
  const medicalInsurancesFilter = checkDisabledMedIns
    ? medicalInsurances
    : medicalInsurances?.filter((m) => !m.inhabilitada);

  React.useMemo(() => {
    setFieldValueIdMedIns(medicalInsurancesFilter?.[0]?.id_os);
    if (checkAllMedIns) {
      setCheckAllProf(true);
      setFieldValueIdMedIns(null);
      setRadioButton({ medical_insurance: false, professional: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAllMedIns, checkDisabledMedIns]);
  const nameLabels = `${
    sentencesAndArticles.sentenceStarter[translations.medical_insurances]
  } ${sentencesAndArticles.articles[translations.medical_insurances]} ${
    translations.medical_insurances
  }`;

  const variants: {
    [key: string]: { container: string; subcontainer: string };
  } = {
    grid: {
      container: 'grid grid-cols-12 gap-10 mb-6',
      subcontainer: 'col-span-4 self-end',
    },
    flex: {
      container: 'flex flex-col',
      subcontainer: 'p-2.5',
    },
  };

  return (
    <div className={variants[variant].container}>
      <div className={variants[variant].subcontainer}>
        <CheckboxForm
          label={nameLabels}
          small
          name={fieldNameAllMedIns}
          showError={false}
          description={`Se incluirán ${nameLabels.toLocaleLowerCase()} al reporte`}
        />
      </div>
      <div className={variants[variant].subcontainer}>
        <DropdownForm
          name={fieldNameMedInsId}
          data={medicalInsurancesFilter}
          label={translations.medical_insurances}
          keyId="id_os"
          keyData="obra_social"
          disabled={checkAllMedIns}
        />
      </div>
      <div className={variants[variant].subcontainer}>
        <CheckboxForm
          label={`Incluir ${nameLabels} deshabilitados`}
          small
          name="show_disabled_med_ins"
          showError={false}
          description={`Se incluirán los ${nameLabels.toLocaleLowerCase()} deshabilitados/as`}
        />
      </div>
    </div>
  );
};
