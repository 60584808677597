import React from 'react';
import { EmptyState, Modal, TabSection } from '#components/commons';
import { Laboratories } from '#components/Tables';
import { ArticlesFormT } from '#interfaces/tables/article';
import { ArticleForm } from './NewArticle';
import { DeleteArticleForm } from './DeleteArticle';
import { SnackbarContext } from '../../../../../context';
import { useForm, useSetHeight } from '../../../../../hooks';
import { tableArticlesMapper } from '../../../../../utils';

export const ArticlesForm: React.FC<ArticlesFormT> = ({
  selectedLab,
  laboratories,
  openTabAction,
  closeTabAction,
  isOpenTabAction,
  onChange,
  is_usd,
  config,
}) => {
  const { showErrorSnackbar } = React.useContext(SnackbarContext);
  const [articleRow, setArticleRow] = React.useState<any>(false);
  const height = useSetHeight(290);
  const { labo_conceptos: conceptsLaboratory = [] } =
    laboratories?.find((l) => l.id_labo === selectedLab) || {};
  const data = tableArticlesMapper(conceptsLaboratory.slice().reverse());
  const closeOrBackArticleForm = () => {
    setArticleRow(false);
    closeTabAction();
  };
  const {
    isOpenForm: isOpenModal,
    closeForm: onCloseModal,
    openForm: openModal,
  } = useForm();
  const refresh = () => {
    onChange();
    setArticleRow(false);
  };
  const actions = (art: { [key: string]: string }, action: string) => {
    setArticleRow(art);
    if (action === 'delete') {
      return openModal();
    } else if (config.params && !config.user && art?.is_usd) {
      return showErrorSnackbar(
        'El trabajo seleccionado se encuentra en USD y el usuario no puede operar doble moneda.',
      );
    }

    openTabAction();
  };

  return (
    <div className="p-10 overflow-hidden" style={{ height: `${height}px` }}>
      {!isOpenTabAction && (
        <>
          {conceptsLaboratory?.length ? (
            <Laboratories data={data} action={actions} />
          ) : (
            <EmptyState
              title="Ups!"
              copy="El laboratorio no tiene trabajos definidos."
            />
          )}
        </>
      )}
      <TabSection
        title={articleRow ? 'Modificar trabajo' : 'Nuevo trabajo'}
        isOpen={isOpenTabAction}
        close={closeOrBackArticleForm}
        open={openTabAction}
      >
        <ArticleForm
          article={articleRow}
          selectedLab={selectedLab}
          closeOrBackArticleForm={closeOrBackArticleForm}
          onChange={onChange}
          is_usd={is_usd}
        />
      </TabSection>
      <Modal
        title="Eliminar trabajo"
        isOpenModal={isOpenModal}
        closeModal={onCloseModal}
        openModal={openModal}
      >
        <DeleteArticleForm article={articleRow} onChange={refresh} />
      </Modal>
    </div>
  );
};
