import React from 'react';
import classNames from 'classnames';
import { Error } from '../';
import { useOnChange, useFocused, useFieldProps } from '../../hooks';
import { NumericFieldT } from '#interfaces/components';

export const NumericField: React.FC<NumericFieldT> = ({
  name,
  className,
  hint,
  placeholder,
  disabled = false,
  label,
  type = 'number',
  min,
  max,
  refTarget,
}) => {
  const { focus } = useFocused();
  const { field } = useFieldProps({ name });
  const onChange = useOnChange({ name });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target.value;
    if (!value && min !== undefined) {
      onChange(min);
    } else if (max && parseFloat(value) > max) {
      onChange(max);
    } else {
      onChange(value);
    }
  };
  const inputCss = classNames(
    'mt-1.5 relative focus:ring-2 focus:ring-blue-500 shadow-sm block w-full px-4 py-2 text-sm ring-1 ring-gray-300 rounded-md',
    disabled && 'bg-gray-100 text-gray-500 cursor-not-allowed',
  );

  return (
    <div
      onFocus={focus}
      onBlur={focus}
      className={`text-left h-13 text-sm font-medium ${className}`}
      ref={refTarget}
    >
      <label htmlFor="input" className="mb-2 block">
        {label}
      </label>
      <input
        {...field}
        className={inputCss}
        name={name}
        placeholder={placeholder}
        onChange={handleChange}
        value={field.value}
        type={type}
        disabled={disabled}
        min={min}
        max={max}
      />
      {hint && <p className="text-sm text-slate-400 mt-2">{hint}</p>}
      <Error name={name} />
    </div>
  );
};
