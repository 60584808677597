import { axiosInstance, platform } from '../utils';

export function createAuditorLiquidation(params: {
  professionalId: number;
  groupId: number;
  medicalInsuranceId: number;
  isRebilling: boolean;
  period: Date | string;
  isLiquidated: boolean | null | undefined;
  pageNumber: number;
  useProfesionalCode: boolean;
}) {
  const body = {
    ServicesToAudit: {
      ...params,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/liquidations/auditor', body);
}

export function generateAuditorDocument(params: {
  professionalId: number;
  groupId: number;
  medicalInsuranceId: number;
  isRebilling: boolean;
  period: Date | string;
  isLiquidated: boolean | null | undefined;
  pageNumber: number;
  useProfesionalCode: boolean;
}) {
  const body = {
    ServicesToAudit: {
      ...params,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/liquidations/auditor/upload', body);
}

export function closeAuditorLiquidation(params: {
  professionalId: number;
  groupId: number;
  medicalInsuranceId: number;
  isRebilling: boolean;
  period: Date | string;
  isLiquidated: boolean | null | undefined;
  pageNumber: number;
  useProfesionalCode: boolean;
}) {
  const body = {
    ServicesToAudit: {
      ...params,
    },
    plataforma: platform,
  };

  return axiosInstance.post('/liquidations/auditor/close', body);
}
