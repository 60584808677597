import React from 'react';
import { Formik } from 'formik';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Resize,
  Toolbar,
  Sort,
  Filter,
} from '@syncfusion/ej2-react-grids';
import { useForm } from './use-form';
import { SuccesFull as Empty, Form } from '../../../../components';
import { CardHeader } from '../NewBudget/card-header.tsx';
import { budgetPaymentsGridMapper } from '../../../../utils';
import { emptyStateBudgetPayments } from '../../../../mocks';

export const ViewBudgetPayments: React.FC<{
  budget: { [key: string]: any };
  payments: { [key: string]: any }[];
}> = ({ budget, payments }) => {
  const grid = React.useRef(null);
  const { initialValues } = useForm({
    budget,
  });
  const setFormHight = React.useMemo(() => {
    let height;
    if (window && window.innerHeight > 560) {
      const rest = window.innerHeight - 404;
      const paymentsHeight = payments.length * 34 + 34;

      if (paymentsHeight > rest) {
        height = window.innerHeight - 560;
      } else {
        height = 'auto';
      }
    }

    return height;
  }, [payments.length]);
  const professional = budget?.id_profNavigation?.nombre || '';

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={() => undefined}
    >
      <Form showActions={false}>
        <CardHeader professionalName={professional} />
        {payments && payments.length ? (
          <div className="mt-10">
            <GridComponent
              ref={grid}
              dataSource={budgetPaymentsGridMapper(payments)}
              pageSettings={{ pageSize: 50 }}
              allowResizing
              allowSorting
              allowFiltering
              height={setFormHight}
            >
              <Inject services={[Resize, Toolbar, Sort, Filter]} />
              <ColumnsDirective>
                <ColumnDirective
                  field="column"
                  visible={true}
                  width="3"
                  allowResizing={false}
                />
                <ColumnDirective field="id_payment" visible={false} />
                <ColumnDirective
                  field="id_budget"
                  headerText="Nro Presupuesto"
                  width="100"
                />
                <ColumnDirective field="date" headerText="Fecha" width="100" />
                <ColumnDirective field="description" headerText="Descripción" />
                <ColumnDirective
                  field="professional"
                  headerText="Profesional"
                  width="150"
                />
                <ColumnDirective
                  field="payment_document"
                  headerText="Comprobante"
                  width="90"
                />
                <ColumnDirective
                  field="payment_type"
                  headerText="Tipo"
                  width="60"
                />
                <ColumnDirective
                  field="number"
                  headerText="Número"
                  width="60"
                />
                <ColumnDirective field="method" headerText="Forma" width="60" />
                <ColumnDirective
                  field="amount_paid"
                  headerText="Importe pagado"
                  width="60"
                />
                <ColumnDirective field="tax" headerText="% IVA" width="60" />
              </ColumnsDirective>
            </GridComponent>
          </div>
        ) : (
          <Empty
            typeSucces="emptyState"
            className="mt-10"
            dataMocks={emptyStateBudgetPayments}
          />
        )}
      </Form>
    </Formik>
  );
};
