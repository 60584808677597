import { useState, useEffect, useCallback } from 'react';

export function useForm(holiday: { [key: string]: any } | boolean) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues(holiday),
  );

  useEffect(() => {
    setInitialValues(getInitialValues(holiday));
  }, [holiday]);

  const validate = useCallback((values: { [key: string]: any }) => {
    const errors: { [key: string]: any } = {};
    const requiredMsg = 'Campo requerido';
    const requiredFields = ['from', 'to'];
    requiredFields.forEach((requiredField) => {
      if (!values[requiredField]) {
        errors[requiredField] = requiredMsg;
      }
    });

    return errors;
  }, []);

  return { initialValues, validate };
}

function getInitialValues(holiday: { [key: string]: any } | boolean) {
  const { id, id_prof, to, from, rowversion } =
    holiday && typeof holiday === 'object'
      ? holiday
      : {
          id: null,
          id_prof: null,
          from: new Date(),
          to: new Date(),
          rowversion: undefined,
        };

  return {
    id,
    id_prof,
    from,
    to,
    rowversion,
  };
}
