import React, { useState } from 'react';
import classNames from 'classnames';
import ReactTooltip, { Place, Type } from 'react-tooltip';
import { TooltipT } from '#interfaces/components/commons';

export const Tooltip: React.FC<TooltipT> = ({
  id,
  className = '',
  children,
  text,
  type = 'dark',
  align = 'bottom',
  offset,
  html,
  delay = 0,
}) => {
  const [focused, setFocused] = useState(false);
  const [randomID] = useState(String(Math.random()));
  const isFocused = () => setFocused(!focused);
  const labelCss = classNames(className);

  return (
    <label className={labelCss} id={id} onFocus={isFocused} onBlur={isFocused}>
      <div>
        <div data-for={randomID} data-tip={text} data-html={html}>
          {children}
        </div>
        <ReactTooltip
          id={randomID}
          place={align as Place}
          type={type as Type}
          effect="solid"
          backgroundColor="#000"
          delayShow={delay}
          offset={offset || { top: 2, right: 10 }}
        />
      </div>
    </label>
  );
};
