import React, { useState } from 'react';
import { AgendaT, OfficesT } from '#interfaces/general-values';

export function useForm(offices: OfficesT[], reminders: AgendaT[]) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues(offices, reminders),
  );

  React.useEffect(() => {
    setInitialValues(getInitialValues(offices, reminders));
  }, [reminders]);
  return { initialValues };
}

function getInitialValues(offices: OfficesT[], reminders: AgendaT[]) {
  const office = offices && offices[0];

  return {
    date: new Date(),
    office: office?.id_sucursal ?? 0,
    professional: -1,
    force_refresh: true,
    turns_to_send: reminders || [],
    send_all: false,
  };
}
