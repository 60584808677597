import { useEffect, useState } from 'react';

export function useForm(
  laboratories: { [key: string]: any }[],
  selectedLab: { [key: string]: any },
) {
  const [initialValues, setInitialValues] = useState(() =>
    getInitialValues(laboratories, selectedLab),
  );
  useEffect(() => {
    setInitialValues(getInitialValues(laboratories, selectedLab));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [laboratories]);

  return { initialValues };
}

function getInitialValues(
  laboratories: { [key: string]: any }[],
  selectedLab: { [key: string]: any },
) {
  return {
    laboratory:
      selectedLab || laboratories?.filter((el) => el.habilitado)?.[0]?.id_labo,
    no_schedule: false,
    show_enabled: false,
  };
}
