import React from 'react';
import { Form, Formik } from 'formik';
import { useForm } from './use-form';
import { useSubmit } from './use-submit';
import { CardDetailTooth } from './CardDetailTooth';
import { AmountsBar } from './amount-bar';
import { Button, Title } from '#components/index';
import { PerformanceIndicatorT } from '#interfaces/components';

export const PerformanceIndicator: React.FC<PerformanceIndicatorT> = ({
  userId,
  benefit = null,
  patientId,
  hiddenView,
  budget,
  benefits,
  medicalInsurances,
  onChange,
}) => {
  const { benefit_code, description, selected_quantity } = benefit || {};
  const findMedicalInsurance = medicalInsurances.find(
    (item: any) => item.id_os === budget?.id_planNavigation.id_os,
  );

  const { initialValues, validate } = useForm({
    userId,
    benefit,
    patientId,
    budget,
    findMedicalInsurance,
  });

  const validationFormTooths =
    ['DIE', 'ALG'].includes(benefit?.needs_thoot || '') || benefit?.thoot_face;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={useSubmit({
        hiddenView,
        budgetServiceid: benefit?.id_budget_benefit || 0,
        onChange,
        budget,
      })}
      validateOnChange
      enableReinitialize
      // validate={validate} //TODO:REVISAR
    >
      {({ submitForm, isSubmitting, isValid }) => (
        <Form>
          <p className="text-base pb-5">
            {validationFormTooths && (
              <>
                Selecciona los dientes, secciones y caras que requiera tu
                prestación para ser incluida en el odontograma.
                <br />
              </>
            )}
            El valor del importe unitario final está compuesto por el valor del
            importe unitario más los intereses menos los descuentos.
          </p>
          <Title className="text-2xl pb-5">{`${benefit_code} - ${description}`}</Title>
          <AmountsBar />
          {validationFormTooths ? (
            <CardDetailTooth
              selectedQuantity={selected_quantity || 1}
              benefits={benefits}
            />
          ) : (
            <div className="mb-12 mt-80 text-slate-600 border-b border-b-slate-200">
              Esta prestación no requiere indicar diente, sector o cara.
            </div>
          )}
          <Button
            size="large"
            onClick={submitForm}
            disabled={isSubmitting || !isValid}
            loading={isSubmitting}
          >
            GUARDAR
          </Button>
        </Form>
      )}
    </Formik>
  );
};
