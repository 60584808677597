import React from 'react';
import { getValuesToPay } from '../services';
import { useDefaultValuesToPayT } from '#interfaces/hooks';
const initialState = {
  medicalInsurance: {
    amount: 0,
    percentage: 0,
  },
  patient: {
    amount: 0,
    percentage: 0,
  },
};

export const useDefaultValuesToPay = ({
  professionalId,
  benefitType,
  valueToChargeFromPatient,
}: useDefaultValuesToPayT) => {
  const [defaultValuesToPay, setDefaultValuesToPay] =
    React.useState(initialState);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setDefaultValuesToPay(initialState);
  }, [
    professionalId,
    benefitType,
    valueToChargeFromPatient,
    setDefaultValuesToPay,
  ]);

  const updateDefaultValuesToPay = async () => {
    setLoading(true);
    try {
      const {
        data: {
          listaValoresAPagar: [apiValues],
        },
      } = await getValuesToPay({
        professionalId,
        benefitType,
        valueToChargeFromPatient,
      });

      setDefaultValuesToPay(transformApiValues(apiValues));
    } catch (err: any) {
      setDefaultValuesToPay(initialState);
    }
    setLoading(false);
  };

  return {
    defaultValuesToPay,
    updateDefaultValuesToPay,
    loadingDefaultValuesToPay: loading,
  };
};

function transformApiValues(apiValues: { [key: string]: any }) {
  const medicalInsurance =
    apiValues.porcOsAPagar !== -1
      ? {
          amount: 0,
          percentage: apiValues.porcOsAPagar,
        }
      : {
          amount: apiValues.valorOsAPagar,
          percentage: 0,
        };

  const patient =
    apiValues.porcPagoAPagar !== -1
      ? {
          amount: 0,
          percentage: apiValues.porcCosegAPagar,
        }
      : {
          amount: apiValues.valorCosegAPagar,
          percentage: 0,
        };

  return {
    medicalInsurance,
    patient,
  };
}
