import React from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import { Columns } from './columns';
import { Data } from './data';
import { tablePatientRecipesMapper } from '../../../utils';

export const Table: React.FC<any> = ({
  data,
  action,
  layout,
  setLayout,
  sorting,
  setSorting,
  showPack,
}) => {
  return (
    <div className="mx-auto w-full">
      <Tabs.Root
        defaultValue={layout}
        onValueChange={(layout) => setLayout(layout)}
      >
        {['expanded', 'compact'].map((value: string, index: number) => (
          <Tabs.Content value={value} key={index}>
            <Data
              columns={Columns({
                action,
                value,
                offices: data?.offices,
                permissions: data?.permissions,
              })}
              data={tablePatientRecipesMapper(data)}
              sorting={sorting}
              setSorting={setSorting}
              showPack={showPack}
            />
          </Tabs.Content>
        ))}
      </Tabs.Root>
    </div>
  );
};
