import * as React from 'react';
import { ArrowUpDown } from 'lucide-react';
import { Icons } from '#components/commons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '#components/shadcn';

type ColumnT = {
  toggleSorting: (isSorted: boolean) => void;
  getIsSorted: () => 'asc' | 'desc' | undefined;
};
type ColumnsT = {
  accessorKey: string;
  header?: string | (({ column }: { column: ColumnT }) => JSX.Element) | any;
  cell: ({ row }: { row: any }) => any;
  enableHiding?: boolean;
  id?: string;
};

export const Columns = ({ action, value, offices, permissions }: any) => {
  const { view, download, to_emit, isSupervisor } = permissions;
  const columns: ColumnsT[] = [
    {
      id: 'action',
      accessorKey: '',
      enableHiding: false,
      cell: ({ row }: { row: any }) => {
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="relative p-2 hover:cursor-pointer flex-none">
                <Icons
                  type="context"
                  className="text-gray-500 hover:text-gray-900 h-4 w-4"
                />
                <span className="sr-only">Abrir menu</span>
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56" align="start">
              <DropdownMenuGroup>
                {(isSupervisor || (view && to_emit && download)) && (
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    onSelect={() => action(row.original, 'view_details')}
                  >
                    <Icons type="edit" className="mr-2 h-4 w-4" />
                    <span>Ver detalles</span>
                  </DropdownMenuItem>
                )}
                {(isSupervisor || download) && (
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    onSelect={() => action(row.original, 'download_pdf')}
                  >
                    <Icons type="download" className="mr-2 h-4 w-4" />
                    <span>Descargar pdf</span>
                  </DropdownMenuItem>
                )}
                {(isSupervisor || download) && (
                  <DropdownMenuItem
                    className="hover:cursor-pointer"
                    onSelect={() => action(row.original, 'send')}
                  >
                    <Icons
                      type="whatsapp"
                      className="mr-2 h-4 w-4"
                      fill="#35c13e"
                    />
                    <span>Enviar link</span>
                  </DropdownMenuItem>
                )}
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
    {
      accessorKey: 'date_recipe',
      header: ({ column }: { column: ColumnT }) => {
        return (
          <p
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
            className="flex items-center text-left align-middle font-medium text-slate-400 hover:cursor-pointer hover:text-slate-600"
          >
            Fecha
            <ArrowUpDown className="ml-2 h-4 w-4 text-slate-400" />
          </p>
        );
      },
      cell: ({ row }: { row: any }) => {
        const { date_recipe } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-900">
            {date_recipe}
          </p>
        );
      },
    },
    {
      accessorKey: 'professional',
      header: 'Profesional',
      cell: ({ row }: { row: any }) => {
        const { professional } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {professional}
          </p>
        );
      },
    },
    {
      accessorKey: 'medications',
      header: 'Medicamentos',
      cell: ({ row }: { row: any }) => {
        const { medications } = row.original;

        return value === 'expanded' ? (
          <ul className="list-disc">
            {medications?.map((m: any, index: number) => (
              <li key={index}>
                <div className="flex gap-x-2">
                  <p className="font-bold text-sm leading-6 text-slate-900 uppercase">
                    {m.desc.split('(')[0]}
                  </p>
                  <p className="font-bold text-sm leading-6 text-gray-500">
                    ({m.desc.split('(')[1]}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="font-bold text-sm leading-6 text-slate-900 uppercase">
            {medications.length}
          </p>
        );
      },
    },
    {
      accessorKey: 'office',
      header: 'Sucursal',
      cell: ({ row }: { row: any }) => {
        const { office } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">{office}</p>
        );
      },
    },
    {
      accessorKey: 'diagnosis',
      header: 'Diagnostico general',
      cell: ({ row }: { row: any }) => {
        const { diagnosis } = row.original;
        return (
          <p className="font-bold text-sm leading-6 text-gray-500">
            {diagnosis}
          </p>
        );
      },
    },
  ];
  !(offices?.length > 0) && columns.splice(4, 1);

  return columns;
};
