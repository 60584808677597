import React from 'react';

export const useForm = (defaultOpened?: boolean) => {
  const [isOpenForm, setIsOpenForm] = React.useState(defaultOpened || false);
  const openForm = React.useCallback(() => {
    setIsOpenForm(true);
  }, [setIsOpenForm]);
  const closeForm = React.useCallback(() => {
    setIsOpenForm(false);
  }, [setIsOpenForm]);

  return { isOpenForm, openForm, closeForm };
};
