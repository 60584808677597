import React from 'react';
import { Formik } from 'formik';
import { useSubmit } from './use-submit';
import { Form } from '../../../../components';
import { DeleteHcFormT } from '#interfaces/components';

export const DeleteHcForm: React.FC<DeleteHcFormT> = ({
  selectedHc,
  patientId,
}) => {
  return (
    <Formik initialValues={{}} onSubmit={useSubmit({ selectedHc, patientId })}>
      <Form submitTitle="Aceptar" ignoreDirty>
        <p className="text-base font-semibold text-slate-500 mb-5">
          Estás por eliminar una historia clínica y esta acción es irreversible,
          la historia clínica se eliminará definitivamente.
        </p>
      </Form>
    </Formik>
  );
};
