import React from 'react';
import { format } from 'date-fns';
import { Icons, Title } from '#components/commons';
import classNames from 'classnames';

export const Header = ({
  data,
}: {
  data: {
    [key: string]: {
      professionalName: string;
      dateRecipe: Date;
      handlerClickProgressBar: (value: number) => void;
      recipe: boolean;
      officeName: string;
      affiliateNumber: string;
      plan: string;
      medInsName: string;
    };
  };
}) => {
  const {
    professionalName,
    dateRecipe,
    handlerClickProgressBar,
    recipe,
    officeName,
    affiliateNumber,
    plan,
    medInsName,
  } = data['header'];
  const sections: any = [
    !recipe && {
      title: 'Obra social:',
      value: medInsName,
    },
    !recipe && {
      title: 'Plan:',
      value: plan,
    },
    !recipe && {
      title: 'Nro de afiliado:',
      value: affiliateNumber,
    },
    {
      title: 'Profesional:',
      value: professionalName,
    },
    {
      title: 'Fecha de receta:',
      value: format(dateRecipe, 'dd/MM/yyyy'),
    },
    !recipe &&
      officeName && {
        title: 'Sucursal:',
        value: officeName,
      },
  ].filter(Boolean);

  return (
    <div className=" shadow-md rounded-md bg-white">
      <Title className="border-b border-b-slate-300 p-5 text-2xl rounded-t-md bg-white truncate">
        Datos de la receta
      </Title>
      <div className="grid grid-cols-12 p-6 pb-0 border-b border-b-slate-300">
        {sections.map((section: any) => (
          <div className="col-span-4 flex items-center space-x-1 pb-4">
            <h3 className="text-slate-500 text-base">{section?.title}</h3>
            <Title>{section?.value}</Title>
          </div>
        ))}
      </div>
      <p
        className={classNames(
          'group flex text-base text-blue-500 justify-between p-4 px-8 border-t border-t-slate-300 hover:cursor-pointer',
          { 'text-gray-400 cursor-not-allowed pointer-events-none': recipe },
        )}
        onClick={() => handlerClickProgressBar(0)}
      >
        Editar
        <Icons
          type="arrow_right"
          className={classNames(
            'h-5 text-sm text-blue-500 transition-transform duration-500 group-hover:translate-x-3',
            {
              'text-gray-400 cursor-not-allowed': recipe,
            },
          )}
        />
      </p>
    </div>
  );
};
